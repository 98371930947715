import React, { useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import NavBar from "../NavBar/Index";
import { Button, TextField, Typography, FormControlLabel } from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Checkbox from "@mui/material/Checkbox";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
import AadharVerification from "./BasicDetailsPages/AadharVerification";
import { useSelector, useDispatch } from "react-redux";
import Alert from "@mui/material/Alert";
import SessionModal from "./../../Common/SessionModel";
import GenInsight from "../GenInsight/GenInsight";
import Snackbar from "@material-ui/core/Snackbar";
import MobileOTPVerification2 from "../../Common/mobileOtpValidationDigitalLoans";
import EmailOTPVerification from "../../Common/emailOTPValidation";
import EmailOTPVerificationCoborrower from "../../Common/emailOTPValidationCoborrower";
import {
  AadharDrawer,
  SAF,
  CompanyDetails,
  SaveDetails,
  SaveAadhar,
  saveguarantorFileUpload,
  saveguarantorDetails,
  ConfirmAllDilaog,
  TopbarViewSteps,
  GetLoanDetails,
  PanAdvance,
  VerifyOTPClearData,
  uploadIdentificationCard,
  getTenure,
  getMaritaldropdown,
  GetLoanProductType,
  isLoanJourneyPending,
  sendverificationCode,
  sendverificationCodeDigital,
  navigateFromLogin,
  verifyPan,
  sendverificationCodeEmail,
  aadharDrawerCoborrower1,
  verifyPanCoborrowwer,
  GenOTPClearData,
  InitiateOTPClearData,
  sendverificationCodeEmailCoborrower,
  VerifyOTPClearDataCoborrower,
} from "../../Components/BasicDetails/BasicDetailsActions/BasicDetailsActions";
import MenuItem from "@mui/material/MenuItem";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import DoneIcon from "@mui/icons-material/Done";
import { v4 as uuidv4 } from "uuid";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogView from "./BasicDetailsPages/DialogView";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Footer from "./../../Common/Footer";
import ErrorModel from "./../../Common/ErrorMessages";
import { useState } from "react";
import UploadIcon from "@mui/icons-material/Upload";
import ResumeJourney from "./../../Common/PopupResumeJourney";
import TYPES from "./BasicDetailsTypes/BasicDetailsTypes";
import AadharVerificationCoborrower from "./BasicDetailsPages/AadharVerificationCoborrower";
function Index2() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const aadharDrawer = useSelector(
    (state) => state.BasicDetailsReducers.aadharDrawer
  );
  const aadharDrawerCoborrower = useSelector(
    (state) => state.BasicDetailsReducers.aadharDrawerCoborrower
  );
  //-----------------------------------------------------------------------

  const safLoading = useSelector(
    (state) => state.BasicDetailsReducers.safLoading
  );

  //-----------------------------------------------------------------------

  const safSuccess = useSelector(
    (state) => state.BasicDetailsReducers.safSuccess
  );
  const safResponse = useSelector(
    (state) => state.BasicDetailsReducers.safResponse
  );
  const companyDataLoaded = useSelector(
    (state) => state.BasicDetailsReducers.companyDataLoaded
  );

  const saf_response_data = safResponse ? safResponse.data : [];

  const loanStatus = saf_response_data ? saf_response_data.LoanStatus : "";

  const saf_message = safResponse ? safResponse.message : "";
  const verifyOTPMobileSuccess = useSelector(
    (state) => state.BasicDetailsReducers.verifyOTPMobileSuccess
  );
  const verifyOTPMobileDigitalLoansSuccess = useSelector(
    (state) => state.BasicDetailsReducers.verifyOTPMobileDigitalLoansSuccess
  );
  const verifyOTPEmailSuccess = useSelector(
    (state) => state.BasicDetailsReducers.verifyOTPEmailSuccess
  );
  const verifyOTPEmailCoborrowerSuccess = useSelector(
    (state) => state.BasicDetailsReducers.verifyOTPEmailCoborrowerSuccess
  );
  const verifyPanSuccess = useSelector(
    (state) => state.BasicDetailsReducers.verifyPanSuccess
  );
  const panDetails = useSelector(
    (state) => state.BasicDetailsReducers.panDetails
  );
  const verifyPanFail = useSelector(
    (state) => state.BasicDetailsReducers.verifyPanFail
  );

  const panDetailsCoborrower = useSelector(
    (state) => state.BasicDetailsReducers.panDetailsCoborrower
  );
  const verifyPanSuccessCoborrower = useSelector(
    (state) => state.BasicDetailsReducers.verifyPanSuccessCoborrower
  );
  //----------------------------------------------------------------------
  const [selectedAadhar, setselectedAadhar] = React.useState();
  const [selectedAadharCoborrower, setselectedAadharCoborrower] =
    React.useState();
  const [currentAadhar, setcurrentAadhar] = React.useState("");
  const [employeeCode, setemployeeCode] = React.useState("");
  const [erroremployeeCode, setErroremployeeCode] = useState(false);
  const [currentCoborrowerSelected, setcurrentCoborrowerSelected] =
    React.useState("");
  const [coborrowerEmail, setcoborrowerEmail] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [isCoBorrower, setIsCoBorrower] = React.useState(false);
  const [isCoBorrowerAadharVerify, setisCoBorrowerAadharVerify] =
    React.useState(false);
  const [isaadharChecked, setisaadharChecked] = React.useState(false);
  const [isApiLoading, setisApiLoading] = React.useState(false);
  const [emailAlreadyVerified, setemailAlreadyVerified] = React.useState(false);
  const [isMobileVerificationChecked, setisMobileVerificationChecked] =
    React.useState(false);
  const [isEmailVerificationChecked, setisEmailVerificationChecked] =
    React.useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [invalidInput, setinvalidInput] = useState(false);
  const [isDirectorAadharValidated, setisDirectorAadharValidated] =
    React.useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isGuarantorAadharValidated, setisGuarantorAadharValidated] =
    React.useState(false);
  const inputRef = useRef(null);
  //----------------------------------------------------------------------
  const handleAadharClick = (aadhaar) => {
    setisaadharChecked(!isaadharChecked);
    if (isaadharChecked == false) {
      localStorage.removeItem("LoanApplicationId");
      setselectedAadhar(aadhaar);
      setIsCoBorrower(false);
      dispatch(AadharDrawer(!aadharDrawer));
    }
  };
  const [openOTPDialog, setOpenOTPDialog] = useState(false);
  const [openOTPEmailDialog, setOpenOTPEmailDialog] = useState(false);
  const [openOTPEmailDialogCoborrower, setopenOTPEmailDialogCoborrower] =
    useState(false);
  const phoneno = localStorage.getItem("EmailId");
  const handleOpenOTPDialog = async () => {
    setisMobileVerificationChecked(!isMobileVerificationChecked);
    if (!isMobileVerificationChecked) {
      try {
        setisApiLoading(true);
        const result = await sendverificationCodeDigital(loadPersonalDetails.phoneNo);
        if (result.data.response_message == "SUCCESS") {
          setOpenOTPDialog(true);
          setisApiLoading(false);
        }
        if (result.data.response_message == "FAILED") {
          setPhoneNoError("OTP verification failed. Please try again");
          setisApiLoading(false);
        }
      } catch (e) {
        console.log(e);
        setPhoneNoError("OTP verification failed. Please try again");
        setisApiLoading(false);
      }
    }
  };

  const handleCloseOTPDialog = () => {
    setOpenOTPDialog(false);
  };
  const handleCloseCoborrowerAadhar = () => {
    isCoBorrowerAadharVerify(false);
  };

  useEffect(() => {
    if (verifyOTPMobileDigitalLoansSuccess?.response_message == "SUCCESS") {
      if(employmentTypeValue == 1 ||
        employmentTypeValue == 2 ||
        employmentTypeValue == 7){
          savePersonalDetails();
        }
      else{
        handleLoanDetails();
      }
    }
  }, [verifyOTPMobileDigitalLoansSuccess]);


  const handleCloseEmailOTPDialogCoborrower = () => {
    setopenOTPEmailDialogCoborrower(false);
  };
  const handleOpenEmailOTPDialogCoborrower = async () => {
    setisEmailVerificationChecked(!isEmailVerificationChecked);
    if (!isEmailVerificationChecked) {
      try {
        setisApiLoading(true);
        const result = await sendverificationCodeEmail();
        if (result?.data?.response_message?.startsWith("SUCCESS")) {
          setemailAlreadyVerified(true);
          setisApiLoading(false);
        } else {
          setopenOTPEmailDialogCoborrower(true);
          setisApiLoading(false);
        }
        if (result.data.response_message == "FAILED") {
          setisApiLoading(false);
        }
      } catch (e) {
        console.log(e);
        setisApiLoading(false);
      }
    }
  };
  const handleCloseEmailOTPDialog = () => {
    setOpenOTPEmailDialog(false);
  };
  //-----------------------------------------------------------------------
  const handleAadharClickDirector = (aadhaar) => {
    setcurrentConext("director");
    setcurrentAadhar(aadhaar);
    setselectedAadhar(aadhaar);
    dispatch(AadharDrawer(!aadharDrawer));
  };
  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setinvalidInput(false);
    setIncompleteAadharDetails(false);
  };
  const handleAadharClickGuarantor = (aadhaar) => {
    setcurrentConext("guarantor");
    setcurrentAadhar(aadhaar);
    setselectedAadhar(aadhaar);
    setIsCoBorrower(true);
    dispatch(AadharDrawer(!aadharDrawer));
  };
  const handleLogoutButton = () => {
    localStorage.removeItem("jwt_token");
    localStorage.removeItem("EmailId");
    localStorage.removeItem("branch");
    setShowAlert(true);
  };
  const handleDateOfBirthChange = (e) => {
    const inputDate = e.target.value;
    handlePersonalDetails(e);
    const dateFormatRegex =
      /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;

    if (inputDate === "" || dateFormatRegex.test(inputDate)) {
      // Valid date format, clear error message
      setErrorMessage("");
      handlePersonalDetails(e);
    } else {
      // Invalid date format, set error message
      setErrorMessage("Please enter a valid date in the format DD/MM/YYYY");
    }
  };
  const employmentType = [
    {
      label: "Salaried",
      value: 1,
    },
    {
      label: "Self Employed Professional",
      value: 2,
    },
    {
      label: "Proprietorship",
      value: 7,
    },
    {
      label: "Private Limited",
      value: 3,
    },
    {
      label: "Partnership",
      value: 4,
    },
    {
      label: "Public Limited",
      value: 5,
    },
    {
      label: "LLP",
      value: 6,
    },
  ];
  const ownProperty = [
    {
      label: "Yes",
      value: "true",
    },
    {
      label: "No",
      value: "false",
    },
  ];
  const natureOfBusiness = [
    {
      label: "Retail Trade",
      value: "Retail Trade",
    },
    {
      label: "Wholesale Trade",
      value: "Wholesale Trade",
    },
    {
      label: "Food Business",
      value: "Food Business",
    },
    {
      label: "E-commerce",
      value: "E-commerce",
    },
    {
      label: "Consulting Services",
      value: "Consulting Services",
    },
    {
      label: "Freelancing",
      value: "Freelancing",
    },
    {
      label: "Real Estate",
      value: "Real Estate",
    },
    {
      label: "Beauty and Wellness",
      value: "Beauty and Wellness",
    },
    {
      label: "Manufacturing",
      value: "Manufacturing",
    },
    {
      label: "Tourism and Hospitality",
      value: "Tourism and Hospitality",
    },
    {
      label: "Education and Training",
      value: "Education and Training",
    },
    {
      label: "Event Management",
      value: "Event Management",
    },
    {
      label: "Import-Export",
      value: "Import-Export",
    },
    {
      label: "Agriculture and Farming",
      value: "Agriculture and Farming",
    },
    {
      label: "Construction",
      value: "Construction",
    },
    {
      label: "Contracting",
      value: "Contracting",
    },
    {
      label: "Healthcare Services",
      value: "Healthcare Services",
    },
    {
      label: "Transport and Logistics",
      value: "Transport and Logistics",
    },
    {
      label: "Renewable Energy",
      value: "Renewable Energy",
    },
    {
      label: "Financial Services",
      value: "Financial Services",
    },
    {
      label: "Arts and Crafts",
      value: "Arts and Crafts",
    },
    {
      label: "Retail and Shopping",
      value: "Retail and Shopping",
    },
  ];
  const guarantorDocument = [
    {
      label: "Bank Statement",
      value: "Bank Statement",
    },
    {
      label: "ITR Document",
      value: "ITR Document",
    },
  ];
  const [selectedGuaratorDocument, setselectedGuaratorDocument] =
    React.useState("");
  const [loanProductList, setLoanProductList] = React.useState([]);
  const [isPANchecked, setisPANchecked] = React.useState(false);
  const [panErrorCategory, setpanErrorCategory] = React.useState(false);
  const [panMessage, setpanMessage] = React.useState("");
  const [panErrorTitle, setpanErrorTitle] = React.useState("");
  const [panErrorLoading, setpanErrorLoading] = React.useState(false);
  const [loanPendingStatus, setloanPendingStatus] = React.useState(false);
  const [iscreditbureauChecked, setiscreditbureauChecked] =
    React.useState(false);
  const [iscreditbureauCheckedDirector, setiscreditbureauCheckedDirector] =
    React.useState(false);
  const [loadCompanyDetails, setCompanyDetails] = React.useState({
    companyName: "",
    dateofIncorportation: "",
    nature: "",
    addressline: "",
    landMark: "",
    city: "",
    pincode: "",
    state: "",
    registration_number: "",
  });
  const [loadPersonalDetails, setloadPersonalDetails] = React.useState({
    name: "",
    annualSalary: "",
    dateofBirth: "",
    phoneNo: phoneno,
    email: "",
    addharCard: "",
    shopName: "",
    shopAddress: "",
    landmark: "",
    mothersName: "",
    fathersName: "",  //Change
  });
  const handleCompanyDetails = (e) => {
    setCompanyDetails({
      ...loadCompanyDetails,
      [e.target.name]: e.target.value,
    });
  };
  const handlePersonalDetails = (e) => {
    setloadPersonalDetails({
      ...loadPersonalDetails,
      [e.target.name]: e.target.value,
    });
  };
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const [employmentTypeValue, setEmploymentTypeValue] = React.useState("");
  const [hasOwnPropertySelected, sethasOwnPropertySelected] =
    React.useState("");
  const handleChangeOwnProperty = (e) => {
    sethasOwnPropertySelected(e);
  };
  const handleChangeemploymentType = async (e) => {
    setEmploymentTypeValue(e);
    const response = await GetLoanProductType(e, handleLogoutButton);
    console.log("response is", response?.data?.response_data);
    const transformedJson = response?.data?.response_data.map((item) => ({
      label: item.display_name,
      value: item.id,
      isDisabled: item.is_active === 0 ? true : false,
    }));

    console.log(transformedJson);
    setLoanProductList(transformedJson);
  };
  const handleGauarantorDocument = (e) => {
    setselectedGuaratorDocument(e);
  };
  const [loanProductOption, setLoanProductOption] = React.useState();
  const handleLoanProductOption = (e) => {
    setLoanProductOption(e);
  };

  //--------------------------------------------------------------------------

  const [collateral, setCollateral] = React.useState();

  const handleCollateral = (e) => {
    setCollateral(e);
  };

  const [CIN, setCIN] = React.useState();

  const handleCIN = (e) => {
    setCIN(e);
  };

  //-------------------------------------------------------------------------

  const [panError, setPanError] = React.useState(false);
  const [pan, setPan] = React.useState("");

  //User Pan - Validation
  const HandlePanChange = (e) => {
    setPan(e);
    var regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    if (regpan.test(e)) {
      setPanError(false);
    } else {
      setPanError(true);
    }
    dispatch({ type: TYPES.VERIFY_PAN_CLEAR });
  };

  //------------------------------------------------------------------------

  const [amountError, setAmountEroor] = React.useState(false);
  const [isGuarantorDetailsCompleted, setisGuarantorDetailsCompleted] =
    React.useState(false);
  const [loanAmount, setLoanAmount] = React.useState();

  //User Loan Amount
  const HandleLoanAmountChange = (e) => {
    if (e >= 10000 && e <= 50000000) {
      setLoanAmount(e);
      setAmountEroor(false);
    } else {
      setLoanAmount(e);
      setAmountEroor(true);
    }
  };

  //-----------------------------------------------------------------------
  const [loantenureError, setLoanTenureError] = React.useState(false);
  const [confirmedPersonalDetails, setconfirmedPersonalDetails] =
    React.useState(false);
  const [loanTenure, setLoanTenure] = React.useState();
  const [maritalStatusSelected, setmaritalStatusSelected] = React.useState();
  //User Loan Tenure
  const HandleLoanTenureChange = (e) => {
    if (e >= 3 && e <= 360) {
      setLoanTenureError(false);
      setLoanTenure(e);
    } else {
      setLoanTenureError(true);
      setLoanTenure(e);
    }
  };

  //-----------------------------------------------------------------------
  const handleLoanDetails = async () => {
    let data = [];
    console.log(lists);
    if (
      loanProductOption == 1 ||
      loanProductOption == 4 ||
      loanProductOption == 5 ||
      loanProductOption == 7 ||
      loanProductOption == 10
    ) {
      if (lists.length > 0) {
        lists.forEach((item) => {
          if (
            !item.isadharDisabled ||
            !item.ispanDisabled ||
            !item.isemailDisabled
          ) {
            setinvalidInput(true);
            return;
            // You can break the loop if you only want to log once
            // break;
          }
        });
        data = lists.map((item) => ({
          aadharData: item.adharData,
          panData: item.panData,
          email: item.email,
        }));
      }
    }
    if (
      !verifyPanSuccess ||
      !iscreditbureauChecked ||
      pan == "" ||
      panError ||
      loanStatus != ""
    ) {
      setinvalidInput(true);
      return;
    }
    try {
      // setpanErrorLoading(true);
      // const results = await PanAdvance(pan, handleLogoutButton);
      // setpanErrorLoading(false);
      // if (
      //   results.data.data.category.toLowerCase() == "company" ||
      //   results.data.data.category.toLowerCase() == "trust" ||
      //   results.data.data.category.toLowerCase() == "firm"
      // ) {
      dispatch(CompanyDetails(CIN));
      dispatch(
        SAF(
          employmentTypeValue,
          pan,
          "",
          loanProductOption,
          loanAmount,
          loanTenure,
          "",
          "",
          "",
          true,
          true,
          true,
          collateral,
          CIN,
          "",
          "",
          hasOwnPropertySelected,
          panDetails,
          data,
          employeeCode,
          handleLogoutButton
        )
      );
      dispatch(VerifyOTPClearData());
      // }
      // else {
      //   setpanErrorTitle("PAN Type Mismatch");
      //   setpanMessage(
      //     "Your PAN type is Individual. Please select the constition type as Salaried or Self Employeed."
      //   );
      //   setpanErrorCategory(true);
      // }
    } catch (error) {
      dispatch(CompanyDetails(CIN));
      dispatch(
        SAF(
          employmentTypeValue,
          pan,
          "",
          loanProductOption,
          loanAmount,
          loanTenure,
          "",
          "",
          "",
          true,
          true,
          true,
          collateral,
          CIN,
          "",
          "",
          hasOwnPropertySelected,
          panDetails,
          data,
          employeeCode,
          handleLogoutButton
        )
      );
      dispatch(VerifyOTPClearData());
      setpanErrorLoading(false);
    }
  };
  const [incompleteAadharDetails, setIncompleteAadharDetails] = useState(false);
  const savePersonalDetails = async () => {
    let data = [];
    let coborrowerStatus = false;
    setIncompleteAadharDetails(false);
    console.log(lists);
    if (
      loanProductOption == 1 ||
      loanProductOption == 4 ||
      loanProductOption == 5 ||
      loanProductOption == 7 ||
      loanProductOption == 10
    ) {
      if (lists.length > 0) {
        lists.forEach((item) => {
          if (
            !item.isadharDisabled ||
            !item.ispanDisabled ||
            !item.isemailDisabled
          ) {
            coborrowerStatus = true;
            // You can break the loop if you only want to log once
            // break;
          }
        });
        data = lists.map((item) => ({
          aadharData: item.adharData,
          panData: item.panData,
          email: item.email,
        }));
      }
    }
    if (coborrowerStatus) {
      setinvalidInput(true);
      return;
    }
    if (identificationCard == null && verifyOTPSuccess?.data?.code == 0) {
      setIncompleteAadharDetails(true);
      return;
    }
    if (
      emailError != "" ||
      !verifyPanSuccess ||
      !iscreditbureauChecked ||
      pan == "" ||
      panError ||
      loanStatus != "" ||
      hasOwnPropertySelected == "" ||
      (verifyOTPSuccess?.data?.code !== 200 &&
        verifyOTPSuccess?.data?.code !== 0)
    ) {
      setinvalidInput(true);
      return;
    }
    // if (verifyOTPEmailSuccess.length <= 0 && !emailAlreadyVerified) {
    //   setinvalidInput(true);
    //   return;
    // }
    // var containsLastName;
    // var containsFirstName;
    setpanErrorLoading(true);
    try {
      // const results = await PanAdvance(pan, handleLogoutButton);
      // setpanErrorLoading(false);
      // if (
      //   results.data.data.category.toLowerCase() == "individual" ||
      //   results.data.data.category.toLowerCase() == "firm"
      // ) {
      dispatch(
        SAF(
          employmentTypeValue,
          pan,
          loadPersonalDetails.addharCard,
          loanProductOption,
          loanAmount,
          loanTenure,
          loadPersonalDetails.email,
          loadPersonalDetails.phoneNo,
          loadPersonalDetails.dateofBirth,
          true,
          true,
          true,
          collateral,
          "",
          verifyOTPSuccess,
          maritalStatusSelected,
          hasOwnPropertySelected,
          panDetails,
          data,
          employeeCode,
          loadPersonalDetails.shopName,
          loadPersonalDetails.shopAddress,
          loadPersonalDetails.landmark,
          loadPersonalDetails.mothersName,
          loadPersonalDetails.fathersName,  //Change
          handleLogoutButton
        )
      );
      dispatch(VerifyOTPClearData());
      setpanErrorLoading(false);
      // } else {
      //   setpanErrorTitle("PAN Type Mismatch");
      //   setpanMessage(
      //     "Your PAN type is Company. Please select the constition type as Private Limited, Public Limited, Partnership or LLP."
      //   );
      //   setpanErrorCategory(true);
      //   setpanErrorLoading(false);
      // }
      // }
      setconfirmedPersonalDetails(true);
      // } else {
      //   setpanErrorTitle("Aadhar Details not found");
      //   setpanMessage("Please enter and verify your Aadhar Details");
      //   setpanErrorCategory(true);
      // }
    } catch (error) {
      dispatch(
        SAF(
          employmentTypeValue,
          pan,
          loadPersonalDetails.addharCard,
          loanProductOption,
          loanAmount,
          loanTenure,
          loadPersonalDetails.email,
          loadPersonalDetails.phoneNo,
          loadPersonalDetails.dateofBirth,
          true,
          true,
          true,
          collateral,
          "",
          verifyOTPSuccess,
          maritalStatusSelected,
          panDetails,
          data,
          employeeCode,
          loadPersonalDetails.shopName,
          loadPersonalDetails.shopAddress,
          loadPersonalDetails.landmark,
          loadPersonalDetails.mothersName,
          loadPersonalDetails.fathersName,   //Change
          handleLogoutButton
        )
      );
      dispatch(VerifyOTPClearData());
      setpanErrorLoading(false);
    }
  };
  const savePersonalDetailsPopup = () => {
    dispatch(ConfirmAllDilaog(!confirmDailog));
  };
  //--------------------------------------------------------------------------
  const companyDetailsLoading = useSelector(
    (state) => state.BasicDetailsReducers.companyDetailsLoading
  );
  //--------------------------------------------------------------------------
  const guarantorDetailsLoading = useSelector(
    (state) => state.BasicDetailsReducers.guarantorDetailsLoading
  );
  //-----------------------------------------------------------------------

  const companyDetailsSuccess = useSelector(
    (state) => state.BasicDetailsReducers.companyDetailsSuccess
  );
  const companyDetailsFail = useSelector(
    (state) => state.BasicDetailsReducers.companyDetailsFail
  );
  const company_data = companyDetailsSuccess ? companyDetailsSuccess.data : [];

  const company_master_data = company_data
    ? company_data["company_master_data"]
    : [];

  //----------------------------------------------------------------------
  const navigateFromDigitalLoans = useSelector(
    (state) => state.BasicDetailsReducers.navigateFromDigitalLoans
  );
  const [compayName, setcompayName] = React.useState();
  useEffect(() => {
    if (navigateFromDigitalLoans) {
      dispatch(navigateFromLogin());
      navigate("/MyLoan");
    }
    dispatch({ type: TYPES.VERIFY_PAN_CLEAR });
  }, []);
  useEffect(() => {
    if (identificationCard != null && saf_response_data != undefined) {
      dispatch(
        uploadIdentificationCard(
          saf_response_data?.LoanApplicationId,
          identificationCard
        )
      );
    }
  }, [saf_response_data]);

  const getCompayDetails = () => {
    if (company_master_data) {
      setCompanyDetails({
        ...loadCompanyDetails,
        companyName: company_master_data.company_name,
        dateofIncorportation: company_master_data.date_of_incorporation,
        nature: company_master_data.class_of_company,
        addressline: "",
        landMark: "",
        city: companyDetailsSuccess.CITY,
        pincode: companyDetailsSuccess.PIN,
        state: companyDetailsSuccess.STATE,
        registration_number: company_master_data.registration_number,
      });
    }
  };

  //----------------------------------------------------------------------
  const [Address, setAddress] = React.useState();
  const [loanApplicationID, setloanApplicationID] = React.useState();
  const [dateOfBirth, setdateOfBirth] = React.useState(null);
  const [dateofIncorportation, setdateofIncorportation] = React.useState(null);
  const [lists, setLists] = useState([]);

  const handleAddList = () => {
    if (lists.length < 2) {
      setLists([
        ...lists,
        {
          id: lists.length + 1,
          adhar: "",
          adharVerified: false,
          adharData: {},
          isadharDisabled: false,
          adharValid: true, // New field for Aadhar validation
          pan: "",
          panVerified: false,
          ispanDisabled: false,
          panValid: true, // New field for PAN validation
          panData: {},
          email: "",
          emailVerified: false,
          isemailDisabled: false,
        },
      ]);
    }
  };

  const handleChange = (listId, name, value) => {
    let isValid = true;
    if (name === "pan") {
      isValid = isValidPAN(value);
    }
    if (name === "adhar") {
      isValid = isValidAadhar(value);
    }
    const newLists = lists.map((list) => {
      if (list.id === listId) {
        return {
          ...list,
          [name]: value,
          panValid: name === "pan" ? isValid : list.panValid,
          adharValid: name === "adhar" ? isValid : list.adharValid,
        };
      }
      return list;
    });
    setLists(newLists);
  };

  const handleVerify = (listId, name) => {
    const newLists = lists.map((list) => {
      if (list.id === listId) {
        setcurrentCoborrowerSelected(listId);
        if (name == "adhar") {
          if (!list.adharVerified) {
            dispatch(aadharDrawerCoborrower1(!aadharDrawerCoborrower));
            setselectedAadharCoborrower(list.adhar);
          }
        }
        if (name == "pan") {
          dispatch(verifyPanCoborrowwer(list.pan, handleLogoutButton));
        }
        if (name == "email") {
          if (!list.emailVerified) {
            sendverificationCodeEmailCoborrower(list.email);
            setcoborrowerEmail(list.email);
            setopenOTPEmailDialogCoborrower(true);
          }
        }
        return { ...list, [`${name}Verified`]: !list[`${name}Verified`] };
      }
      return list;
    });
    setLists(newLists);
  };

  const handleSubmit = () => {
    const isValid = lists.every(
      (list) => list.adharVerified && list.panVerified && list.emailVerified
    );
    if (!isValid) {
      alert("Please verify all fields");
      return;
    }
    // Submit logic
    console.log("Submitted:", lists);
    console.log("adhar", verifyOTPCoborrowerSuccess);
    console.log("pan ", panDetailsCoborrower);
  };
  const handleDelete = (listId) => {
    const newLists = lists.filter((list) => list.id !== listId);
    setLists(newLists);
  };
  const handleAddress = (e) => {
    setAddress(e);
  };

  //----------------------------------------------------------------------
  const [Landmark, setLandmark] = React.useState();
  const [directorDetailsSaved, setdirectorDetailsSaved] = React.useState(false);
  const [formValid, setFormValid] = React.useState(false);
  const handleLandmark = (e) => {
    setLandmark(e);
  };

  //----------------------------------------------------------------------
  const [City, setCity] = React.useState();

  const handleCity = (e) => {
    setCity(e);
  };

  const validateForm = () => {
    const aadharRegex = /^\d{12}$/; // Regex for 12-digit Aadhar number
    const panRegex = /^[A-Z]{5}\d{4}[A-Z]{1}$/; // Regex for PAN number

    for (const guarantor of guarantorDetails) {
      if (!guarantor.aadhar || !guarantor.PAN) {
        return false;
      }

      if (
        !aadharRegex.test(guarantor.aadhar) ||
        !panRegex.test(guarantor.PAN)
      ) {
        return false;
      }
    }
    return true;
  };

  //----------------------------------------------------------------------
  const [Pincode, setPincode] = React.useState();

  const handlePincode = (e) => {
    setPincode(e);
  };

  //----------------------------------------------------------------------
  const [State, setState] = React.useState();

  const handleState = (e) => {
    setState(e);
  };
  const changePANchecked = () => {
    if (!verifyPanSuccess) {
      dispatch(verifyPan(pan, handleLogoutButton));
    }
  };
  const changeCreditchecked = () => {
    setiscreditbureauChecked(!iscreditbureauChecked);
  };
  const changeCreditcheckedDirector = () => {
    setiscreditbureauCheckedDirector(!iscreditbureauCheckedDirector);
  };
  const isValidAadhar = (aadhar) => {
    const aadharRegex = /^\d{12}$/;
    return aadharRegex.test(aadhar);
  };

  const isValidPAN = (pan) => {
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
    return panRegex.test(pan);
  };

  const handleSaveCompanyDetails = () => {
    // if (saf_response_data.LoanApplicationId != undefined) {
    //   const loanApplicationId = localStorage.setItem(
    //     "LoanApplicationId",
    //     saf_response_data.LoanApplicationId
    //   );
    //   setloanApplicationID(loanApplicationId);
    // } else {
    const loanApplicationId = localStorage.getItem("LoanApplicationId");
    setloanApplicationID(loanApplicationId);
    // }
    dispatch(
      SaveDetails(
        localStorage.getItem("LoanApplicationId"),
        loadCompanyDetails.addressline,
        loadCompanyDetails.addressline,
        loadCompanyDetails.city,
        "",
        loadCompanyDetails.dateofIncorportation,
        loadCompanyDetails.nature,
        loadCompanyDetails.landMark,
        loadCompanyDetails.companyName,
        "",
        loadCompanyDetails.pincode,
        "",
        "",
        loadCompanyDetails.state,
        "",
        handleLogoutButton
      )
    );
  };
  //--------------------------------------------------------------------

  const saveDetailsLoading = useSelector(
    (state) => state.BasicDetailsReducers.saveDetailsLoading
  );

  const saveDetailsSuccess = useSelector(
    (state) => state.BasicDetailsReducers.saveDetailsSuccess
  );

  const saveStatus = saveDetailsSuccess ? saveDetailsSuccess.status : "";

  const saveStatusData = saveDetailsSuccess ? saveDetailsSuccess.data : [];

  const company_id = saveStatusData ? saveStatusData.Company_Id : "";

  //---------------------------------------------------------------------

  // const [inputFields, setInputFields] = React.useState([
  //   {
  //     id: uuidv4(),
  //     FullName: "",
  //     AadharDetails: "",
  //     PAN: 0,
  //     ShareValue: 0,
  //     DIN: 0,
  //   },
  // ]);
  const [guarantorDetails, setguarantorDetails] = React.useState([]);
  //---------------------------------------------------------------------
  const directors_signatory_details = company_data
    ? company_data["directors/signatory_details"]
    : [];
  const tenure = useSelector((state) => state.BasicDetailsReducers.tenure);
  const maritalStatus = useSelector(
    (state) => state.BasicDetailsReducers.maritalStatus
  );

  //--------------------------------------------------------------------
  const [inputFields, setInputFields] = React.useState();
  // setInputFields(newData)

  useEffect(() => {
    updateDirectorsList();
    getCompayDetails();
  }, [company_data]);
  useEffect(async () => {
    const response = await isLoanJourneyPending();
    console.log("Loan Journey pending Status", response);
    setloanPendingStatus(response?.data.response_data);
  }, []);
  useEffect(() => {
    dispatch(getTenure(loanProductOption, handleLogoutButton));
    dispatch(getMaritaldropdown(handleLogoutButton));
  }, [loanProductOption]);
  const updateDirectorsList = () => {
    if (directors_signatory_details != undefined) {
      const fields = directors_signatory_details.map((data) => ({
        id: uuidv4(),
        FullName: data.name,
        PAN: data["din/pan"],
        DIN: "",
        ShareValue: "",
        AadharDetails: "",
        PhoneNo: "",
      }));
      setInputFields(fields);
    }
  };
  const handleSaveGuarantorDetails = () => {
    let hasEmptyFile = false;

    guarantorDetails.forEach((item, index) => {
      if (item.file === "") {
        hasEmptyFile = true;
      }
    });

    if (hasEmptyFile) {
      setOpen(true);
      return;
    }

    dispatch(
      saveguarantorDetails(
        localStorage.getItem("LoanApplicationId"),
        guarantorDetails,
        loanAmount,
        loanProductOption,
        handleLogoutButton
      )
    );
    dispatch(VerifyOTPClearData());
    setisGuarantorDetailsCompleted(true);
  };
  //---------------------------------------------------------------------
  const handleInputChange = (id, e) => {
    const guarantorDetails1 = guarantorDetails.map((guarantor) => {
      if (id === guarantor.id) {
        return {
          ...guarantor,
          [e.target.name]: e.target.value,
        };
      }
      return guarantor;
    });

    setguarantorDetails(guarantorDetails1);
    const isFormValid = validateForm();
    setFormValid(isFormValid);
  };
  const handleAddNewDirector = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        FullName: "",
        AadharDetails: "",
        PAN: "",
        ShareValue: "",
        DIN: "",
        PhoneNo: "",
      },
    ]);
  };
  //----- New Guarantor--------
  const handleAddNewGuarantor = () => {
    setguarantorDetails([
      ...guarantorDetails,
      {
        id: uuidv4(),
        aadhar: "",
        aadhar_verification: "",
        address_line2: "",
        address_line1: "",
        city: "",
        PAN: "",
        create_date: "",
        full_name: "",
        landmark: "",
        loan_id: "",
        modify_date: "",
        pincode: "",
        state: "",
        documentType: "Bank Statement",
        file: "",
      },
    ]);
  };
  //--------------------------------------------------------------------

  const handleRemoveDirector = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
  };
  const handleRemoveGuarantor = (id) => {
    const values = [...guarantorDetails];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setguarantorDetails(values);
  };
  //--------------------------------------------------------------------
  const [shareError, setshareError] = React.useState(false);
  const [totalShare, setTotalShare] = React.useState(0);
  const [emailError, setEmailError] = React.useState("");
  const [phoneNoError, setPhoneNoError] = React.useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(true);
  const [guarantorFileData, setguarantorFileData] = React.useState([]);
  const [currentConext, setcurrentConext] = React.useState("");
  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id && event.target.name == "ShareValue") {
        i[event.target.name] = event.target.value;
      }
      if (id === i.id && event.target.name == "AadharDetails") {
        i[event.target.name] = event.target.value;
      }
      if (id === i.id && event.target.name == "PAN") {
        i[event.target.name] = event.target.value;
      }
      if (id === i.id && event.target.name == "FullName") {
        i[event.target.name] = event.target.value;
      }
      if (id === i.id && event.target.name == "PhoneNo") {
        i[event.target.name] = event.target.value;
      }
      let sum = 0;
      for (let i = 0; i < inputFields.length; i++) {
        sum = sum + Number(inputFields[i].ShareValue);
      }
      setTotalShare(sum);
      if (sum > 100) {
        setshareError(true);
      } else {
        setshareError(false);
      }

      return i;
    });

    setInputFields(newInputFields);
  };
  //---------------------------------------------------------------------------
  function validateShareValueSum(inputFields) {
    let sum = 0;
    for (let i = 0; i < inputFields.length; i++) {
      sum += inputFields[i].ShareValue;
    }
    return sum;
  }
  //----------------------------------------------------------------------------
  const confirmDailog = useSelector(
    (state) => state.BasicDetailsReducers.confirmDailog
  );

  const handleSaveDetails = () => {
    dispatch(ConfirmAllDilaog(!confirmDailog));
    dispatch(
      SaveAadhar(
        company_id,
        pan,
        localStorage.getItem("LoanApplicationId"),
        inputFields,
        loanProductOption,
        loanAmount,
        employmentTypeValue,
        handleLogoutButton
      )
    );
  };
  const onFileInputChange = (id, e) => {
    setguarantorFileData(e);
    const guarantorDetails1 = guarantorDetails.map((guarantor) => {
      if (id === guarantor.id) {
        return {
          ...guarantor,
          file: e.target.files[0],
        };
      }
      return guarantor;
    });

    setguarantorDetails(guarantorDetails1);
  };
  const saveDirectorDetails = () => {
    setdirectorDetailsSaved(true);
    dispatch(VerifyOTPClearData());
  };
  const saveAadharSuccess = useSelector(
    (state) => state.BasicDetailsReducers.saveAadharSuccess
  );
  const verifyOTPSuccess = useSelector(
    (state) => state.BasicDetailsReducers.verifyOTPSuccess
  );
  const verifyOTPCoborrowerSuccess = useSelector(
    (state) => state.BasicDetailsReducers.verifyOTPCoborrowerSuccess
  );
  const messsage = saveAadharSuccess ? saveAadharSuccess.message : "";

  //----------------------------------------------------------------------------
  let stepCount = useSelector((state) => state.BasicDetailsReducers.stepCount);

  const handleMovieStep = () => {
    dispatch(TopbarViewSteps(stepCount++));
    navigate("/UploadDocument");
  };
  useEffect(() => {
    if (verifyOTPSuccess.data) {
      if (verifyOTPSuccess.data.data) {
        const isLastFourDigitsSame = matchAadharDetails(
          verifyOTPSuccess.data.data.maskedNumber
        );
        if (currentConext == "director") {
          setisDirectorAadharValidated(isLastFourDigitsSame);
        }
        if (currentConext == "guarantor") {
          setisGuarantorAadharValidated(isLastFourDigitsSame);
        }
      }
    }
  }, [verifyOTPSuccess]);
  const matchAadharDetails = (AadharData) => {
    // Extract the last 4 digits from the original number
    const lastFourDigitsOriginal = currentAadhar.slice(-4);

    // Replace 'x' characters in the masked number with the corresponding digits from the original number
    const unmaskedNumber = AadharData.replace(/x/g, (index) =>
      AadharData.charAt(index)
    );

    // Extract the last 4 digits from the unmasked number
    const lastFourDigitsUnmasked = unmaskedNumber.slice(-4);

    // Compare if the last four digits are the same
    return lastFourDigitsOriginal === lastFourDigitsUnmasked;
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}/${month}/${day}`;
  }
  const handledob = (date) => {
    if (date != null) {
      const formattedDate = formatDate(date.toDate());
      setdateOfBirth(formattedDate);
    }
  };
  const handleDateofIncorportation = (date) => {
    if (date != null) {
      const formattedDate = formatDate(date.toDate());
      setdateofIncorportation(formattedDate);
    }
  };
  const validateEmail = (email) => {
    // Regular expression to validate email format
    const emailRegex = /^\S+@\S+\.\S+$/;

    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };

  const handleEmailChange = (e) => {
    validateEmail(email);
    setEmail(e.target.value);
    setloadPersonalDetails({ ...loadPersonalDetails, email });
  };
  const validatePhoneNumber = (phoneNo) => {
    // Regular expression to validate phone number format
    const phoneNoRegex = /^\d{10}$/;

    if (!phoneNoRegex.test(phoneNo)) {
      setPhoneNoError("Please enter a valid 10-digit phone number");
    } else {
      setPhoneNoError("");
    }
  };

  const handlePhoneNoChange = (e) => {
    setPhoneNoError("");
    const phoneNo = e.target.value;
    setloadPersonalDetails({ ...loadPersonalDetails, phoneNo });
    validatePhoneNumber(phoneNo);
  };
  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    dispatch(TopbarViewSteps(stepCount + 2));
    navigate("/MyLoan");
  };
  const handlePanPopupClose = () => {
    setpanErrorCategory(false);
    navigate("/BasicDetails");
    window.location.reload();
  };
  const handleMyLoan = () => {
    dispatch(TopbarViewSteps(5));
    dispatch(GetLoanDetails(handleLogoutButton));
    navigate("/MyLoan");
  };
  const handleCloseErrorModal1 = () => {
    setShowAlert(false);
    navigate("/login");
  };
  const handleCloseErrorModalJourney = () => {
    setloanPendingStatus(false);
  };
  const fileInputRef = useRef(null);
  const [identificationCard, setidentificationCard] = React.useState(null);
  const handleUploadClick = () => {
    // Trigger the file input click event
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      // Handle the selected file, for example, you can upload it or process it
      setidentificationCard(selectedFile);
    }
  };
  useEffect(() => {
    if (verifyOTPCoborrowerSuccess?.data?.code == 200) {
      setLists((prevLists) => {
        return prevLists.map((item) => {
          if (item.id === currentCoborrowerSelected) {
            return {
              ...item,
              adharData: verifyOTPCoborrowerSuccess,
              isadharDisabled: true,
            };
          }
          return item;
        });
      });
      dispatch(GenOTPClearData());
      dispatch(InitiateOTPClearData());
      dispatch(VerifyOTPClearDataCoborrower());
    }
  }, [verifyOTPCoborrowerSuccess]);
  useEffect(() => {
    if (panDetailsCoborrower?.code == 200) {
      setLists((prevLists) => {
        return prevLists.map((item) => {
          if (item.id === currentCoborrowerSelected) {
            return {
              ...item,
              panData: panDetailsCoborrower,
              ispanDisabled: true,
            };
          }
          return item;
        });
      });
    }
  }, [panDetailsCoborrower]);
  const handleChangeEmployeeCode = (e) => {
    const value = e.target.value;

    // Check if the value matches the pattern
    if (/^G\d{6}$/.test(value)) {
      // Update the employee code and clear error state
      setemployeeCode(value);
      setErroremployeeCode(false);
    } else {
      setemployeeCode(value);
      // Set error state if the value doesn't match the pattern
      setErroremployeeCode(true);
    }
  };

  useEffect(() => {
    if (
      verifyOTPEmailCoborrowerSuccess?.response_message?.startsWith("SUCCESS")
    ) {
      setLists((prevLists) => {
        return prevLists.map((item) => {
          if (item.id === currentCoborrowerSelected) {
            return {
              ...item,
              isemailDisabled: true,
            };
          }
          return item;
        });
      });
    }
  }, [verifyOTPEmailCoborrowerSuccess]);
  return (
    <>
      {saf_message && saf_message.startsWith("MRAC failed") ? (
        <div style={{ height: "50%", width: "80%" }}>
          <ErrorModel
            isOpen={isErrorModalOpen}
            onClose={handleCloseErrorModal}
            title="Loan Application Declined"
            message="Sorry you didn't qualify our minimum risk assessment"
          />
        </div>
      ) : null}
      {saf_message && saf_message.startsWith("Dedupe Check Failed") ? (
        <div style={{ height: "50%", width: "80%" }}>
          <ErrorModel
            isOpen={isErrorModalOpen}
            onClose={handleCloseErrorModal}
            title="Loan Application Declined"
            message="Duplicate Loan Application found. Please try again after 30 days"
          />
        </div>
      ) : null}
      {panErrorCategory ? (
        <div style={{ height: "50%", width: "80%" }}>
          <ErrorModel
            isOpen={isErrorModalOpen}
            onClose={handlePanPopupClose}
            title={panErrorTitle}
            message={panMessage}
          />
        </div>
      ) : null}
      <Snackbar
        open={invalidInput}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="error">
          Please fill in all required fields and ensure they are valid.
        </Alert>
      </Snackbar>
      <Snackbar
        open={incompleteAadharDetails}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="error">
          Please upload your Aadhaar Card
        </Alert>
      </Snackbar>
      <Grid container sx={{ mb: 8 }}>
        <GenInsight />
        <Grid item xs={12}>
          <NavBar />
        </Grid>
        {/* {showAlert && (
          <Alert severity="error" onClose={() => setShowAlert(false)}>
            Session has expired
          </Alert>
        )} */}
        <div>
          {/* Your component content */}
          <SessionModal isOpen={showAlert} onClose={handleCloseErrorModal1} />
        </div>
        <div>
          <ResumeJourney
            isOpen={loanPendingStatus}
            onClose={handleCloseErrorModalJourney}
          />
        </div>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 3,
            height: "9vh",
            background: "#e5f6fd",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: 900,
              fontSize: "20px",
            }}
          >
            Basic Information
          </Typography>
        </Grid>
        <Grid item xs={12} md={5}></Grid>
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            height: "9vh",
            mt: 6,
          }}
        >
          <Typography onClick={handleMyLoan} sx={{ cursor: "pointer", ml: 4 }}>
            My Loans
          </Typography>
          <AssignmentIcon
            onClick={handleMyLoan}
            sx={{ ml: 1, color: "#148F5B" }}
          />
        </Grid>
        <Grid item xs={12} md={4} sx={{ mt: 1 }}>
          <Grid container>
            <Grid item xs={2}></Grid>
            <Grid item xs={10} sx={{ display: "flex" }}>
              <div
                style={{ height: "30px", borderLeft: "4px solid #148F5B" }}
              ></div>
              {loanStatus == "Approved in principle" ||
              loanStatus == "IN-PRINCIPLE APPROVAL" ||
              loanStatus == "APPROVED" ||
              loanStatus == "PENDING" ||
              loanStatus == "PROCESSING" ||
              loanStatus == "Decline" ||
              loanStatus == "REFERRED" ||
              loanStatus == "DENIED" ||
              loanStatus == "DECLINED" ? (
                <>
                  <Typography
                    sx={{
                      ml: 1,
                      mt: 0.3,
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#4BB543",
                    }}
                  >
                    Loan Information
                  </Typography>
                  <DoneIcon sx={{ color: "#4BB543", ml: 1, mt: 0.2 }} />
                </>
              ) : null}

              {loanStatus == "" ? (
                <>
                  <Typography
                    sx={{ ml: 1, mt: 0.3, fontWeight: 600, fontSize: 16 }}
                  >
                    Loan Information
                  </Typography>
                </>
              ) : null}
              {/* 
              {loanStatus == "Decline" ||
              loanStatus == "DENIED" ||
              loanStatus == "DECLINED" ? (
                <>
                  <Typography
                    sx={{
                      ml: 1,
                      mt: 0.3,
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#FF0000",
                    }}
                  >
                    Loan Information
                  </Typography>
                  <DoNotDisturbIcon sx={{ color: "#FF0000", ml: 1, mt: 0.2 }} />
                </>
              ) : null} */}
            </Grid>
            {/* {loanStatus == "Decline" ||
            loanStatus == "DENIED" ||
            loanStatus == "DECLINED" ? (
              <Grid item xs={12}>
                {saf_message && !saf_message.startsWith("MRAC failed") ? (
                  <Typography
                    sx={{ color: "#FF0000", fontSize: 14, ml: 11.5, mt: 2 }}
                  >
                    *{saf_message}
                  </Typography>
                ) : null}
              </Grid>
            ) : null} */}
            <Grid item xs={2}></Grid>
            <Grid item xs={10} sx={{}}>
              <Typography sx={{ ml: 1, mt: 3, fontWeight: 300, fontSize: 14 }}>
                Loan information provides borrowers with details about the terms
                and conditions of the loan, including the amount borrowed,
                interest rate, repayment period, and any fees or penalties
                associated with the loan.
              </Typography>
              {/* <Typography
                sx={{
                  mt: 1,
                  ml: 1,
                  fontWeight: 600,
                  fontSize: 12,
                  color: "#148F5B",
                  cursor: "pointer",
                }}
              >
                Learn More
              </Typography> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={1}></Grid>

        <Grid item xs={12} md={6} sx={{ mt: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <TextField
                select
                onChange={(e) => handleChangeemploymentType(e.target.value)}
                fullWidth
                label="Constitution Type"
              >
                {employmentType.map((option, key) => (
                  <MenuItem
                    key={key}
                    value={option.value}
                    sx={{
                      fontFamily: "'Roboto Condensed', sans-serif",
                      "&:hover": {
                        borderLeft: "5px solid #148F5B",
                        borderRadius: 1,
                      },
                    }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                select
                onChange={(e) => handleLoanProductOption(e.target.value)}
                fullWidth
                label="Loan Product"
              >
                {loanProductList?.map((option, key) => (
                  <MenuItem
                    sx={{
                      fontFamily: "'Roboto Condensed', sans-serif",
                      "&:hover": {
                        borderLeft: "5px solid #148F5B",
                        borderRadius: 1,
                      },
                    }}
                    key={key}
                    value={option.value}
                    disabled={option.isDisabled} // Add this line to make MenuItem disabled if isDisabled is true
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {loanProductOption == 1 ||
            loanProductOption == 4 ||
            loanProductOption == 5 ||
            loanProductOption == 7 ||
            loanProductOption == 10 ? (
              <Grid item xs={12} md={4}>
                <TextField
                  value={collateral}
                  type={"number"}
                  onChange={(e) => handleCollateral(e.target.value)}
                  fullWidth
                  label="Value of Collateral"
                />
              </Grid>
            ) : null}
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                required
                label="PAN"
                error={panError || verifyPanFail}
                disabled={verifyPanSuccess}
                id="pan"
                value={pan}
                onChange={(e) => HandlePanChange(e.target.value)}
                variant="outlined"
                helperText={
                  panError || verifyPanFail ? "Incorrect Pan Number." : null
                }
              />
            </Grid>
            {loanProductOption == 1 ||
            loanProductOption == 4 ||
            loanProductOption == 5 ||
            loanProductOption == 7 ||
            loanProductOption == 10 ? (
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Loan Amount (upto 1 cr)"
                  type={"number"}
                  id="Loan Amount"
                  value={loanAmount}
                  onChange={(e) => HandleLoanAmountChange(e.target.value)}
                  variant="outlined"
                  // helperText={
                  //   amountError
                  //     ? "Loan Amount Limit - 10000 to 5 cr (50000000) "
                  //     : null
                  // }
                />
              </Grid>
            ) : (
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Loan Amount (upto 500000)"
                  type={"number"}
                  id="Loan Amount"
                  value={loanAmount}
                  onChange={(e) => HandleLoanAmountChange(e.target.value)}
                  variant="outlined"
                  // helperText={
                  //   amountError
                  //     ? "Loan Amount Limit - 10000 to 5 cr (50000000) "
                  //     : null
                  // }
                />
              </Grid>
            )}

            {/* <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Loan Tenure"
                type={"number"}
                id="Loan Tenure"
                error={loantenureError}
                value={loanTenure}
                onChange={(e) => HandleLoanTenureChange(e.target.value)}
                variant="outlined"
                helperText={
                  // loanTenure ? "Loan Tenure in months. Example 5" : null;
                  loantenureError
                    ? "Loan Tenure in months (Input value range) [3-300] "
                    : null
                }
              />
            </Grid> */}
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                select
                label="Loan Tenure"
                id="Loan Tenure"
                error={loantenureError}
                value={loanTenure}
                onChange={(e) => HandleLoanTenureChange(e.target.value)}
                variant="outlined"
                helperText={
                  loantenureError
                    ? "Loan Tenure in months (Input value range) [3-300]"
                    : null
                }
              >
                {/* Static options */}
                {tenure?.response_data?.map((option) => (
                  <MenuItem
                    key={option.loan_tenure}
                    value={option.loan_tenure}
                    sx={{
                      fontFamily: "'Roboto Condensed', sans-serif",
                      "&:hover": {
                        borderLeft: "5px solid #148F5B",
                        borderRadius: 1,
                      },
                    }}
                  >
                    {option.loan_tenure}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {employmentTypeValue != 1 &&
            employmentTypeValue != 2 &&
            employmentTypeValue != 7 &&
            employmentTypeValue != 4 &&
            employmentTypeValue != 6 ? (
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="CIN"
                  value={CIN}
                  onChange={(e) => handleCIN(e.target.value)}
                />
              </Grid>
            ) : null}
            {employmentTypeValue == 1 ||
            employmentTypeValue == 2 ||
            employmentTypeValue == 7 ? (
              <>
                {/* <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="Name"
                    name="name"
                    id="name"
                    value={loadPersonalDetails.name}
                    onChange={(e) => handlePersonalDetails(e)}
                  />
                </Grid> */}
                {employmentTypeValue == 2 ? (
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      label="Annual Turnover"
                      name="annualSalary"
                      type={"number"}
                      id="annualSalary"
                      value={loadPersonalDetails.annualSalary}
                      onChange={(e) => handlePersonalDetails(e)}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      label="Annual Salary"
                      name="annualSalary"
                      type={"number"}
                      id="annualSalary"
                      value={loadPersonalDetails.annualSalary}
                      onChange={(e) => handlePersonalDetails(e)}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    select
                    label="Marital Status"
                    id="Marital Status"
                    value={maritalStatusSelected}
                    onChange={(e) => setmaritalStatusSelected(e.target.value)}
                    variant="outlined"
                  >
                    {/* Static options */}
                    {maritalStatus?.response_data?.map((option) => (
                      <MenuItem
                        key={option.marital_status}
                        value={option.marital_status}
                        sx={{
                          fontFamily: "'Roboto Condensed', sans-serif",
                          "&:hover": {
                            borderLeft: "5px solid #148F5B",
                            borderRadius: 1,
                          },
                        }}
                      >
                        {option.marital_status}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {/* <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="Date Of Birth(DD/MM/YYYY)"
                    name="dateofBirth"
                    id="dateofBirth"
                    value={loadPersonalDetails.dateofBirth}
                    onChange={handleDateOfBirthChange}
                    error={!!errorMessage} // Set error prop based on whether there's an error message
                    helperText={errorMessage} // Display error message
                  />
                </Grid> */}
                {employmentTypeValue == 1 ||
                employmentTypeValue == 2 ||
                employmentTypeValue == 4 ||
                employmentTypeValue == 6 ||
                employmentTypeValue == 7 ? (
                  <Grid item xs={12} md={4}>
                    <TextField
                      select
                      onChange={(e) => handleChangeOwnProperty(e.target.value)}
                      fullWidth
                      label="Do you possess a house of your own?"
                    >
                      {ownProperty.map((option, key) => (
                        <MenuItem
                          key={key}
                          value={option.value}
                          sx={{
                            fontFamily: "'Roboto Condensed', sans-serif",
                            "&:hover": {
                              borderLeft: "5px solid #148F5B",
                              borderRadius: 1,
                            },
                          }}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                ) : null}
                <Grid item xs={12} md={4}>
                  <TextField
                    required
                    fullWidth
                    label="Phone No. (registered with Aadhaar)"
                    type={"text"}
                    name="phoneNo"
                    id="phoneNo"
                    value={loadPersonalDetails.phoneNo}
                    onChange={handlePhoneNoChange}
                    error={!!phoneNoError}
                    helperText={phoneNoError}
                    disabled={true}
                  />
                </Grid>{" "}
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="Aadhaar *"
                    name="addharCard"
                    type={"number"}
                    id="addharCard"
                    value={loadPersonalDetails.addharCard}
                    onChange={(e) => handlePersonalDetails(e)}
                    disabled={
                      (verifyOTPSuccess && !verifyOTPSuccess.length == 0) ||
                      verifyOTPSuccess?.data?.code == 200
                    }
                  />
                </Grid>
                <Grid item xs={12} md={2} sx={{ display: "flex" }}>
                  <Checkbox
                    sx={{ ml: 0 }}
                    disabled={
                      (verifyOTPSuccess && !verifyOTPSuccess.length == 0) ||
                      verifyOTPSuccess?.data?.code == 200 ||
                      verifyOTPSuccess?.data?.code == 0 ||
                      loadPersonalDetails.addharCard == ""
                    }
                    checked={isaadharChecked}
                    onClick={() =>
                      handleAadharClick(loadPersonalDetails.addharCard)
                    }
                  />

                  <Typography sx={{ mt: 2 }} style={{ fontSize: "12px" }}>
                    Aadhaar Verification
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    required
                    fullWidth
                    label="Email"
                    name="email"
                    onChange={handleEmailChange}
                    id="email"
                    value={email}
                    error={emailError != ""}
                    helperText={emailError}
                    disabled={emailAlreadyVerified ||
                      verifyOTPEmailSuccess?.response_message?.startsWith(
                        "SUCCESS"
                      )}
                  />
                </Grid>
              </>
            ) : null}
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Employee Code"
                id="employee_code"
                value={employeeCode}
                onChange={handleChangeEmployeeCode}
                variant="outlined"
                error={erroremployeeCode}
                helperText={
                  erroremployeeCode
                    ? "Employee code should start with G and be 7 digits long."
                    : ""
                }
              />
            </Grid>
            {employmentTypeValue == 1 ||
            employmentTypeValue == 2 ||
            employmentTypeValue == 7 ? (
              <Grid container spacing={2} sx={{ mt: 3 }}>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="Shop Name"
                    name="shopName"
                    id="shopName"
                    value={loadPersonalDetails.shopName}
                    onChange={(e) => handlePersonalDetails(e)}
                  />
                </Grid>{" "}
                <Grid item xs={12} md={8}>
                  <TextField
                    fullWidth
                    label="Shop Address"
                    name="shopAddress"
                    id="shopAddress"
                    value={loadPersonalDetails.shopAddress}
                    onChange={(e) => handlePersonalDetails(e)}
                  />
                </Grid>{" "}
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="Landmark"
                    name="landmark"
                    id="landmark"
                    value={loadPersonalDetails.landmark}
                    onChange={(e) => handlePersonalDetails(e)}
                  />
                </Grid>{" "}
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="Mother's Name"
                    name="mothersName"
                    id="mothersName"
                    value={loadPersonalDetails.mothersName}
                    onChange={(e) => handlePersonalDetails(e)}
                  />
                </Grid>{" "}
                <Grid item xs={12} md={4}>
                  <TextField                     //Change
                    fullWidth
                    label="Fathers's Name"
                    name="fathersName"
                    id="fathersName"
                    value={loadPersonalDetails.fathersName}
                    onChange={(e) => handlePersonalDetails(e)}
                  />
                </Grid>{" "}
              </Grid>
            ) : null}
            {employmentTypeValue == 1 ||
            employmentTypeValue == 2 ||
            employmentTypeValue == 7 ? (
              <Grid container>
                <Grid item xs={12} md={11} sx={{ display: "flex", mt: 3 }}>
                  <Typography
                    sx={{
                      ml: 3,
                      mt: 0.3,
                      fontWeight: 600,
                      fontSize: 16,
                      color: identificationCard != null ? "#4BB543" : "#148F5B",
                    }}
                  >
                    Please upload your Aadhaar Card. (Optional if aadhaar
                    verified)
                  </Typography>
                </Grid>
                {identificationCard == null ? (
                  <Grid item xs={12} md={1} sx={{ display: "flex", mt: 3 }}>
                    {/* Upload Icon */}
                    <div
                      onClick={handleUploadClick}
                      style={{ cursor: "pointer", color: "#148F5B" }}
                    >
                      {/* You can use an icon library or an image for your upload icon */}
                      <UploadIcon></UploadIcon>
                    </div>

                    {/* Hidden file input */}
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12} md={1} sx={{ display: "flex", mt: 3 }}>
                    <div
                      onClick={handleUploadClick}
                      style={{ cursor: "pointer", color: "#148F5B" }}
                    >
                      {" "}
                      <UploadIcon sx={{ color: "#4BB543", ml: 1, mt: 0.2 }} />
                    </div>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </Grid>
                )}
              </Grid>
            ) : (
              <Grid container>
                <Grid item xs={12} md={11} sx={{ display: "flex", mt: 3 }}>
                  <Typography
                    sx={{
                      ml: 3,
                      mt: 0.3,
                      fontWeight: 600,
                      fontSize: 16,
                      color: identificationCard != null ? "#4BB543" : "#148F5B",
                    }}
                  >
                    Please upload your MSME certificate.
                  </Typography>
                </Grid>
                {identificationCard == null ? (
                  <Grid item xs={12} md={1} sx={{ display: "flex", mt: 3 }}>
                    {/* Upload Icon */}
                    <div
                      onClick={handleUploadClick}
                      style={{ cursor: "pointer", color: "#148F5B" }}
                    >
                      {/* You can use an icon library or an image for your upload icon */}
                      <UploadIcon></UploadIcon>
                    </div>

                    {/* Hidden file input */}
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12} md={1} sx={{ display: "flex", mt: 3 }}>
                    <div
                      onClick={handleUploadClick}
                      style={{ cursor: "pointer", color: "#148F5B" }}
                    >
                      {" "}
                      <UploadIcon sx={{ color: "#4BB543", ml: 1, mt: 0.2 }} />
                    </div>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </Grid>
                )}
              </Grid>
            )}
            {employmentTypeValue != 1 &&
            employmentTypeValue != 2 &&
            employmentTypeValue != 7 ? (
              <>
                <Grid container>
                  <Grid item xs={12} md={6} sx={{ display: "flex", mt: 3 }}>
                    <Checkbox
                      id="pan-verification-company-saf"
                      checked={verifyPanSuccess}
                      disabled={verifyPanSuccess}
                      onChange={changePANchecked}
                      sx={{ ml: 1 }}
                    />
                    <Typography sx={{ mt: 1 }}>
                      Consent for PAN verification*
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ mt: 3, display: "flex" }}>
                    <Checkbox
                      id="cibil-verification-company-saf"
                      sx={{ ml: -1 }}
                      checked={iscreditbureauChecked}
                      onChange={changeCreditchecked}
                    />
                    <Typography sx={{ mt: 1.3 }}>
                      Consent for credit bureau verification*
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ) : null}
            {employmentTypeValue == 1 ||
            employmentTypeValue == 2 ||
            employmentTypeValue == 7 ? (
              <>
                <Grid container>
                  <Grid item xs={12} md={6} sx={{ display: "flex", mt: 3 }}>
                    <Checkbox
                      id="pan-verification-individual-saf"
                      checked={verifyPanSuccess}
                      disabled={verifyPanSuccess}
                      onChange={changePANchecked}
                      sx={{ ml: 1 }}
                    />
                    <Typography sx={{ mt: 1 }}>
                      Consent for PAN verification*
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ mt: 3, display: "flex" }}>
                    <Checkbox
                      id="cibil-verification-individual-saf"
                      sx={{ ml: -1 }}
                      checked={iscreditbureauChecked}
                      onChange={changeCreditchecked}
                    />
                    <Typography sx={{ mt: 1.3 }}>
                      Consent for credit bureau verification*
                    </Typography>
                  </Grid>
                  {loanProductOption == 1 ||
                  loanProductOption == 4 ||
                  loanProductOption == 5 ||
                  loanProductOption == 7 ||
                  loanProductOption == 10 ? (
                    <div>
                      <Grid item xs={12} sx={{ display: "flex", m: 4 }}>
                        <Typography>Add Co-Borrower</Typography>
                        {lists.length < 2 && (
                          <Button
                            onClick={handleAddList}
                            sx={{ ml: 1, mt: -0.5 }}
                            variant="outlined"
                          >
                            +
                          </Button>
                        )}
                      </Grid>
                      {lists.map((list) => (
                        <div key={list.id}>
                          <TextField
                            label="Aadhaar"
                            value={list.adhar}
                            onChange={(e) =>
                              handleChange(list.id, "adhar", e.target.value)
                            }
                            disabled={list.isadharDisabled}
                            error={!list.adharValid}
                            helperText={
                              !list.adharValid ? "Invalid Aadhaar number" : ""
                            }
                            style={{ margin: 10 }}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={list.isadharDisabled}
                                checked={list.adharVerified}
                                onChange={() => handleVerify(list.id, "adhar")}
                              />
                            }
                            label="Verify Aadhaar*"
                            style={{ margin: 10 }}
                          />
                          <TextField
                            label="PAN"
                            value={list.pan}
                            onChange={(e) =>
                              handleChange(list.id, "pan", e.target.value)
                            }
                            disabled={list.ispanDisabled}
                            error={!list.panValid}
                            helperText={
                              !list.panValid ? "Invalid PAN number" : ""
                            }
                            style={{ margin: 10 }}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={list.ispanDisabled}
                                checked={list.panVerified}
                                onChange={() => handleVerify(list.id, "pan")}
                              />
                            }
                            label="Verify PAN*"
                            style={{ margin: 10 }}
                          />
                          <TextField
                            label="Email"
                            value={list.email}
                            onChange={(e) =>
                              handleChange(list.id, "email", e.target.value)
                            }
                            disabled={list.isemailDisabled}
                            style={{ margin: 10 }}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={list.emailVerified}
                                disabled={list.isemailDisabled}
                                onChange={() => handleVerify(list.id, "email")}
                              />
                            }
                            label="Verify Email*"
                            style={{ margin: 10 }}
                          />
                          <DeleteIcon
                            onClick={() => handleDelete(list.id)}
                            sx={{
                              color: "#FF0000",
                              cursor: "pointer",
                              mt: 2,
                            }}
                          />
                        </div>
                      ))}

                      {/* <Button onClick={handleSubmit} variant="contained">
                      Submit
                    </Button> */}
                    </div>
                  ) : null}

                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      m: 2,
                      md: 10,
                    }}
                  >
                    <Button
                      endIcon={<CheckIcon />}
                      onClick={handleOpenOTPDialog}
                      disabled={safSuccess}
                      variant="contained"
                      sx={{ textTransform: "capitalize" }}
                    >
                      Confirm & Continue
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : null}

            {employmentTypeValue != 1 &&
            employmentTypeValue != 2 &&
            employmentTypeValue != 7 ? (
              <>
                {employmentTypeValue == 4 || employmentTypeValue == 6 ? (
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      endIcon={<CheckIcon />}
                      onClick={handleOpenOTPDialog}
                      disabled={safSuccess}
                      variant="contained"
                      sx={{ textTransform: "capitalize" }}
                    >
                      Confirm & Continue
                    </Button>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      endIcon={<CheckIcon />}
                      onClick={handleOpenOTPDialog}
                      disabled={safSuccess}
                      variant="contained"
                      sx={{ textTransform: "capitalize" }}
                    >
                      Confirm & Continue
                    </Button>
                  </Grid>
                )}
              </>
            ) : null}
          </Grid>
        </Grid>
        <Grid item xs={12} md={1}></Grid>
        {employmentTypeValue == 1 ||
        employmentTypeValue == 2 ||
        employmentTypeValue == 7 ? (
          <>
            <Grid item xs={12} md={12} sx={{ mt: 4 }}>
              <Grid container>
                <Grid item xs={12} md={4} sx={{ mt: 4 }}>
                  <Grid container>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={10} sx={{ display: "flex" }}>
                      <div
                        style={{
                          height: "30px",
                          borderLeft: "4px solid #148F5B",
                        }}
                      ></div>
                      {isGuarantorDetailsCompleted ? (
                        <>
                          <Typography
                            sx={{
                              ml: 1,
                              mt: 0.3,
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#4BB543",
                            }}
                          >
                            Guarantor's Details (Optional)
                          </Typography>
                          <DoneIcon sx={{ color: "#4BB543", ml: 1, mt: 0.2 }} />
                        </>
                      ) : (
                        <>
                          <Typography
                            sx={{
                              ml: 1,
                              mt: 0.3,
                              fontWeight: 600,
                              fontSize: 16,
                            }}
                          >
                            Guarantor's Details (Optional)
                          </Typography>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={10} sx={{}}>
                      <Typography
                        sx={{ ml: 1, mt: 3, fontWeight: 300, fontSize: 14 }}
                      >
                        A guarantor is a person or entity who agrees to take on
                        the responsibility of paying back a loan or fulfilling
                        an obligation if the original borrower or obligor is
                        unable to do so. Guarantor's details typically refer to
                        the personal information and financial details of the
                        guarantor, including their name, address, contact
                        information, employment status, income, and assets.
                      </Typography>
                      {/* <Typography
                  sx={{
                    mt: 1,
                    ml: 1,
                    fontWeight: 600,
                    fontSize: 12,
                    color: "#148F5B",
                    cursor: "pointer",
                  }}
                >
                  Learn More
                </Typography> */}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={1}></Grid>

                <Grid item xs={12} md={6} sx={{ mt: 4 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ display: "flex" }}>
                      <Typography>Add Guarantor</Typography>
                      <Button
                        onClick={handleAddNewGuarantor}
                        sx={{ ml: 1, mt: -0.5 }}
                        variant="outlined"
                      >
                        +
                      </Button>
                    </Grid>
                    {guarantorDetails?.map((guarantor, index) => (
                      <>
                        <Grid item xs={12} md={12}>
                          <Typography
                            sx={{
                              mt: 2,
                              color: "#148F5B",
                              fontWeight: "800",
                            }}
                          >
                            Guarantor {index + 1}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            label="Full Name"
                            name="full_name"
                            required
                            id="FullName"
                            value={guarantor.full_name}
                            onChange={(event) =>
                              handleInputChange(guarantor.id, event)
                            }
                            // onChange={(event) =>
                            //   handleChangeInput(inputField.id, event)
                            // }
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            type={"number"}
                            label="Aadhaar"
                            name="aadhar"
                            id="AadharDetalis"
                            required
                            value={guarantor.aadhar}
                            onChange={(event) =>
                              handleInputChange(guarantor.id, event)
                            }
                          />
                        </Grid>

                        <Grid item xs={12} md={4} sx={{ display: "flex" }}>
                          <Checkbox
                            sx={{ ml: 0 }}
                            onClick={() =>
                              handleAadharClickGuarantor(guarantor.aadhar)
                            }
                          />
                          <Typography sx={{ mt: 2 }}>
                            Aadhaar Verification
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            label="PAN"
                            name="PAN"
                            id="PAN"
                            required
                            value={guarantor.PAN}
                            onChange={(event) =>
                              handleInputChange(guarantor.id, event)
                            }
                          />
                        </Grid>
                        <br></br>
                        <Grid item xs={12} sx={{ display: "flex" }}>
                          <Typography sx={{ fontSize: 12, ml: 2, mt: 0.5 }}>
                            *Address details of Guarantor(as per Aadhaar)
                          </Typography>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            label="Address Line 1"
                            name="address_lne1"
                            id="AddressLine1"
                            value={guarantor.address_lne1}
                            onChange={(event) =>
                              handleInputChange(guarantor.id, event)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            label="Address Line 2"
                            name="address_line2"
                            id="AddressLine2"
                            value={guarantor.address_line2}
                            onChange={(event) =>
                              handleInputChange(guarantor.id, event)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            label="Landmark"
                            name="landmark"
                            id="Landmark"
                            value={guarantor.landmark}
                            onChange={(event) =>
                              handleInputChange(guarantor.id, event)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            label="City"
                            name="city"
                            id="City"
                            value={guarantor.city}
                            onChange={(event) =>
                              handleInputChange(guarantor.id, event)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            type={"number"}
                            label="Pin Code"
                            name="pincode"
                            id="PinCode"
                            value={guarantor.pincode}
                            onChange={(event) =>
                              handleInputChange(guarantor.id, event)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            label="State"
                            name="state"
                            id="State"
                            value={guarantor.state}
                            onChange={(event) =>
                              handleInputChange(guarantor.id, event)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sx={{ display: "flex" }}>
                          <Typography
                            sx={{
                              color: "#FF0000",
                              fontSize: 12,
                              ml: 2,
                              mt: 0.5,
                            }}
                          >
                            *Upload either Guarantor's Bank Statement or ITR
                            Document.
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            select
                            required
                            onChange={(event) =>
                              handleInputChange(guarantor.id, event)
                            }
                            // onChange={(e) => handleGauarantorDocument(e.target.value)}
                            fullWidth
                            value={guarantor.documentType}
                            name="documentType"
                            label="Document Type"
                          >
                            {guarantorDocument.map((option, key) => (
                              <MenuItem
                                key={key}
                                value={option.value}
                                sx={{
                                  fontFamily: "'Roboto Condensed', sans-serif",
                                  "&:hover": {
                                    borderLeft: "5px solid #148F5B",
                                    borderRadius: 1,
                                  },
                                }}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          sx={{ display: "flex" }}
                          className="uploadFileGuarantor"
                        >
                          <div>
                            <input
                              ref={inputRef}
                              type="file"
                              required
                              onChange={(event) =>
                                onFileInputChange(guarantor.id, event)
                              }
                            />
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={1}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: 1.5,
                          }}
                        >
                          <DeleteIcon
                            onClick={() => handleRemoveGuarantor(guarantor.id)}
                            sx={{ color: "#FF0000", cursor: "pointer" }}
                          />
                        </Grid>
                      </>
                    ))}
                    {guarantorDetails.length > 0 ? (
                      <Grid
                        item
                        xs={12}
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          endIcon={<CheckIcon />}
                          onClick={handleSaveGuarantorDetails}
                          disabled={isGuarantorDetailsCompleted}
                          variant="contained"
                          sx={{ textTransform: "capitalize" }}
                        >
                          Confirm & Continue
                        </Button>
                        <Dialog open={open} onClose={handleClose}>
                          <DialogTitle>
                            Error- Guarantor File Required{" "}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                              Guarantor file is required. Please click the
                              'Browse' or 'Choose File' button to select a file
                              from your device.
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              sx={{ textTransform: "capitalize" }}
                              onClick={handleClose}
                            >
                              Close
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    m: 2,
                    md: 10,
                  }}
                >
                  <Button
                    endIcon={<CheckIcon />}
                    onClick={savePersonalDetailsPopup}
                    disabled={
                      emailError != "" ||
                      !verifyPanSuccess ||
                      !iscreditbureauChecked ||
                      !confirmedPersonalDetails ||
                      pan == "" ||
                      panError
                    }
                    variant="contained"
                    full
                    sx={{ textTransform: "capitalize", pl: 5, pr: 5 }}
                  >
                    Save Details
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : null}
        {employmentTypeValue != 1 &&
        employmentTypeValue != 2 &&
        employmentTypeValue != 7 ? (
          <>
            <>
              {saf_response_data ? (
                <Grid item xs={12} md={4} sx={{ mt: 4 }}>
                  <Grid container>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={10} sx={{ display: "flex" }}>
                      <div
                        style={{
                          height: "30px",
                          borderLeft: "4px solid #148F5B",
                        }}
                      ></div>
                      {saveStatus == "success" ? (
                        <>
                          <Typography
                            sx={{
                              ml: 1,
                              mt: 0.3,
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#4BB543",
                            }}
                          >
                            Company Details
                          </Typography>
                          <DoneIcon sx={{ color: "#4BB543", ml: 1, mt: 0.2 }} />
                        </>
                      ) : (
                        <>
                          <Typography
                            sx={{
                              ml: 1,
                              mt: 0.3,
                              fontWeight: 600,
                              fontSize: 16,
                            }}
                          >
                            Company Details
                          </Typography>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={10} sx={{}}>
                      <Typography
                        sx={{ ml: 1, mt: 3, fontWeight: 300, fontSize: 14 }}
                      >
                        Company details refer to specific information about a
                        company, such as its name, address, contact information,
                        ownership structure, business activities, and financial
                        performance.
                      </Typography>
                      {/* <Typography
                  sx={{
                    mt: 1,
                    ml: 1,
                    fontWeight: 600,
                    fontSize: 12,
                    color: "#148F5B",
                    cursor: "pointer",
                  }}
                >
                  Learn More
                </Typography> */}
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
              <Grid item xs={12} md={1}></Grid>

              {saf_response_data ? (
                <Grid item xs={12} md={6} sx={{ mt: 4 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        required
                        label="Company Name"
                        value={loadCompanyDetails.companyName}
                        onChange={(e) => handleCompanyDetails(e)}
                        name="companyName"
                        id="CompanyName"
                        inputRef={inputRef}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        label="Date Of Incorporation"
                        name="dateofIncorportation"
                        id="DateOfIncorporation"
                        value={loadCompanyDetails.dateofIncorportation}
                        onChange={(e) => handleCompanyDetails(e)}
                      />
                    </Grid>
                    {/* <Grid item xs={12} md={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        fullWidth
                        disableFuture
                        name="dateofIncorportation"
                        label="Date Of Incorportation"
                        dateFormat="dd/MM/yyyy" // Set the desired date format here
                        dateFormatCalendar="dd/MM/yyyy" // Set the desired format for the calendar view
                        locale="en-GB" // Set the locale to ensure the desired format
                        value={dateofIncorportation}
                        onChange={(e) => handleDateofIncorportation(e)}
                      />
                    </LocalizationProvider>
                  </Grid> */}
                    {/* <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        label="Nature of Business"
                        name="nature"
                        id="NatureofBusiness"
                        value={loadCompanyDetails.nature}
                        onChange={(e) => handleCompanyDetails(e)}
                      />
                    </Grid>{" "} */}
                    <Grid item xs={12} md={4}>
                      <TextField
                        select
                        name="nature"
                        id="NatureofBusiness"
                        onChange={(e) => handleCompanyDetails(e)}
                        fullWidth
                        label="Nature of Business"
                      >
                        {natureOfBusiness.map((option, key) => (
                          <MenuItem
                            key={key}
                            value={option.value}
                            sx={{
                              fontFamily: "'Roboto Condensed', sans-serif",
                              "&:hover": {
                                borderLeft: "5px solid #148F5B",
                                borderRadius: 1,
                              },
                            }}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        value={loadCompanyDetails.addressline}
                        onChange={(e) => handleCompanyDetails(e)}
                        fullWidth
                        label="Address Line"
                        name="addressline"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        value={loadCompanyDetails.landMark}
                        onChange={(e) => handleCompanyDetails(e)}
                        fullWidth
                        label="Landmark"
                        name="landMark"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        value={loadCompanyDetails.city}
                        onChange={(e) => handleCompanyDetails(e)}
                        fullWidth
                        label="City"
                        name="city"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        required
                        type={"number"}
                        value={loadCompanyDetails.pincode}
                        onChange={(e) => handleCompanyDetails(e)}
                        fullWidth
                        label="Pincode"
                        name="pincode"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        value={loadCompanyDetails.state}
                        onChange={(e) => handleCompanyDetails(e)}
                        fullWidth
                        label="State"
                        name="state"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 2,
                      }}
                    >
                      <Button
                        endIcon={<CheckIcon />}
                        disabled={
                          loanStatus == "Decline" ||
                          loanStatus == "" ||
                          loadCompanyDetails.companyName == "" ||
                          loadCompanyDetails.pincode == "" ||
                          saveStatus == "success"
                        }
                        onClick={handleSaveCompanyDetails}
                        variant="contained"
                        sx={{ textTransform: "capitalize" }}
                      >
                        Confirm & Continue
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
              <Grid item xs={12} md={1}></Grid>
              {saf_response_data ? (
                <Grid item xs={12} md={4} sx={{ mt: 4 }}>
                  <Grid container>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={10} sx={{ display: "flex" }}>
                      <div
                        style={{
                          height: "30px",
                          borderLeft: "4px solid #148F5B",
                        }}
                      ></div>
                      {directorDetailsSaved ? (
                        <>
                          <Typography
                            sx={{
                              ml: 1,
                              mt: 0.3,
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#4BB543",
                            }}
                          >
                            Directors Details
                          </Typography>
                          <DoneIcon sx={{ color: "#4BB543", ml: 1, mt: 0.2 }} />
                        </>
                      ) : (
                        <>
                          <Typography
                            sx={{
                              ml: 1,
                              mt: 0.3,
                              fontWeight: 600,
                              fontSize: 16,
                            }}
                          >
                            Directors Details
                          </Typography>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={10} sx={{}}>
                      <Typography
                        sx={{ ml: 1, mt: 3, fontWeight: 300, fontSize: 14 }}
                      >
                        Director details refer to specific information about a
                        company's board of directors, such as their names,
                        titles, professional backgrounds, and roles within the
                        company.
                      </Typography>
                      {/* <Typography
                  sx={{
                    mt: 1,
                    ml: 1,
                    fontWeight: 600,
                    fontSize: 12,
                    color: "#148F5B",
                    cursor: "pointer",
                  }}
                >
                  Learn More
                </Typography> */}
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
              <Grid item xs={12} md={1}></Grid>
              {saf_response_data ? (
                <Grid item xs={12} md={6} sx={{ mt: 4 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ display: "flex" }}>
                      <Typography>Add Director/Borrower</Typography>
                      <Button
                        onClick={handleAddNewDirector}
                        sx={{ ml: 1, mt: -0.5 }}
                        variant="outlined"
                      >
                        +
                      </Button>
                      <Typography
                        sx={{
                          color: "#FF0000",
                          fontSize: 12,
                          ml: 2,
                          mt: 0.5,
                        }}
                      >
                        *Minimum of one director details are required and
                        mandatory to have aadhaar
                      </Typography>
                    </Grid>

                    {inputFields?.map((inputField) => (
                      <>
                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            required
                            label="Name"
                            name="FullName"
                            id="FullName"
                            value={inputField.FullName}
                            onChange={(event) =>
                              handleChangeInput(inputField.id, event)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            name="PAN"
                            id="PAN"
                            label="PAN/DIN"
                            value={inputField.PAN}
                            onChange={(event) =>
                              handleChangeInput(inputField.id, event)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            error={shareError}
                            fullWidth
                            type={"number"}
                            name="ShareValue"
                            value={inputField.ShareValue}
                            id="ShareValue"
                            label="Share %"
                            onChange={(event) =>
                              handleChangeInput(inputField.id, event)
                            }
                            helperText={
                              shareError
                                ? "Total share % should be equal to 100."
                                : null
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            label="Phone No"
                            name="PhoneNo"
                            id="PhoneNo"
                            type={"number"}
                            value={inputField.PhoneNo}
                            onChange={(event) =>
                              handleChangeInput(inputField.id, event)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            type={"number"}
                            label="Aadhaar"
                            name="AadharDetails"
                            id="AadharDetalis"
                            value={inputField.AadharDetalis}
                            onChange={(event) =>
                              handleChangeInput(inputField.id, event)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={4} sx={{ display: "flex" }}>
                          <Checkbox
                            sx={{ ml: 0 }}
                            disabled={inputField.ShareValue < 25}
                            onClick={() =>
                              handleAadharClickDirector(
                                inputField.AadharDetails
                              )
                            }
                          />
                          <Typography sx={{ mt: 2 }}>
                            Aadhaar Verification
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            mt: 1.5,
                          }}
                        >
                          <DeleteIcon
                            onClick={() => handleRemoveDirector(inputField.id)}
                            sx={{ color: "#FF0000", cursor: "pointer" }}
                          />
                        </Grid>
                      </>
                    ))}

                    <Grid item xs={12} sx={{ mt: 2, display: "flex" }}>
                      <Checkbox
                        id="cibil-verification-director-saf"
                        sx={{ ml: -1 }}
                        checked={iscreditbureauCheckedDirector}
                        onChange={changeCreditcheckedDirector}
                      />
                      <Typography sx={{ mt: 1.3 }}>
                        Consent for credit bureau verification*
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 2,
                      }}
                    >
                      <Button
                        endIcon={<CheckIcon />}
                        disabled={
                          !saf_response_data ||
                          totalShare != 100 ||
                          !iscreditbureauCheckedDirector ||
                          directorDetailsSaved
                          // || !isDirectorAadharValidated
                        }
                        onClick={saveDirectorDetails}
                        variant="contained"
                        sx={{ textTransform: "capitalize" }}
                      >
                        Confirm & Continue
                      </Button>
                      {/* <Button
                      endIcon={<CheckIcon />}
                      onClick={handleSaveDetails}
                      disabled={
                        saveStatus != "success" ||
                        !saf_response_data ||
                        totalShare != 100
                      }
                      variant="contained"
                      full
                      sx={{ textTransform: "capitalize", pl: 5, pr: 5 }}
                    >
                      Save Details
                    </Button> */}
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
              <Grid item xs={12} md={1}></Grid>
              {saf_response_data ? (
                <Grid item xs={12} md={4} sx={{ mt: 4 }}>
                  <Grid container>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={10} sx={{ display: "flex" }}>
                      <div
                        style={{
                          height: "30px",
                          borderLeft: "4px solid #148F5B",
                        }}
                      ></div>
                      {isGuarantorDetailsCompleted ? (
                        <>
                          <Typography
                            sx={{
                              ml: 1,
                              mt: 0.3,
                              fontWeight: 600,
                              fontSize: 16,
                              color: "#4BB543",
                            }}
                          >
                            Guarantor's Details (Optional)
                          </Typography>
                          <DoneIcon sx={{ color: "#4BB543", ml: 1, mt: 0.2 }} />
                        </>
                      ) : (
                        <>
                          <Typography
                            sx={{
                              ml: 1,
                              mt: 0.3,
                              fontWeight: 600,
                              fontSize: 16,
                            }}
                          >
                            Guarantor's Details (Optional)
                          </Typography>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={10} sx={{}}>
                      <Typography
                        sx={{ ml: 1, mt: 3, fontWeight: 300, fontSize: 14 }}
                      >
                        A guarantor is a person or entity who agrees to take on
                        the responsibility of paying back a loan or fulfilling
                        an obligation if the original borrower or obligor is
                        unable to do so. Guarantor's details typically refer to
                        the personal information and financial details of the
                        guarantor, including their name, address, contact
                        information, employment status, income, and assets.
                      </Typography>
                      {/* <Typography
                  sx={{
                    mt: 1,
                    ml: 1,
                    fontWeight: 600,
                    fontSize: 12,
                    color: "#148F5B",
                    cursor: "pointer",
                  }}
                >
                  Learn More
                </Typography> */}
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
              <Grid item xs={12} md={1}></Grid>
              {saf_response_data ? (
                <Grid item xs={12} md={6} sx={{ mt: 4 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ display: "flex" }}>
                      <Typography>Add Guarantor(Optional)</Typography>
                      <Button
                        onClick={handleAddNewGuarantor}
                        sx={{ ml: 1, mt: -0.5 }}
                        variant="outlined"
                      >
                        +
                      </Button>
                    </Grid>
                    {guarantorDetails?.map((guarantor, index) => (
                      <>
                        <Grid item xs={12} md={12}>
                          <Typography
                            sx={{
                              mt: 2,
                              color: "#148F5B",
                              fontWeight: "800",
                            }}
                          >
                            Guarantor {index + 1}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            label="Full Name"
                            name="full_name"
                            required
                            id="FullName"
                            value={guarantor.full_name}
                            onChange={(event) =>
                              handleInputChange(guarantor.id, event)
                            }
                            // onChange={(event) =>
                            //   handleChangeInput(inputField.id, event)
                            // }
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            type={"number"}
                            label="Aadhaar"
                            name="aadhar"
                            id="AadharDetalis"
                            required
                            value={guarantor.aadhar}
                            onChange={(event) =>
                              handleInputChange(guarantor.id, event)
                            }
                          />
                        </Grid>

                        <Grid item xs={12} md={4} sx={{ display: "flex" }}>
                          <Checkbox
                            sx={{ ml: 0 }}
                            onClick={() =>
                              handleAadharClickGuarantor(guarantor.aadhar)
                            }
                          />
                          <Typography sx={{ mt: 2 }}>
                            Aadhaar Verification
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            label="PAN"
                            name="PAN"
                            id="PAN"
                            required
                            value={guarantor.PAN}
                            onChange={(event) =>
                              handleInputChange(guarantor.id, event)
                            }
                          />
                        </Grid>
                        <br></br>
                        <Grid item xs={12} sx={{ display: "flex" }}>
                          <Typography sx={{ fontSize: 12, ml: 2, mt: 0.5 }}>
                            *Address details of Guarantor(as per Aadhaar)
                          </Typography>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            label="Address Line 1"
                            name="address_lne1"
                            id="AddressLine1"
                            value={guarantor.address_lne1}
                            onChange={(event) =>
                              handleInputChange(guarantor.id, event)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            label="Address Line 2"
                            name="address_line2"
                            id="AddressLine2"
                            value={guarantor.address_line2}
                            onChange={(event) =>
                              handleInputChange(guarantor.id, event)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            label="Landmark"
                            name="landmark"
                            id="Landmark"
                            value={guarantor.landmark}
                            onChange={(event) =>
                              handleInputChange(guarantor.id, event)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            label="City"
                            name="city"
                            id="City"
                            value={guarantor.city}
                            onChange={(event) =>
                              handleInputChange(guarantor.id, event)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            type={"number"}
                            label="Pin Code"
                            name="pincode"
                            id="PinCode"
                            value={guarantor.pincode}
                            onChange={(event) =>
                              handleInputChange(guarantor.id, event)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            label="State"
                            name="state"
                            id="State"
                            value={guarantor.state}
                            onChange={(event) =>
                              handleInputChange(guarantor.id, event)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sx={{ display: "flex" }}>
                          <Typography
                            sx={{
                              color: "#FF0000",
                              fontSize: 12,
                              ml: 2,
                              mt: 0.5,
                            }}
                          >
                            *Upload either Guarantor's Bank Statement or ITR
                            Document.
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            select
                            required
                            onChange={(event) =>
                              handleInputChange(guarantor.id, event)
                            }
                            // onChange={(e) => handleGauarantorDocument(e.target.value)}
                            fullWidth
                            value={guarantor.documentType}
                            name="documentType"
                            label="Document Type"
                          >
                            {guarantorDocument.map((option, key) => (
                              <MenuItem
                                key={key}
                                value={option.value}
                                sx={{
                                  fontFamily: "'Roboto Condensed', sans-serif",
                                  "&:hover": {
                                    borderLeft: "5px solid #148F5B",
                                    borderRadius: 1,
                                  },
                                }}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          sx={{ display: "flex" }}
                          className="uploadFileGuarantor"
                        >
                          <div>
                            <input
                              ref={inputRef}
                              type="file"
                              required
                              onChange={(event) =>
                                onFileInputChange(guarantor.id, event)
                              }
                            />
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={1}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: 1.5,
                          }}
                        >
                          <DeleteIcon
                            onClick={() => handleRemoveGuarantor(guarantor.id)}
                            sx={{ color: "#FF0000", cursor: "pointer" }}
                          />
                        </Grid>
                      </>
                    ))}
                    {guarantorDetails.length > 0 ? (
                      <Grid
                        item
                        xs={12}
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          endIcon={<CheckIcon />}
                          onClick={handleSaveGuarantorDetails}
                          // disabled={!isGuarantorAadharValidated}
                          // disabled={!formValid}
                          disabled={isGuarantorDetailsCompleted}
                          variant="contained"
                          sx={{ textTransform: "capitalize" }}
                        >
                          Confirm & Continue
                        </Button>
                        <Dialog open={open} onClose={handleClose}>
                          <DialogTitle>
                            Error- Guarantor File Required{" "}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                              Guarantor file is required. Please click the
                              'Browse' or 'Choose File' button to select a file
                              from your device.
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              sx={{ textTransform: "capitalize" }}
                              onClick={handleClose}
                            >
                              Close
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </Grid>
                    ) : null}
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 2,
                      }}
                    >
                      {messsage ==
                      "The company director data saved successfully." ? (
                        <>
                          <Button
                            onClick={handleMovieStep}
                            disabled={
                              saveStatus != "success" || !saf_response_data
                            }
                            variant="contained"
                            full
                            sx={{ textTransform: "capitalize", pl: 5, pr: 5 }}
                          >
                            Next Step
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            endIcon={<CheckIcon />}
                            onClick={handleSaveDetails}
                            disabled={
                              saveStatus != "success" ||
                              !saf_response_data ||
                              totalShare != 100 ||
                              !directorDetailsSaved
                            }
                            variant="contained"
                            full
                            sx={{ textTransform: "capitalize", pl: 5, pr: 5 }}
                          >
                            Save Details
                          </Button>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
            </>
          </>
        ) : null}
        <Grid item xs={12} md={1}></Grid>
        <Footer />
      </Grid>
      {aadharDrawer ? (
        <AadharVerification
          selectedAadhar={selectedAadhar}
          isCoBorrower={isCoBorrower}
        />
      ) : null}
      {aadharDrawerCoborrower ? (
        <AadharVerificationCoborrower
          selectedAadhar={selectedAadharCoborrower}
          isCoBorrower={isCoBorrower}
        />
      ) : null}
      <MobileOTPVerification2
        open={openOTPDialog}
        handleClose={handleCloseOTPDialog}
        data={loadPersonalDetails.phoneNo}
      />
      <EmailOTPVerification
        open={openOTPEmailDialog}
        handleClose={handleCloseEmailOTPDialog}
      />
      <EmailOTPVerificationCoborrower
        open={openOTPEmailDialogCoborrower}
        email={coborrowerEmail}
        handleClose={handleCloseEmailOTPDialogCoborrower}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={
          safLoading ||
          companyDetailsLoading ||
          saveDetailsLoading ||
          guarantorDetailsLoading ||
          panErrorLoading
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isApiLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {confirmDailog ? <DialogView /> : null}
    </>
  );
}

export default Index2;