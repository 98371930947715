import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import {
  UploadDocument,
  uploadPassport,
  UploadDocumentDialog,
  UploadDocumentClearData,
  misssingDocumentStatus,
  GetLoanDetails,
  getBankNames,
  uploadPAN,
} from "../BasicDetails/BasicDetailsActions/BasicDetailsActions";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useNavigate } from "react-router-dom";
import SessionModal from "./../../Common/SessionModel";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UploadDocumentPopup(data) {
  const [documentsMissing, setDocumentsMissing] = useState([]);
  const [bankName1, setBankName1] = React.useState("");
  const [businessLicence1, setBuisnessLicence1] = React.useState("");
  const [businessVintage1, setBuisnessVintage1] = React.useState("");
  const [employmentType, setemploymentType] = React.useState();
  const [bankStatement1, setBankStatement1] = React.useState("");
  const [password1, setpassword1] = React.useState("");
  const [gstDoc, setGstDoc] = React.useState([]);
  const [financailStatemnt, setfinancailStatemnt] = React.useState([]);
  const [salaryStatements, setSalaryStatements] = React.useState([]);
  const [itrStatements, setitrStatements] = React.useState([]);
  const [passport, setpassport] = React.useState([]);
  const [PAN, setPAN] = React.useState([]);
  const [has401Error, sethas401Error] = React.useState(false);
  const [isUploaded, setisUploaded] = React.useState(false);
  const [accumulatedArray, setAccumulatedArray] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    localStorage.setItem("LoanApplicationId", data.data.Loan_Application_id);
    setemploymentType(data.data.constitution_type_id);
    setDocumentsMissing(data.data.missing_documents.split(", "));
  }, []);
  const bank = useSelector((state) => state.BasicDetailsReducers.bankNames);
  useEffect(() => {
    dispatch(getBankNames(handleLogoutButton));
  }, []);
  const uploadDialog = useSelector(
    (state) => state.BasicDetailsReducers.uploadDailog
  );
  const uploadDocumentResponse = useSelector(
    (state) => state.BasicDetailsReducers.uploadDocumentResponse
  );
  useEffect(() => {
    if (uploadDocumentResponse != null) {
      const has401Error1 = uploadDocumentResponse?.upload_status.some(
        (status) => status.errorCode === 401
      );
      if (has401Error1 || uploadDocumentResponse.code == 500) {
        sethas401Error(true);
        setisUploaded(false);
      } else {
        dispatch(UploadDocumentDialog(false));
        dispatch(UploadDocumentClearData());
        dispatch(GetLoanDetails(handleLogoutButton));
        setisUploaded(false);
      }
    }
  }, [uploadDocumentResponse]);
  const handleBankNameChange1 = (e) => {
    setBankName1(e.target.value);
  };
  const handleBankStatement1 = (e) => {
    setBankStatement1(e.target.files[0]);
  };
  const handlePasswordChange1 = (e) => {
    setpassword1(e.target.value);
  };
  const handlefinancailStatemnt = (e) => {
    setfinancailStatemnt(e.target.files);
  };
  const handleBuisnessLicence = (e) => {
    setBuisnessLicence1(e.target.files[0]);
  };
  const handleBuisnessVintage = (e) => {
    setBuisnessVintage1(e.target.files[0]);
  };
  const handleGstDoc = (e) => {
    setGstDoc(e.target.files);
  };
  const handleSalaryStatement = (e) => {
    setSalaryStatements(e.target.files);
  };
  const handleITRStatement = (e) => {
    setitrStatements(e.target.files);
  };
  const handlePassport = (e) => {
    setpassport(e.target.files);
  };
  const handlePAN = (e) => {
    setPAN(e.target.files);
  };
  const handleClose = () => {
    dispatch(UploadDocumentDialog(false));
  };
  useEffect(() => {
    const missingDocumentsArray = data.data.missing_documents.split(", ");

    const updatedArray = [];

    if (bankStatement1 !== "") {
      updatedArray.push("Bank Statement");
    }

    if (salaryStatements.length > 0) {
      updatedArray.push("Salary Slip");
    }

    if (gstDoc.length > 0) {
      updatedArray.push("GST Document");
    }

    if (itrStatements.length > 0) {
      updatedArray.push("ITR Document");
    }

    if (financailStatemnt.length > 0) {
      updatedArray.push("Financial Statement");
    }

    if (passport.length > 0) {
      updatedArray.push("Passport/Aadhar");
    }
    if (PAN.length > 0) {
      updatedArray.push("PAN");
    }
    if (businessLicence1 !== "") {
      updatedArray.push("Business Licence");
    }
    if (businessVintage1 !== "") {
      updatedArray.push("Business Vintage");
    }

    setAccumulatedArray(updatedArray);

    const newArray = missingDocumentsArray.filter(
      (item) => !updatedArray.includes(item)
    );

    // Update documentsMissing if needed
    setDocumentsMissing(newArray);
  }, [
    financailStatemnt,
    itrStatements,
    salaryStatements,
    gstDoc,
    bankStatement1,
    passport,
    PAN,
    businessLicence1,
    businessVintage1
  ]);
  //--------------------------------------------------------------
  const handleLogoutButton = () => {
    localStorage.removeItem("jwt_token");
    localStorage.removeItem("EmailId");
    localStorage.removeItem("branch");
    setShowAlert(true);
  };
  const handleCloseErrorModal1 = () => {
    setShowAlert(false);
    navigate("/login");
  };
  const handleConfirmAllDetails = () => {
    setisUploaded(true);
    dispatch(
      UploadDocument(
        "",
        financailStatemnt,
        gstDoc,
        "",
        salaryStatements,
        itrStatements,
        bankName1,
        "",
        "",
        "",
        "",
        "",
        bankStatement1,
        "",
        "",
        "",
        "",
        "",
        businessVintage1,
        businessLicence1,
        true,
        password1,
        "",
        "",
        "",
        "",
        "",
        handleLogoutButton
      )
    );
    if (passport != null && passport != "") {
      dispatch(
        uploadPassport(
          data.data.Loan_Application_id,
          passport[0],
          handleLogoutButton
        )
      );
    }
    if (PAN != null && PAN != "") {
      dispatch(
        uploadPAN(data.data.Loan_Application_id, PAN[0], handleLogoutButton)
      );
    }
    if (documentsMissing.length == 0) {
      dispatch(misssingDocumentStatus(data.data.Loan_Application_id, ""));
    } else {
      const myArrayAsString = documentsMissing.join(", ");
      dispatch(
        misssingDocumentStatus(data.data.Loan_Application_id, myArrayAsString)
      );
    }
  };
  return (
    <div>
      <div>
        {/* Your component content */}
        <SessionModal isOpen={showAlert} onClose={handleCloseErrorModal1} />
      </div>
      <Dialog
        fullScreen
        open={uploadDialog}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Upload Documents
            </Typography>
            <Button
              autoFocus
              color="inherit"
              sx={{ textTransform: "capitalize" }}
              onClick={handleClose}
            >
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <Grid container>
          <Grid item xs={12}>
            <Grid item sx={{ m: 3 }}>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: 16,
                  color: "#FF0000",
                }}
              >
                Missing Documents: {data.data.missing_documents}
              </Typography>
            </Grid>
            {has401Error == true ? (
              <Box style={{ marginLeft: "5%", textAlign: "center" }}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    ml: 5,
                    ml: 0,
                    justifyContent: "center",
                  }}
                >
                  <Alert severity="error">
                    Please try uploading correct bank statement
                  </Alert>
                </Grid>
              </Box>
            ) : null}
            {data.data.missing_documents.includes("Bank Statement") ? (
              <Grid item xs={12} md={12} sx={{ m: 4 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={3}>
                    <Typography
                      variant="body1"
                      sx={{
                        ml: 1,
                        mt: 0.3,
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      Bank Statement
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      select
                      id="Choose Bank"
                      label="Choose Bank"
                      variant="outlined"
                      onChange={handleBankNameChange1}
                      fullWidth
                    >
                      {bank.response_data &&
                        bank.response_data.map((option) => (
                          <MenuItem
                            key={option.bank_name}
                            value={option.bank_name}
                            sx={{
                              fontFamily: "'Roboto Condensed', sans-serif",
                              "&:hover": {
                                borderLeft: "5px solid #148F5B",
                                borderRadius: 1,
                              },
                            }}
                          >
                            {option.bank_name}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <div
                      style={{
                        border: "1px solid #dadada",
                        height: 55,
                        borderRadius: 3,
                      }}
                    >
                      <form id="inputType1">
                        <input
                          style={{
                            marginTop: 15,
                            marginLeft: 10,
                            width: "91%",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          type="file"
                          onChange={handleBankStatement1}
                          name="bankStatement"
                        ></input>
                      </form>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      fullWidth
                      type="password"
                      variant="outlined"
                      name="Password1"
                      label="Password (Optional)"
                      onChange={handlePasswordChange1}
                      value={password1}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
            {data.data.missing_documents.includes("Business Licence") ? (
              <Grid item xs={12} md={12} sx={{ m: 4 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={3}>
                    <Typography
                      variant="body1"
                      sx={{
                        ml: 1,
                        mt: 0.3,
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      Business Licence
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <div
                      style={{
                        border: "1px solid #dadada",
                        height: 55,
                        borderRadius: 3,
                      }}
                    >
                      <form id="inputType1">
                        <input
                          style={{
                            marginTop: 15,
                            marginLeft: 10,
                            width: "91%",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          type="file"
                          onChange={handleBuisnessLicence}
                          name="bankStatement"
                        ></input>
                      </form>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
            {data.data.missing_documents.includes("Business Vintage") ? (
              <Grid item xs={12} md={12} sx={{ m: 4 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={3}>
                    <Typography
                      variant="body1"
                      sx={{
                        ml: 1,
                        mt: 0.3,
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      Business Vintage
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <div
                      style={{
                        border: "1px solid #dadada",
                        height: 55,
                        borderRadius: 3,
                      }}
                    >
                      <form id="inputType1">
                        <input
                          style={{
                            marginTop: 15,
                            marginLeft: 10,
                            width: "91%",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          type="file"
                          onChange={handleBuisnessVintage}
                          name="bankStatement"
                        ></input>
                      </form>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
            {employmentType != 1 &&
            employmentType != 2 &&
            employmentType != 7 ? (
              <>
                {data.data.missing_documents.includes("Financial Document") ? (
                  <Grid item xs={12} md={12} sx={{ m: 4 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={3}>
                        <Typography
                          variant="body1"
                          sx={{
                            ml: 1,
                            mt: 0.3,
                            fontWeight: 600,
                            fontSize: 16,
                          }}
                        >
                          Financial Document
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <div
                          style={{
                            border: "1px solid #dadada",
                            height: 55,
                            borderRadius: 3,
                          }}
                        >
                          <form id="inputType1">
                            <input
                              style={{ marginTop: 15, marginLeft: 10 }}
                              type="file"
                              onChange={handlefinancailStatemnt}
                              name="financialStatement"
                            ></input>
                          </form>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
                {data.data.missing_documents.includes("GST Document") ? (
                  <Grid item xs={12} md={12} sx={{ m: 4 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={3}>
                        <Typography
                          variant="body1"
                          sx={{
                            ml: 1,
                            mt: 0.3,
                            fontWeight: 600,
                            fontSize: 16,
                          }}
                        >
                          GST Document
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <div
                          style={{
                            border: "1px solid #dadada",
                            height: 55,
                            borderRadius: 3,
                          }}
                        >
                          <form id="inputType1">
                            <input
                              style={{ marginTop: 15, marginLeft: 10 }}
                              type="file"
                              onChange={handleGstDoc}
                              name="gstStatement"
                              multiple
                            ></input>
                          </form>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
              </>
            ) : (
              <>
                {data.data.missing_documents.includes("Salary Slip") ? (
                  <Grid item xs={12} md={12} sx={{ m: 4 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={3}>
                        <Typography
                          variant="body1"
                          sx={{
                            ml: 1,
                            mt: 0.3,
                            fontWeight: 600,
                            fontSize: 16,
                          }}
                        >
                          Salary Slip
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <div
                          style={{
                            border: "1px solid #dadada",
                            height: 55,
                            borderRadius: 3,
                          }}
                        >
                          <form id="inputType1">
                            <input
                              style={{ marginTop: 15, marginLeft: 10 }}
                              type="file"
                              onChange={handleSalaryStatement}
                              name="bankStatement"
                              multiple
                            ></input>
                          </form>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
                {data.data.missing_documents.includes("ITR Document") ? (
                  <Grid item xs={12} md={12} sx={{ m: 4 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={3}>
                        <Typography
                          variant="body1"
                          sx={{
                            ml: 1,
                            mt: 0.3,
                            fontWeight: 600,
                            fontSize: 16,
                          }}
                        >
                          ITR Document
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <div
                          style={{
                            border: "1px solid #dadada",
                            height: 55,
                            borderRadius: 3,
                          }}
                        >
                          <form id="inputType1">
                            <input
                              style={{ marginTop: 15, marginLeft: 10 }}
                              type="file"
                              onChange={handleITRStatement}
                              name="bankStatement"
                              multiple
                            ></input>
                          </form>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
                {data.data.missing_documents.includes("Passport/Aadhar") ? (
                  <Grid item xs={12} md={12} sx={{ m: 4 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={3}>
                        <Typography
                          variant="body1"
                          sx={{
                            ml: 1,
                            mt: 0.3,
                            fontWeight: 600,
                            fontSize: 16,
                          }}
                        >
                          Passport/Aadhar Card
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <div
                          style={{
                            border: "1px solid #dadada",
                            height: 55,
                            borderRadius: 3,
                          }}
                        >
                          <form id="inputType1">
                            <input
                              style={{ marginTop: 15, marginLeft: 10 }}
                              type="file"
                              onChange={handlePassport}
                              name="passport"
                            ></input>
                          </form>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
                {data.data.missing_documents.includes("PAN") ? (
                  <Grid item xs={12} md={12} sx={{ m: 4 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={3}>
                        <Typography
                          variant="body1"
                          sx={{
                            ml: 1,
                            mt: 0.3,
                            fontWeight: 600,
                            fontSize: 16,
                          }}
                        >
                          PAN
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <div
                          style={{
                            border: "1px solid #dadada",
                            height: 55,
                            borderRadius: 3,
                          }}
                        >
                          <form id="inputType1">
                            <input
                              style={{ marginTop: 15, marginLeft: 10 }}
                              type="file"
                              onChange={handlePAN}
                              name="pan"
                            ></input>
                          </form>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
              </>
            )}
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "flex-end", m: 4 }}
            >
              <Button
                disabled={isUploaded}
                onClick={handleConfirmAllDetails}
                endIcon={<CloudUploadIcon />}
                variant="contained"
                sx={{ textTransform: "capitalize" }}
              >
                Upload Documents
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
