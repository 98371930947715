import React, { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import { Button, CircularProgress, TextField, Typography } from "@mui/material";
import NavBar from "../NavBar/Index";
import AssignmentIcon from "@mui/icons-material/Assignment";
import TableView from "../LoanConfirmation/TableView";
import {
  GetLoanDetails,
  GetLoanDetailsById,
  TopbarViewSteps,
} from "../BasicDetails/BasicDetailsActions/BasicDetailsActions";
import { useSelector, useDispatch } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  LoanConfirm,
  BRE,
  KFSDocument,
} from "../BasicDetails/BasicDetailsActions/BasicDetailsActions";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import CircularProgressWithLabel from "../../Common/CircularProgressWithLabel";
import Footer from "./../../Common/Footer";
import SessionModal from "./../../Common/SessionModel";
import GenInsight from "../GenInsight/GenInsight";
import WebcamWithFaceDetection from "./WebcamComponent";
function Index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const htmlFilePath = process.env.PUBLIC_URL + "/MITC_html.html";
  const [htmlContent, setHtmlContent] = useState(null);
  const divRef = useRef(null);
  const [istermschecked, setistermschecked] = React.useState(true);
  const [iskycchecked, setiskycchecked] = React.useState(false);
  const [photoTaken, setPhotoTaken] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [kycConfirm, setkycConfirm] = useState(false);
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    //dispatch(BRE(handleLogoutButton));
    const emailId = localStorage.getItem("EmailId");
    //setEmail(emailId);
  }, []);

  useEffect(() => {
    const fetchDocument = async () => {
      try {
        const response = await dispatch(KFSDocument(loanApplicationId));
        console.log(response?.data?.status_code);

        if (response?.data?.status_code === 200) {
          setLoading(false);
          const base64Content = response?.data?.data;
          const base64Data = base64Content;
          const pdfData = atob(base64Data);
          const uint8Array = new Uint8Array(pdfData.length);
          for (let i = 0; i < pdfData.length; i++) {
            uint8Array[i] = pdfData.charCodeAt(i);
          }
          const pdfBlob = new Blob([uint8Array], { type: "application/pdf" });
          const pdfUrl = URL.createObjectURL(pdfBlob);
          setData(pdfUrl);
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching document:', error);
        setLoading(false);
      }
    };
    fetchDocument();
  }, []);

  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    const fetchHtmlContent = async () => {
      try {
        const response = await fetch(htmlFilePath);
        const content = await response.text();
        setHtmlContent(content);
      } catch (error) {
        console.error("Error fetching HTML content:", error);
      }
    };

    fetchHtmlContent();
  }, [htmlFilePath]);
  const handleConfirm = () => {
    const emailId = localStorage.getItem("EmailId");
    dispatch(LoanConfirm(emailId, handleLogoutButton));
    navigate("/MyLoan");
    localStorage.removeItem("LoanApplicationId");
    // window.location.reload();
    //setOpen(true);
  };
  const handleScroll = () => {
    const div = divRef.current;
    if (div) {
      const isBottom = div.scrollHeight - div.scrollTop <= div.clientHeight + 1;
      setIsScrolledToBottom(isBottom);
    }
  };
  const handlePhotoTaken = (taken) => {
    setPhotoTaken(taken);
  };
  const handleClose = () => {
    if (loanConfirmResponse == "success") {
      setOpen(false);
      navigate("/BasicDetails");
      window.location.reload();
      localStorage.removeItem("LoanApplicationId");
    } else {
      setOpen(false);
    }
  };
  const [email, setEmail] = React.useState("");
  const [emailSavedSucessfully, setemailSavedSucessfully] =
    React.useState(false);
  const [emailError, setEmailError] = React.useState("");
  const changetermsChecked = () => {
    setistermschecked(!istermschecked);
  };
  const changekycChecked = () => {
    setiskycchecked(!iskycchecked);
    setistermschecked(!istermschecked);
  };
  const handlesave = () => {
    setemailSavedSucessfully(true);
    dispatch(LoanConfirm(email, handleLogoutButton));
  };
  //-----------------------------------------------------------------------

  const breLoading = useSelector(
    (state) => state.BasicDetailsReducers.breLoading
  );
  const breResponse = useSelector(
    (state) => state.BasicDetailsReducers.breResponse
  );
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);

    if (validateEmail(inputEmail)) {
      setEmailError("");
    } else {
      setEmailError("Invalid email address");
    }
  };
  const loanApplicationId = localStorage.getItem("LoanApplicationId");

  useEffect(() => {
    dispatch(GetLoanDetailsById(loanApplicationId, handleLogoutButton));
  }, [breResponse]);
  //------------------------------------------------------------------------

  const loanConfirmResponse = useSelector(
    (state) => state.BasicDetailsReducers.loanConfirmResponse
  );

  const KfsDocumentResponse = useSelector(
    (state) => state.BasicDetailsReducers.KfsDocumentResponse
  );

  const getLoanDetailsLoading = useSelector(
    (state) => state.BasicDetailsReducers.getLoanDetailsLoading
  );

  const getLoanDetailsResponse = useSelector(
    (state) => state.BasicDetailsReducers.getLoanDetailsResponse
  );
  const handleMyLoan = () => {
    dispatch(TopbarViewSteps(5));
    dispatch(GetLoanDetails(handleLogoutButton));
    navigate("/MyLoan");
  };
  const handleLogoutButton = () => {
    localStorage.removeItem("jwt_token");
    localStorage.removeItem("EmailId");
    localStorage.removeItem("branch");
    setShowAlert(true);
  };
  const handleCloseErrorModal1 = () => {
    setShowAlert(false);
    navigate("/login");
  };

  return (
    <>
      <Grid container sx={{ mb: 8 }}>
        <GenInsight />
        <Grid item xs={12}>
          <NavBar />
        </Grid>
        <div>
          {/* Your component content */}
          <SessionModal isOpen={showAlert} onClose={handleCloseErrorModal1} />
        </div>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 3,
            height: "9vh",
            background: "#e5f6fd",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: 900,
              fontSize: "20px",
            }}
          >
            Loan Confirmation
          </Typography>
        </Grid>
        <Grid item xs={12} md={5}></Grid>
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            height: "9vh",
            mt: 6,
          }}
        >
          <Typography onClick={handleMyLoan} sx={{ cursor: "pointer", ml: 4 }}>
            My Loans
          </Typography>
          <AssignmentIcon
            onClick={handleMyLoan}
            sx={{ ml: 1, color: "#148F5B" }}
          />
        </Grid>
        {getLoanDetailsLoading || breLoading ? (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 5,
            }}
          >
            <CircularProgressWithLabel />
          </Grid>
        ) : (
          <>
            <Grid item xs={1} sx={{ mt: 2 }}></Grid>
            <Grid item xs={10} sx={{ mt: 3 }}>
              <TableView />
            </Grid>
            {getLoanDetailsResponse && getLoanDetailsResponse != undefined ? (
              <>
                <Grid item xs={1} sx={{ mt: 2 }}></Grid>
                {getLoanDetailsResponse.loan_status == "DECLINE" ||
                  getLoanDetailsResponse.loan_status == "Decline" ? (
                  <>
                    <Grid item xs={1} sx={{ mt: 2 }}></Grid>
                    <Grid item xs={10} sx={{ mt: 5 }}>
                      {/* <div
                        ref={divRef}
                        style={{
                          textAlign: "center",
                          overflowY: "auto",
                          maxHeight: "400px",
                          border: "1px solid #ccc",
                          padding: "10px",
                        }}
                        onScroll={handleScroll}
                      >
                        <h4>Most Important Terms and Conditions</h4>
                        <div>
                          {loading ? <CircularProgress /> :
                            (<>
                              <div style={{ width: '100%', height: "60vh" }}>
                                <iframe
                                  src={data}
                                  width="100%"
                                  height="60vh"
                                  title="PDF Viewer"
                                  style={{ border: 'none' }}
                                ></iframe>
                              </div></>)}
                        </div>
                      </div> */}
                    </Grid>
                    <Grid item xs={1} sx={{ mt: 2 }}></Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={1} sx={{ mt: 2 }}></Grid>
                    <Grid item xs={10} sx={{ mt: 5 }}>
                      {/* <div
                        ref={divRef}
                        style={{
                          textAlign: "center",
                          overflowY: "auto",
                          maxHeight: "90vh",
                          border: "1px solid #ccc",
                          padding: "10px",
                        }}
                        onScroll={handleScroll}
                      >
                        <h4>Most Important Terms and Conditions</h4>
                        <div
                        // dangerouslySetInnerHTML={{ __html: htmlContent }}
                        >
                          {loading ? <CircularProgress /> :
                            (<>
                              <div style={{ width: '100%', height: '100%' }}>
                                <iframe
                                  src={data}
                                  width="100%"
                                  height="600px"
                                  title="PDF Viewer"
                                  style={{ border: 'none' }}
                                ></iframe>
                              </div></>)}
                        </div>
                      </div> */}
                      {/* <div style={{ display: "flex" }}>
                        <Checkbox
                          checked={istermschecked}
                          onChange={changetermsChecked}
                          sx={{ ml: -1, mt: 2 }}
                          size="small"
                        />
                        <Typography sx={{ mt: 3 }}>
                          Accept terms and conditions
                        </Typography>
                      </div> */}
                      {!photoTaken ? (
                        <div style={{ display: "flex" }}>
                          <Checkbox
                            checked={iskycchecked}
                            onChange={changekycChecked}
                            sx={{ ml: -1, mt: 2 }}
                            size="small"
                          />
                          <Typography sx={{ mt: 3 }}>
                            Video KYC
                          </Typography>
                        </div>
                      ) : (
                        <div style={{ display: "flex" }}>
                          <Checkbox
                            checked={iskycchecked}
                            onChange={changekycChecked}
                            sx={{ ml: -1, mt: 2 }}
                            size="small"
                            disabled
                          />
                          <Typography sx={{ mt: 3 }}>
                            Video KYC
                          </Typography>
                        </div>
                      )}
                      {iskycchecked && <WebcamWithFaceDetection onPhotoTaken={handlePhotoTaken} loanId={loanApplicationId} />}
                      <Button
                        variant="contained"
                        onClick={handleConfirm}
                        disabled={!photoTaken}
                        sx={{ textTransform: "capitalize", mt: 2 }}
                      >
                        Confirm Loan
                      </Button>
                    </Grid>
                    <Grid item xs={1} sx={{ mt: 2 }}></Grid>
                  </>
                )}
              </>
            ) : null}
          </>
        )}
        <Footer />
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Loan Confirmation </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to confirm the loan? Please provide
            applicant's email address for further communication with us.
          </DialogContentText>
          <TextField
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            value={email}
            variant="standard"
            onChange={handleEmailChange}
            error={!!emailError}
            helperText={emailError}
          />
        </DialogContent>
        {loanConfirmResponse == "success" ? (
          <DialogContent>
            <Alert severity="success">
              Verification initiated successfully!
            </Alert>
          </DialogContent>
        ) : (
          //   <<DialogContent>
          //     <Alert severity="error">
          //       Sorry! failed to initiate verification
          //     </Alert>
          //   </DialogContent>>
          <></>
        )}

        <DialogActions>
          <Button sx={{ textTransform: "capitalize" }} onClick={handleClose}>
            Close
          </Button>
          <Button
            disabled={
              loanConfirmResponse == "success" ||
              emailError != "" ||
              emailSavedSucessfully ||
              email == ""
            }
            sx={{ textTransform: "capitalize" }}
            onClick={handlesave}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Index;
