import React from "react";
import "./digitalLoans.css";
import Header from "../../Common/Header/Header";
import Grid from "@mui/material/Grid";
import image1 from "../../Assets/RBIregistered.png";
import Box from "@mui/material/Box";
import { useState } from "react";
import { base_url2 } from "../../constants";
import Axios from "axios";
import ReplayIcon from "@mui/icons-material/Replay";
import {
  Button,
  TextField,
  InputAdornment,
  Typography,
  Divider,
  Paper,
  Container,
} from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { lightTheme } from "../../Common/Theme";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import {
  RegistrationUser,
  navigateFromDigitalLoans,
} from "../BasicDetails/BasicDetailsActions/BasicDetailsActions";
import Alert from "@mui/material/Alert";
import featureicon1 from "./../../Assets/featureicon1.png";
import featureicon2 from "./../../Assets/featureicon2.png";
import featureicon3 from "./../../Assets/featureicon3.png";
import featureicon4 from "./../../Assets/featureicon4.png";
import logo from "./../../Assets/customLogo.png";
import socialMediaicon from "./../../Assets/socialMedia.png";
import socialMediaicon1 from "./../../Assets/socialMedia1.png";
import socialMediaicon2 from "./../../Assets/socialMedia2.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const BackgroundImageComponent = () => {
  const [open, setOpen] = useState(false);
  const [loginCompleted, setloginCompleted] = React.useState(true);
  const [emailError, setEmailError] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);
  const [duplicateRecord, setduplicateRecord] = React.useState(false);
  const [UsernameValid, setUsernameValid] = React.useState(false);
  const [OTPerror, setOTPerror] = React.useState(false);
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [phoneNoError, setPhoneNoError] = useState(false);
  const GenericMobileNumberRegex = /^\d{10}$/;
  const [zipCode, setzipCode] = useState(false);
  const [captcha, setCaptcha] = useState(null);
  const [isCaptchaError, setCaptchaError] = useState(false);
  const [signupErrorMessage, setSignupErrorMessage] = useState("");
  const zipCodeRegex = /^\d{6}(?:[-\s]\d{4})?$/;
  const steps = [
    {
      title: "Trust",
      description: "Custom-tailored product suites for SMEs and MSMEs",
    },
    { title: "Speed", description: "Decision in minutes" },
    {
      title: "Convenience",
      description: "No documentation needed & repay at your own pace",
    },
  ];

  const initiateSession = () => {
    Axios.get(`${base_url2}/digitalLoans/initiateSession`)
      .then((result) => {
        if (result.status === 200) {
          setCaptcha(result.data?.data?.captcha);
          setregistration({
            ...registration,
            ["session_id"]: result.data?.data?.session_id,
          });
          setCaptchaError(false);
        }
      })
      .catch((error) => {
        setCaptchaError(true);
      });
  };
  useEffect(() => {
    localStorage.removeItem("jwt_token");
    localStorage.removeItem("EmailId");
    localStorage.removeItem("branch");
    initiateSession();
  }, []);
  const [registration, setregistration] = useState({
    username: "",
    email: "",
    mobileNo: "",
    zipcode: "",
  });
  const handleClose = () => {
    setOpen1(false);
    setduplicateRecord(false);
    setloginCompleted(true);
    setregistration({
      ...registration,
      username: "",
      email: "",
      mobileNo: "",
      zipcode: "",
    });
  };
  const navigateToLogin = (e) => {
    navigate("/login");
    dispatch(navigateFromDigitalLoans());
  };
  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleInputChangeRegistration = (e) => {
    if (e.target.name == "email") {
      const valueWithoutSpaces = e.target.value.replace(/\s/g, "");
      setregistration({ ...registration, [e.target.name]: valueWithoutSpaces });
    } else {
      setregistration({ ...registration, [e.target.name]: e.target.value });
    }
    // if (e.target.name == "email") {
    //   const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    //   if (regex.test(e.target.value)) {
    //     setEmailError(false);
    //   } else {
    //     setEmailError(true);
    //   }
    // }
    if (e.target.name == "mobileNo") {
      setregistration({ ...registration, [e.target.name]: e.target.value });
      // Check if the input matches the generic regex pattern
      const isValid = GenericMobileNumberRegex.test(e.target.value);

      // Update the error state based on the validation result
      setPhoneNoError(!isValid);
    }
    // if (e.target.name == "zipcode") {
    //   setregistration({ ...registration, [e.target.name]: e.target.value });
    //   // Check if the input matches the generic regex pattern
    //   const isValid = zipCodeRegex.test(e.target.value);

    //   // Update the error state based on the validation result
    //   setzipCode(!isValid);
    // }
    if (e.target.name == "user_captcha") {
      setregistration({ ...registration, [e.target.name]: e.target.value });
    }
  };
  const [open1, setOpen1] = React.useState(false);
  const handleLoginSignup = async (e) => {
    setduplicateRecord(false);
    setloginCompleted(false);
    setUsernameValid(false);

    // if (registration.email === "") {
    //   setEmailError(true);
    // }
    if (registration.username === "") {
      setUsernameValid(true);
    }
    if (registration.username === "") {
      setloginCompleted(true);
      return;
    }

    // ... (other validation and setup code)

    if ( phoneNoError) {
      setloginCompleted(true);
    } else {
      setOTPerror(false);

      try {
        const response = await RegistrationUser(registration, "digital_advert");

        if (response.status === 200) {
          setOpen1(true);
          setloginCompleted(true);
          // localStorage.setItem("EmailId", registration.email);

          setOpen(true);
          setOpen1(false);
          console.log(response);
          localStorage.setItem("EmailId", response.data.response_data.user_id);
          localStorage.setItem(
            "branch",
            response.data.response_data.branch_name
          );
          localStorage.setItem(
            "jwt_token",
            response.data.response_data.jwt_token
          );
          setOTPerror(false);

          //setislogin(true);
          setloginCompleted(true);
          setTimeout(() => {
            // Navigate after 3 seconds
            navigate("/BasicDetailsDigitalLoans");
          }, 3000);
        } else {
          const errorMessage = response.data.response_message;
          setSignupErrorMessage(errorMessage);
          setduplicateRecord(true);
          setloginCompleted(true);
        }
      } catch (error) {
        console.error("Error registering user:", error);
      }
    }
  };

  const handleOTPChange = (val) => {
    setOtp(val);
  };
  const verifyOTP = () => {
    setOTPerror(false);
    setloginCompleted(false);

    Axios.post(`${base_url2}/digitalLoans/verifyOtpLogin`, {
      email_id: registration.email,
      email_verification_code: otp,
    })
      .then((result) => {
        // Check for the specific error response
        if (
          result.data.response_data === null &&
          result.data.response_message === "Invalid OTP"
        ) {
          setOTPerror(true);
          setloginCompleted(true);
        }
        if (
          result.data.response_message == "success" &&
          result.data.response_data !== null
        ) {
          setOpen(true);
          setOpen1(false);
          localStorage.setItem("EmailId", result.data.response_data.email_id);
          localStorage.setItem("branch", result.data.response_data.branch);
          localStorage.setItem(
            "jwt_token",
            result.data.response_data.jwt_token
          );
          setOTPerror(false);

          //setislogin(true);
          setloginCompleted(true);
          setTimeout(() => {
            // Navigate after 3 seconds
            navigate("/BasicDetails");
          }, 3000);
        }
      })
      .catch((error) => {
        // Handle any other errors here
        console.error("Error verifying OTP:", error);
        setOTPerror(true);
        setloginCompleted(true);
      });
  };
  const handleButtonClick = () => {
    const section = document.getElementById("target-section");
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
    <Helmet>
        <script>
          {`(function(h,o,t,j,a,r){         h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};         h._hjSettings={hjid:5034910,hjsv:6};         a=o.getElementsByTagName('head')[0];         r=o.createElement('script');r.async=1;         r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;         a.appendChild(r);     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
        </script>
      </Helmet>
    <div>
      <div className="background-image-container">
        <div className="image-overlay"></div>
        <div className="header-container">
          <Header />
        </div>
        <div className="text-container">
          <h1 className="headingFont">Unsecured Micro loan</h1>
          <p>Meet your urgent needs without a worry</p>
          <button className="outline-button" onClick={handleButtonClick}>
            Apply Now
          </button>
        </div>
      </div>
      <div className="info-section">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography className="headingFont" variant="h3">
              Who is Gosree?
            </Typography>
            <Typography variant="body1" fontSize={"1.2em"}>
              This is our Journey
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" fontSize={"1.2em"}>
              Founded in Kerala, we are a non-banking financial company (NBFC),
              reborn in 2023, with a mission of serving the SME & MSME customers
              of India. Regulated by RBI, we offer convenient and cost-effective
              solutions for all your financial needs.
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div className="belief-section">
        <Grid container className="belief-grid" spacing={2}>
          <Grid item xs={12} className="belief-text">
            <p className="headingFont">
              At Gosree, we believe in the power of service, innovation, and
              teamwork
            </p>
          </Grid>
          <Grid item xs={12} className="belief-image">
            <img src={image1} alt="Gosree Belief" />
          </Grid>
        </Grid>
      </div>
      <div className="stats-section">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} className="stat-item">
            <p className="stat-number headingFont">10+</p>
            <p className="stat-label">Years in Business</p>
            <hr className="stat-line" />
          </Grid>
          <Grid item xs={12} sm={4} className="stat-item">
            <p className="stat-number headingFont">500 cr</p>
            <p className="stat-label">Loan disbursed so far</p>
            <hr className="stat-line" />
          </Grid>
          <Grid item xs={12} sm={4} className="stat-item">
            <p className="stat-number headingFont">99%</p>
            <p className="stat-label">Customer satisfaction</p>
            <hr className="stat-line" />
          </Grid>
        </Grid>
      </div>
      <Grid container spacing={2} className="productfeatures">
        <Grid item lg={1}></Grid>
        <Grid item xs={12} lg={6}>
          <div className="left-content">
            <h1 className="headingFont">Product Highlights</h1>
            <p>Features of unsecured microloans</p>
            <hr />
            <h2 className="headingFont">Collateral-free loans</h2>
            <p>Loans up to ₹1.5 lakhs</p>
            <h2 className="headingFont">Competitive rates</h2>
            <p>Interest rates starting from 18%.</p>
            <h2 className="headingFont">Flexible tenor</h2>
            <p>Term of 12 to 36 months.</p>
          </div>
        </Grid>
        <Grid item xs={12} lg={5}>
          <div className="right">
            <Grid item xs={12} sm={12} md={12} sx={{ mt: 4, mb: 8 }}>
              <Box
                maxWidth
                align="center"
                style={{
                  maxheight: "100vh",
                  overflow: "auto",
                  height: "auto",
                  display: "flex",
                  width: "100%",
                  borderRadius: "10px",
                }}
              >
                <Grid item xs={12} id="target-section">
                  <Grid container spacing={4}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      style={{ marginTop: "3%" }}
                    >
                      <Box>
                        <Typography className="decisionText">
                          {" "}
                          <span
                            style={{
                              color: "#ffffff",
                            }}
                          >
                            <b className="headingFont">Gosree Instant Loans</b>
                          </span>{" "}
                        </Typography>
                      </Box>
                      {duplicateRecord || isCaptchaError ? (
                        <Alert severity="error">{signupErrorMessage}</Alert>
                      ) : (
                        ""
                      )}
                      <div
                        style={{
                          maxHeight: "100vh",
                          overflow: "auto",
                          height: "46vh",
                        }}
                      >
                        <Box sx={{ ml: 4, mr: 4, mt: 10 }}>
                          <TextField
                            fullWidth
                            className="custom-text-field"
                            error={UsernameValid}
                            label="Username *"
                            variant="standard"
                            name="username"
                            value={registration.username}
                            onChange={(e) => handleInputChangeRegistration(e)}
                            helperText={
                              UsernameValid ? "Username cannot be Empty" : null
                            }
                          />
                        </Box>
                        <Box sx={{ ml: 4, mr: 4, mt: 2 }}>
                        <TextField
                          fullWidth
                          className="custom-text-field"
                          type="text"
                          label="Mobile No. (with Country Code)"
                          variant="standard"
                          name="mobileNo"
                          value={registration.mobileNo}
                          onChange={(e) => handleInputChangeRegistration(e)}
                          error={phoneNoError}
                          helperText={phoneNoError ? "Invalid mobile number" : ""}
                          InputProps={{
                            startAdornment: (
                              <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignContent:'center', backgroundColor:'#E1E8EB',height:35,width:35,marginLeft:0,marginRight:5}}>
                              <InputAdornment position="start">
                                +91-    
                              </InputAdornment>
                              </div>
                            )
                          }}
                        />
                        </Box>
                      </div>
                      <>
                        <Box style={{ marginBottom: "0.5rem" }}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                              <img
                                src={`data:image/png;base64,${captcha}`}
                                alt="Image"
                                style={{
                                  height: "7vh",
                                  width: "40%",
                                  marginLeft: "10%",
                                }}
                              />
                              <Button onClick={initiateSession}>
                                <ReplayIcon style={{ color: "#ffffff" }} />
                              </Button>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <TextField
                                label="Enter Captcha above"
                                type="text"
                                className="custom-text-field"
                                name="user_captcha"
                                style={{
                                  height: "auto",
                                  width: "40%",
                                  marginBottom: "1rem",
                                }}
                                onChange={(e) =>
                                  handleInputChangeRegistration(e)
                                }
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </>
                      <Box textAlign="center">
                        <Button
                          size="large"
                          variant="contained"
                          style={{
                            background: "#148F5B",
                            color: "#ffffff",
                            borderRadius: "5px",
                            textTransform: "none",
                            fontWeight: "600",
                            marginBottom: "4%",
                          }}
                          onClick={(e) => handleLoginSignup(e)}
                        >
                          Apply for a loan
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <div>
        <hr />
      </div>
      <div className="feature-section">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3} className="feature-item">
            <img src={featureicon1} alt="Icon" className="stat-icon" />
            <p className="stat-label">Seamless online application</p>
            <hr className="stat-line" />
          </Grid>
          <Grid item xs={12} sm={3} className="feature-item">
            <img src={featureicon2} alt="Icon" className="stat-icon" />
            <p className="stat-label">Disbursal within hours</p>
            <hr className="stat-line" />
          </Grid>
          <Grid item xs={12} sm={3} className="feature-item">
            <img src={featureicon3} alt="Icon" className="stat-icon" />
            <p className="stat-label">Minimum revenue not required</p>
            <hr className="stat-line" />
          </Grid>
          <Grid item xs={12} sm={3} className="feature-item">
            <img src={featureicon4} alt="Icon" className="stat-icon" />
            <p className="stat-label">Minimum documentation</p>
            <hr className="stat-line" />
          </Grid>
        </Grid>
      </div>
      <div className="footer-container">
        <Grid container spacing={2} className="background-image-container1">
          <Grid item xs={12} sm={12} style={{ height: "23vh" }}>
            <div className="logo-container1">
              <img src={logo} alt="Logo" />
            </div>
          </Grid>
          <Grid container>
            <Grid item xs={12} lg={7} sx={{ ml: 2 }} className="footer-section">
              <Grid container spacing={2}>
                <Grid item xs={12} lg={2} sx={{ ml: 2 }}>
                  <h2 className="headingFont">Company</h2>
                  <p>
                    <Link
                      to="https://gosreefinance.com/about"
                      className="footer-link"
                    >
                      About
                    </Link>
                  </p>
                  <p>
                    <Link
                      to="https://gosreefinance.com/career"
                      className="footer-link"
                    >
                      Careers
                    </Link>
                  </p>
                  <p>
                    <Link
                      to="https://gosreefinance.com/contact"
                      className="footer-link"
                    >
                      Contact
                    </Link>
                  </p>
                  <p>
                    <Link
                      to="https://gosreefinance.com/partner"
                      className="footer-link"
                    >
                      Partner
                    </Link>
                  </p>
                </Grid>
                <Grid item xs={12} lg={2} sx={{ ml: 2 }}>
                  <h2 className="headingFont">Investors</h2>
                  <p>
                    <Link
                      to="https://gosreefinance.com/investor?tab=bod"
                      className="footer-link"
                    >
                      Board of Directors
                    </Link>
                  </p>
                  <p>
                    <Link
                      to="https://gosreefinance.com/investor?tab=fr"
                      className="footer-link"
                    >
                      Financial Results
                    </Link>
                  </p>
                  <p>
                    <Link
                      to="https://gosreefinance.com/investor?tab=policy"
                      className="footer-link"
                    >
                      Policies
                    </Link>
                  </p>
                  <p>
                    <Link
                      to="https://gosreefinance.com/investor?tab=pn"
                      className="footer-link"
                    >
                      Public Notices
                    </Link>
                  </p>
                </Grid>
                <Grid item xs={12} lg={2} sx={{ ml: 2 }}>
                  <h2 className="headingFont">Information</h2>
                  <p>
                    <Link
                      to="https://gosreefinance.com/calculator"
                      className="footer-link"
                    >
                      Calculator
                    </Link>
                  </p>
                  <p>
                    <Link
                      to="https://gosreefinance.com/blog"
                      className="footer-link"
                    >
                      Blog
                    </Link>
                  </p>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={3} sx={{ m: 3 }} style={{ display: "flex" }}>
              <Grid item xs={12} lg={1} sx={{ ml: 2 }}>
                <a
                  href="https://www.linkedin.com/company/gosree-finance-limited/?originalSubdomain=in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={socialMediaicon}
                    alt="LinkedIn Icon"
                    className="stat-icon"
                  />
                </a>
              </Grid>
              <Grid item xs={12} lg={1} sx={{ ml: 2 }}>
                <a target="_blank" rel="noopener noreferrer">
                  <img
                    src={socialMediaicon2}
                    alt="Facebook Icon"
                    className="stat-icon"
                  />
                </a>
              </Grid>
              <Grid item xs={12} lg={1} sx={{ ml: 2 }}>
                <a target="_blank" rel="noopener noreferrer">
                  <img
                    src={socialMediaicon1}
                    alt="Twitter Icon"
                    className="stat-icon"
                  />
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
    </>
  );
};

export default BackgroundImageComponent;
