import React, { useEffect } from "react";
import NavBar from "../NavBar/Index";
import { Button, Divider, Grid, Typography } from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DoneIcon from "@mui/icons-material/Done";
import Box from "@mui/material/Box";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import MenuItem from "@mui/material/MenuItem";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import TextField from "@mui/material/TextField";
import { UploadFile } from "@mui/icons-material";
import {
  UploadDocument,
  UploadDilaogView,
  TopbarViewSteps,
  CollateralSave,
  GetLoanDetails,
  getGstinByPan,
  GSTUsernameandGSTINPost,
  GSTOTPVerification,
  getBankNames,
  docsUploadeded,
} from "../BasicDetails/BasicDetailsActions/BasicDetailsActions";
import { useSelector, useDispatch } from "react-redux";
import DialogView from "./UploadDocumentPages/DialogView";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import Alert from "@mui/material/Alert";
import Footer from "./../../Common/Footer";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Checkbox from "@mui/material/Checkbox";
import SessionModal from "./../../Common/SessionModel";
import GenInsight from "../GenInsight/GenInsight";
const label = { inputProps: { "aria-label": "Auto Debit" } };

function Index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const coll = [
    {
      label: "Property ",
      value: 1,
    },
    {
      label: "Shares",
      value: 2,
    },
    {
      label: "Receivables",
      value: 3,
    },
  ];

  const year = [
    "2022",
    "2021",
    "2020",
    "2019",
    "2018",
    "2017",
    "2016",
    "2015",
    "2014",
    "2013",
    "2012",
    "2011",
    "2010",
  ];
  //--------------------------------------------------------------------
  const [bankFlag, setBankFlag] = React.useState(false);
  const [vintageFlag, setVintageFlag] = React.useState(false);
  const [licenceFlag, setLicenceFlag] = React.useState(false);
  const [salaryFlag, setSalaryFlag] = React.useState(false);
  const [itrFlag, setITRFlag] = React.useState(false);
  const [financialStatemntFlag, setFinancialStatemntFlag] =
    React.useState(false);
  const [GSTFlag, setGSTFlag] = React.useState(false);
  const [collateralFlag, setCollateralFlag] = React.useState(false);
  const [showAlert, setShowAlert] = useState(false);

  //------------------------------------------------------------------

  const [bankName, setBankName] = React.useState("");
  const [bankStatements, setBankStatements] = React.useState();
  const [salaryStatements, setSalaryStatements] = React.useState();
  const [itrStatements, setitrStatements] = React.useState();
  const [gstDoc, setGstDoc] = React.useState("");
  const [financailStatemnt, setfinancailStatemnt] = React.useState("");
  const [collateralDoc, setCollateralDoc] = React.useState("");

  //----------------------------------------------------------------
  const handleBankNameChange = (e) => {
    setBankName(e.target.value);
  };

  const handleBankStatement = (e) => {
    setBankStatements(e.target.files);
    setBankFlag(true);
  };
  const handleSalaryStatement = (e) => {
    setSalaryStatements(e.target.files);
    setSalaryFlag(true);
  };
  const handleITRStatement = (e) => {
    setitrStatements(e.target.files);
    setITRFlag(true);
  };

  const handleGstDoc = (e) => {
    setGstDoc(e.target.files);
    setGSTFlag(true);
  };

  const handlefinancailStatemnt = (e) => {
    setfinancailStatemnt(e.target.files[0]);
    setFinancialStatemntFlag(true);
  };

  const handleCollateralDoc = (e) => {
    setCollateralDoc(e.target.files);
    setCollateralFlag(true);
  };

  //--------------------------------------------------------------------=
  const [collateralType, setCollateralType] = React.useState();
  const handleCollateralType = (e) => {
    setCollateralType(e);
  };
  const handleLogoutButton = () => {
    localStorage.removeItem("jwt_token");
    localStorage.removeItem("EmailId");
    localStorage.removeItem("branch");
    setShowAlert(true);
  };
  const handleCloseErrorModal1 = () => {
    setShowAlert(false);
    navigate("/login");
  };
  const [collateralView, setcollateralView] =
    React.useState("Upload Statement");

  // const [businessView, setBuisnessView] =
  //   React.useState("Upload Statement");

  const handleCollateralView = (type) => {
    setcollateralView(type);
  };

  // const handleBuisnessView = (type) =>{
  //   setBuisnessView(type);
  // }

  //--------------------------------------------------------------------
  const [bankConfirmFlag, setBankConfirmFlag] = React.useState(false);
  const [businessVintageFlag, setBusinessVintageFlag] = React.useState(false);
  const [businessLicenceFlag, setBusinessLicenceFlag] = React.useState(false);
  const [salaryConfirmFlag, setsalaryConfirmFlag] = React.useState(false);
  const [itrConfirmFlag, setitrConfirmFlag] = React.useState(false);
  const handleBankConfirm = () => {
    setBankConfirmFlag(true);
  };
  const handleBusinessVintageConfirm = () => {
    setBusinessVintageFlag(true);
  };
  const handleBusinessLicenceConfirm = () => {
    setBusinessLicenceFlag(true);
  };
  const handleSalaryConfirm = () => {
    setsalaryConfirmFlag(true);
  };
  const handleITRConfirm = () => {
    setitrConfirmFlag(true);
  };

  const [financialStatementConfirmFlag, setFinancialStatementConfirmFlag] =
    React.useState(false);
  const handlefinancailStatemntConfirm = () => {
    setFinancialStatementConfirmFlag(true);
  };

  const [GSTConfirmFlag, setGSTConfirmFlag] = React.useState(false);
  const handleGSTConfirm = () => {
    setGSTConfirmFlag(true);
  };

  const [collateralConfirmFlag, setCollateralConfirmFlag] =
    React.useState(false);
  const handleCollateralConfirm = () => {
    setCollateralConfirmFlag(true);
  };

  //------------------------------------------------------------------------------------

  const [TypeofProperty, setTypeofProperty] = React.useState("");
  const [DeveloperName, setDeveloperName] = React.useState("");
  const [ProjectName, setProjectName] = React.useState("");
  const [BuiltUpArea, setBuiltUpArea] = React.useState("");
  const [PurchasePrice, setPurchasePrice] = React.useState("");
  const [APF, setAPF] = React.useState("");
  const [UnderConstruction, setUnderConstruction] = React.useState("");
  const [CompletePercentage, setCompletePercentage] = React.useState("");
  const [AgeofProperty, setAgeofProperty] = React.useState("");
  const [Currentvalueinthemarket, setCurrentvalueinthemarket] =
    React.useState("");
  const [AddressLine, setAddressLine] = React.useState("");
  const [Landmark, setLandmark] = React.useState("");
  const [City, setCity] = React.useState("");
  const [PinCode, setPinCode] = React.useState("");
  const [State, setState] = React.useState("");
  const [isOnlineGST, setisOnlineGST] = useState("GSTInfo");
  const [ErrorPan, setErrorPan] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    setonlineGSTDetails({
      ...onlineGSTDetails,
      pan: "",
      stateCode: "",
      GSTNo: "",
      UserName: "",
      OTP: "",
    });
    setErrorPan(false);
  };
  const setForgotGST = (e) => {
    setisOnlineGST(e);
  };
  const [onlineGSTDetails, setonlineGSTDetails] = React.useState({
    pan: "",
    stateCode: "",
    GSTNo: "",
    UserName: "",
    OTP: "",
  });
  //----------------------------------------------
  const saveOnlineGSTDetails = async () => {
    setErrorPan(false);
    if (isOnlineGST == "PANInfo") {
      const result = await getGstinByPan(
        onlineGSTDetails.pan,
        onlineGSTDetails.stateCode
      );

      if (result.gstin != undefined) {
        setonlineGSTDetails((prevState) => ({
          ...prevState,
          GSTNo: result.gstin,
        }));
        setisOnlineGST("GSTInfo");
      } else {
        setErrorPan(true);
      }
    }
    if (isOnlineGST == "GSTInfo") {
      GSTUsernameandGSTINPost(
        onlineGSTDetails.GSTNo,
        onlineGSTDetails.UserName
      );
      setisOnlineGST("OTPVerification");
    }
    if (isOnlineGST == "OTPVerification") {
      const results = await GSTOTPVerification(
        onlineGSTDetails.GSTNo,
        onlineGSTDetails.UserName,
        onlineGSTDetails.OTP
      );
      setOpen(false);
      setGSTConfirmFlag(true);
    }
  };
  //--------------------------------------------------------
  const handleGSTDetailsChange = (e) => {
    setonlineGSTDetails({
      ...onlineGSTDetails,
      [e.target.name]: e.target.value,
    });
  };
  //---------------------------------------------------------------------------
  const handleOnlineGST = () => {
    setOpen(true);
    setisOnlineGST("GSTInfo");
  };
  const uploadDailog = useSelector(
    (state) => state.BasicDetailsReducers.uploadDailog
  );

  //
  //--------------------------------------------------------------

  //Bank Statements for Multiple Banks
  //Max banks can be 3 - Max value of number Of Banks is 3

  const addNewBankStatement = (bankNumber) => {
    if (showInputs2 === false && showInputs3 === false) {
      setShowInputs2(true);
    }
    if (showInputs2 === true && showInputs3 === false) {
      setShowInputs3(true);
    }
    if (showInputs3 === true && showInputs4 === false) {
      setShowInputs4(true);
    }
    if (showInputs4 === true && showInputs5 === false) {
      setShowInputs5(true);
    }
    if (showInputs5 === true && showInputs6 === false) {
      setShowInputs6(true);
    }
  };

  const deleteBankStatements = (bankNumber) => {
    if (bankNumber === 1) {
      setShowInputs1(false);
    }
    if (bankNumber === 2) {
      setShowInputs2(false);
    }
    if (bankNumber === 3) {
      setShowInputs3(false);
    }
    if (bankNumber === 4) {
      setShowInputs4(false);
    }
    if (bankNumber === 5) {
      setShowInputs5(false);
    }
    if (bankNumber === 6) {
      setShowInputs6(false);
    }
  };

  //-----------------------------------------------------------
  //For the first bank - Default one
  const [showInputs1, setShowInputs1] = React.useState(true);
  const [bankName1, setBankName1] = React.useState(null);
  const [bankStatement1, setBankStatement1] = React.useState();
  const [businessVintage1, setBusinessVintage1] = React.useState();
  const [businessLicence1, setBusinessLicence1] = React.useState();

  const handleBankNameChange1 = (e) => {
    setBankName1(e.target.value);
  };
  const handleBankStatement1 = (e) => {
    setBankFlag(true);
    setBankStatement1(e.target.files[0]);
  };

  const handleBusinessVintage1 = (e) => {
    setVintageFlag(true);
    setBusinessVintage1(e.target.files[0]);
  };

  const handleBusinessLicence1 = (e) => {
    setLicenceFlag(true);
    setBusinessLicence1(e.target.files[0]);
  };

  //-----------------------------------------------------------
  //For the seccond bank
  const [showInputs2, setShowInputs2] = React.useState(false);
  const [showInputs4, setShowInputs4] = React.useState(false);
  const [showInputs5, setShowInputs5] = React.useState(false);
  const [showInputs6, setShowInputs6] = React.useState(false);
  const [bankName2, setBankName2] = React.useState();
  const [password1, setpassword1] = React.useState(null);
  const [password2, setpassword2] = React.useState(null);
  const [password3, setpassword3] = React.useState(null);
  const [password4, setpassword4] = React.useState(null);
  const [password5, setpassword5] = React.useState(null);
  const [password6, setpassword6] = React.useState(null);
  const [has401Error, sethas401Error] = React.useState(false);
  const [has400Error, sethas400Error] = React.useState(false);
  const [bankStatement2, setBankStatement2] = React.useState();
  const handleBankNameChange2 = (e) => {
    setBankName2(e.target.value);
  };
  const handleBankStatement2 = (e) => {
    setBankStatement2(e.target.files[0]);
  };
  const handlePasswordChange1 = (e) => {
    setpassword1(e.target.value);
  };
  const handlePasswordChange2 = (e) => {
    setpassword2(e.target.value);
  };
  const handlePasswordChange3 = (e) => {
    setpassword3(e.target.value);
  };
  const handlePasswordChange4 = (e) => {
    setpassword4(e.target.value);
  };
  const handlePasswordChange5 = (e) => {
    setpassword5(e.target.value);
  };
  const handlePasswordChange6 = (e) => {
    setpassword6(e.target.value);
  };
  //-----------------------------------------------------------

  //For the third bank
  const [showInputs3, setShowInputs3] = React.useState(false);
  const [bankName3, setBankName3] = React.useState();
  const [bankName4, setBankName4] = React.useState();
  const [bankName5, setBankName5] = React.useState();
  const [bankName6, setBankName6] = React.useState();
  const [bankStatement3, setBankStatement3] = React.useState();
  const [bankStatement4, setBankStatement4] = React.useState();
  const [bankStatement5, setBankStatement5] = React.useState();
  const [bankStatement6, setBankStatement6] = React.useState();
  const handleBankNameChange3 = (e) => {
    setBankName3(e.target.value);
  };
  const handleBankStatement3 = (e) => {
    setBankStatement3(e.target.files[0]);
  };
  const handleBankNameChange4 = (e) => {
    setBankName4(e.target.value);
  };
  const handleBankStatement4 = (e) => {
    setBankStatement4(e.target.files[0]);
  };
  const handleBankNameChange5 = (e) => {
    setBankName5(e.target.value);
  };
  const handleBankStatement5 = (e) => {
    setBankStatement5(e.target.files[0]);
  };
  const handleBankNameChange6 = (e) => {
    setBankName6(e.target.value);
  };
  const handleBankStatement6 = (e) => {
    setBankStatement6(e.target.files[0]);
  };

  //-------------------------   Auto Debit CheckBox  -----------------------------

  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  //---------------------------- Upload All Doc API  Call ------------------------
  const handleConfirmAllDetails = () => {
    dispatch(UploadDilaogView(!uploadDailog));
    dispatch(
      UploadDocument(
        bankStatements,
        financailStatemnt,
        gstDoc,
        collateralDoc,
        salaryStatements,
        itrStatements,
        bankName1,
        bankName2,
        bankName3,
        bankName4,
        bankName5,
        bankName6,
        bankStatement1,
        bankStatement2,
        bankStatement3,
        bankStatement4,
        bankStatement5,
        bankStatement6,
        businessVintage1,
        businessLicence1,
        checked,
        password1,
        password2,
        password3,
        password4,
        password5,
        password6,
        handleLogoutButton
      )
    );

    if (collateralView == "Collateral Inputs") {
      dispatch(
        CollateralSave(
          TypeofProperty,
          DeveloperName,
          ProjectName,
          BuiltUpArea,
          PurchasePrice,
          APF,
          UnderConstruction,
          CompletePercentage,
          AgeofProperty,
          AddressLine,
          Landmark,
          City,
          PinCode,
          State,
          handleLogoutButton
        )
      );
    }
  };
  const navigateToLoanDetails = () => {
    dispatch(TopbarViewSteps(2));
    navigate("/LoanCofirmation");
  };
  //-------------------------------------------------------------------------------------

  const uploadDocumentResponse = useSelector(
    (state) => state.BasicDetailsReducers.uploadDocumentResponse
  );
  const employmentType = localStorage.getItem("employmentType");
  const loanProductType = localStorage.getItem("loanProduct");
  const messsage = uploadDocumentResponse ? uploadDocumentResponse.status : "";
  const [docsPresent, setDocsPresent] = useState(false);
  //--------------------------------------------------------------------------------------
  let stepCount = useSelector((state) => state.BasicDetailsReducers.stepCount);

  const handleNextStep = () => {
    dispatch(TopbarViewSteps(stepCount++));
    navigate("/LoanCofirmation");
  };
  const bank = useSelector((state) => state.BasicDetailsReducers.bankNames);
  useEffect(async() => {
    dispatch(getBankNames(handleLogoutButton));
    //add api to with loanProductType to check uploaded docs
    // localStorage.setItem("LoanApplicationId", details.Loan_Application_id);
    const response = await docsUploadeded(localStorage.getItem("LoanApplicationId"));
    console.log(response?.data?.data?.docs_present);
    if(response?.data?.data?.docs_present == true){
      setDocsPresent(true);
    }
    else{
      setDocsPresent(false);
    }
  }, []);
  useEffect(() => {
    dispatch(TopbarViewSteps(1));
    const has401Error1 = uploadDocumentResponse?.upload_status.some(
      (status) => status.errorCode === 401
    );
    const has400Error1 = uploadDocumentResponse?.upload_status.some(
      (status) => status.errorCode === 400
    );

    sethas401Error(has401Error1);
    sethas400Error(has400Error1);
    if (has401Error1) {
      setBankConfirmFlag(false);
    }
  }, [uploadDocumentResponse]);
  // Upload Enable Validation

  const [uploadButtonFlag, setUploadButtonFlag] = React.useState(false);
  useEffect(() => {
    dispatch(TopbarViewSteps(1));
    getInputValidate();
  }, [
    bankConfirmFlag,
    businessVintageFlag,
    businessLicenceFlag,
    salaryConfirmFlag,
    itrConfirmFlag,
    financialStatementConfirmFlag,
    GSTConfirmFlag,
    collateralDoc,
    collateralView,
    collateralConfirmFlag,
  ]);

  const getInputValidate = () => {
    if (employmentType == 1 || employmentType == 2 || employmentType == 7) {
      if (bankConfirmFlag || salaryConfirmFlag || itrConfirmFlag) {
        if (
          loanProductType == 1 ||
          loanProductType == 4 ||
          loanProductType == 5 ||
          loanProductType == 7 ||
          loanProductType == 10
        ) {
          if (collateralView == "Upload Statement") {
            if (collateralConfirmFlag) {
              setUploadButtonFlag(true);
            } else {
              setUploadButtonFlag(false);
            }
          } else {
            setUploadButtonFlag(true);
          }
        } 
        // else if(loanProductType == 7){
        //   {
        //     if (collateralView == "Upload Statement") {
        //       if (collateralConfirmFlag && businessVintageFlag && businessLicenceFlag) {
        //         setUploadButtonFlag(true);
        //       } else {
        //         setUploadButtonFlag(false);
        //       }
        //     } else {
        //       setUploadButtonFlag(true);
        //     }
        //   } 
        // }
        else {
          setUploadButtonFlag(true);
        }
      } else {
        setUploadButtonFlag(false);
      }
    } else {
      if (bankConfirmFlag || financialStatementConfirmFlag || GSTConfirmFlag) {
        if (
          loanProductType == 1 ||
          loanProductType == 4 ||
          loanProductType == 5 ||
          loanProductType == 7 ||
          loanProductType == 10
        ) {
          if (collateralView == "Upload Statement") {
            if (collateralConfirmFlag) {
              setUploadButtonFlag(true);
            } else {
              setUploadButtonFlag(false);
            }
          } else {
            setUploadButtonFlag(true);
          }
        } 
        // else if(loanProductType == 7){
        //   {
        //     if (collateralView == "Upload Statement") {
        //       if (collateralConfirmFlag && businessVintageFlag && businessLicenceFlag) {
        //         setUploadButtonFlag(true);
        //       } else {
        //         setUploadButtonFlag(false);
        //       }
        //     } else {
        //       setUploadButtonFlag(true);
        //     }
        //   } 
        // }
        else {
          setUploadButtonFlag(true);
        }
      } else {
        setUploadButtonFlag(false);
      }
    }
  };
  const handleMyLoan = () => {
    dispatch(TopbarViewSteps(5));
    dispatch(GetLoanDetails(handleLogoutButton));
    navigate("/MyLoan");
  };

  return (
    <>
      <Grid container sx={{ mb: 8 }}>
        <GenInsight />
        <Grid item xs={12}>
          <NavBar />
        </Grid>
        <div>
          {/* Your component content */}
          <SessionModal isOpen={showAlert} onClose={handleCloseErrorModal1} />
        </div>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 3,
            height: "9vh",
            background: "#e5f6fd",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: 900,
              fontSize: "20px",
            }}
          >
            Upload Documents
          </Typography>
        </Grid>
        <Grid item xs={12} md={5}></Grid>
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            height: "9vh",
            mt: 6,
          }}
        >
          <Typography onClick={handleMyLoan} sx={{ cursor: "pointer", ml: 4 }}>
            My Loans
          </Typography>
          <AssignmentIcon
            onClick={handleMyLoan}
            sx={{ ml: 1, color: "#148F5B" }}
          />
        </Grid>
        {/* <Grid item xs={12} sx={{ m: 4 }}>
          {employmentType != 1 && employmentType != 2 && employmentType != 7 ? (
            <Typography sx={{ fontSize: 14, color: "#FF0000" }}>
              *Please provide Bank Statement (Complusory)
            </Typography>
          ) : (
            <Typography sx={{ fontSize: 14, color: "#FF0000" }}>
              *Please provide Bank Statement (Complusory)
            </Typography>
          )}
        </Grid> */}
        <Grid item xs={12} md={4} sx={{ mt: 1 }}>
          <Grid container>
            <Grid item xs={2}></Grid>
            <Grid item xs={10} sx={{ display: "flex" }}>
              <div
                style={{ height: "30px", borderLeft: "4px solid #148F5B" }}
              ></div>
              {bankConfirmFlag ? (
                <>
                  <Typography
                    sx={{
                      ml: 1,
                      mt: 0.3,
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#4BB543",
                    }}
                  >
                    Bank Statement (Required)
                  </Typography>
                  <DoneIcon sx={{ color: "#4BB543", ml: 1, mt: 0.2 }} />
                </>
              ) : (
                <Typography
                  sx={{
                    ml: 1,
                    mt: 0.3,
                    fontWeight: 600,
                    fontSize: 16,
                  }}
                >
                  Bank Statement (Required)
                </Typography>
              )}
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={10} sx={{}}>
              <Typography sx={{ ml: 1, mt: 3, fontWeight: 300, fontSize: 14 }}>
                A bank statement is a document that provides a summary of all
                transactions, including deposits, withdrawals, and other
                activities that occur in a bank account over a specific period
                of time.
              </Typography>
              {/* <Typography
                sx={{
                  mt: 1,
                  ml: 1,
                  fontWeight: 600,
                  fontSize: 12,
                  color: "#148F5B",
                  cursor: "pointer",
                }}
              >
                Learn More
              </Typography> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={2} sx={{ mt: 4 }}>
          <Box
            sx={{
              background: "#DCDCDC",
              display: "flex",
              ml: 2,
              mr: 2,
              height: 35,
              borderRadius: 1,
              cursor: "pointer",
            }}
          >
            <AssignmentIcon sx={{ ml: 2, color: "#148F5B", mt: 0.7 }} />
            <Typography sx={{ mt: 0.7, ml: 1, fontSize: 14 }}>
              Upload Statement
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              mt: 2,
              ml: 2,
              mr: 2,
              cursor: "pointer",
            }}
          >
            <AccountBalanceIcon sx={{ ml: 2, color: "#148F5B", mt: 0.7 }} />
            <Typography sx={{ mt: 0.7, ml: 1, fontSize: 14 }}>
              Bank Login
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={1}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Box
            className="horizontalBar"
            sx={{ borderLeft: "1px solid #148F5B", height: 180, mt: 2 }}
          ></Box>
        </Grid>
        <Grid item xs={12} md={5} sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            {showInputs1 ? (
              <>
                <Grid item xs={12} md={3}>
                  <TextField
                    select
                    id="Choose Bank"
                    label="Choose Bank*"
                    variant="outlined"
                    onChange={handleBankNameChange1}
                    fullWidth
                  >
                    {bank.response_data &&
                      bank.response_data.map((option) => (
                        <MenuItem
                          key={option.bank_name}
                          value={option.bank_name}
                          sx={{
                            fontFamily: "'Roboto Condensed', sans-serif",
                            "&:hover": {
                              borderLeft: "5px solid #148F5B",
                              borderRadius: 1,
                            },
                          }}
                        >
                          {option.bank_name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div
                    style={{
                      border: "1px solid #dadada",
                      height: 55,
                      borderRadius: 3,
                    }}
                  >
                    <form id="inputType1">
                      <input
                        style={{
                          marginTop: 15,
                          marginLeft: 10,
                          width: "91%",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        type="file"
                        onChange={handleBankStatement1}
                        name="bankStatement"
                      ></input>
                    </form>
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    type="password"
                    variant="outlined"
                    name="Password1"
                    label="Password (Optional)"
                    onChange={handlePasswordChange1}
                    value={password1}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2}
                  sx={{ display: "flex", justifyContent: "space-around" }}
                >
                  <AddIcon
                    onClick={() => addNewBankStatement()}
                    sx={{
                      color: "#148F5B",
                      fontSize: 25,
                      pt: 1.4,
                      cursor: "pointer",
                    }}
                  />
                </Grid>
              </>
            ) : null}

            {showInputs2 ? (
              <>
                <Grid item xs={12} md={3}>
                  <TextField
                    select
                    id="Choose Bank"
                    label="Choose Bank"
                    variant="outlined"
                    onChange={handleBankNameChange2}
                    fullWidth
                  >
                    {bank.response_data &&
                      bank.response_data.map((option) => (
                        <MenuItem
                          key={option.bank_name}
                          value={option.bank_name}
                          sx={{
                            fontFamily: "'Roboto Condensed', sans-serif",
                            "&:hover": {
                              borderLeft: "5px solid #148F5B",
                              borderRadius: 1,
                            },
                          }}
                        >
                          {option.bank_name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div
                    style={{
                      border: "1px solid #dadada",
                      height: 55,
                      borderRadius: 3,
                    }}
                  >
                    <form id="inputType1">
                      <input
                        style={{
                          marginTop: 15,
                          marginLeft: 10,
                          width: "91%",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        type="file"
                        onChange={handleBankStatement2}
                        name="bankStatement"
                        multiple
                      ></input>
                    </form>
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    type="password"
                    variant="outlined"
                    name="Password2"
                    label="Password (Optional)"
                    onChange={handlePasswordChange2}
                    value={password2}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2}
                  sx={{ display: "flex", justifyContent: "space-around" }}
                >
                  <DeleteIcon
                    onClick={() => deleteBankStatements(2)}
                    sx={{
                      color: "#FF0000",
                      fontSize: 25,
                      pt: 1.4,
                      cursor: "pointer",
                    }}
                  />
                </Grid>
              </>
            ) : null}
            {showInputs3 ? (
              <>
                <Grid item xs={12} md={3}>
                  <TextField
                    select
                    id="Choose Bank"
                    label="Choose Bank"
                    variant="outlined"
                    onChange={handleBankNameChange3}
                    fullWidth
                  >
                    {bank.response_data &&
                      bank.response_data.map((option) => (
                        <MenuItem
                          key={option.bank_name}
                          value={option.bank_name}
                          sx={{
                            fontFamily: "'Roboto Condensed', sans-serif",
                            "&:hover": {
                              borderLeft: "5px solid #148F5B",
                              borderRadius: 1,
                            },
                          }}
                        >
                          {option.bank_name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div
                    style={{
                      border: "1px solid #dadada",
                      height: 55,
                      borderRadius: 3,
                    }}
                  >
                    <form id="inputType1">
                      <input
                        style={{
                          marginTop: 15,
                          marginLeft: 10,
                          width: "91%",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        type="file"
                        onChange={handleBankStatement3}
                        name="bankStatement"
                        multiple
                      ></input>
                    </form>
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    type="password"
                    variant="outlined"
                    name="Password3"
                    label="Password (Optional)"
                    onChange={handlePasswordChange3}
                    value={password3}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2}
                  sx={{ display: "flex", justifyContent: "space-around" }}
                >
                  <DeleteIcon
                    onClick={() => deleteBankStatements(3)}
                    sx={{
                      color: "#FF0000",
                      fontSize: 25,
                      pt: 1.4,
                      cursor: "pointer",
                    }}
                  />
                </Grid>
              </>
            ) : null}
            {showInputs4 ? (
              <>
                <Grid item xs={12} md={3}>
                  <TextField
                    select
                    id="Choose Bank"
                    label="Choose Bank"
                    variant="outlined"
                    onChange={handleBankNameChange4}
                    fullWidth
                  >
                    {bank.response_data &&
                      bank.response_data.map((option) => (
                        <MenuItem
                          key={option.bank_name}
                          value={option.bank_name}
                          sx={{
                            fontFamily: "'Roboto Condensed', sans-serif",
                            "&:hover": {
                              borderLeft: "5px solid #148F5B",
                              borderRadius: 1,
                            },
                          }}
                        >
                          {option.bank_name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div
                    style={{
                      border: "1px solid #dadada",
                      height: 55,
                      borderRadius: 3,
                    }}
                  >
                    <form id="inputType1">
                      <input
                        style={{
                          marginTop: 15,
                          marginLeft: 10,
                          width: "91%",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        type="file"
                        onChange={handleBankStatement4}
                        name="bankStatement"
                        multiple
                      ></input>
                    </form>
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    type="password"
                    variant="outlined"
                    name="Password4"
                    label="Password (Optional)"
                    onChange={handlePasswordChange4}
                    value={password4}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2}
                  sx={{ display: "flex", justifyContent: "space-around" }}
                >
                  <DeleteIcon
                    onClick={() => deleteBankStatements(4)}
                    sx={{
                      color: "#FF0000",
                      fontSize: 25,
                      pt: 1.4,
                      cursor: "pointer",
                    }}
                  />
                </Grid>
              </>
            ) : null}
            {showInputs5 ? (
              <>
                <Grid item xs={12} md={3}>
                  <TextField
                    select
                    id="Choose Bank"
                    label="Choose Bank"
                    variant="outlined"
                    onChange={handleBankNameChange5}
                    fullWidth
                  >
                    {bank.response_data &&
                      bank.response_data.map((option) => (
                        <MenuItem
                          key={option.bank_name}
                          value={option.bank_name}
                          sx={{
                            fontFamily: "'Roboto Condensed', sans-serif",
                            "&:hover": {
                              borderLeft: "5px solid #148F5B",
                              borderRadius: 1,
                            },
                          }}
                        >
                          {option.bank_name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div
                    style={{
                      border: "1px solid #dadada",
                      height: 55,
                      borderRadius: 3,
                    }}
                  >
                    <form id="inputType1">
                      <input
                        style={{
                          marginTop: 15,
                          marginLeft: 10,
                          width: "91%",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        type="file"
                        onChange={handleBankStatement5}
                        name="bankStatement"
                        multiple
                      ></input>
                    </form>
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    type="password"
                    variant="outlined"
                    name="Password5"
                    label="Password (Optional)"
                    onChange={handlePasswordChange5}
                    value={password5}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2}
                  sx={{ display: "flex", justifyContent: "space-around" }}
                >
                  <DeleteIcon
                    onClick={() => deleteBankStatements(5)}
                    sx={{
                      color: "#FF0000",
                      fontSize: 25,
                      pt: 1.4,
                      cursor: "pointer",
                    }}
                  />
                </Grid>
              </>
            ) : null}
            {showInputs6 ? (
              <>
                <Grid item xs={12} md={3}>
                  <TextField
                    select
                    id="Choose Bank"
                    label="Choose Bank"
                    variant="outlined"
                    onChange={handleBankNameChange6}
                    fullWidth
                  >
                    {bank.response_data &&
                      bank.response_data.map((option) => (
                        <MenuItem
                          key={option.bank_name}
                          value={option.bank_name}
                          sx={{
                            fontFamily: "'Roboto Condensed', sans-serif",
                            "&:hover": {
                              borderLeft: "5px solid #148F5B",
                              borderRadius: 1,
                            },
                          }}
                        >
                          {option.bank_name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={4}>
                  <div
                    style={{
                      border: "1px solid #dadada",
                      height: 55,
                      borderRadius: 3,
                    }}
                  >
                    <form id="inputType1">
                      <input
                        style={{
                          marginTop: 15,
                          marginLeft: 10,
                          width: "91%",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        type="file"
                        onChange={handleBankStatement6}
                        name="bankStatement"
                        multiple
                      ></input>
                    </form>
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    type="password"
                    variant="outlined"
                    name="Password6"
                    label="Password (Optional)"
                    onChange={handlePasswordChange6}
                    value={password6}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2}
                  sx={{ display: "flex", justifyContent: "space-around" }}
                >
                  <DeleteIcon
                    onClick={() => deleteBankStatements(6)}
                    sx={{
                      color: "#FF0000",
                      fontSize: 25,
                      pt: 1.4,
                      cursor: "pointer",
                    }}
                  />
                </Grid>
              </>
            ) : null}

            <Grid item xs={6} sx={{ display: "flex" }}>
              <Checkbox
                checked={checked}
                {...label}
                onChange={handleChange}
                sx={{ ml: -1.4 }}
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography sx={{ mt: 2, fontWeight: 900, fontSize: 15 }}>
                Auto Debit
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={handleBankConfirm}
                variant="outlined"
                disabled={!bankFlag}
                endIcon={<DoneIcon />}
                fullWidth
                sx={{ textTransform: "capitalize", p: 1.7 }}
              >
                Confirm Details
              </Button>
            </Grid>
            <Grid item xs={12} sx={{ m: 2 }}>
              <Typography sx={{ fontSize: 12, color: "#FF0000" }}>
                *Latest 6 months' bank statement with Account Holder's Name,
                Account Number, and IFSC code.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {loanProductType == 1 ||
        loanProductType == 4 ||
        loanProductType == 5 ||
        loanProductType == 7 ||
        loanProductType == 10 ? (
          <>
            <Grid item xs={12} md={4} sx={{ mt: 3 }}>
              <Grid container>
                <Grid item xs={2}></Grid>
                <Grid item xs={10} sx={{ display: "flex" }}>
                  <div
                    style={{ height: "30px", borderLeft: "4px solid #148F5B" }}
                  ></div>
                  {collateralConfirmFlag ? (
                    <>
                      <Typography
                        sx={{
                          ml: 1,
                          mt: 0.3,
                          fontWeight: 600,
                          fontSize: 16,
                          color: "#4BB543",
                        }}
                      >
                        Collateral Details (Required)
                      </Typography>
                      <DoneIcon sx={{ color: "#4BB543", ml: 1, mt: 0.2 }} />
                    </>
                  ) : (
                    <Typography
                      sx={{
                        ml: 1,
                        mt: 0.3,
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      Collateral Details (Required)
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={2}></Grid>
                <Grid item xs={10} sx={{}}>
                  <Typography
                    sx={{ ml: 1, mt: 3, fontWeight: 300, fontSize: 14 }}
                  >
                    A collateral document is a legal agreement that provides a
                    lender with a security interest in specific assets or
                    property of the borrower, which are pledged as collateral to
                    secure a loan or other financial obligation.
                  </Typography>
                  {/* <Typography
                sx={{
                  mt: 1,
                  ml: 1,
                  fontWeight: 600,
                  fontSize: 12,
                  color: "#148F5B",
                  cursor: "pointer",
                }}
              >
                Learn More
              </Typography> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={2} sx={{ mt: 6 }}>
              {collateralView == "Upload Statement" ? (
                <>
                  <Box
                    onClick={() => handleCollateralView("Upload Statement")}
                    sx={{
                      background: "#DCDCDC",
                      display: "flex",
                      ml: 2,
                      mr: 2,
                      height: 35,
                      borderRadius: 1,
                      cursor: "pointer",
                    }}
                  >
                    <AssignmentIcon sx={{ ml: 2, color: "#148F5B", mt: 0.7 }} />
                    <Typography sx={{ mt: 0.7, ml: 1, fontSize: 14 }}>
                      Upload Statement
                    </Typography>
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    onClick={() => handleCollateralView("Upload Statement")}
                    sx={{
                      display: "flex",
                      ml: 2,
                      mr: 2,
                      height: 35,
                      borderRadius: 1,
                      cursor: "pointer",
                    }}
                  >
                    <AssignmentIcon sx={{ ml: 2, color: "#148F5B", mt: 0.7 }} />
                    <Typography sx={{ mt: 0.7, ml: 1, fontSize: 14 }}>
                      Upload Statement
                    </Typography>
                  </Box>
                </>
              )}
              {collateralView == "Collateral Inputs" ? (
                <>
                  <Box
                    onClick={() => handleCollateralView("Collateral Inputs")}
                    sx={{
                      background: "#DCDCDC",
                      display: "flex",
                      ml: 2,
                      mr: 2,
                      height: 35,
                      mt: 2,
                      borderRadius: 1,
                      cursor: "pointer",
                    }}
                  >
                    <KeyboardIcon sx={{ ml: 2, color: "#148F5B", mt: 0.7 }} />
                    <Typography sx={{ mt: 0.7, ml: 1, fontSize: 14 }}>
                      Collateral Inputs
                    </Typography>
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    onClick={() => handleCollateralView("Collateral Inputs")}
                    sx={{
                      display: "flex",
                      mt: 2,
                      ml: 2,
                      mr: 2,
                      cursor: "pointer",
                    }}
                  >
                    <KeyboardIcon sx={{ ml: 2, color: "#148F5B", mt: 0.7 }} />
                    <Typography sx={{ mt: 0.7, ml: 1, fontSize: 14 }}>
                      Collateral Inputs
                    </Typography>
                  </Box>
                </>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              md={1}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                className="horizontalBar"
                sx={{ borderLeft: "1px solid #148F5B", height: 180, mt: 4 }}
              ></Box>
            </Grid>
            {collateralView == "Upload Statement" ? (
              <>
                <Grid item xs={12} md={5} sx={{ mt: 4 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="collateralType"
                        select
                        name="collateralType"
                        label="Collateral Type"
                        variant="outlined"
                        value={collateralType}
                        onChange={(e) => handleCollateralType(e.target.value)}
                        fullWidth
                      >
                        {coll.map((option, key) => (
                          <MenuItem
                            sx={{
                              fontFamily: "'Roboto Condensed', sans-serif",
                              "&:hover": {
                                borderLeft: "5px solid #148F5B",
                                borderRadius: 1,
                              },
                            }}
                            key={key}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div
                        style={{
                          border: "1px solid #dadada",
                          height: 55,
                          borderRadius: 3,
                        }}
                      >
                        <form id="inputType1">
                          <input
                            style={{ marginTop: 15, marginLeft: 10 }}
                            type="file"
                            onChange={handleCollateralDoc}
                            name="bankStatement"
                            multiple
                          ></input>
                        </form>
                      </div>
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={6}>
                      <Button
                        onClick={handleCollateralConfirm}
                        disabled={!collateralFlag}
                        variant="outlined"
                        endIcon={<DoneIcon />}
                        fullWidth
                        sx={{ textTransform: "capitalize", p: 1.7 }}
                      >
                        Confirm Details
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={{ fontSize: 12, color: "#FF0000" }}>
                        *Required
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} md={5} sx={{ mt: 4 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <TextField
                        required
                        value={TypeofProperty}
                        id="TypeofProperty"
                        name="TypeofProperty"
                        onChange={(e) => setTypeofProperty(e.target.value)}
                        fullWidth
                        label="Type of Property"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        value={DeveloperName}
                        id="DeveloperName"
                        name="DeveloperName"
                        onChange={(e) => setDeveloperName(e.target.value)}
                        fullWidth
                        label="Developer Name"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        value={ProjectName}
                        id="ProjectName"
                        name="ProjectName"
                        onChange={(e) => setProjectName(e.target.value)}
                        fullWidth
                        label="Project Name"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      {" "}
                      <TextField
                        value={BuiltUpArea}
                        id="BuiltUpArea"
                        name="BuiltUpArea"
                        onChange={(e) => setBuiltUpArea(e.target.value)}
                        fullWidth
                        label="Built Up Area"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      {" "}
                      <TextField
                        value={PurchasePrice}
                        type={"number"}
                        id="PurchasePrice"
                        name="PurchasePrice"
                        onChange={(e) => setPurchasePrice(e.target.value)}
                        fullWidth
                        label="Purchase Price"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        value={APF}
                        id="APF"
                        name="APF"
                        onChange={(e) => setAPF(e.target.value)}
                        fullWidth
                        label="APF"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider sx={{ mt: 1, mb: 1 }} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        value={UnderConstruction}
                        id="UnderConstruction"
                        name="UnderConstruction"
                        onChange={(e) => setUnderConstruction(e.target.value)}
                        fullWidth
                        label="Under Construction"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        value={CompletePercentage}
                        id="CompletePercentage"
                        name="CompletePercentage"
                        type={"number"}
                        onChange={(e) => setCompletePercentage(e.target.value)}
                        fullWidth
                        label="Complete Percentage"
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        value={AgeofProperty}
                        id="AgeofProperty"
                        name="AgeofProperty"
                        type={"number"}
                        onChange={(e) => setAgeofProperty(e.target.value)}
                        fullWidth
                        label="Age of Property"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider sx={{ mt: 1, mb: 1 }} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      {" "}
                      <TextField
                        value={AddressLine}
                        id="AddressLine"
                        name="AddressLine"
                        onChange={(e) => setAddressLine(e.target.value)}
                        fullWidth
                        label="AddressLine"
                      />{" "}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      {" "}
                      <TextField
                        value={Landmark}
                        id="Landmark"
                        name="Landmark"
                        onChange={(e) => setLandmark(e.target.value)}
                        fullWidth
                        label="Landmark"
                      />{" "}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      {" "}
                      <TextField
                        value={City}
                        id="City"
                        name="City"
                        onChange={(e) => setCity(e.target.value)}
                        fullWidth
                        label="City"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {" "}
                      <TextField
                        value={PinCode}
                        type={"number"}
                        id="PinCode"
                        name="PinCode"
                        onChange={(e) => setPinCode(e.target.value)}
                        fullWidth
                        label="Pin Code"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {" "}
                      <TextField
                        value={State}
                        id="State"
                        name="State"
                        onChange={(e) => setState(e.target.value)}
                        fullWidth
                        label="State"
                      />{" "}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        ) : null}
        {employmentType == 2 ||
        employmentType == 3 ||
        employmentType == 4 ||
        employmentType == 5 ||
        employmentType == 6 ||
        employmentType == 7 ? (<>
        {/** */}
        <Grid item xs={12} md={4} sx={{ mt: 1 }}>
          <Grid container>
            <Grid item xs={2}></Grid>
            <Grid item xs={10} sx={{ display: "flex" }}>
              <div
                style={{ height: "30px", borderLeft: "4px solid #148F5B" }}
              ></div>
              {businessVintageFlag ? (
                <>
                  <Typography
                    sx={{
                      ml: 1,
                      mt: 0.3,
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#4BB543",
                    }}
                  >
                    Business Vintage
                  </Typography>
                  <DoneIcon sx={{ color: "#4BB543", ml: 1, mt: 0.2 }} />
                </>
              ) : (
                <Typography
                  sx={{
                    ml: 1,
                    mt: 0.3,
                    fontWeight: 600,
                    fontSize: 16,
                  }}
                >
                  Business Vintage
                </Typography>
              )}
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={10} sx={{}}>
              <Typography sx={{ ml: 1, mt: 3, fontWeight: 300, fontSize: 14 }}>
                 A business vintage document is a record that verifies the age and history of a business, 
                 including its establishment date and operational timeline.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={2} sx={{ mt: 4 }}>
          <Box
            sx={{
              background: "#DCDCDC",
              display: "flex",
              ml: 2,
              mr: 2,
              height: 35,
              borderRadius: 1,
              cursor: "pointer",
            }}
          >
            <AssignmentIcon sx={{ ml: 2, color: "#148F5B", mt: 0.7 }} />
            <Typography sx={{ mt: 0.7, ml: 1, fontSize: 14 }}>
              Upload Statement
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={1}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Box
            className="horizontalBar"
            sx={{ borderLeft: "1px solid #148F5B", height: 180, mt: 2 }}
          ></Box>
        </Grid>
        <Grid item xs={12} md={5} sx={{ mt: 2 }}>
          <Grid container spacing={2}>
              <>
                <Grid item xs={12} md={6}>
                  <div
                    style={{
                      border: "1px solid #dadada",
                      height: 55,
                      borderRadius: 3,
                    }}
                  >
                    <form id="inputType1">
                      <input
                        style={{
                          marginTop: 15,
                          marginLeft: 10,
                          width: "91%",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        type="file"
                        onChange={handleBusinessVintage1}
                        name="businessVintage"
                      ></input>
                    </form>
                  </div>
                </Grid>
              </>
            <Grid item xs={6}>
              <Button
                onClick={handleBusinessVintageConfirm}
                variant="outlined"
                disabled={!vintageFlag}
                endIcon={<DoneIcon />}
                fullWidth
                sx={{ textTransform: "capitalize", p: 1.7 }}
              >
                Confirm Details
              </Button>
            </Grid>
            <Grid item xs={12} sx={{ m: 2 }}>
              <Typography sx={{ fontSize: 12, color: "#FF0000" }}>
                *Latest 3 to 6 months Business Vintage Document.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/** */}
        <Grid item xs={12} md={4} sx={{ mt: 1 }}>
          <Grid container>
            <Grid item xs={2}></Grid>
            <Grid item xs={10} sx={{ display: "flex" }}>
              <div
                style={{ height: "30px", borderLeft: "4px solid #148F5B" }}
              ></div>
              {businessLicenceFlag ? (
                <>
                  <Typography
                    sx={{
                      ml: 1,
                      mt: 0.3,
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#4BB543",
                    }}
                  >
                    Business Licence
                  </Typography>
                  <DoneIcon sx={{ color: "#4BB543", ml: 1, mt: 0.2 }} />
                </>
              ) : (
                <Typography
                  sx={{
                    ml: 1,
                    mt: 0.3,
                    fontWeight: 600,
                    fontSize: 16,
                  }}
                >
                  Business Licence
                </Typography>
              )}
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={10} sx={{}}>
              <Typography sx={{ ml: 1, mt: 3, fontWeight: 300, fontSize: 14 }}>
              A business licence is an official document that authorizes a business to operate legally, 
              often required for compliance, loan applications, or regulatory purposes.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={2} sx={{ mt: 4 }}>
          <Box
            sx={{
              background: "#DCDCDC",
              display: "flex",
              ml: 2,
              mr: 2,
              height: 35,
              borderRadius: 1,
              cursor: "pointer",
            }}
          >
            <AssignmentIcon sx={{ ml: 2, color: "#148F5B", mt: 0.7 }} />
            <Typography sx={{ mt: 0.7, ml: 1, fontSize: 14 }}>
              Upload Statement
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={1}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Box
            className="horizontalBar"
            sx={{ borderLeft: "1px solid #148F5B", height: 180, mt: 2 }}
          ></Box>
        </Grid>
        <Grid item xs={12} md={5} sx={{ mt: 2 }}>
          <Grid container spacing={2}>
              <>
                <Grid item xs={12} md={6}>
                  <div
                    style={{
                      border: "1px solid #dadada",
                      height: 55,
                      borderRadius: 3,
                    }}
                  >
                    <form id="inputType1">
                      <input
                        style={{
                          marginTop: 15,
                          marginLeft: 10,
                          width: "91%",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        type="file"
                        onChange={handleBusinessLicence1}
                        name="businessLicence"
                      ></input>
                    </form>
                  </div>
                </Grid>
              </>
            <Grid item xs={6}>
              <Button
                onClick={handleBusinessLicenceConfirm}
                variant="outlined"
                disabled={!licenceFlag}
                endIcon={<DoneIcon />}
                fullWidth
                sx={{ textTransform: "capitalize", p: 1.7 }}
              >
                Confirm Details
              </Button>
            </Grid>
            <Grid item xs={12} sx={{ m: 2 }}>
              <Typography sx={{ fontSize: 12, color: "#FF0000" }}>
                *Latest 3 to 6 months Business Licence Document.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        </>):null}
        {employmentType != 1 && employmentType != 2 && employmentType != 7 ? (
          <>
            <Grid item xs={12} md={4} sx={{ mt: 3 }}>
              <Grid container>
                <Grid item xs={2}></Grid>
                <Grid item xs={10} sx={{ display: "flex" }}>
                  <div
                    style={{ height: "30px", borderLeft: "4px solid #148F5B" }}
                  ></div>

                  {financialStatementConfirmFlag ? (
                    <>
                      <Typography
                        sx={{
                          ml: 1,
                          mt: 0.3,
                          fontWeight: 600,
                          fontSize: 16,
                          color: "#4BB543",
                        }}
                      >
                        Financial Statement (Optional)
                      </Typography>
                      <DoneIcon sx={{ color: "#4BB543", ml: 1, mt: 0.2 }} />
                    </>
                  ) : (
                    <Typography
                      sx={{
                        ml: 1,
                        mt: 0.3,
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      Financial Statement (Optional)
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={10} sx={{}}>
                  <Typography
                    sx={{ ml: 1, mt: 3, fontWeight: 300, fontSize: 14 }}
                  >
                    Financial details refer to specific information about an
                    individual's or a company's financial position and
                    performance.
                  </Typography>
                  {/* <Typography
                sx={{
                  mt: 1,
                  ml: 1,
                  fontWeight: 600,
                  fontSize: 12,
                  color: "#148F5B",
                  cursor: "pointer",
                }}
              >
                Learn More
              </Typography> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={2} sx={{ mt: 6 }}>
              <Box
                sx={{
                  background: "#DCDCDC",
                  display: "flex",
                  ml: 2,
                  mr: 2,
                  height: 35,
                  borderRadius: 1,
                  cursor: "pointer",
                }}
              >
                <AssignmentIcon sx={{ ml: 2, color: "#148F5B", mt: 0.7 }} />
                <Typography sx={{ mt: 0.7, ml: 1, fontSize: 14 }}>
                  Upload Statement
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={1}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                className="horizontalBar"
                sx={{ borderLeft: "1px solid #148F5B", height: 180, mt: 4 }}
              ></Box>
            </Grid>
            <Grid item xs={12} md={5} sx={{ mt: 4 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField select label="Select financial year" fullWidth>
                    {year.map((option, key) => (
                      <MenuItem
                        key={key}
                        value={option}
                        sx={{
                          fontFamily: "'Roboto Condensed', sans-serif",
                          "&:hover": {
                            borderLeft: "5px solid #148F5B",
                            borderRadius: 1,
                          },
                        }}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div
                    style={{
                      border: "1px solid #dadada",
                      height: 55,
                      borderRadius: 3,
                    }}
                  >
                    <form id="inputType1">
                      <input
                        style={{ marginTop: 15, marginLeft: 10 }}
                        type="file"
                        onChange={handlefinancailStatemnt}
                        name="financialStatement"
                      ></input>
                    </form>
                  </div>
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                  <Button
                    onClick={handlefinancailStatemntConfirm}
                    disabled={!financialStatemntFlag}
                    variant="outlined"
                    endIcon={<DoneIcon />}
                    fullWidth
                    sx={{ textTransform: "capitalize", p: 1.7 }}
                  >
                    Confirm Details
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: 12, color: "#FF0000" }}>
                    *Financial statement for last 2 years
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} sx={{ mt: 3 }}>
              <Grid container>
                <Grid item xs={2}></Grid>
                <Grid item xs={10} sx={{ display: "flex" }}>
                  <div
                    style={{ height: "30px", borderLeft: "4px solid #148F5B" }}
                  ></div>

                  {GSTConfirmFlag ? (
                    <>
                      <Typography
                        sx={{
                          ml: 1,
                          mt: 0.3,
                          fontWeight: 600,
                          fontSize: 16,
                          color: "#4BB543",
                        }}
                      >
                        GST Document (Optional)
                      </Typography>
                      <DoneIcon sx={{ color: "#4BB543", ml: 1, mt: 0.2 }} />
                    </>
                  ) : (
                    <Typography
                      sx={{
                        ml: 1,
                        mt: 0.3,
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      GST Document (Optional)
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={10} sx={{}}>
                  <Typography
                    sx={{ ml: 1, mt: 3, fontWeight: 300, fontSize: 14 }}
                  >
                    A GST document refers to any document related to the Goods
                    and Services Tax (GST), which is a value-added tax levied on
                    most goods and services sold for domestic consumption in
                    many countries around the world.
                  </Typography>
                  {/* <Typography
                sx={{
                  mt: 1,
                  ml: 1,
                  fontWeight: 600,
                  fontSize: 12,
                  color: "#148F5B",
                  cursor: "pointer",
                }}
              >
                Learn More
              </Typography> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={2} sx={{ mt: 6 }}>
              <Box
                sx={{
                  background: "#DCDCDC",
                  display: "flex",
                  ml: 2,
                  mr: 2,
                  height: 35,
                  borderRadius: 1,
                  cursor: "pointer",
                }}
              >
                <AssignmentIcon sx={{ ml: 2, color: "#148F5B", mt: 0.7 }} />
                <Typography sx={{ mt: 0.7, ml: 1, fontSize: 14 }}>
                  Upload Statement
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  mt: 2,
                  ml: 2,
                  mr: 2,
                  cursor: "pointer",
                }}
                // onClick={() => handleOnlineGST()}
              >
                <AccountBalanceIcon sx={{ ml: 2, color: "#148F5B", mt: 0.7 }} />
                <Typography sx={{ mt: 0.7, ml: 1, fontSize: 14 }}>
                  GST Login
                </Typography>
              </Box>
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Online GST Document </DialogTitle>
                <DialogContent>
                  {ErrorPan ? (
                    <Alert severity="error">Wrong PAN or State Code</Alert>
                  ) : null}

                  <DialogContentText
                    style={{
                      color: "blue",
                      fontSize: "14px",
                      textAlign: "right",
                      cursor: "pointer",
                    }}
                    onClick={() => setForgotGST("PANInfo")}
                  >
                    Forgot your GST Number
                  </DialogContentText>
                  <Grid item xs={12} sx={{ mt: 3 }}>
                    <Grid container spacing={4} sx={{ pl: 1, pr: 1 }}>
                      {isOnlineGST == "PANInfo" ? (
                        <>
                          <Grid item xs={12} sm={6} md={6}>
                            <Box style={{ margin: "1%" }}>
                              <TextField
                                fullWidth
                                label="PAN"
                                variant="outlined"
                                name="pan"
                                value={onlineGSTDetails.pan}
                                onChange={(e) => handleGSTDetailsChange(e)}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Box style={{ margin: "1%" }}>
                              <TextField
                                fullWidth
                                label="State Code"
                                variant="outlined"
                                name="stateCode"
                                value={onlineGSTDetails.stateCode}
                                onChange={(e) => handleGSTDetailsChange(e)}
                              />
                            </Box>
                          </Grid>
                        </>
                      ) : null}
                      {isOnlineGST == "GSTInfo" ? (
                        <>
                          <Grid item xs={12} sm={6} md={6}>
                            <Box style={{ margin: "1%" }}>
                              <TextField
                                fullWidth
                                label="GST No."
                                variant="outlined"
                                name="GSTNo"
                                value={onlineGSTDetails.GSTNo}
                                onChange={(e) => handleGSTDetailsChange(e)}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Box style={{ margin: "1%" }}>
                              <TextField
                                fullWidth
                                label="Username"
                                variant="outlined"
                                name="UserName"
                                value={onlineGSTDetails.UserName}
                                onChange={(e) => handleGSTDetailsChange(e)}
                              />
                            </Box>
                          </Grid>
                        </>
                      ) : null}
                      {isOnlineGST == "OTPVerification" ? (
                        <>
                          <Grid item xs={12} sm={12} md={12}>
                            <Box style={{ margin: "1%" }}>
                              <TextField
                                fullWidth
                                type={"number"}
                                label="OPT"
                                variant="outlined"
                                name="OTP"
                                value={onlineGSTDetails.OTP}
                                onChange={(e) => handleGSTDetailsChange(e)}
                              />
                            </Box>
                          </Grid>
                        </>
                      ) : null}
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    sx={{ textTransform: "capitalize" }}
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                  <Button
                    sx={{ textTransform: "capitalize" }}
                    onClick={saveOnlineGSTDetails}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
            <Grid
              item
              xs={12}
              md={1}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                className="horizontalBar"
                sx={{ borderLeft: "1px solid #148F5B", height: 180, mt: 4 }}
              ></Box>
            </Grid>
            <Grid item xs={12} md={5} sx={{ mt: 4 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <div
                    style={{
                      border: "1px solid #dadada",
                      height: 55,
                      borderRadius: 3,
                    }}
                  >
                    <form id="inputType1">
                      <input
                        style={{ marginTop: 15, marginLeft: 10 }}
                        type="file"
                        onChange={handleGstDoc}
                        name="gstStatement"
                        multiple
                      ></input>
                    </form>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    onClick={handleGSTConfirm}
                    disabled={!GSTFlag}
                    variant="outlined"
                    endIcon={<DoneIcon />}
                    fullWidth
                    sx={{ textTransform: "capitalize", p: 1.7 }}
                  >
                    Confirm Details
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: 12, color: "#FF0000" }}>
                    *GST document for last 6 month
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} md={4} sx={{ mt: 1 }}>
              <Grid container>
                <Grid item xs={2}></Grid>
                <Grid item xs={10} sx={{ display: "flex" }}>
                  <div
                    style={{ height: "30px", borderLeft: "4px solid #148F5B" }}
                  ></div>
                  {salaryConfirmFlag ? (
                    <>
                      <Typography
                        sx={{
                          ml: 1,
                          mt: 0.3,
                          fontWeight: 600,
                          fontSize: 16,
                          color: "#4BB543",
                        }}
                      >
                        Salary Slip (Optional)
                      </Typography>
                      <DoneIcon sx={{ color: "#4BB543", ml: 1, mt: 0.2 }} />
                    </>
                  ) : (
                    <Typography
                      sx={{
                        ml: 1,
                        mt: 0.3,
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      Salary Slip (Optional)
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={10} sx={{}}>
                  <Typography
                    sx={{ ml: 1, mt: 3, fontWeight: 300, fontSize: 14 }}
                  >
                    A salary slip, also known as a payslip or paycheck, is a
                    document provided to an employee by their employer on a
                    regular basis (usually monthly) that outlines the details of
                    their salary, earnings, deductions, and other related
                    information.
                  </Typography>
                  {/* <Typography
                sx={{
                  mt: 1,
                  ml: 1,
                  fontWeight: 600,
                  fontSize: 12,
                  color: "#148F5B",
                  cursor: "pointer",
                }}
              >
                Learn More
              </Typography> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={2} sx={{ mt: 4 }}>
              <Box
                sx={{
                  background: "#DCDCDC",
                  display: "flex",
                  ml: 2,
                  mr: 2,
                  height: 35,
                  borderRadius: 1,
                  cursor: "pointer",
                }}
              >
                <AssignmentIcon sx={{ ml: 2, color: "#148F5B", mt: 0.7 }} />
                <Typography sx={{ mt: 0.7, ml: 1, fontSize: 14 }}>
                  Upload Statement
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={1}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                className="horizontalBar"
                sx={{ borderLeft: "1px solid #148F5B", height: 180, mt: 2 }}
              ></Box>
            </Grid>
            <Grid item xs={12} md={5} sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                {/* <Grid item xs={12} md={6}>
                  <TextField
                    select
                    id="Choose Bank"
                    label="Choose Bank"
                    variant="outlined"
                    onChange={handleBankNameChange}
                    fullWidth
                  >
                    {bankNames.map((option) => (
                      <MenuItem
                        key={option}
                        value={option}
                        sx={{
                          fontFamily: "'Roboto Condensed', sans-serif",
                          "&:hover": {
                            borderLeft: "5px solid #148F5B",
                            borderRadius: 1,
                          },
                        }}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid> */}
                <Grid item xs={12} md={6}>
                  <div
                    style={{
                      border: "1px solid #dadada",
                      height: 55,
                      borderRadius: 3,
                    }}
                  >
                    <form id="inputType1">
                      <input
                        style={{ marginTop: 15, marginLeft: 10 }}
                        type="file"
                        onChange={handleSalaryStatement}
                        name="bankStatement"
                        multiple
                      ></input>
                    </form>
                  </div>
                </Grid>

                <Grid item xs={6} md={6}>
                  <Button
                    onClick={handleSalaryConfirm}
                    variant="outlined"
                    disabled={!salaryFlag}
                    endIcon={<DoneIcon />}
                    fullWidth
                    sx={{ textTransform: "capitalize", p: 1.7 }}
                  >
                    Confirm Details
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: 12, color: "#FF0000" }}>
                    *Salary Slip for last 1 month
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} sx={{ mt: 1 }}>
              <Grid container>
                <Grid item xs={2}></Grid>
                <Grid item xs={10} sx={{ display: "flex" }}>
                  <div
                    style={{ height: "30px", borderLeft: "4px solid #148F5B" }}
                  ></div>
                  {itrConfirmFlag ? (
                    <>
                      <Typography
                        sx={{
                          ml: 1,
                          mt: 0.3,
                          fontWeight: 600,
                          fontSize: 16,
                          color: "#4BB543",
                        }}
                      >
                        ITR Statement (Optional)
                      </Typography>
                      <DoneIcon sx={{ color: "#4BB543", ml: 1, mt: 0.2 }} />
                    </>
                  ) : (
                    <Typography
                      sx={{
                        ml: 1,
                        mt: 0.3,
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      ITR Statement (Optional)
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={10} sx={{}}>
                  <Typography
                    sx={{ ml: 1, mt: 3, fontWeight: 300, fontSize: 14 }}
                  >
                    ITR stands for Income Tax Return. It is a document that
                    individuals or entities file with the tax authorities to
                    report their income, calculate their tax liability, and
                    provide other relevant financial information for a specific
                    assessment year.
                  </Typography>
                  {/* <Typography
                sx={{
                  mt: 1,
                  ml: 1,
                  fontWeight: 600,
                  fontSize: 12,
                  color: "#148F5B",
                  cursor: "pointer",
                }}
              >
                Learn More
              </Typography> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={2} sx={{ mt: 4 }}>
              <Box
                sx={{
                  background: "#DCDCDC",
                  display: "flex",
                  ml: 2,
                  mr: 2,
                  height: 35,
                  borderRadius: 1,
                  cursor: "pointer",
                }}
              >
                <AssignmentIcon sx={{ ml: 2, color: "#148F5B", mt: 0.7 }} />
                <Typography sx={{ mt: 0.7, ml: 1, fontSize: 14 }}>
                  Upload Statement
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={1}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                className="horizontalBar"
                sx={{ borderLeft: "1px solid #148F5B", height: 180, mt: 2 }}
              ></Box>
            </Grid>
            <Grid item xs={12} md={5} sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                {/* <Grid item xs={12} md={6}>
                  <TextField
                    select
                    id="Choose Bank"
                    label="Choose Bank"
                    variant="outlined"
                    onChange={handleBankNameChange}
                    fullWidth
                  >
                    {bankNames.map((option) => (
                      <MenuItem
                        key={option}
                        value={option}
                        sx={{
                          fontFamily: "'Roboto Condensed', sans-serif",
                          "&:hover": {
                            borderLeft: "5px solid #148F5B",
                            borderRadius: 1,
                          },
                        }}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid> */}
                <Grid item xs={12} md={6}>
                  <div
                    style={{
                      border: "1px solid #dadada",
                      height: 55,
                      borderRadius: 3,
                    }}
                  >
                    <form id="inputType1">
                      <input
                        style={{ marginTop: 15, marginLeft: 10 }}
                        type="file"
                        onChange={handleITRStatement}
                        name="bankStatement"
                        multiple
                      ></input>
                    </form>
                  </div>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Button
                    onClick={handleITRConfirm}
                    variant="outlined"
                    disabled={!itrFlag}
                    endIcon={<DoneIcon />}
                    fullWidth
                    sx={{ textTransform: "capitalize", p: 1.7 }}
                  >
                    Confirm Details
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: 12, color: "#FF0000" }}>
                    *ITR statement for last 6 month
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
        >
          {loanProductType == 15 ? (
            <Button
              onClick={navigateToLoanDetails}
              variant="contained"
              sx={{ textTransform: "capitalize", m: 1 }}
            >
              Skip
            </Button>
          ) : null}
          {!has401Error && messsage ? (
            <Button
              onClick={handleNextStep}
              endIcon={<DoneIcon />}
              variant="contained"
              sx={{ textTransform: "capitalize", m: 1 }}
            >
              Next Step
            </Button>
          ) : (
            <Button
              disabled={
                (!uploadButtonFlag || bankName1 == null || checked == false) && !docsPresent
              }
              onClick={handleConfirmAllDetails}
              endIcon={<CloudUploadIcon />}
              variant="contained"
              sx={{ textTransform: "capitalize", m: 1 }}
            >
              Upload Documents
            </Button>
          )}
        </Grid>
        <Footer />
      </Grid>
      {uploadDailog ? <DialogView /> : null}
    </>
  );
}

export default Index;
