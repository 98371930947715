import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import {
  TopbarViewSteps,
  UploadDilaogView,
  ConfirmAllDilaog,
  docsUploadeded
} from "../../BasicDetails/BasicDetailsActions/BasicDetailsActions";

export default function DialogView() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [fullWidth, setFullWidth] = React.useState(true);
  const [has401Error, sethas401Error] = React.useState(false);
  const [has400Error, sethas400Error] = React.useState(false);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [error401Files, setError401Files] = React.useState([]);

  const uploadDailog = useSelector(
    (state) => state.BasicDetailsReducers.uploadDailog
  );

  const stepCount = useSelector(
    (state) => state.BasicDetailsReducers.stepCount
  );

  const handleNext = async() => {
    let count = stepCount + 1;
    dispatch(UploadDilaogView(!uploadDailog));
    dispatch(TopbarViewSteps(count));
    const response = await docsUploadeded(localStorage.getItem("LoanApplicationId"));
    console.log(response?.data?.data?.liveliness_flag);
    if(response?.data?.data?.liveliness_flag == true){
      navigate("/MyLoan");
    }
    else{
    navigate("/LoanCofirmation");
    }
  };
  const handleClose = () => {
    dispatch(UploadDilaogView(!uploadDailog));
    window.location.reload();
  };

  const uploadDocumentLoading = useSelector(
    (state) => state.BasicDetailsReducers.uploadDocumentLoading
  );
  const uploadDocumentResponse = useSelector(
    (state) => state.BasicDetailsReducers.uploadDocumentResponse
  );
  useEffect(() => {
    const has401Error1 = uploadDocumentResponse?.upload_status.some(
      (status) => status.errorCode === 401
    );
    const has400Error1 = uploadDocumentResponse?.upload_status.some(
      (status) => status.errorCode === 400
    );
    sethas401Error(has401Error1);
    sethas400Error(has400Error1);
    const error401Files =
      uploadDocumentResponse?.upload_status
        ?.filter((item) => item.errorCode === 401)
        ?.map((item) => {
          const parts = item.file_name.split("Bank_");
          return parts.length > 1 ? parts[1] : item.file_name;
        }) || [];

    const error401FilesString = error401Files.join(", ");

    // Set the error401FilesString to the state or directly use it in your JSX
    setError401Files(error401FilesString);
  }, [uploadDocumentResponse]);

  const messsage = uploadDocumentResponse ? uploadDocumentResponse.status : "";

  const uploadDocumentFail = useSelector(
    (state) => state.BasicDetailsReducers.uploadDocumentFail
  );

  return (
    <div>
      <Dialog
        open={uploadDailog}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ color: "#148F5B" }} id="alert-dialog-title">
          {"Confirmation Message"}
        </DialogTitle>
        <DialogContent>
          {uploadDocumentLoading ? (
            <>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            </>
          ) : null}
          {/* {uploadDocumentLoading && has400Error ? (<><DialogContentText
              sx={{ fontWeight: 900 }}
              id="alert-dialog-description"
            >
              Either the the stat
            </DialogContentText></>):(<></>)} */}
          {has401Error && !uploadDocumentLoading ? (
            <DialogContentText
              sx={{ fontWeight: 900 }}
              id="alert-dialog-description"
            >
              Either the bank name or password of the statement is incorrect for
              the following file/files-<b> {error401Files}</b>. Please re-Upload
              these files and try again.
            </DialogContentText>
          ) : (
            <>
              {uploadDocumentResponse && !uploadDocumentLoading ? (
                <>
                  <DialogContentText
                    sx={{ fontWeight: 900 }}
                    id="alert-dialog-description"
                  >
                    Thank you for saving your information. We have received your
                    details and can confirm that your information has been
                    successfully saved in our system.
                  </DialogContentText>
                </>
              ) : null}
            </>
          )}
        </DialogContent>
        <DialogActions>
          {has401Error && !uploadDocumentLoading ? (
            <Button sx={{ textTransform: "capitalize" }} onClick={handleClose}>
              ReUpload
            </Button>
          ) : (
            <>
              {uploadDocumentResponse && !uploadDocumentLoading ? (
                <>
                  {messsage == "success" ? (
                    <>
                      <Button
                        sx={{ textTransform: "capitalize" }}
                        onClick={handleClose}
                      >
                        Close
                      </Button>
                      <Button
                        sx={{ textTransform: "capitalize" }}
                        onClick={handleNext}
                        autoFocus
                      >
                        Next Step
                      </Button>
                    </>
                  ) : null}
                </>
              ) : null}
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
