import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { getRepaymentSchedule1 } from "../../Components/BasicDetails/BasicDetailsActions/BasicDetailsActions";
import SessionModal from "./../../Common/SessionModel";
import { useNavigate } from "react-router-dom";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#148F5B",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function CustomizedTables() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get("loanID");

  const myloanLoading = useSelector(
    (state) => state.BasicDetailsReducers.myloanLoading
  );

  const myloanResponse = useSelector(
    (state) => state.BasicDetailsReducers.myloanResponse
  );

  const getLoanDetailsResponse = useSelector(
    (state) => state.BasicDetailsReducers.getLoanDetailsResponse
  );
  const getRepaymentSchedule = useSelector(
    (state) => state.BasicDetailsReducers.getRepaymentScheduleSuccess
  );
  const handleLogoutButton = () => {
    localStorage.removeItem("jwt_token");
    localStorage.removeItem("EmailId");
    localStorage.removeItem("branch");
    setShowAlert(true);
  };
  const handleCloseErrorModal1 = () => {
    setShowAlert(false);
    navigate("/login");
  };
  useEffect(() => {
    if (code != null) {
      if (getLoanDetailsResponse.length != 0) {
        dispatch(
          getRepaymentSchedule1(
            getLoanDetailsResponse[0].Loan_Application_id,
            getLoanDetailsResponse[0].Sanctioned_amount,
            getLoanDetailsResponse[0].interest_rate,
            getLoanDetailsResponse[0].loan_tenure,
            handleLogoutButton
          )
        );
      }
    } else {
      // dispatch(GetLoanDetails());
    }
  }, [getLoanDetailsResponse]);
  return (
    <div>
      <div>
        {/* Your component content */}
        <SessionModal isOpen={showAlert} onClose={handleCloseErrorModal1} />
      </div>
      {code != null ? (
        <TableContainer component={Paper}>
          {getRepaymentSchedule.length >= 0 &&
          getRepaymentSchedule != undefined ? (
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell sx={{ fontWeight: 900 }}>
                    Month
                  </StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: 900 }} align="center">
                    Interest paid this month
                  </StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: 900 }} align="center">
                    EMI per month
                  </StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: 900 }} align="center">
                    Opening Amount
                  </StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: 900 }} align="center">
                    Closing Amount
                  </StyledTableCell>
                  <StyledTableCell sx={{ fontWeight: 900 }} align="center">
                    Principal amount paid this month
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              {getRepaymentSchedule.length >= 0 &&
              getRepaymentSchedule != undefined ? (
                <TableBody sx={{ p: 2 }}>
                  {getRepaymentSchedule.data.map((Details) => (
                    <StyledTableRow sx={{ p: 2 }}>
                      <StyledTableCell
                        sx={{ fontWeight: 900 }}
                        component="th"
                        scope="row"
                      >
                        {" "}
                        {Details.month}
                      </StyledTableCell>
                      <StyledTableCell sx={{ fontWeight: 900 }} align="center">
                        {Details.interest_amount_month}
                      </StyledTableCell>
                      <StyledTableCell sx={{ fontWeight: 900 }} align="center">
                        {Details.emi_per_month}
                      </StyledTableCell>
                      <StyledTableCell sx={{ fontWeight: 900 }} align="center">
                        {Details.opening_amount_month}
                      </StyledTableCell>
                      <StyledTableCell sx={{ fontWeight: 900 }} align="center">
                        {Details.outstanding_loan_balance}
                      </StyledTableCell>
                      <StyledTableCell sx={{ fontWeight: 900 }} align="center">
                        {Details.principal_amount_month}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              ) : null}
            </Table>
          ) : null}
        </TableContainer>
      ) : null}
    </div>
  );
}
