import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Alert, Grid, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  AadharDrawer,
  InitiateSession,
  GenOTP,
  VerifyOTP,
  GenOTPClearData,
  InitiateOTPClearData,
  VerifyOTPClearData,
  SaveaadharInformation,
} from "../BasicDetailsActions/BasicDetailsActions";
import ImageTextField from "./ImageTextField";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { initialData, gender, states } from "./constant";
import MenuItem from "@mui/material/MenuItem";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import adharVerification from "./../../../Assets/adharVerification.svg";
import dayjs from "dayjs";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

export default function AadharVerification(props) {
  const dispatch = useDispatch();
  const [sessionID, setSessionID] = React.useState();
  const [captcha, setCaptcha] = React.useState();
  //----------------------------------------------------------------
  const [progress, setProgress] = React.useState(5);
  const [jsonData, setJsonData] = React.useState(initialData);
  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 5
      );
    }, 3000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  //-----------------------------------------------------------------
  useEffect(() => {
    dispatch(InitiateSession());
  }, []);

  const initiateSessionAadharSuccess = useSelector(
    (state) => state.BasicDetailsReducers.initiateSessionAadharSuccess
  );

  const initiateSessionAadharLoading = useSelector(
    (state) => state.BasicDetailsReducers.initiateSessionAadharLoading
  );

  const initiateSessionAadharFail = useSelector(
    (state) => state.BasicDetailsReducers.initiateSessionAadharFail
  );

  useEffect(() => {
    handleParse();
  }, [initiateSessionAadharSuccess]);

  const handleParse = () => {
    if (Object.keys(initiateSessionAadharSuccess).length) {
      const data1 = initiateSessionAadharSuccess.data;
      const obj = JSON.parse(data1);
      setSessionID(obj.data.session_id);
      setCaptcha(obj.data.captcha);
    }
  };

  //------------------------------------------------------------------------------------

  const data = { ...props };
  const aadharDrawer = useSelector(
    (state) => state.BasicDetailsReducers.aadharDrawer
  );
  const handleClose = () => {
    setOtpMessage("");
    setOtVerifypMessage("");
    dispatch(AadharDrawer(!aadharDrawer));
    dispatch(GenOTPClearData());
    dispatch(InitiateOTPClearData());
    if (verifyOTPSuccess?.data?.code == 400) {
      dispatch(VerifyOTPClearData());
    }
  };

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  //-----------------------------------------------------------
  const [confirmnCaptcha, setConfirmCaptcha] = React.useState();
  const [otpMessage, setOtpMessage] = React.useState();
  const [otVerifypMessage, setOtVerifypMessage] = React.useState();
  const [otpFailStatusCode, setotpFailStatusCode] = React.useState("");
  const [errorInAadharDetails, seterrorInAadharDetails] = React.useState(false);
  const [havingtroubleVerifying, sethavingtroubleVerifying] =
    React.useState(false);
  const [regenerateCaptcha, setregenerateCaptcha] = React.useState(false);
  //-----------------------------------------------------------
  const handleConfirmCaptcha = (e) => {
    setConfirmCaptcha(e);
  };

  const verifyCaptcha = () => {
    setOtpMessage("");
    dispatch(GenOTP(data.selectedAadhar, confirmnCaptcha, sessionID));
  };
  //--------------------------------------------------------------

  //--------------------------------------------------------------
  const [otp, setOTP] = React.useState();

  const handleOTP = (e) => {
    setOTP(e);
  };

  const handleConfirmOTP = () => {
    setOtVerifypMessage("");
    dispatch(VerifyOTP(otp, sessionID, data.isCoBorrower));
  };

  //------------------------------------------------------------
  const genOTPLoading = useSelector(
    (state) => state.BasicDetailsReducers.genOTPLoading
  );

  const genOTPSuccess = useSelector(
    (state) => state.BasicDetailsReducers.genOTPSuccess
  );

  const genOTPFail = useSelector(
    (state) => state.BasicDetailsReducers.genOTPFail
  );

  useEffect(() => {
    handleParseOTP();
  }, [genOTPSuccess]);

  //--------------------------------------------------------------

  const handleParseOTP = () => {
    if (Object.keys(genOTPSuccess).length) {
      const data1 = genOTPSuccess.data;
      const obj = JSON.parse(data1);
      setOtpMessage(obj.message);
    }
  };

  //-------------------------------------------------------------
  const verifyOTPLoading = useSelector(
    (state) => state.BasicDetailsReducers.verifyOTPLoading
  );

  const verifyOTPSuccess = useSelector(
    (state) => state.BasicDetailsReducers.verifyOTPSuccess
  );

  useEffect(() => {
    handleVerifyOTP();
  }, [verifyOTPSuccess]);

  useEffect(() => {
    if (verifyOTPSuccess?.data?.code == 200) {
      handleClose();
    }
  }, [verifyOTPSuccess]);

  //--------------------------------------------------------------

  const handleVerifyOTP = () => {
    if (Object.keys(verifyOTPSuccess).length) {
      const data1 = verifyOTPSuccess.data;
      const obj = data1;
      setOtVerifypMessage(obj.message);
      setotpFailStatusCode(obj.code);
    }
  };
  const regenerateOTP = () => {
    dispatch(InitiateSession());
    setConfirmCaptcha("");
    setOtpMessage("");
    setOtVerifypMessage("");
    setOTP("");
    setregenerateCaptcha(true);
  };
  const handleChange = (event, type) => {
    seterrorInAadharDetails(false);
    if (type == "gender") {
      const newGender = event;

      // Update the gender value in the JSON data
      const updatedData = { ...jsonData };
      updatedData.data.data.gender = newGender;
      setJsonData(updatedData);
    }
    if (type == "dob") {
      const date = formatDate(event);
      const updatedData = { ...jsonData };
      updatedData.data.data.dateOfBirth = date;
      setJsonData(updatedData);
    }
    if (type == "state") {
      const state = event;

      // Update the gender value in the JSON data
      const updatedData = { ...jsonData };
      updatedData.data.data.address.state = state;
      setJsonData(updatedData);
    }
    if (type == "house") {
      const house = event;

      // Update the gender value in the JSON data
      const updatedData = { ...jsonData };
      updatedData.data.data.address.house = house;
      setJsonData(updatedData);
    }
    if (type == "street") {
      const street = event;

      // Update the gender value in the JSON data
      const updatedData = { ...jsonData };
      updatedData.data.data.address.street = street;
      setJsonData(updatedData);
    }
    if (type == "locality") {
      const locality = event;

      // Update the gender value in the JSON data
      const updatedData = { ...jsonData };
      updatedData.data.data.address.locality = locality;
      setJsonData(updatedData);
    }
    if (type == "pincode") {
      const pin = event;

      // Update the gender value in the JSON data
      const updatedData = { ...jsonData };
      updatedData.data.data.address.pin = pin;
      setJsonData(updatedData);
    }
    if (type == "city") {
      const district = event;

      // Update the gender value in the JSON data
      const updatedData = { ...jsonData };
      updatedData.data.data.address.district = district;
      setJsonData(updatedData);
    }
  };
  const saveAadharDetails = () => {
    console.log(jsonData);
    if (
      jsonData.data.data.gender == "" ||
      jsonData.data.data.dateOfBirth == "" ||
      jsonData.data.data.address.state == "" ||
      jsonData.data.data.address.house == "" ||
      jsonData.data.data.address.street == "" ||
      jsonData.data.data.address.district == "" ||
      jsonData.data.data.address.pin == ""
    ) {
      seterrorInAadharDetails(true);
      return;
    } else {
      dispatch(SaveaadharInformation(jsonData));
      handleClose();
    }
  };
  const formatDate = (date) => {
    if (!date) return "";
    const dob = new Date(date);
    const day = dob.getDate();
    const month = dob.getMonth() + 1;
    const year = dob.getFullYear();
    return `${day < 10 ? "0" + day : day}-${
      month < 10 ? "0" + month : month
    }-${year}`;
  };
  const handleChangeAadharVerify = () => {
    sethavingtroubleVerifying(!havingtroubleVerifying);
  };
  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={aadharDrawer}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{ fontweight: 900 }}
        >
          <Typography sx={{ fontweight: 900 }}>
            Aadhaar Verification{" "}
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent>
          {(otpFailStatusCode == "" || otpFailStatusCode == 200) &&
          !havingtroubleVerifying ? (
            <>
              <Grid container sx={{ pl: 4, pr: 4, mt: 4 }}>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center", pt: 2 }}
                >
                  {initiateSessionAadharLoading ? (
                    <>
                      <CircularProgressWithLabel value={progress} />
                    </>
                  ) : null}
                </Grid>
                {initiateSessionAadharFail ? (
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Typography sx={{ fontSize: 14, color: "#FF0000", mb: 2 }}>
                      * Sorry! Session has expired. Please try again.
                    </Typography>
                  </Grid>
                ) : null}
                <Grid item xs={12} sx={{ borderBottom: "2px solid #BEBEBE" }}>
                  <Typography sx={{ fontSize: 14 }}>Generate OTP</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1} sx={{ mt: 2 }}>
                    <Grid item xs={12} md={3}>
                      <TextField
                        label="Aadhaar"
                        fullWidth
                        value={data.selectedAadhar}
                      />
                    </Grid>
                    {!initiateSessionAadharLoading ? (
                      <>
                        <Grid item xs={12} md={3}>
                          <ImageTextField base64Image={captcha} />
                        </Grid>
                      </>
                    ) : null}

                    <Grid item xs={12} md={3}>
                      <TextField
                        id="confirmcaptcha"
                        fullWidth
                        name="confirmcaptcha"
                        value={confirmnCaptcha}
                        onChange={(e) => handleConfirmCaptcha(e.target.value)}
                        label="Confirm Captcha"
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <Button
                        variant="outlined"
                        fullWidth
                        disabled={!confirmnCaptcha || !data.selectedAadhar}
                        onClick={verifyCaptcha}
                        sx={{ textTransform: "capitalize", p: 1.8 }}
                      >
                        Generate OTP
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                      style={{
                        width: "100%",
                      }}
                    >
                      <Button
                        sx={{ textTransform: "none" }}
                        variant="contained"
                        className="download-button"
                        onClick={regenerateOTP}
                      >
                        Regenerate Captcha
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={{ color: "#148F5B", mt: 1 }}>
                        * {otpMessage}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", justifyContent: "center", pt: 2 }}
                    >
                      {genOTPLoading ? (
                        <>
                          <CircularProgressWithLabel value={progress} />
                        </>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container sx={{ pl: 4, pr: 4, mt: 4 }}>
                <Grid item xs={12} sx={{ borderBottom: "2px solid #BEBEBE" }}>
                  <Typography sx={{ fontSize: 14 }}>Verify OTP</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1} sx={{ mt: 2 }}>
                    <Grid item xs={12} md={3}>
                      <TextField
                        label="OTP"
                        id="otp"
                        fullWidth
                        name="otp"
                        value={otp}
                        onChange={(e) => handleOTP(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Button
                        variant="outlined"
                        onClick={handleConfirmOTP}
                        disabled={
                          initiateSessionAadharFail ||
                          genOTPFail == null ||
                          genOTPFail == true
                        }
                        fullWidth
                        sx={{ textTransform: "capitalize", p: 1.8 }}
                      >
                        Verify OTP
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={{ color: "#148F5B", mt: 1 }}>
                        * {otVerifypMessage}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", justifyContent: "center", pt: 2 }}
                    >
                      {verifyOTPLoading ? (
                        <>
                          <CircularProgressWithLabel value={progress} />
                        </>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {genOTPSuccess?.data ? (
                <Grid container sx={{ pl: 4, pr: 4, mt: 4 }}>
                  <Typography
                    sx={{ fontWeight: 900, color: "#0093fb" }}
                    onClick={(e) => handleChangeAadharVerify()}
                  >
                    Having trouble verifying your Aadhaar? Click here
                  </Typography>
                </Grid>
              ) : null}
            </>
          ) : (
            <div style={{ height: "80vh", padding: "20px" }}>
              <Typography variant="body1">
                Oops! It seems like there was an issue with the Aadhaar
                verification. Not to worry though, we've got you covered!
              </Typography>
              <img
                src={adharVerification}
                style={{ height: "20vh", width: "50vw" }}
              />
              <Typography variant="body1" sx={{ textAlign: "center" }}>
                Fill up these details as per your Aadhaar
              </Typography>
              {errorInAadharDetails ? (
                <Alert severity="error">
                  Please fill in all the required details
                </Alert>
              ) : null}
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={12}>
                  <Typography variant="body1">Basic Details</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    select
                    onChange={(e) => handleChange(e.target.value, "gender")}
                    fullWidth
                    label="Gender"
                  >
                    {gender.map((option, key) => (
                      <MenuItem
                        key={key}
                        value={option.value}
                        sx={{
                          fontFamily: "'Roboto Condensed', sans-serif",
                          "&:hover": {
                            borderLeft: "5px solid #148F5B",
                            borderRadius: 1,
                          },
                        }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date Of Birth"
                      onChange={(newValue) => handleChange(newValue, "dob")}
                      maxDate={dayjs()}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography variant="body1">Address Details</Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="House No"
                    onChange={(e) => handleChange(e.target.value, "house")}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="Street"
                    onChange={(e) => handleChange(e.target.value, "street")}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="Locality"
                    onChange={(e) => handleChange(e.target.value, "locality")}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="City"
                    onChange={(e) => handleChange(e.target.value, "city")}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    select
                    onChange={(e) => handleChange(e.target.value, "state")}
                    fullWidth
                    label="State"
                  >
                    {states.map((state, key) => (
                      <MenuItem
                        key={key}
                        value={state}
                        sx={{
                          fontFamily: "'Roboto Condensed', sans-serif",
                          "&:hover": {
                            borderLeft: "5px solid #148F5B",
                            borderRadius: 1,
                          },
                        }}
                      >
                        {state}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="Pincode"
                    onChange={(e) => handleChange(e.target.value, "pincode")}
                    variant="outlined"
                  />
                </Grid>
                <Grid container sx={{ pl: 4, pr: 4, mt: 4 }}>
                  <Typography
                    sx={{ fontWeight: 900, color: "#0093fb" }}
                    onClick={(e) => handleChangeAadharVerify()}
                  >
                    Verify Aadhaar using OTP
                  </Typography>
                </Grid>
              </Grid>
            </div>
          )}
        </DialogContent>
        {(otpFailStatusCode == "" || otpFailStatusCode == 200) &&
        !havingtroubleVerifying ? (
          <>
            <DialogActions sx={{ mt: 3, mb: 2 }}>
              <Button
                variant="outlined"
                autoFocus
                sx={{ textTransform: "capitalize" }}
                onClick={handleClose}
              >
                Close
              </Button>
            </DialogActions>
          </>
        ) : (
          <DialogActions sx={{ mt: 3, mb: 2 }}>
            <Button
              variant="outlined"
              autoFocus
              sx={{
                textTransform: "capitalize",
                fontWeight: "800",
              }}
              onClick={saveAadharDetails}
            >
              Save Details
            </Button>
          </DialogActions>
        )}
      </BootstrapDialog>
    </>
  );
}
