import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import UploadIcon from "@mui/icons-material/Upload";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import RestoreIcon from "@mui/icons-material/Restore";
import { Button, TextField, Typography, Tooltip } from "@mui/material";
import {
  getRepaymentSchedule1,
  loanUserDecision,
  ESignDialogView,
  UploadDocumentDialog,
  GenerateLoanAgreementClearData,
  ESignClearData,
  EStampClearData,
  Status,
  EnachStatusClearData,
  ESignCheckClearData,
  EnachClearData,
  GenerateLoanApplicationClearData,
  GetSanctionLetterClearData,
  GetSanctionLetterStatusClearData,
  GetEsignedLoanApplication,
  GetEsanctionLetter,
  GetELoanAgreement,
  GetLoanDetails,
} from "../../Components/BasicDetails/BasicDetailsActions/BasicDetailsActions";
import RepaymentPopUp from "../MyLoan/RepaymentPopup";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import ESignDialog from "./EsignDialog";
import UploadDocumentPopup from "./UploadDocuments";
import SessionModal from "./../../Common/SessionModel";
import { useNavigate } from "react-router-dom";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#148F5B",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
export default function CustomizedTables() {
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get("loanID");
  const [open1, setOpen1] = React.useState(false);
  const [openDialog, setopenDialog] = React.useState(false);
  const [openUploadDialog, setopenUploadDialog] = React.useState(false);
  const [loanapplicationID, setloanapplicationID] = React.useState();
  const [applicationDetails, setapplicationDetails] = React.useState();
  const [reason, setReason] = React.useState("");
  const [userDecision, setuserDecision] = React.useState("");
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen1(false);
  };
  const handleUploadClose = () => {
    setopenUploadDialog(false);
  };
  const showUploadPopup = (details) => {
    dispatch(UploadDocumentDialog(true));
    setopenUploadDialog(true);
    setapplicationDetails(details);
  };
  const navigateToPages = (details) => {
    console.log("Details", details);
    localStorage.setItem("employmentType", details.constitution_type_id);
    localStorage.setItem("loanProduct", details.loan_product_id);
    localStorage.setItem("LoanApplicationId", details.Loan_Application_id);
    if (details.loan_resume_status == "doc_upload") {
      navigate("/UploadDocument");
    } else if (details.loan_resume_status == "accept_tnc") {
      navigate("/LoanCofirmation");
    }
  };
  const handleCloseAcceptDecline = () => {
    setopenDialog(false);
  };
  const handleLogoutButton = () => {
    localStorage.removeItem("jwt_token");
    localStorage.removeItem("EmailId");
    localStorage.removeItem("branch");
    setShowAlert(true);
  };
  const handleCloseErrorModal1 = () => {
    setShowAlert(false);
    navigate("/login");
  };
  const myloanLoading = useSelector(
    (state) => state.BasicDetailsReducers.myloanLoading
  );

  const myloanResponse = useSelector(
    (state) => state.BasicDetailsReducers.myloanResponse
  );

  const getLoanDetailsResponse = useSelector(
    (state) => state.BasicDetailsReducers.getLoanDetailsResponse
  );

  //--------------------------------------------------------------
  const esiginDialog = useSelector(
    (state) => state.BasicDetailsReducers.esiginDialog
  );
  const uploadDialog = useSelector(
    (state) => state.BasicDetailsReducers.uploadDailog
  );
  //-------------------------------------------------------------
  const showSchedule = (Details) => {
    dispatch(
      getRepaymentSchedule1(
        Details.Loan_Application_id,
        Details.Sanctioned_amount,
        Details.interest_rate,
        Details.loan_tenure,
        handleLogoutButton
      )
    );
    setOpen1(true);
  };

  const acceptDecline = (confirm, id, Details) => {
    setloanapplicationID(id);
    dispatch(ESignCheckClearData());
    dispatch(EnachClearData());
    dispatch(GenerateLoanApplicationClearData());
    dispatch(GetSanctionLetterClearData());
    dispatch(Status(id, handleLogoutButton));
    dispatch(GenerateLoanAgreementClearData());
    dispatch(GetSanctionLetterStatusClearData());
    dispatch(ESignClearData());
    dispatch(EStampClearData());
    dispatch(ESignDialogView(!esiginDialog));
    dispatch(GetEsignedLoanApplication(id, handleLogoutButton));
    setapplicationDetails(Details);
    //  dispatch(GetEsanctionLetter(id));
    //  dispatch(GetELoanAgreement(id));
  };

  const review = (confirm, id) => {
    setloanapplicationID(id);
    setuserDecision(confirm);
    if (confirm == "decline") {
      setopenDialog(true);
    }
  };
  const submitReason = () => {
    if (userDecision == "decline") {
      dispatch(
        loanUserDecision(
          loanapplicationID,
          "DECLINED",
          reason,
          handleLogoutButton
        )
      );
    }
    if (userDecision == "review") {
      dispatch(
        loanUserDecision(
          loanapplicationID,
          "IN-REVIEW",
          reason,
          handleLogoutButton
        )
      );
    }
    setopenDialog(false);
    dispatch(GetLoanDetails(handleLogoutButton));
  };
  return (
    <div>
      <div>
        {/* Your component content */}
        <SessionModal isOpen={showAlert} onClose={handleCloseErrorModal1} />
      </div>
      <TableContainer component={Paper}>
        {getLoanDetailsResponse.length >= 0 &&
        getLoanDetailsResponse != undefined ? (
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ fontWeight: 800 }}>
                  Loan ID
                </StyledTableCell>
                <StyledTableCell sx={{ fontWeight: 800 }} align="center">
                  Reference Number
                </StyledTableCell>
                <StyledTableCell sx={{ fontWeight: 800 }} align="center">
                  Customer Name
                </StyledTableCell>
                <StyledTableCell sx={{ fontWeight: 800 }} align="center">
                  Loan Type
                </StyledTableCell>
                <StyledTableCell sx={{ fontWeight: 800 }} align="center">
                  Amount Requested
                </StyledTableCell>
                <StyledTableCell sx={{ fontWeight: 800 }} align="center">
                  Amount Sanctioned
                </StyledTableCell>
                <StyledTableCell sx={{ fontWeight: 800 }} align="center">
                  Interest Rate
                </StyledTableCell>
                <StyledTableCell sx={{ fontWeight: 800 }} align="center">
                  Tenure
                </StyledTableCell>
                <StyledTableCell sx={{ fontWeight: 800 }} align="center">
                  EMI
                </StyledTableCell>
                <StyledTableCell sx={{ fontWeight: 800 }} align="center">
                  Status
                </StyledTableCell>
                <StyledTableCell sx={{ fontWeight: 800 }} align="center">
                  Schedule
                </StyledTableCell>

                <StyledTableCell sx={{ fontWeight: 800 }} align="center">
                  User Decision
                </StyledTableCell>
                <StyledTableCell sx={{ fontWeight: 800 }} align="center">
                  Upload Documents
                </StyledTableCell>
                <StyledTableCell sx={{ fontWeight: 800 }} align="center">
                  Resume Journey
                </StyledTableCell>
              </TableRow>
            </TableHead>
            {getLoanDetailsResponse.length >= 0 &&
            getLoanDetailsResponse != undefined ? (
              <TableBody sx={{ p: 2 }}>
                {getLoanDetailsResponse.map((Details) => (
                  <StyledTableRow sx={{ p: 2 }}>
                    <StyledTableCell
                      sx={{ fontWeight: 800 }}
                      component="th"
                      scope="row"
                    >
                      {" "}
                      {Details.Loan_Application_id}
                    </StyledTableCell>
                    <Tooltip title={Details.loan_reference_number}>
                      <StyledTableCell
                        sx={{
                          fontWeight: 800,
                          maxWidth: "100px", // Set the maximum width as needed
                          whiteSpace: "nowrap", // Prevent text from wrapping
                          overflow: "hidden",
                          textOverflow: "ellipsis", // Display ellipsis (...) for overflowed text
                        }}
                        component="th"
                        scope="row"
                      >
                        {Details.loan_reference_number}
                      </StyledTableCell>
                    </Tooltip>

                    <StyledTableCell sx={{ fontWeight: 800 }} align="center">
                      {Details.customer_name}
                    </StyledTableCell>
                    {Object.keys(Details).length ? (
                      <>
                        <StyledTableCell
                          sx={{ fontWeight: 800 }}
                          align="center"
                        >
                          {Details.loan_product_name}
                        </StyledTableCell>
                      </>
                    ) : (
                      <>
                        <StyledTableCell
                          sx={{ fontWeight: 800 }}
                          align="center"
                        ></StyledTableCell>
                      </>
                    )}
                    <StyledTableCell sx={{ fontWeight: 800 }} align="center">
                      {Details.loan_amount?.toLocaleString("en-IN", {
                        style: "currency",
                        currency: "INR",
                      })}
                    </StyledTableCell>
                    {Details.loan_status == "APPROVED" ? (
                      <>
                        <StyledTableCell
                          sx={{ color: "#ff9966", fontWeight: 800 }}
                          align="center"
                        >
                          {Details?.Sanctioned_amount?.toLocaleString("en-IN", {
                            style: "currency",
                            currency: "INR",
                          })}
                        </StyledTableCell>
                      </>
                    ) : (
                      <>
                        <StyledTableCell
                          sx={{ color: "#ff9966", fontWeight: 800 }}
                          align="center"
                        >
                          -
                        </StyledTableCell>
                      </>
                    )}
                    {Details.loan_status == "APPROVED" ? (
                      <>
                        <StyledTableCell
                          sx={{ color: "#ff9966", fontWeight: 800 }}
                          align="center"
                        >
                          {Details.interest_rate}
                        </StyledTableCell>
                      </>
                    ) : (
                      <>
                        <StyledTableCell
                          sx={{ color: "#ff9966", fontWeight: 800 }}
                          align="center"
                        >
                          -
                        </StyledTableCell>
                      </>
                    )}
                    {Details.loan_status == "APPROVED" ? (
                      <>
                        <StyledTableCell
                          sx={{ color: "#ff9966", fontWeight: 800 }}
                          align="center"
                        >
                          {Details.loan_tenure}
                        </StyledTableCell>
                      </>
                    ) : (
                      <>
                        <StyledTableCell
                          sx={{ color: "#ff9966", fontWeight: 800 }}
                          align="center"
                        >
                          -
                        </StyledTableCell>
                      </>
                    )}
                    {Details.loan_status == "APPROVED" ? (
                      <>
                        <StyledTableCell
                          sx={{ color: "#ff9966", fontWeight: 800 }}
                          align="center"
                        >
                          {Details.monthly_emi?.toLocaleString("en-IN", {
                            style: "currency",
                            currency: "INR",
                          })}
                        </StyledTableCell>
                      </>
                    ) : (
                      <>
                        <StyledTableCell
                          sx={{ color: "#ff9966", fontWeight: 800 }}
                          align="center"
                        >
                          -
                        </StyledTableCell>
                      </>
                    )}
                    {/* {Details.loan_status == "APPROVED" ? (
                      <>
                        <StyledTableCell
                          sx={{ color: "#ff9966", fontWeight: 800 }}
                          align="center"
                        >
                          {Details.repayment_mode}
                        </StyledTableCell>
                      </>
                    ) : (
                      <>
                        <StyledTableCell
                          sx={{ color: "#ff9966", fontWeight: 800 }}
                          align="center"
                        >
                          -
                        </StyledTableCell>
                      </>
                    )} */}
                    {Details.loan_status == "DECLINE" ||
                    Details.loan_status == "Decline" ? (
                      <Tooltip title={Details.reject_desc}>
                        <StyledTableCell
                          sx={{ color: "#FF0000", fontWeight: 800 }}
                          align="center"
                        >
                          {Object.keys(Details).length
                            ? Details.loan_status.replace(/_/g, " ")
                            : ""}
                        </StyledTableCell>
                      </Tooltip>
                    ) : null}
                    {Details.loan_status == "Approved" ||
                    Details.loan_status == "APPROVED" ? (
                      <StyledTableCell
                        sx={{ color: "#339800", fontWeight: 800 }}
                        align="center"
                      >
                        {Object.keys(Details).length
                          ? Details.loan_status.replace(/_/g, " ")
                          : ""}
                      </StyledTableCell>
                    ) : (
                      <></>
                    )}
                    {Details.loan_status != "Approved" &&
                    Details.loan_status != "Decline" &&
                    Details.loan_status != "APPROVED" &&
                    Details.loan_status != null &&
                    Details.loan_status != undefined &&
                    Details.loan_status != "DECLINE" ? (
                      <Tooltip title={Details.reject_desc}>
                        <StyledTableCell
                          sx={{ color: "#ff9966", fontWeight: 800 }}
                          align="center"
                        >
                          {Object.keys(Details).length
                            ? Details.loan_status.replace(/_/g, " ")
                            : ""}
                        </StyledTableCell>
                      </Tooltip>
                    ) : (
                      <></>
                    )}
                    <>
                      {Details.loan_status == "APPROVED" &&
                      Details.loan_resume_status == "done" &&
                      Details.interest_rate != null &&
                      Details.Sanctioned_amount != null &&
                      Details.interest_rate != "" &&
                      Details.Sanctioned_amount != "" ? (
                        <>
                          <StyledTableCell
                            sx={{ color: "#ff9966", fontWeight: 800 }}
                            align="center"
                          >
                            <Button
                              variant="contained"
                              sx={{
                                textTransform: "capitalize",
                                background: "#890044",
                                "&:hover": {
                                  background: "#890044",
                                },
                              }}
                              onClick={() => showSchedule(Details)}
                            >
                              {" "}
                              Get Schedule{" "}
                            </Button>

                            {/* <RepaymentPopUp
                              open={open1}
                              onPopupFunction={handleClose}
                            /> */}
                            {/* <BootstrapDialog
                              className="repaymentCSS"
                              onClose={handleClose}
                              aria-labelledby="customized-dialog-title"
                              open={open1}
                            >
                              <BootstrapDialogTitle
                                id="customized-dialog-title"
                                onClose={handleClose}
                                style={{
                                  fontWeight: "800",
                                  color: "#148F5B",
                                }}
                              >
                                Repayment Schedule
                              </BootstrapDialogTitle>

                              <DialogContent>
                                <RepaymentPopUp />
                              </DialogContent>
                            </BootstrapDialog> */}
                          </StyledTableCell>
                        </>
                      ) : (
                        <>
                          <StyledTableCell
                            sx={{ color: "#ff9966", fontWeight: 800 }}
                            align="center"
                          >
                            -
                          </StyledTableCell>
                        </>
                      )}

                      {Details.loan_status == "APPROVED" &&
                      Details.loan_resume_status == "done" &&
                      Details.interest_rate != null &&
                      Details.Sanctioned_amount != null &&
                      Details.interest_rate != "" &&
                      Details.Sanctioned_amount != "" &&
                      (Details.user_decision == "" ||
                        Details.user_decision == null) ? (
                        <>
                          <StyledTableCell
                            sx={{
                              fontWeight: 800,
                              display: "flex",
                              flexDirection: "column", // Set flex direction to column
                              alignItems: "center", // Align items in the center
                            }}
                            align="center"
                          >
                            <Button
                              variant="contained"
                              sx={{
                                textTransform: "capitalize",
                                background: "#148F5B",
                                "&:hover": {
                                  background: "#148F5B",
                                },
                                marginBottom: 2, // Add margin to create space between buttons
                              }}
                              onClick={() =>
                                acceptDecline(
                                  "accept",
                                  Details.Loan_Application_id,
                                  Details
                                )
                              }
                            >
                              {" "}
                              Accept{" "}
                            </Button>
                            <Button
                              variant="contained"
                              sx={{
                                textTransform: "capitalize",
                                background: "#890044",
                                "&:hover": {
                                  background: "#890044",
                                },
                              }}
                              onClick={() =>
                                review("decline", Details.Loan_Application_id)
                              }
                            >
                              {" "}
                              Decline{" "}
                            </Button>
                            <BootstrapDialog
                              className="acceptDeclineCSS"
                              onClose={handleCloseAcceptDecline}
                              aria-labelledby="customized-dialog-title"
                              open={openDialog}
                            >
                              <BootstrapDialogTitle
                                id="customized-dialog-title"
                                onClose={handleCloseAcceptDecline}
                                style={{
                                  fontWeight: "800",
                                  color: "#148F5B",
                                }}
                              >
                                Reason
                              </BootstrapDialogTitle>

                              <DialogContent>
                                <TextField
                                  fullWidth
                                  sx={{
                                    mt: 2,
                                    mb: 2,
                                  }}
                                  label="Reason"
                                  value={reason}
                                  onChange={(e) => setReason(e.target.value)}
                                />
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    mt: 2,
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    sx={{
                                      textTransform: "capitalize",
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                    onClick={() => submitReason()}
                                  >
                                    {" "}
                                    Submit{" "}
                                  </Button>
                                </Grid>
                              </DialogContent>
                            </BootstrapDialog>
                          </StyledTableCell>
                        </>
                      ) : (
                        <>
                          <StyledTableCell
                            sx={{ color: "#ff9966", fontWeight: 800 }}
                            align="center"
                          >
                            {Details.user_decision}
                          </StyledTableCell>
                        </>
                      )}
                      {Details.missing_documents != "" &&
                      Details.missing_documents != null &&
                      Details.missing_documents != undefined ? (
                        <>
                          <StyledTableCell
                            sx={{
                              color: "#148F5B",
                              fontWeight: 800,
                              cursor: "pointer",
                            }}
                            align="center"
                            onClick={(e) => {
                              e.stopPropagation(); // Stop event propagation
                              showUploadPopup(Details);
                            }}
                          >
                            <UploadIcon></UploadIcon>
                            {/* <BootstrapDialog
                              onClose={handleUploadClose}
                              aria-labelledby="customized-dialog-title"
                              open={openUploadDialog}
                            >
                              <BootstrapDialogTitle
                                id="customized-dialog-title"
                                onClose={handleUploadClose}
                                style={{
                                  fontWeight: "800",
                                  color: "#148F5B",
                                }}
                              >
                                Upload Documents
                              </BootstrapDialogTitle>

                              <DialogContent>
                              
                              </DialogContent>
                            </BootstrapDialog> */}
                          </StyledTableCell>
                        </>
                      ) : (
                        <>
                          <StyledTableCell
                            sx={{ color: "#ff9966", fontWeight: 800 }}
                            align="center"
                          >
                            -
                          </StyledTableCell>
                        </>
                      )}
                      {Details.is_resumable != "" &&
                      Details.is_resumable != null &&
                      Details.is_resumable == 1 ? (
                        <>
                          <StyledTableCell
                            sx={{
                              color: "#148F5B",
                              fontWeight: 800,
                              cursor: "pointer",
                            }}
                            align="center"
                            onClick={(e) => {
                              e.stopPropagation(); // Stop event propagation
                              navigateToPages(Details);
                            }}
                          >
                            <RestoreIcon></RestoreIcon>
                          </StyledTableCell>
                        </>
                      ) : (
                        <>
                          <StyledTableCell
                            sx={{ color: "#ff9966", fontWeight: 800 }}
                            align="center"
                          >
                            -
                          </StyledTableCell>
                        </>
                      )}
                    </>
                  </StyledTableRow>
                ))}
              </TableBody>
            ) : null}
          </Table>
        ) : null}
      </TableContainer>
      {esiginDialog ? (
        <ESignDialog loanID={loanapplicationID} data={applicationDetails} />
      ) : null}
      {uploadDialog ? <UploadDocumentPopup data={applicationDetails} /> : null}
      {open1 ? <RepaymentPopUp onPopupFunction={() => handleClose()} /> : null}
      {/* {esiginDialog ? <ESignDialog loanID={"2585"} /> : null} */}
    </div>
  );
}
