import React from "react";
import "./../../Components/styles.css";
import pic1 from "./../../Assets/customLogo.png";
import pic2 from "./../../Assets/signup.svg";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import { base_url2 } from "../../constants";
import Axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import {
  Button,
  TextField,
  Typography,
  Divider,
  Paper,
  Container,
} from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { lightTheme } from "../../Common/Theme";
import { useNavigate } from "react-router-dom";
import {
  RegistrationUser,
  navigateFromDigitalLoans,
} from "../BasicDetails/BasicDetailsActions/BasicDetailsActions";
import { OTPInputField } from "react-otp-input-type";
import LinearProgress from "@mui/material/LinearProgress";
import Alert from "@mui/material/Alert";
import GenInsight from "../GenInsight/GenInsight";
import Header from "../../Common/Header/Header1";
import Snackbar from "@material-ui/core/Snackbar";
import { useParams } from "react-router-dom";
import Footer from "../../Common/Footer/footer";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import ReplayIcon from "@mui/icons-material/Replay";
import "./register.css";
import ImageTextField from "./ImageTextField";
import { Helmet } from "react-helmet";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
const StepsIndicator = ({ steps }) => {
  return (
    <Paper
      elevation={3}
      style={{
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        border: "none",
        boxShadow: "none",
      }}
    >
      {steps.map((step, index) => (
        <Box
          key={index}
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <span
              style={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                backgroundColor: "#AF0C5D",
                marginBottom: "8px",
              }}
            />
            <Typography
              variant="h6"
              style={{ marginLeft: "8px", fontSize: "1rem" }}
            >
              <b> {step.title}</b>
            </Typography>
          </div>
          {index !== steps.length && (
            <div style={{ display: "flex" }}>
              <span
                style={{
                  width: "2px",
                  height: "40px",
                  backgroundColor: "#AF0C5D",
                  marginBottom: "8px",
                  marginLeft: "3px",
                }}
              />
              <Typography
                style={{
                  marginLeft: "8px",
                  fontSize: "14px",
                  textAlign: "left",
                }}
              >
                {step.description}
              </Typography>
            </div>
          )}
        </Box>
      ))}
    </Paper>
  );
};

export default function Registration() {
  const [open, setOpen] = useState(false);
  const [loginCompleted, setloginCompleted] = React.useState(true);
  const [emailError, setEmailError] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);
  const [duplicateRecord, setduplicateRecord] = React.useState(false);
  const [UsernameValid, setUsernameValid] = React.useState(false);
  const [OTPerror, setOTPerror] = React.useState(false);
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [phoneNoError, setPhoneNoError] = useState(false);
  const GenericMobileNumberRegex = /^(\+\d{1,3}[- ]?)?\d{10,13}$/;
  const [zipCode, setzipCode] = useState(false);
  const [captcha, setCaptcha] = useState(null);
  const [isCaptchaError, setCaptchaError] = useState(false);
  const [signupErrorMessage, setSignupErrorMessage] = useState("");
  const zipCodeRegex = /^\d{6}(?:[-\s]\d{4})?$/;
  const steps = [
    {
      title: "Trust",
      description: "Custom-tailored product suites for SMEs and MSMEs",
    },
    { title: "Speed", description: "Decision in minutes" },
    {
      title: "Convenience",
      description: "No documentation needed & repay at your own pace",
    },
  ];

  const initiateSession = () => {
    Axios.get(`${base_url2}/digitalLoans/initiateSession`)
      .then((result) => {
        if (result.status === 200) {
          setCaptcha(result.data?.data?.captcha);
          setregistration({
            ...registration,
            ["session_id"]: result.data?.data?.session_id,
          });
          setCaptchaError(false);
        }
      })
      .catch((error) => {
        setCaptchaError(true);
      });
  };
  useEffect(() => {
    localStorage.removeItem("jwt_token");
    localStorage.removeItem("EmailId");
    localStorage.removeItem("branch");
    initiateSession();
  }, []);
  const [registration, setregistration] = useState({
    username: "",
    email: "",
    mobileNo: "",
    zipcode: "",
  });
  const handleClose = () => {
    setOpen1(false);
    setduplicateRecord(false);
    setloginCompleted(true);
    setregistration({
      ...registration,
      username: "",
      email: "",
      mobileNo: "",
      zipcode: "",
    });
  };
  const navigateToLogin = (e) => {
    navigate("/login");
    dispatch(navigateFromDigitalLoans());
  };
  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleInputChangeRegistration = (e) => {
    if (e.target.name == "email") {
      const valueWithoutSpaces = e.target.value.replace(/\s/g, "");
      setregistration({ ...registration, [e.target.name]: valueWithoutSpaces });
    } else {
      setregistration({ ...registration, [e.target.name]: e.target.value });
    }
    if (e.target.name == "email") {
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (regex.test(e.target.value)) {
        setEmailError(false);
      } else {
        setEmailError(true);
      }
    }
    if (e.target.name == "mobileNo") {
      setregistration({ ...registration, [e.target.name]: e.target.value });
      // Check if the input matches the generic regex pattern
      const isValid = GenericMobileNumberRegex.test(e.target.value);

      // Update the error state based on the validation result
      setPhoneNoError(!isValid);
    }
    if (e.target.name == "zipcode") {
      setregistration({ ...registration, [e.target.name]: e.target.value });
      // Check if the input matches the generic regex pattern
      const isValid = zipCodeRegex.test(e.target.value);

      // Update the error state based on the validation result
      setzipCode(!isValid);
    }
    if (e.target.name == "user_captcha") {
      setregistration({ ...registration, [e.target.name]: e.target.value });
    }
  };
  const [open1, setOpen1] = React.useState(false);
  const handleLoginSignup = async (e) => {
    setduplicateRecord(false);
    setloginCompleted(false);
    setUsernameValid(false);

    if (registration.email === "") {
      setEmailError(true);
    }
    if (registration.username === "") {
      setUsernameValid(true);
    }
    if (registration.email === "" || registration.username === "") {
      setloginCompleted(true);
      return;
    }

    // ... (other validation and setup code)

    if (emailError || phoneNoError || zipCode) {
      setloginCompleted(true);
    } else {
      setOTPerror(false);

      try {
        const response = await RegistrationUser(registration, "digital_advert");

        if (response.status === 200) {
          setOpen1(true);
          setloginCompleted(true);
          // localStorage.setItem("EmailId", registration.email);

          setOpen(true);
          setOpen1(false);
          console.log(response);
          localStorage.setItem("EmailId", response.data.response_data.email_id);
          localStorage.setItem(
            "branch",
            response.data.response_data.branch_name
          );
          localStorage.setItem(
            "jwt_token",
            response.data.response_data.jwt_token
          );
          setOTPerror(false);

          //setislogin(true);
          setloginCompleted(true);
          setTimeout(() => {
            // Navigate after 3 seconds
            navigate("/BasicDetails");
          }, 3000);
        } else {
          const errorMessage = response.data.response_message;
          setSignupErrorMessage(errorMessage);
          setduplicateRecord(true);
          setloginCompleted(true);
        }
      } catch (error) {
        console.error("Error registering user:", error);
      }
    }
  };

  const handleOTPChange = (val) => {
    setOtp(val);
  };
  const verifyOTP = () => {
    setOTPerror(false);
    setloginCompleted(false);

    Axios.post(`${base_url2}/digitalLoans/verifyOtpLogin`, {
      email_id: registration.email,
      email_verification_code: otp,
    })
      .then((result) => {
        // Check for the specific error response
        if (
          result.data.response_data === null &&
          result.data.response_message === "Invalid OTP"
        ) {
          setOTPerror(true);
          setloginCompleted(true);
        }
        if (
          result.data.response_message == "success" &&
          result.data.response_data !== null
        ) {
          setOpen(true);
          setOpen1(false);
          localStorage.setItem("EmailId", result.data.response_data.email_id);
          localStorage.setItem("branch", result.data.response_data.branch);
          localStorage.setItem(
            "jwt_token",
            result.data.response_data.jwt_token
          );
          setOTPerror(false);

          //setislogin(true);
          setloginCompleted(true);
          setTimeout(() => {
            // Navigate after 3 seconds
            navigate("/BasicDetails");
          }, 3000);
        }
      })
      .catch((error) => {
        // Handle any other errors here
        console.error("Error verifying OTP:", error);
        setOTPerror(true);
        setloginCompleted(true);
      });
  };

  return (
    <>
      <Helmet>
        <script>
          {`(function(h,o,t,j,a,r){         h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};         h._hjSettings={hjid:5004592,hjsv:6};         a=o.getElementsByTagName('head')[0];         r=o.createElement('script');r.async=1;         r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;         a.appendChild(r);     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
        </script>
      </Helmet>
      <ThemeProvider theme={lightTheme}>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleCloseSnackBar}
        >
          <Alert onClose={handleCloseSnackBar} severity="success">
            Username and Password sent on email!
          </Alert>
        </Snackbar>
        {!loginCompleted ? (
          <Grid item xs={12} sx={{ m: 1 }}>
            <LinearProgress
              sx={{
                color: "#148F5B",
              }}
              size={40}
              thickness={4}
            />
          </Grid>
        ) : null}
        <div>
          <Header />
        </div>
        {/* <GenInsight /> */}
        <Grid container spacing={4}>
          <Grid item xs={12} sm={1} md={1}></Grid>
          <Grid item xs={12} sm={7} md={7}>
            <Typography className="body-text">
              <b>
                Located in the historic city of Cochin, our team includes
                experienced professionals from banking, development banking, and
                top regulatory organizations.
              </b>
            </Typography>
            <Container>
              <Box
                display="inline-flex"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
              >
                <FormatQuoteIcon
                  style={{
                    fontSize: 100,
                    color: "#AF0C5D",
                    transform: "rotate(180deg)",
                    opacity: 0.5,
                    marginBottom: "5%",
                  }}
                  className="format-quote-icon"
                />
                <Typography className="body-subtext">
                  <b>
                    Our mission is to provide our customers with easy access to
                    finance, partner in their progress...
                  </b>
                </Typography>
              </Box>
            </Container>
            <Grid container spacing={2} className="lower-body-container">
              <Grid container spacing={2} sx={{ textAlign: "center" }}>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography className="lower-body-title">
                    <b>
                      {" "}
                      Loans &{" "}
                      <span
                        style={{ color: "#148F5B", whiteSpace: "pre-wrap" }}
                      >
                        Financing
                      </span>{" "}
                    </b>
                  </Typography>
                </Grid>
              </Grid>
              <Grid item className="lower-body-column-1">
                <Typography className="lower-body-column-1-row-1">
                  <b>Unsecured Business Loans</b>
                </Typography>
                <Typography className="lower-body-column-1-row-2">
                  <b>
                    {" "}
                    Starting from{" "}
                    <span style={{ color: "#AF0C5D", fontSize: "1.5em" }}>
                      18%
                    </span>{" "}
                    p.a.*
                  </b>
                </Typography>
                <Typography className="lower-body-column-1-row-3">
                  <b>*T & C Apply</b>
                </Typography>
              </Grid>
              <Grid item className="lower-body-column-2" width={"45%"}>
                <StepsIndicator steps={steps} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={3} md={3} sx={{ mt: 4, mb: 8 }}>
            <Box
              maxWidth
              align="center"
              style={{
                maxheight: "70vh",
                overflow: "auto",
                height: "auto",
                display: "flex",
                width: "23rem",
                background: "#F0EDE4",
                borderRadius: "10px",
              }}
            >
              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    style={{ marginTop: "3%" }}
                  >
                    <Box>
                      <Typography className="decisionText">
                        {" "}
                        <span
                          style={{
                            color: "#AF0C5D",
                          }}
                        >
                          <b>Decision in 5 minutes</b>
                        </span>{" "}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        className="getStarted"
                        style={{
                          color: "#AF0C5D",
                        }}
                      >
                        Get Started {">"} Get Approved* {">"} Get Cash
                      </Typography>
                    </Box>
                    {duplicateRecord || isCaptchaError ? (
                      <Alert severity="error">{signupErrorMessage}</Alert>
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        Maxheight: "50vh",
                        overflow: "auto",
                        height: "46vh",
                      }}
                    >
                      <Box sx={{ ml: 4, mr: 4, mt: 4 }}>
                        <TextField
                          fullWidth
                          error={UsernameValid}
                          label="Username *"
                          variant="standard"
                          name="username"
                          value={registration.username}
                          onChange={(e) => handleInputChangeRegistration(e)}
                          helperText={
                            UsernameValid ? "Username cannot be Empty" : null
                          }
                        />
                      </Box>
                      <Box sx={{ ml: 4, mr: 4, mt: 2 }}>
                        <TextField
                          fullWidth
                          label="Email *"
                          error={emailError}
                          variant="standard"
                          value={registration.email}
                          name="email"
                          onChange={(e) => handleInputChangeRegistration(e)}
                          helperText={emailError ? "Email is Invalid." : null}
                        />
                      </Box>
                      <Box sx={{ ml: 4, mr: 4, mt: 2 }}>
                        <TextField
                          fullWidth
                          type="text"
                          label="Mobile No. (with Country Code)"
                          variant="standard"
                          name="mobileNo"
                          value={registration.mobileNo}
                          onChange={(e) => handleInputChangeRegistration(e)}
                          error={phoneNoError}
                          helperText={
                            phoneNoError ? "Invalid mobile number" : ""
                          }
                        />
                      </Box>
                      <Box sx={{ ml: 4, mr: 4, mt: 2 }}>
                        <TextField
                          fullWidth
                          label="Zipcode"
                          variant="standard"
                          name="zipcode"
                          value={registration.zipcode}
                          onChange={(e) => handleInputChangeRegistration(e)}
                          error={zipCode}
                          helperText={zipCode ? "Invalid Zipcode" : ""}
                        />
                      </Box>
                    </div>
                    <>
                      <Box style={{ marginBottom: "0.5rem" }}>
                        <img
                          src={`data:image/png;base64,${captcha}`}
                          alt="Image"
                          style={{ height: "auto", width: "50%" }}
                        />
                        <Button onClick={initiateSession}>
                          <ReplayIcon />
                        </Button>
                      </Box>
                      <Box>
                        <br />
                        <TextField
                          label="Enter Captcha above"
                          type="text"
                          name="user_captcha"
                          style={{
                            height: "auto",
                            width: "50%",
                            marginBottom: "1rem",
                          }}
                          onChange={(e) => handleInputChangeRegistration(e)}
                        />
                      </Box>
                    </>
                    <Box textAlign="center">
                      <Button
                        size="large"
                        variant="contained"
                        style={{
                          background: "#148F5B",
                          color: "#ffffff",
                          borderRadius: "5px",
                          textTransform: "none",
                          fontWeight: "600",
                          marginBottom: "4%",
                        }}
                        onClick={(e) => handleLoginSignup(e)}
                      >
                        Apply for a loan
                      </Button>

                      <BootstrapDialog
                        className="optCSS"
                        onClose={handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={open1}
                      >
                        {/* <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleClose}
                        style={{ fontWeight: "800", color: "#148F5B" }}
                      >
                        Register
                      </BootstrapDialogTitle> */}

                        {/* <DialogContent>
                        {OTPerror ? (
                          <Box
                            className="errorMessage"
                            style={{ margin: "5%" }}
                          >
                            Invalid OTP
                          </Box>
                        ) : (
                          ""
                        )}
                        <Grid item xs={12} sm={12} md={12} mt={2}>
                          <Typography
                            sx={{ m: 4, flex: 1 }}
                            component="div"
                            style={{
                              maxWidth: "100% !important",
                              textAlign: "center",
                            }}
                          >
                            We have sent you <b>One Time Password</b> to your
                            email.
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} mt={2}>
                          <Typography
                            sx={{ m: 4, flex: 1 }}
                            component="div"
                            style={{
                              maxWidth: "100% !important",
                              textAlign: "center",
                              color: "#148F5B",
                            }}
                          >
                            Please Enter OTP
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} mt={2}>
                          <OTPInputField
                            className="otp"
                            numOfInputs={6}
                            handleChange={handleOTPChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} mt={2}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            mt={2}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            className="buttonText"
                          >
                            <Button
                              variant="contained"
                              sx={{
                                textTransform: "capitalize",
                                fontWeight: "800",
                                m: 2,
                              }}
                              className="buttonText1"
                              onClick={verifyOTP}
                            >
                              Verify OTP
                            </Button>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} mt={2}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            mt={4}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              variant="outlined"
                              sx={{
                                textTransform: "capitalize",
                                color: "#148F5B",
                                fontWeight: "800",
                              }}
                              onClick={handleClose}
                            >
                              Login with another account
                            </Button>
                          </Grid>
                        </Grid>
                      </DialogContent> */}
                      </BootstrapDialog>
                    </Box>
                    <Box textAlign="center" marginBottom="4%">
                      <Typography variant="body1" style={{ fontWeight: "600" }}>
                        OR
                      </Typography>
                    </Box>
                    <Box textAlign="center">
                      <Button
                        size="large"
                        variant="outlined"
                        style={{
                          borderRadius: "5px",
                          textTransform: "none",
                          fontWeight: "600",
                          marginBottom: "4%",
                        }}
                        onClick={(e) => navigateToLogin(e)}
                      >
                        Check loan status
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Footer />
      </ThemeProvider>
    </>
  );
}
