import React, { useState, useEffect } from "react";
import { Button, Grid, Typography, Box, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { OTPInputField } from "react-otp-input-type";
import Alert from "@mui/material/Alert";
import "./../Components/styles.css";
import {
  VerifyOTPMobile,
  sendverificationCode,
} from "./../Components/BasicDetails/BasicDetailsActions/BasicDetailsActions";
import { useSelector, useDispatch } from "react-redux";

const MobileOTPVerification = ({ open, handleClose, data }) => {
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const [otpError, setOtpError] = useState(false);
  const [resendOTPSuccess, setresendOTPSuccess] = useState(false);
  const verifyOTPMobileSuccess = useSelector(
    (state) => state.BasicDetailsReducers.verifyOTPMobileSuccess
  );
  const verifyOTPMobileFail = useSelector(
    (state) => state.BasicDetailsReducers.verifyOTPMobileFail
  );
  const handleOTPChange = (otpValue) => {
    setOtp(otpValue);
  };
  useEffect(() => {
    if (verifyOTPMobileFail) {
      setOtpError(true);
    }
    if (verifyOTPMobileSuccess?.response_message == "SUCCESS") {
      console.log("verifyOPTmobile is", verifyOTPMobileSuccess);
      handleClose1();
    }
  }, [verifyOTPMobileSuccess, verifyOTPMobileFail]);
  const resendOTP = async () => {
    setOtpError(false);
    setOtp("");
    setresendOTPSuccess(false);
    try {
      const result = await sendverificationCode(data);
      if (result.data.response_message == "SUCCESS") {
        setresendOTPSuccess(true);
      }
    } catch (e) {
      console.log(e);
      setresendOTPSuccess(false);
    }
  };
  const handleClose1 = () => {
    setOtpError(false);
    setOtp("");
    setresendOTPSuccess(false);
    handleClose();
  };
  const verifyOTP = async () => {
    setresendOTPSuccess(false);
    setOtpError(false);
    if (otp.length !== 6) {
      setOtpError(true);
      return;
    }
    try {
      dispatch(VerifyOTPMobile(data, otp));
    } catch (e) {
      // Handle other errors, such as network issues
      console.error("Error during OTP verification:", e);
      setOtpError(true);
    }
    // Implement logic to verify OTP
    //handleVerify(otp);
  };

  return (
    <Dialog
      className="optCSS"
      onClose={handleClose1}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={handleClose1}
        sx={{ fontWeight: "800", color: "#148F5B", m: 0, p: 2 }}
      >
        Verify Otp
        <IconButton
          aria-label="close"
          onClick={handleClose1}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {otpError && <Alert severity="error">Invalid or Expired OTP</Alert>}
        {resendOTPSuccess && (
          <Alert severity="success">OTP has sent to mobile no.</Alert>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} mt={2}>
            <Typography
              component="div"
              style={{
                textAlign: "center",
                color: "#000000",
              }}
            >
              We have sent you <b>One Time Password</b> to your mobile number.
            </Typography>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Typography
              component="div"
              style={{
                textAlign: "center",
                color: "#000000",
              }}
            >
              Please Enter OTP
            </Typography>
          </Grid>
          <Grid item xs={12} mt={2}>
            <OTPInputField
              className="otp"
              numOfInputs={6}
              handleChange={handleOTPChange}
            />
          </Grid>
          <Grid item xs={12} mt={2}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Button
                  variant="outlined"
                  style={{
                    textTransform: "capitalize",
                    color: "#148F5B",
                    fontWeight: "800",
                  }}
                  onClick={(e) => resendOTP(e)}
                >
                  Resend OTP
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  style={{
                    textTransform: "capitalize",
                    fontWeight: "800",
                    backgroundColor: "#148F5B",
                    color: "#fff",
                  }}
                  onClick={verifyOTP}
                >
                  Verify OTP
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={4}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Button
                  variant="outlined"
                  style={{
                    textTransform: "capitalize",
                    color: "#148F5B",
                    fontWeight: "800",
                  }}
                  onClick={handleClose1}
                >
                  Verify with another Phone No.
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default MobileOTPVerification;
