import { createTheme } from "@mui/material/styles";

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#148F5B",
    },
    secondary: {
      main: "#F55353",
    },
    paper: {
      background: "#fff",
    },
    typography: {
      fontFamily: "'Roboto Condensed', sans-serif",
      color: "#44475B",
    },
    card: {
      background: "#fff",
      boxShadow: "0 1px 7px 0 rgba(0,0,0,0.1)",
    },
    button: {
      background: "#F55353",
      color: "#ffffff",
    },
    container: {
      background: "#eeefef",
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#148F5B",
    },
    secondary: {
      main: "#F55353",
    },
    paper: {
      background: "#121212",
    },
    typography: {
      fontFamily: "'Roboto Condensed', sans-serif",
      color: "#DCDCDD",
    },
    card: {
      background: "#1D1D1D",
      boxShadow: "0 1px 7px 0 rgba(0,0,0,0.6)",
    },
    button: {
      background: "#F55353",
      color: "#121212",
    },
    container: {
      background: "#eeefef",
    },
  },
});
