import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import NavBar from "../../NavBar/Quickapplyheader";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import GenInsight from "../../GenInsight/GenInsight";
import Checkbox from "@mui/material/Checkbox";
import { Button, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import DialogTitle from "@mui/material/DialogTitle";
import AadharVerification from "../../BasicDetails/BasicDetailsPages/AadharVerification";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import EmailOTPVerificationCoborrower from "../../../Common/emailOTPValidationCoborrower";
import Footer from "./../../../Common/Footer";
import {
  getQuickapplydetails,
  AadharDrawer,
  sendverificationCodeEmailCoborrower,
  verifyPan,
  QuickApply,
  dummyQuickApply,
  GetLoanProductType,
  getTenure,
  getCustomerDetails,
  sendverificationCode,
  verifyEmailMobile,
  SAF1,
} from "../../BasicDetails/BasicDetailsActions/BasicDetailsActions";
import "../../LoginSignUp/SplashScreen.css";
import Typography from "@mui/material/Typography";
import { IconButton } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import CustomLoader from "../../../Common/CustomLoader/customLoader";
import { OTPInputField } from "react-otp-input-type";
import Alert from "@mui/material/Alert";
import ErrorModel from "../../../Common/ErrorMessages";
import QuickApplyDialog from "./quickApplyDialog";
const QuickApply1 = () => {
  const [showSplash, setShowSplash] = useState(true);
  const [open, setopen] = useState(true);
  const [aadharNo, setAadharNo] = useState("");
  const [isaadharChecked, setisaadharChecked] = useState(false);
  const [selectedAadhar, setselectedAadhar] = React.useState();
  const [isApicalled, setisApicalled] = useState(false);
  const [isApiLoading, setisApiLoading] = React.useState(false);
  const [emailAlreadyVerified, setemailAlreadyVerified] = React.useState(false);
  const [pan, setPan] = React.useState("");
  const [openOTPEmailDialog, setOpenOTPEmailDialog] = useState(false);
  const [emailId, setEmailId] = useState("");
  const [mobileNo, setmobileNo] = useState("");
  const [isemailChecked, setisemailChecked] = useState(false);
  const [loanamt, setLoanamt] = useState();
  const [error, setError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [aadharError, setAadharError] = useState("");
  const [submitError, setSubmitError] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [mobileError, setmobileError] = useState("");
  const [maxLoan, setMaxLoan] = useState(123);
  const [otp, setOtp] = useState("");
  const [isLoanSubmitted, setIsLoanSubmitted] = useState(false);
  const [resendOTPSuccess, setresendOTPSuccess] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [otpInitiated, setotpInitiated] = useState(false);
  const [isEmailVerification, setisEmailVerification] = useState(true);
  const [loanProductList, setLoanProductList] = React.useState([
    {
      label: "Micro Loans Unsecure",
      value: 11,
    },
  ]);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(true);
  const aadharRegex = /^\d{12}$/;
  const emailRegex = /^\S+@\S+\.\S+$/;
  const MobileRegex = /^\d{10}$/;
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const employmentType = [
    {
      label: "Salaried",
      value: 1,
    },
    {
      label: "Self Employed Professional",
      value: 2,
    },
  ];
  const details = useSelector(
    (state) => state.BasicDetailsReducers.QuickApplyDetailsSuccess
  );
  const safResponse = useSelector(
    (state) => state.BasicDetailsReducers.safResponse
  );
  const safSuccess = useSelector(
    (state) => state.BasicDetailsReducers.safSuccess
  );
  const saf_message = safResponse ? safResponse.message : "";
  const detailsFail = useSelector(
    (state) => state.BasicDetailsReducers.QuickApplyDetailsFail
  );
  const detailsload = useSelector(
    (state) => state.BasicDetailsReducers.QuickApplyDetailsLoading
  );
  const verifyOTPSuccess = useSelector(
    (state) => state.BasicDetailsReducers.verifyOTPSuccess
  );
  const aadharDrawer = useSelector(
    (state) => state.BasicDetailsReducers.aadharDrawer
  );
  const quickapplysuccess = useSelector(
    (state) => state.BasicDetailsReducers.QuickApplySuccess
  );
  const quickapplyloading = useSelector(
    (state) => state.BasicDetailsReducers.QuickApplyLoading
  );
  const quickapplyfail = useSelector(
    (state) => state.BasicDetailsReducers.QuickApplyFail
  );
  const verifyOTPEmailCoborrowerSuccess = useSelector(
    (state) => state.BasicDetailsReducers.verifyOTPEmailCoborrowerSuccess
  );
  const verifyPanSuccess = useSelector(
    (state) => state.BasicDetailsReducers.verifyPanSuccess
  );
  const pandetailssuccess = useSelector(
    (state) => state.BasicDetailsReducers.panDetails
  );
  const initiateSessionAadharSuccess = useSelector(
    (state) => state.BasicDetailsReducers.initiateSessionAadharSuccess
  );
  const safLoading = useSelector(
    (state) => state.BasicDetailsReducers.safLoading
  );
  const tenure = useSelector((state) => state.BasicDetailsReducers.tenure);
  const handleCloseEmailOTPDialog = () => {
    if (
      !verifyOTPEmailCoborrowerSuccess?.response_message?.startsWith("SUCCESS")
    ) {
      setisemailChecked(false);
    }
    setOpenOTPEmailDialog(false);
  };

  const handleAadharClick = (aadhaar) => {
    setisaadharChecked(!isaadharChecked);
    if (isaadharChecked == false) {
      localStorage.removeItem("LoanApplicationId");
      setselectedAadhar(aadhaar);
      dispatch(AadharDrawer(!aadharDrawer));
    }
  };
  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
    navigate("/MyLoan");
  };
  // const handleOpenEmailOTPDialog = async () => {
  //   setisemailChecked(!isemailChecked);
  //   if (!isemailChecked) {
  //     try {
  //       setisApiLoading(true);
  //       const result = await sendverificationCodeEmailCoborrower(emailId);
  //       if (result?.data?.response_message?.startsWith("SUCCESS")) {
  //         setemailAlreadyVerified(true);
  //         setisemailChecked(true);
  //         setisApiLoading(false);
  //       } else {
  //         setOpenOTPEmailDialog(true);
  //         setisApiLoading(false);
  //       }
  //       if (result.data.response_message == "FAILED") {
  //         setisApiLoading(false);
  //       }
  //     } catch (e) {
  //       console.log(e);
  //       setisApiLoading(false);
  //     }
  //   }
  // };

  const handleSubmitClick = async () => {
    try {
      dispatch(
        SAF1(
          employmentTypeValue,
          pan,
          aadharNo,
          11,
          loanamt,
          loanTenure,
          emailId,
          mobileNo,
          "",
          true,
          true,
          true,
          "",
          "",
          verifyOTPSuccess,
          false,
          "false",
          pandetailssuccess,
          "",
          handleLogoutButton
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleLogoutButton = () => {
    localStorage.removeItem("jwt_token");
    localStorage.removeItem("EmailId");
    localStorage.removeItem("branch");
    setShowAlert(true);
  };

  const changePANchecked = () => {
    if (!verifyPanSuccess) {
      dispatch(verifyPan(pan, handleLogoutButton));
    }
    //console.log(pandetailssuccess);
  };

  useEffect(() => {
    // After 5 seconds, hide the splash screen and navigate to another page
    const timer = setTimeout(() => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const token = urlParams.get("applyToken");
      if (!isApicalled) {
        const response1 = dispatch(getCustomerDetails(token)); //API call
        setisApicalled(true);
      }
    }, 5000);
    if (detailsFail) {
      setShowSplash(false);
    }
    if (details?.response_data) {
      setShowSplash(false);
      setPan(details.response_data?.pan);
      localStorage.setItem("jwt_token", details?.response_data?.jwt_token);
      setEmailId(details.response_data?.email_id);
      setmobileNo(details.response_data?.mobile_number);
      // setLoanamt(details.response_data?.customer_details.max_loan_amount);
    }
    // Cleanup the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [detailsload]);

  useEffect(() => {
    if (isLoanSubmitted) {
      setShowSplash(false);
      navigate("/MyLoan");
    }
  }, [isLoanSubmitted]);
  useEffect(() => {
    if (
      safSuccess &&
      !saf_message.startsWith("MRAC failed") &&
      !saf_message.startsWith("Dedupe Check Failed")
    ) {
      navigate("/UploadDocument");
    }
  }, [safSuccess]);
  const handleAadhar = (e) => {
    const value = e.target.value;
    setAadharNo(e.target.value);
    if (!aadharRegex.test(value)) {
      setAadharError("Invalid Aadhar number (12 digits required)");
    } else {
      setAadharError("");
    }
  };

  // const handleEmail = (e) => {
  //   const value = e.target.value;
  //   setEmailId(e.target.value);
  //   if (!emailRegex.test(value)) {
  //     setEmailError("Invalid Email ID");
  //   } else {
  //     setEmailError("");
  //   }
  // };
  // const handleMobile = (e) => {
  //   const value = e.target.value;
  //   setmobileNo(e.target.value);
  //   if (!MobileRegex.test(value)) {
  //     setmobileError("Invalid Mobile Number");
  //   } else {
  //     setmobileError("");
  //   }
  // };
  const handleLoanamount = (e) => {
    setLoanamt(e.target.value);
    // const inputLoanamt = e.target.value;
    // if (
    //   inputLoanamt > details.response_data?.customer_details.max_loan_amount
    // ) {
    //   setError(true); // Set error state if value exceeds maximum amount
    // } else {
    //   setError(false); // Clear error state if value is within limit
    // }
  };
  const handleClose1 = () => {
    setopen(false);
  };
  const [employmentTypeValue, setEmploymentTypeValue] = React.useState("");
  const handleChangeemploymentType = async (e) => {
    setEmploymentTypeValue(e);
  };
  const [loanProductOption, setLoanProductOption] = React.useState();
  const handleLoanProductOption = (e) => {
    setLoanProductOption(e);
  };
  useEffect(() => {
    dispatch(getTenure(11, handleLogoutButton));
  }, [pan]);
  const [loanTenure, setLoanTenure] = React.useState("");
  //User Loan Tenure
  const HandleLoanTenureChange = (e) => {
    setLoanTenure(e);
  };
  const [iscreditbureauChecked, setiscreditbureauChecked] =
    React.useState(false);
  const changeCreditchecked = () => {
    setiscreditbureauChecked(!iscreditbureauChecked);
  };
  const handleOTPChange = (otpValue) => {
    setOtp(otpValue);
  };
  // const verifyOTP = async () => {
  //   setresendOTPSuccess(false);
  //   setOtpError(false);
  //   if (otp.length !== 6) {
  //     setOtpError(true);
  //     return;
  //   }
  //   try {
  //     const result = await verifyEmailMobile(
  //       isEmailVerification,
  //       emailId,
  //       mobileNo,
  //       otp,
  //       details.response_data?.fullname
  //     );
  //     if (result?.response_data) {
  //       handleClose1();
  //       localStorage.setItem("jwt_token", result?.response_data?.jwt_token);
  //       localStorage.setItem("EmailId", result?.response_data?.email_id);
  //     }
  //   } catch (e) {
  //     // Handle other errors, such as network issues
  //     console.error("Error during OTP verification:", e);
  //     setOtpError(true);
  //   }
  //   // Implement logic to verify OTP
  //   //handleVerify(otp);
  // };
  // const sendOTP = async (e) => {
  //   setotpInitiated(true);
  //   if (e == "Email" && emailError == "") {
  //     try {
  //       setisApiLoading(true);
  //       const result = await sendverificationCodeEmailCoborrower(emailId);
  //       if (result.data?.response_message == "OTP sent on registered email") {
  //         setisEmailVerification(true);
  //         setisApiLoading(false);
  //       } else {
  //         setisApiLoading(false);
  //       }
  //       if (result.data.response_message == "FAILED") {
  //         setisApiLoading(false);
  //       }
  //     } catch (e) {
  //       console.log(e);
  //       setisApiLoading(false);
  //     }
  //   }
  //   if (e == "Mobile" && mobileError == "") {
  //     try {
  //       const result = await sendverificationCode(mobileNo);
  //       if (result.data.response_message == "SUCCESS") {
  //         setisEmailVerification(false);
  //         setisApiLoading(false);
  //       } else {
  //         setisApiLoading(false);
  //       }
  //       if (result.data.response_message == "FAILED") {
  //         setisApiLoading(false);
  //       }
  //     } catch (e) {
  //       console.log(e);
  //       setisApiLoading(false);
  //     }
  //   }
  //   setOtpError(false);
  //   setOtp("");
  //   setresendOTPSuccess(false);
  //   try {
  //     //const result = await sendverificationCodeEmail();
  //     //  if (result.data?.response_message == "OTP sent on registered email") {
  //     setresendOTPSuccess(true);
  //     //}
  //   } catch (e) {
  //     console.log(e);
  //     setresendOTPSuccess(false);
  //   }
  // };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {saf_message && saf_message.startsWith("MRAC failed") ? (
        <div style={{ height: "50%", width: "80%" }}>
          <ErrorModel
            isOpen={isErrorModalOpen}
            onClose={handleCloseErrorModal}
            title="Loan Application Declined"
            message="Sorry you didn't qualify our minimum risk assessment"
          />
        </div>
      ) : null}
      {saf_message && saf_message.startsWith("Dedupe Check Failed") ? (
        <div style={{ height: "50%", width: "80%" }}>
          <ErrorModel
            isOpen={isErrorModalOpen}
            onClose={handleCloseErrorModal}
            title="Loan Application Declined"
            message="Duplicate Loan Application found. Please try again after 30 days"
          />
        </div>
      ) : null}
      {showSplash ? (
        <CustomLoader />
      ) : submitError || quickapplyfail ? (
        <Dialog open={true}>
          <DialogTitle
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ErrorOutlineIcon
              sx={{ color: "#f44336", marginRight: "0.5rem" }}
            />
            INVALID APPLICATION
          </DialogTitle>
        </Dialog>
      ) : detailsFail ? (
        <Dialog open={true}>
          <DialogTitle
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ErrorOutlineIcon
              sx={{ color: "#f44336", marginRight: "0.5rem" }}
            />
            INVALID APPLICATION
          </DialogTitle>
        </Dialog>
      ) : (
        <>
          {/* <Dialog
            aria-labelledby="customized-dialog-title"
            open={open}
            PaperProps={{
              sx: {
                position: "fixed",
                bottom: 0,
                left: 0,
                width: "100vw",
                maxWidth: "100vw",
                minWidth: "100vw",
                height: "65vh",
                borderRadius: "10px 10px 0 0",
                padding: "0% !important",
                margin: "0% !important",
              },
            }}
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={handleClose1}
              sx={{ fontWeight: "800", color: "#000000", mt: 2, p: 2 }}
            >
              <Typography
                sx={{
                  ml: 1,
                  mt: 0.3,
                  fontWeight: 800,
                  fontSize: 20,
                  color: "#148F5B",
                }}
              >
                Hi {details.response_data?.fullname}
              </Typography>
              <Typography
                sx={{ ml: 1, mt: 0.3, fontWeight: 600, fontSize: 16 }}
              >
                Please complete the verification process for your email address
                or mobile number.
              </Typography>
            </DialogTitle>

            <DialogContent>
              <Grid container spacing={4} sx={{ mt: 2 }}>
                <Grid item xs={12} md={1}></Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    fullWidth
                    name="emailid"
                    id="email"
                    value={emailId}
                    onChange={(e) => handleEmail(e)}
                    error={emailError !== ""}
                    helperText={emailError}
                    label="Email"
                  ></TextField>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ textTransform: "capitalize", height: "6.5vh" }}
                    onClick={(e) => sendOTP("Email")}
                    disabled={emailError !== ""}
                  >
                    Send OTP
                  </Button>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography
                    sx={{
                      ml: 1,
                      mt: 0.3,
                      fontWeight: 600,
                      fontSize: 16,
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                  >
                    OR
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    fullWidth
                    value={mobileNo}
                    label="Mobile Number"
                    onChange={(e) => handleMobile(e)}
                    error={mobileError !== ""}
                    helperText={mobileError}
                  ></TextField>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ textTransform: "capitalize", height: "6.5vh" }}
                    onClick={(e) => sendOTP("Mobile")}
                    disabled={mobileError !== ""}
                  >
                    Send OTP
                  </Button>
                </Grid>
                {otpInitiated ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12} mt={4}>
                      {otpError && (
                        <Alert severity="error">Invalid or Expired OTP</Alert>
                      )}
                    </Grid>
                    <Grid item xs={12} mt={4}>
                      <Typography
                        component="div"
                        style={{
                          textAlign: "center",
                          color: "#000000",
                        }}
                      >
                        Please Enter OTP
                      </Typography>
                    </Grid>
                    <Grid item xs={12} mt={2}>
                      <OTPInputField
                        className="otp"
                        numOfInputs={6}
                        handleChange={handleOTPChange}
                      />
                    </Grid>
                    <Grid item xs={12} mt={2}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid item></Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            style={{
                              textTransform: "capitalize",
                              fontWeight: "800",
                              backgroundColor: "#148F5B",
                              color: "#fff",
                            }}
                            onClick={verifyOTP}
                          >
                            Verify OTP
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </DialogContent>
          </Dialog> */}
          <QuickApplyDialog/>

          <Grid container sx={{ mb: 8 }}>
            <GenInsight />
            <Grid item xs={12} md={12}>
              <NavBar />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};
export default QuickApply1;
