import React, { useState } from "react";
import "./Header.css";
import logo from "../../Assets/customLogo.png";
import { Link } from "react-router-dom";

const Header = () => {
  const [showContactPopup, setShowContactPopup] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleContactHover = () => {
    setShowContactPopup(true);
  };

  const handleContactLeave = () => {
    setShowContactPopup(false);
  };

  return (
    <div className="header-container">
      <div className="header">
        <div className="logo-container">
          <img src={logo} className="logo" alt="Logo" />
        </div>
        <div className="menu">
          <Link to="https://gosreefinance.com/about" className="menu-item">
            About
          </Link>
          <Link to="https://gosreefinance.com/investor" className="menu-item">
            Investors
          </Link>
          <Link to="https://gosreefinance.com/career" className="menu-item">
            Careers
          </Link>
          <Link to="https://gosreefinance.com/partner" className="menu-item">
            Partners
          </Link>
        </div>
        <div
          className="contact"
          onMouseEnter={handleContactHover}
          onMouseLeave={handleContactLeave}
        >
          Contact Us
          {showContactPopup && (
            <div className="contact-popup">
              Feel free to reach out to us at DlaSupport@corestrat.ai <br />
              connect with us via chat at 8129767770.
            </div>
          )}
        </div>
        <div className="hamburger-icon" onClick={handleMenuToggle}>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      {isMenuOpen && (
        <div className="mobile-menu">
          <Link to="https://gosreefinance.com/about" className="menu-item">
            About
          </Link>
          <Link to="https://gosreefinance.com/investor" className="menu-item">
            Investors
          </Link>
          <Link to="https://gosreefinance.com/career" className="menu-item">
            Careers
          </Link>
          <Link to="https://gosreefinance.com/partner" className="menu-item">
            Partners
          </Link>

          <Link to="https://gosreefinance.com/contact" className="menu-item">
            Contact Us
          </Link>
        </div>
      )}
    </div>
  );
};

export default Header;
