import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { lightTheme } from "./Common/Theme";
import BasicDetails from "./Components/BasicDetails/Index";
import Index2 from "./Components/BasicDetails/basicDetailsDigitalLoans";
import UploadDocument from "./Components/UploadDocument/Index";
import LoanCofirmation from "./Components/LoanConfirmation/Index";
import MyLoan from "./Components/MyLoan/Index";
import CoverPage from "./Components/LoginSignUp/coverPage";
import SplashScreen from "./Components/LoginSignUp/splashScreen";
import LoginSignup from "./Components/LoginSignUp/loginSignup";
import Registration from "./Components/LoginSignUp/register";
import RegistrationSms from "./Components/LoginSignUp/registerSms";
import RegistrationWomen from "./Components/LoginSignUp/registerWomen";
import RegistrationCrh from "./Components/LoginSignUp/registerCrh";
import QuickBasicDetails from "./Components/BasicDetails/QuickApply/quickBasicDetails";
import Apply from "./Components/LoginSignUp/apply";
import QuickApply1 from "./Components/BasicDetails/QuickApply/index";
import BulkUpload from "./Common/BulkLoanApplicatinUpload/bulkLoanApplicationUpload";
import DigitalLoans from "./Components/LoginSignUp/digitalLoans";
// import PersonalDiscussion from "./Components/MyLoan/PersonalDiscussion";
function App() {
  return (
    <ThemeProvider theme={lightTheme}>
      <Router>
        <Routes>
          <Route path="/" element={<LoginSignup />} />
          <Route path="/Login" element={<LoginSignup />} />
          <Route path="/BasicDetails" element={<BasicDetails />} />
          <Route path="/BasicDetailsDigitalLoans" element={<Index2 />} />
          <Route path="/UploadDocument" element={<UploadDocument />} />
          <Route path="/LoanCofirmation" element={<LoanCofirmation />} />
          <Route path="/MyLoan" element={<MyLoan />} />
          {/* <Route path="/PersonalDiscussion" element={<PersonalDiscussion/>}/> */}
          {/* <Route path="/digitalLoans" element={<DigitalLoans />} /> */}
          {/* <Route path="/digitalLoans/iwd" element={<RegistrationWomen />} /> */}
          {/* <Route path="/digitalLoans/crh" element={<RegistrationCrh />} /> */}
          <Route path="/digitalLoans/:channel" element={<RegistrationSms />} />
          <Route path="/quickApply" element={<Apply />} />
          {/* <Route path="/quickApply/crh" element={<QuickApply1 />} /> */}
          <Route path="/v2/BasicDetails" element={<QuickBasicDetails />} />
          <Route path="/BulkUpload" element={<BulkUpload />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
