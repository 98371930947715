import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import corestratLogo from "../../Assets/corestratWhite.png";
import Grid from "@mui/material/Grid";
import NavBar from "../NavBar/Quickapplyheader";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import GenInsight from "../GenInsight/GenInsight";
import Checkbox from "@mui/material/Checkbox";
import { Button, TextField } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DialogTitle from '@mui/material/DialogTitle';
import AadharVerification from "../BasicDetails/BasicDetailsPages/AadharVerification";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import EmailOTPVerification from "../../Common/emailOTPValidation";
import EmailOTPVerificationCoborrower from "../../Common/emailOTPValidationCoborrower";
import Footer from "./../../Common/Footer";
import { getQuickapplydetails, 
        AadharDrawer, 
        sendverificationCodeEmailCoborrower,
        verifyPan, 
        QuickApply, dummyQuickApply} from "../BasicDetails/BasicDetailsActions/BasicDetailsActions";
import "./SplashScreen.css";
import Typography from "@mui/material/Typography";
import CustomLoader from "../../Common/CustomLoader/customLoader";

const Apply = () => {
  const [showSplash, setShowSplash] = useState(true);
  const [aadharNo, setAadharNo] = useState("");
  const [isaadharChecked, setisaadharChecked] = useState(false);
  const [selectedAadhar, setselectedAadhar] = React.useState();
  const [isApicalled, setisApicalled] = useState(false);
  const [isApiLoading, setisApiLoading] = React.useState(false);
  const [emailAlreadyVerified, setemailAlreadyVerified] = React.useState(false);
  const [pan, setPan] = React.useState("");
  const [openOTPEmailDialog, setOpenOTPEmailDialog] = useState(false);
  const [emailId, setEmailId] = useState("");
  const [isemailChecked, setisemailChecked] = useState(false);
  const [loanamt, setLoanamt] = useState();
  const [error, setError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [aadharError, setAadharError] = useState("");
  const [submitError, setSubmitError] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [maxLoan, setMaxLoan] = useState(123);
  const [isLoanSubmitted, setIsLoanSubmitted] = useState(false);
  const aadharRegex = /^\d{12}$/;
  const emailRegex = /^\S+@\S+\.\S+$/;
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const details = useSelector(
    (state) => state.BasicDetailsReducers.QuickApplyDetailsSuccess
  );
  const detailsFail = useSelector(
    (state) => state.BasicDetailsReducers.QuickApplyDetailsFail
  );
  const detailsload = useSelector(
    (state) => state.BasicDetailsReducers.QuickApplyDetailsLoading
  );
  const verifyOTPSuccess = useSelector(
    (state) => state.BasicDetailsReducers.verifyOTPSuccess
  );
  const aadharDrawer = useSelector(
    (state) => state.BasicDetailsReducers.aadharDrawer
  );
  const quickapplysuccess = useSelector(
    (state) => state.BasicDetailsReducers.QuickApplySuccess
  );
  const quickapplyloading = useSelector(
    (state) => state.BasicDetailsReducers.QuickApplyLoading
  )
  const quickapplyfail = useSelector(
    (state) => state.BasicDetailsReducers.QuickApplyFail
  )
  const verifyOTPEmailCoborrowerSuccess = useSelector(
    (state) => state.BasicDetailsReducers.verifyOTPEmailCoborrowerSuccess
  );
  const verifyPanSuccess = useSelector(
    (state) => state.BasicDetailsReducers.verifyPanSuccess
  );
  const pandetailssuccess = useSelector(
    (state) => state.BasicDetailsReducers.panDetails
  );
  const initiateSessionAadharSuccess = useSelector(
    (state) => state.BasicDetailsReducers.initiateSessionAadharSuccess
  );

  const handleCloseEmailOTPDialog = () => {
    if(!verifyOTPEmailCoborrowerSuccess?.response_message?.startsWith("SUCCESS")){
      setisemailChecked(false);
    }
    setOpenOTPEmailDialog(false);
  };

  const handleAadharClick = (aadhaar) => {
    setisaadharChecked(!isaadharChecked);
    if (isaadharChecked == false) {
      localStorage.removeItem("LoanApplicationId");
      setselectedAadhar(aadhaar);
      dispatch(AadharDrawer(!aadharDrawer));
    }
  };

  const handleOpenEmailOTPDialog = async () => {
    setisemailChecked(!isemailChecked);
    if (!isemailChecked) {
      try {
        setisApiLoading(true);
        const result = await sendverificationCodeEmailCoborrower(emailId);
        if (result?.data?.response_message?.startsWith("SUCCESS")) {
          setemailAlreadyVerified(true);
          setisemailChecked(true)
          setisApiLoading(false);
        } else {
          setOpenOTPEmailDialog(true);
          setisApiLoading(false);
        }
        if (result.data.response_message == "FAILED") {
          setisApiLoading(false);
        }
      } catch (e) {
        console.log(e);
        setisApiLoading(false);
      }
    }
  };

  const handleSubmitClick = async () => {
    try{
      setShowSplash(true);
      const response = await dispatch(
        QuickApply(
          pan,
          pandetailssuccess,
          aadharNo,
          loanamt,
          emailId,
          verifyOTPSuccess.data,
          isaadharChecked,
          verifyPanSuccess
        )
      );

      // await dispatch(dummyQuickApply());
      
      if (response) {
        localStorage.setItem("jwt_token", response?.response_data?.jwt_token);
        localStorage.setItem("EmailId", response?.response_data?.email_id);
        setIsLoanSubmitted(true);
      }
      else{
        setShowSplash(false);
        setSubmitError(true);
      }

    }catch(error) {
      console.error(error);
    }
  };

  const handleLogoutButton = () => {
    localStorage.removeItem("jwt_token");
    localStorage.removeItem("EmailId");
    localStorage.removeItem("branch");
    setShowAlert(true);
  };

  const changePANchecked = () => {
    if (!verifyPanSuccess) {
      dispatch(verifyPan(pan,handleLogoutButton));
    }
    //console.log(pandetailssuccess);
  };

  useEffect(() => {
    // After 5 seconds, hide the splash screen and navigate to another page
    const timer = setTimeout(() => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const token = urlParams.get("apply_token");
      if(!isApicalled){
        const response1 = dispatch(getQuickapplydetails(token));//API call
        setisApicalled(true)
      }
    }, 5000);
    if(detailsFail){
      setShowSplash(false);
    }
    if(details?.response_data?.customer_details){
      setShowSplash(false);
      setPan(details.response_data?.customer_details.pan);
      localStorage.setItem("jwt_token", details?.response_data?.jwt_token);
      setLoanamt(details.response_data?.customer_details.max_loan_amount);
    }
    // Cleanup the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [detailsload]);

  useEffect(() => {
    if (isLoanSubmitted){
      setShowSplash(false);
      navigate("/MyLoan");
    }
  }, [isLoanSubmitted]);


  const handleAadhar = (e) => {
    const value = e.target.value;
    setAadharNo(e.target.value);
    if (!aadharRegex.test(value)) {
      setAadharError("Invalid Aadhar number (12 digits required)");
    } else {
      setAadharError("");
    }
  };

  const handleEmail = (e) => {
    const value = e.target.value;
    setEmailId(e.target.value);
    if (!emailRegex.test(value)) {
      setEmailError("Invalid Email ID");
    } else {
      setEmailError("");
    }
  };

  const handleLoanamount = (e) => {
    setLoanamt(e.target.value);
    const inputLoanamt=e.target.value;
    if (inputLoanamt > details.response_data?.customer_details.max_loan_amount) {
      setError(true); // Set error state if value exceeds maximum amount
    } else {
      setError(false); // Clear error state if value is within limit
    }
  };


  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {showSplash ? (
        <CustomLoader />
      ) : submitError || quickapplyfail ? (
        <Dialog open={true} >
          <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <ErrorOutlineIcon sx={{ color: '#f44336', marginRight: '0.5rem' }} />
            INVALID APPLICATION
          </DialogTitle>
        </Dialog>
      ) :  detailsFail ? (
        <Dialog open={true}>
          <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <ErrorOutlineIcon sx={{ color: '#f44336', marginRight: '0.5rem' }} />
            INVALID APPLICATION
          </DialogTitle>
        </Dialog>
      ) : (
        <>
        <Grid container sx={{ mb: 8 }}>
          <GenInsight />
          <Grid item xs={12} md={12}>
            <NavBar />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 3,
              height: "9vh",
              background: "#e5f6fd",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: 900,
                fontSize: "20px",
              }}
            >
              Quick Apply
            </Typography>
          </Grid>

          <Grid item xs={12} md={2}></Grid>
          <Grid item xs={12} md={10} sx={{ mt: 5 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={5}>
              <TextField
                disabled
                fullWidth
                value={details.response_data?.customer_details.customer_name}
                label="Name"
              >
              </TextField>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                disabled
                fullWidth
                value={details.response_data?.customer_details.pan}
                label="PAN"
              >
              </TextField>
            </Grid>
            <Grid item xs={12} md={4} sx={{ display: "flex" }}>
              <Checkbox
                sx={{ ml: 0 }}
                checked={verifyPanSuccess}
                disabled={verifyPanSuccess}
                onChange={changePANchecked}
              />

              <Typography sx={{ mt: 2 }} style={{ fontSize: "12px" }}>
                PAN Verification
              </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                disabled
                fullWidth
                value={details.response_data?.customer_details.branch}
                label="Branch"
              >
              </TextField>
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                disabled
                fullWidth
                value={details.response_data?.customer_details.phone_no}
                label="Phone"
              >
              </TextField>
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                fullWidth
                label="Email"
                name="emailid"
                id="email"
                value={emailId}
                onChange={(e) => handleEmail(e)}
                error={emailError !== ""}
                helperText={emailError}
                disabled={emailAlreadyVerified || 
                  verifyOTPEmailCoborrowerSuccess?.response_message?.startsWith(
                  "SUCCESS"
                )}
              >
              </TextField>
            </Grid>
            <Grid item xs={12} md={4} sx={{ display: "flex" }}>
              <Checkbox
                  sx={{ ml: 0 }}
                  checked={isemailChecked}
                  onClick={() =>
                    handleOpenEmailOTPDialog()
                  }
                  disabled={
                    emailAlreadyVerified ||
                    verifyOTPEmailCoborrowerSuccess?.response_message?.startsWith(
                      "SUCCESS" 
                    ) || emailError !== "" || emailId == ""
                  }
                />

              <Typography sx={{ mt: 2 }} style={{ fontSize: "12px" }}>
                Email Verification
              </Typography>
            </Grid>
            <EmailOTPVerificationCoborrower
              open={openOTPEmailDialog}
              email={emailId}
              handleClose={handleCloseEmailOTPDialog}
            />
            <Grid item xs={12} md={5}>
              <TextField
                fullWidth
                label="Aadhar"
                name="addharCard"
                id="addharCard"
                value={aadharNo}
                onChange={(e) => handleAadhar(e)}
                error={aadharError !== ""}
                helperText={aadharError}
                disabled={
                  (verifyOTPSuccess && !verifyOTPSuccess.length == 0) ||
                  verifyOTPSuccess?.data?.code == 200
                }
              />
            </Grid>
            <Grid item xs={12} md={4} sx={{ display: "flex" }}>
              <Checkbox
                sx={{ ml: 0 }}
                checked={isaadharChecked}
                onClick={() =>
                  handleAadharClick(aadharNo)
                }
                disabled={
                  (verifyOTPSuccess && !verifyOTPSuccess.length == 0) ||
                  verifyOTPSuccess?.data?.code == 200 ||
                  aadharNo == "" || aadharError !== ""
                }
              />

              <Typography sx={{ mt: 2 }} style={{ fontSize: "12px" }}>
                Aadhar Verification
              </Typography>
            </Grid>
            {aadharDrawer ? (
              <AadharVerification
                selectedAadhar={selectedAadhar}
              />
            ) : null}
            <Grid item xs={12} md={5}>
              <TextField
                fullWidth
                label="Loan Amount"
                name="loan_amt"
                type={"number"}
                id="loan_amt"
                value={loanamt}
                onChange={(e) => handleLoanamount(e)}
                error={error} // Pass error state to TextField
                helperText={error ? "Loan amount exceeds limit" : ""}
              />
            </Grid>
            <Grid item xs={12} md={5} sx={{ display: "flex" }}>
              <Typography sx={{ mt: 2 }} style={{ fontSize: "14px" }}>
                *Maximum Loan Amount is {details.response_data?.customer_details.max_loan_amount}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ display: "flex" }}></Grid>
            <Grid item xs={12} md={5} sx={{ display: "flex" }}>
              <Button
                //onClick={handleSaveGuarantorDetails}
                fullWidth
                variant="contained"
                sx={{ textTransform: "capitalize" }}
                onClick={handleSubmitClick}
                disabled= {!verifyPanSuccess || !isaadharChecked || !isemailChecked || error || !verifyOTPEmailCoborrowerSuccess || !verifyOTPSuccess?.data?.code == 200 }
              >
                Submit
              </Button>
            </Grid>
            <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isApiLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          </Grid>
          </Grid>
          <Grid item xs={12} md={1}></Grid>
        <Footer />
        </Grid>
        </>
      )}
    </div>
  );
};
export default Apply;
