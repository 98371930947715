import React, { useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { useSelector, useDispatch } from "react-redux";
import { ESignDialogView } from "../../Components/BasicDetails/BasicDetailsActions/BasicDetailsActions";
import VerticalLinearStepper from "./VerticalLinearStepper";
import LoanConfirmationScreen from "./LoanConfirmationScreen";
import LoanApprovalFlow from "./LoanApprovalFlow";
import LoanApprovalFlowDigitalLoans from "./LoanApprovalFlowDigitalLoans";
import Grid from "@mui/material/Grid";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ESignDialog(props) {
  const selectedLoanId = props.loanID;
  const data = props.data;
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(ESignDialogView(!esiginDialog));
  };
  //--------------------------------------------------------------
  const esiginDialog = useSelector(
    (state) => state.BasicDetailsReducers.esiginDialog
  );

  return (
    <div>
      <Dialog
        fullScreen
        open={esiginDialog}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>

            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Loan Approval
            </Typography>
            <Button
              autoFocus
              color="inherit"
              sx={{ textTransform: "capitalize" }}
              onClick={handleClose}
            >
              Close
            </Button>
          </Toolbar>
        </AppBar>
        {data?.loan_product_id == 11 ? (
          <Grid container>
            <Grid item xs={12}>
              {/* <VerticalLinearStepper loanID={selectedLoanId} /> */}
              <LoanApprovalFlowDigitalLoans
                loanID={selectedLoanId}
                loanData={data}
              />
              {/* <LoanApprovalFlow loanID={selectedLoanId} />  */}
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid item xs={12}>
              {/* <VerticalLinearStepper loanID={selectedLoanId} /> */}
              <LoanApprovalFlow loanID={selectedLoanId} loanData={data} />
            </Grid>
          </Grid>
        )}
      </Dialog>
    </div>
  );
}
