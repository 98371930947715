import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import DialogTitle from "@mui/material/DialogTitle";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import EmailOTPVerificationCoborrower from "../../../Common/emailOTPValidationCoborrower";
import Footer from "./../../../Common/Footer";
import {
  getQuickapplydetails,
  AadharDrawer,
  sendverificationCodeEmailCoborrower,
  verifyPan,
  QuickApply,
  dummyQuickApply,
  GetLoanProductType,
  getTenure,
  getCustomerDetails,
  sendverificationCode,
  verifyEmailMobile,
  SAF1,
} from "../../BasicDetails/BasicDetailsActions/BasicDetailsActions";
import "../../LoginSignUp/SplashScreen.css";
import Typography from "@mui/material/Typography";
import CustomLoader from "../../../Common/CustomLoader/customLoader";
import { OTPInputField } from "react-otp-input-type";
import Alert from "@mui/material/Alert";
import ErrorModel from "../../../Common/ErrorMessages";

const QuickApplyDialog = () => {
  const [showSplash, setShowSplash] = useState(true);
  const [open, setopen] = useState(true);
  const [isApicalled, setisApicalled] = useState(false);
  const [isApiLoading, setisApiLoading] = React.useState(false);
  const [redirectLoading, setredirectLoading] = React.useState(false);
  const [emailAlreadyVerified, setemailAlreadyVerified] = React.useState(false);
  const [openOTPEmailDialog, setOpenOTPEmailDialog] = useState(false);
  const [emailId, setEmailId] = useState("");
  const [mobileNo, setmobileNo] = useState("");
  const [isemailChecked, setisemailChecked] = useState(false);
  const [error, setError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [mobileError, setmobileError] = useState("");
  const [otp, setOtp] = useState("");
  const [resendOTPSuccess, setresendOTPSuccess] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [otpInitiated, setotpInitiated] = useState(false);
  const [isEmailVerification, setisEmailVerification] = useState(true);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(true);
  const emailRegex = /^\S+@\S+\.\S+$/;
  const MobileRegex = /^\d{10}$/;
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const details = useSelector(
    (state) => state.BasicDetailsReducers.QuickApplyDetailsSuccess
  );

  const handleClose1 = () => {
    setopen(false);
  };

  // const handleEmail = (e) => {
  //     const value = e.target.value;
  //     setEmailId(e.target.value);
  //     if (!emailRegex.test(value)) {
  //       setEmailError("Invalid Email ID");
  //     } else {
  //       setEmailError("");
  //     }
  // }

  useEffect(() => {
    setmobileNo(details?.response_data?.mobile_number);
  }, [details])

  const handleMobile = (e) => {
    const value = e.target.value;
    setmobileNo(e.target.value);
    if (!MobileRegex.test(value)) {
      setmobileError("Invalid Mobile Number");
    } else {
      setmobileError("");
    }
  };

  const handleOTPChange = (otpValue) => {
    setOtp(otpValue);
  };

  const sendOTP = async (e) => {
    setotpInitiated(true);
    // if (e == "Email" && emailError == "") {
    //   try {
    //     setisApiLoading(true);
    //     const result = await sendverificationCodeEmailCoborrower(emailId);
    //     if (result.data?.response_message == "OTP sent on registered email") {
    //       setisEmailVerification(true);
    //       setisApiLoading(false);
    //     } else {
    //       setisApiLoading(false);
    //     }
    //     if (result.data.response_message == "FAILED") {
    //       setisApiLoading(false);
    //     }
    //   } catch (e) {
    //     console.log(e);
    //     setisApiLoading(false);
    //   }
    // }
    if (e == "Mobile" && mobileError == "") {
      try {
        const result = await sendverificationCode(mobileNo);
        if (result.data.response_message == "SUCCESS") {
          setisEmailVerification(false);
          setisApiLoading(false);
        } else {
          setisApiLoading(false);
        }
        if (result.data.response_message == "FAILED") {
          setisApiLoading(false);
        }
      } catch (e) {
        console.log(e);
        setisApiLoading(false);
      }
    }
    setOtpError(false);
    setOtp("");
    setresendOTPSuccess(false);
    try {
      //const result = await sendverificationCodeEmail();
      //  if (result.data?.response_message == "OTP sent on registered email") {
      setresendOTPSuccess(true);
      //}
    } catch (e) {
      console.log(e);
      setresendOTPSuccess(false);
    }
  };

  const verifyOTP = async () => {
    setresendOTPSuccess(false);
    setOtpError(false);
    if (otp.length !== 6) {
      setOtpError(true);
      return;
    }
    try {
      setredirectLoading(true);
      const result = await verifyEmailMobile(
        isEmailVerification,
        emailId,
        mobileNo,
        otp,
        details.response_data?.fullname
      );
      if (result?.response_data) {
        handleClose1();
        localStorage.setItem("jwt_token", result?.response_data?.jwt_token);
        localStorage.setItem("EmailId", result?.response_data?.email_id);
        setredirectLoading(false);
        navigate("/v2/BasicDetails");
      }
    } catch (e) {
      // Handle other errors, such as network issues
      console.error("Error during OTP verification:", e);
      setOtpError(true);
    }
    // Implement logic to verify OTP
    //handleVerify(otp);
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          sx: {
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100vw",
            maxWidth: "100vw",
            minWidth: "100vw",
            height: "65vh",
            borderRadius: "10px 10px 0 0",
            padding: "0% !important",
            margin: "0% !important",
          },
        }}
      >
        {redirectLoading ? ( // Display loader if loading is true
          <div
            style={{
              textAlign: "center",
              padding: "20px",
              marginTop: "150px",
            }}
          >
            <CircularProgress />
            <Typography>Loading...</Typography>
          </div>
        ) : (
          <>
            <DialogTitle
              id="customized-dialog-title"
              onClose={handleClose1}
              sx={{ fontWeight: "800", color: "#000000", mt: 2, p: 2 }}
            >
              <Typography
                sx={{
                  ml: 1,
                  mt: 0.3,
                  fontWeight: 800,
                  fontSize: 20,
                  color: "#148F5B",
                }}
              >
                Hi {details.response_data?.fullname}
              </Typography>
              <Typography
                sx={{ ml: 1, mt: 0.3, fontWeight: 600, fontSize: 16 }}
              >
                Please complete the verification process for your mobile number
              </Typography>
            </DialogTitle>

            <DialogContent>
              <Grid container spacing={4} sx={{ mt: 2 }}>
                {/* <Grid item xs={12} md={1}></Grid>
                    <Grid item xs={12} md={2}>
                      <TextField
                        fullWidth
                        name="emailid"
                        id="email"
                        value={emailId}
                        onChange={(e) => handleEmail(e)}
                        error={emailError !== ""}
                        helperText={emailError}
                        label="Email"
                      ></TextField>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <Button
                        fullWidth
                        variant="contained"
                        sx={{ textTransform: "capitalize", height: "6.5vh" }}
                        onClick={(e) => sendOTP("Email")}
                        disabled={emailError !== ""}
                      >
                        Send OTP
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <Typography
                        sx={{
                          ml: 1,
                          mt: 0.3,
                          fontWeight: 600,
                          fontSize: 16,
                          textAlign: "center",
                          justifyContent: "center",
                        }}
                      >
                        OR
                      </Typography>
                    </Grid> */}
                <Grid item xs={12} md={2}>
                  <TextField
                    fullWidth
                    disabled
                    value={details?.response_data?.mobile_number}
                    label="Mobile Number"
                    onChange={(e) => handleMobile(e)}
                    error={mobileError !== ""}
                    helperText={mobileError}
                  ></TextField>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ textTransform: "capitalize", height: "6.5vh" }}
                    onClick={(e) => sendOTP("Mobile")}
                    disabled={mobileError !== ""}
                  >
                    Send OTP
                  </Button>
                </Grid>
                {otpInitiated ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12} mt={4}>
                      {otpError && (
                        <Alert severity="error">Invalid or Expired OTP</Alert>
                      )}
                    </Grid>
                    <Grid item xs={12} mt={4}>
                      <Typography
                        component="div"
                        style={{
                          textAlign: "center",
                          color: "#000000",
                        }}
                      >
                        Please Enter OTP
                      </Typography>
                    </Grid>
                    <Grid item xs={12} mt={2}>
                      <OTPInputField
                        className="otp"
                        numOfInputs={6}
                        handleChange={handleOTPChange}
                      />
                    </Grid>
                    <Grid item xs={12} mt={2}>
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid item></Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            style={{
                              textTransform: "capitalize",
                              fontWeight: "800",
                              backgroundColor: "#148F5B",
                              color: "#fff",
                            }}
                            onClick={verifyOTP}
                          >
                            Verify OTP
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </DialogContent>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default QuickApplyDialog;
