import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { DropzoneArea } from "material-ui-dropzone";
import UploadPicture from "./../../Assets/uploadFiles.svg";
import { useNavigate } from "react-router-dom";
import {
  saveBulkUpload,
  statementUploadBulk,
} from "../../Components/BasicDetails/BasicDetailsActions/BasicDetailsActions";
import { useSelector, useDispatch } from "react-redux";
import SessionModal from "./../../Common/SessionModel";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { styled } from "@mui/system";
import Alert from "@mui/material/Alert";
const StyledTable = styled(Table)(({ theme }) => ({
  "& .MuiTableCell-root": {
    padding: "8px",
  },
  "& .MuiCheckbox-root": {
    color: theme.palette.primary.main,
  },
}));
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "97vh",
  },
  leftPanel: {
    backgroundColor: "rgba(137,0,68,0.9)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
    height: "97vh",
    color: "#ffffff",
  },
  rightPanel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
    height: "97vh",
  },
}));

const BulkUpload = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [file, setFile] = useState(null);
  const [data, setdata] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isApiLoading, setisApiLoading] = useState(false);
  const [uploadCompleted, setuploadCompleted] = useState(false);
  const handleFileChange = (files) => {
    setFile(files[0]);
  };
  const convertToTargetJson = (input) => {
    return input.map((item) => ({
      LoanApplicationId: item.data.LoanApplicationId,
      bank_name: item.bank_name,
      statement_password: item.statement_password,
      collateral_flag: true, // Default value as it's not present in the input JSON
      other_docs_flag: false, // Default value as it's not present in the input JSON
      ApplicantName: item.data.ApplicantName,
      LoanStatus: item.data.LoanStatus,
    }));
  };
  const handleUpload = async () => {
    setisApiLoading(true);
    // Implement your upload logic here
    console.log("Uploaded file:", file);
    try {
      const res = await saveBulkUpload(file, handleLogoutButton);
      if (res.data.response_data) {
        setuploadCompleted(true);
        const filteredData = res.data.response_data.filter(
          (item) =>
            !item.message?.startsWith("Dedupe") &&
            !item.message?.startsWith("MRAC")
        );
        if (filteredData && filteredData.length > 0) {
          const convertedData = convertToTargetJson(filteredData);
          console.log(convertedData);
          setdata(convertedData);
        }
        setisApiLoading(false);
        //setDialogOpen(true);
      } else {
        setisApiLoading(false);
      }
    } catch {
      setisApiLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleFillMoreApplications = () => {
    // Refresh the page
    window.location.reload();
  };

  const handleNavigateToLogin = () => {
    navigate("/login");
    // Navigate to login page
    // Implement your navigation logic here
  };
  const handleLogoutButton = () => {
    localStorage.removeItem("jwt_token");
    localStorage.removeItem("EmailId");
    localStorage.removeItem("branch");
    setShowAlert(true);
    localStorage.removeItem("LoanApplicationId");
  };
  const handleCloseErrorModal1 = () => {
    setShowAlert(false);
    window.location.href = "./login";
  };
  const handleCheckboxChange = (index, field) => (event) => {
    const newData = [...data];
    newData[index][field] = event.target.checked;
    setdata(newData);
  };

  const handleSubmit = async () => {
    setisApiLoading(true);
    console.log("Current data:", data);
    const newData = data.map((item) => ({
      LoanApplicationId: item.LoanApplicationId,
      bank_name: item.bank_name,
      statement_password: item.statement_password,
      collateral_flag: true, // Default value as it's not present in the input JSON
      other_docs_flag: item.other_docs_flag, // Default value as it's not present in the input JSON
    }));

    console.log(newData);
    try {
      const res = await statementUploadBulk(newData, handleLogoutButton);
      if (res) {
        setisApiLoading(false);
        setDialogOpen(true);
      }
    } catch (e) {
      setisApiLoading(false);
    }
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        {/* Left Panel */}
        <SessionModal isOpen={showAlert} onClose={handleCloseErrorModal1} />
        <Grid item xs={6} className={classes.leftPanel}>
          <div>
            <Typography variant="h4" gutterBottom>
              Digital Lending Automation
            </Typography>
            <Typography variant="body1">
              Upload documents in .csv format for Bulk Loan Apply
            </Typography>
            <img
              src={UploadPicture}
              alt="Upload"
              style={{ height: "30vh", width: "30vw" }}
            />
          </div>
        </Grid>
        {/* Right Panel */}

        <Grid item xs={6} className={classes.rightPanel}>
          {data.length > 0 ? (
            <div>
              <>
                {uploadCompleted ? (
                  <>
                    <TableContainer
                      component={Paper}
                      style={{
                        width: "80%",
                        marginLeft: "10%",
                        height: "60vh !important",
                        overflow: "auto",
                      }}
                    >
                      <StyledTable>
                        <TableHead>
                          <TableRow>
                            <TableCell>Loan Application Id</TableCell>
                            <TableCell>Bank Name</TableCell>
                            <TableCell>Collateral Flag</TableCell>
                            <TableCell>Other Docs Flag</TableCell>
                            <TableCell>Applicant Name</TableCell>
                            <TableCell>Loan Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell>{item.LoanApplicationId}</TableCell>
                              <TableCell>{item.bank_name}</TableCell>
                              <TableCell>
                                <Checkbox
                                  checked={item.collateral_flag}
                                  disabled
                                  onChange={handleCheckboxChange(
                                    index,
                                    "collateral_flag"
                                  )}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  checked={item.other_docs_flag}
                                  onChange={handleCheckboxChange(
                                    index,
                                    "other_docs_flag"
                                  )}
                                />
                              </TableCell>
                              <TableCell>{item.ApplicantName}</TableCell>
                              <TableCell>{item.LoanStatus}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </StyledTable>
                    </TableContainer>
                    <Grid
                      container
                      spacing={0}
                      justifyContent={"flex-end"}
                      sx={{ mt: 3, mr: 5 }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <Alert severity="error">
                    All Cases uploaded failed due to MRAC or Dedupe Check
                  </Alert>
                )}
              </>
            </div>
          ) : (
            <>
              {!uploadCompleted ? (
                <div style={{ width: "80%" }}>
                  <Typography
                    variant="body1"
                    style={{
                      margin: "20px auto",
                      fontFamily: "Outfit",
                      textAlign: "center",
                      fontSize: "1rem",
                    }}
                  >
                    Simply drag and drop your files into the box below, or click
                    to select them from your computer. Once uploaded, you can
                    proceed with ease. Experience seamless digital lending at
                    your fingertips!
                  </Typography>
                  <DropzoneArea
                    sx={{ width: "37vw !important" }}
                    acceptedFiles={[".csv", ".xls", ".xlsx"]}
                    dropzoneText="Drag and drop a file here or click"
                    onChange={handleFileChange}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleUpload}
                      disabled={!file}
                      style={{ margin: "16px" }}
                    >
                      Upload
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleLogoutButton}
                      style={{ margin: "16px" }}
                    >
                      Close & Logout
                    </Button>
                  </div>
                </div>
              ) : (
                <Alert severity="error">
                  All Cases uploaded failed due to MRAC or Dedupe Check
                </Alert>
              )}
            </>
          )}
        </Grid>
      </Grid>
      {/* Dialog */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Thank You!</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Your applications have been uploaded successfully.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFillMoreApplications} color="primary">
            Fill More Applications
          </Button>
          <Button onClick={handleNavigateToLogin} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isApiLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default BulkUpload;
