import React from "react";
import Grid from "@mui/material/Grid";
import CorestratLogo from "../../Assets/customLogo.png";
import HorizontalNonLinearStepper from "./HorizontalNonLinearStepper";
import LogoutIcon from "@mui/icons-material/Logout";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { Stepper, Step, StepLabel, Typography } from '@mui/material';
import StepButton from "@mui/material/StepButton";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";

const steps = ['Quick Apply'];

const Quickapply = () => {
  const navigate = useNavigate();
  const navigateTofirstPage = () => {
    navigate("/BasicDetails");
    localStorage.removeItem("LoanApplicationId");
    //window.location.reload();
  };

  const activeStep = 0;

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md={4}
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "12vh",
          borderBottom: "2px solid #148F5B",
        }}
      >
        <img
          style={{ padding: "20px", cursor: "pointer" }}
          src={CorestratLogo}
          onClick={navigateTofirstPage}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "12vh",
          borderBottom: "2px solid #BEBEBE",
        }}
      ></Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "12vh",
          borderBottom: "2px solid #BEBEBE",
          //   ml:-4
        }}
      >
        <Box sx={{ width: "100%", p: 0, pt: 4 }}>
        <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
            <Step key={label}>
                <StepButton
                color="inherit"
                // onClick={handleStep(index)}
                style={{ cursor: "not-allowed" }}
                >
                {label}
                </StepButton>
            </Step>
            ))}
        </Stepper>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Quickapply;