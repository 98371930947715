import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { TypeAnimation } from "react-type-animation";

function Message(props) {
  let date = new Date();
  const CURSOR_CLASS_NAME = "custom-type-animation-cursor";
  const inputRef = useRef();
  const [graphMessageInput, setGraphMessageInput] = useState("");
  const [graphId, setGraphId] = useState(0);
  const [loading, setLoading] = useState(false);
  let user = sessionStorage.getItem("username");
  const [time, setTime] = useState("");

  useEffect(() => {
    if (props.typing === false && props.image === true && props.graphID !== 0) {
      setLoading(false);
    }

    let date = new Date(props.time + "Z");

    setTime(`${date.getHours()}:${date.getMinutes()}`);
  }, [props]);

  const handleKeyDown = (event) => {
    //<------use enter key to submit form
    if (event.which === 13) {
      // inputRef.current.getElementsByTagName("textarea")[0].style.height = "auto";
      setGraphId(props.graphID);
      props.graphQueryDetails({
        graphMSG: graphMessageInput,
        graphID: props.graphID,
      });
      setGraphMessageInput("");
      setLoading(true);
      event.preventDefault();
    }
  };
  return (
    <div>
      <Box
        sx={{
          // my: 2,
          display: "grid",
          flexFlow: "row",
          justifyContent: props.isUser ? "right" : "left",
          backgroundColor: props.isUser ? "" : "#ffffff",
          width: props.isUser ? "100%" : "70%",
          padding: "8px",
          margin: "2%",
        }}
      >
        <Box>
          <Typography
            gutterBottom
            variant="body2"
            component="div"
            sx={{
              maxWidth: props.image ? "100%" : "400px",
              overflowX: "auto",
              fontSize: "1rem",
              margin: "2%",
              backgroundColor: props.isUser ? "#890044" : "#ffffff",
              color: props.isUser ? "#ffffff" : "",
              borderRadius: props.isUser ? "5px !important" : "10px !important",
              padding: props.isUser ? "0.5rem !important" : "",
            }}
          >
            {props.isUser ? (
              props.content
            ) : props.typing ? (
              <TypeAnimation
                sequence={[
                  // Same substring at the start will only be typed out once, initially
                  `${props.content}`,
                  (el) => el.classList.remove(CURSOR_CLASS_NAME), // A reference to the element gets passed as the first argument of a callback function
                  // 2000,
                  // (el) => el.classList.add(CURSOR_CLASS_NAME),
                ]}
                style={{ whiteSpace: "pre-line" }}
                wrapper="span"
                speed={90}
                repeat={0}
                cursor={false}
                className={CURSOR_CLASS_NAME}
              />
            ) : props.content === "loading_message" ? (
              <div className="loader3">
                <div className="circle1"></div>
                <div className="circle1"></div>
                <div className="circle1"></div>
                <div className="circle1"></div>
                <div className="circle1"></div>
              </div>
            ) : props.image ? (
              <Stack rowGap={2}></Stack>
            ) : (
              props.content.split("\n").map((line, index) => (
                <span key={index} style={{ margin: "20%" }}>
                  {line} <br />
                </span>
              ))
            )}
          </Typography>
          <Typography
            sx={{
              display: "grid",
              flexFlow: "row",
              justifyContent: props.isUser ? "right" : "left",
              color: "#707070",
              fontSize: "12px",
            }}
          >
            {props.isUser
              ? `User ${
                  time === "NaN:NaN"
                    ? `${date.getHours()}:${date.getMinutes()}`
                    : time
                }`
              : `DigiAssistant ${
                  time === "NaN:NaN"
                    ? `${date.getHours()}:${date.getMinutes()}`
                    : time
                }`}
          </Typography>
          {/* {props.image && <img src={props.image} alt="Bot response" style={{ width: "100%" }} />}
          {!props.isUser && props.choices && (
            <Box>
              {props.choices.map((choice, index) => (
                <Chip key={index} label={choice} onClick={() => props.handleChoice(choice)} sx={{ mr: 0.5, mb: 0.5 }} />
              ))}
            </Box>
          )} */}
        </Box>
      </Box>
    </div>
  );
}

export default Message;
