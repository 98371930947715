import React, { useEffect, useRef, useState } from "react";

// project imports
import "./genInsight.css";
import genInsight from "../../Assets/ChatBotLogo.svg";
import axios from "axios";
import Message from "./message";
import { genInsight_base_url } from "../../constants";
import CorestratLogo from "../../Assets/CorestartLogo.png";
import {
  UserQuery,
  UserQueryClearData,
  ChatMessages,
  ChatMessagesClearData,
} from "../BasicDetails/BasicDetailsActions/BasicDetailsActions";
import ErrorAlert from "./errorAlert";

// mui imports
import {
  Avatar,
  Box,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useSelector, useDispatch } from "react-redux";
import { Menu, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
// constant declaration

function GenInsight() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef();
  const [jwtToken, setJwtToken] = useState(localStorage.getItem("jwt_token"));
  const [chatOpenStatus, setChatOpenStatus] = useState(false);
  const [showIcon, setShowIcon] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
  });

  const [messageInput, setMessageInput] = useState("");
  const [userContent, setUserContent] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [isChatHistory, setisChatHistory] = useState(true);
  const [jwtTokenStatus, setJwtTokenStatus] = useState("");
  const [menuAnchorChatHistory, setmenuAnchorChatHistory] = useState(null);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [subMenuAnchor, setSubMenuAnchor] = useState(null);
  const [selectedLoanType, setSelectedLoanType] = useState(null);
  const [contactUsMenuAnchor, setContactUsMenuAnchor] = useState(null);
  const [sampleQuestions, setsampleQuestions] = useState([]);
  const [typeOfLoan, settypeOfLoan] = useState(null);

  const boxRef = useRef(null);
  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleMenuOpenChatHistory = (event) => {
    setmenuAnchorChatHistory(event.currentTarget);
  };

  const handleMenuCloseChatHistory = () => {
    setmenuAnchorChatHistory(null);
  };
  const handleChatHistoryClick = async () => {
    setMessages([]);
    console.log("Chat History clicked");
    if (isChatHistory == true && chat_history.length > 0) {
      let update_messages = [
        ...messages,
        {
          content: userContent,
          isUser: true,
        },
        {
          content: "loading_message",
          isUser: false,
        },
      ];
      setMessages(update_messages);
      if (messages.length === 0 && jwtToken) {
        setMessages(chat_history);
        if (userQuerySuccess === true && messages.length !== 0) {
          if (messages[messages.length - 1].content === "loading_message") {
            update_messages = messages.map((item, index) => {
              // if it is the last element, return a new object with the updated content
              if (index === messages.length - 1) {
                return {
                  ...item,
                  content: userQuerySuccess
                    ? userQueryResponse.response_data.bot_response
                    : "",
                  typing: true,
                  isUser: false,
                  // time: err.response.data.response_data.created_datetime,
                };
              }
              // otherwise, return the original item
              return item;
            });
            setMessages(update_messages);
          }
        }
      }
      setisChatHistory(false);
      // try {
      //   const response = await dispatch(ChatMessages());
      //   if (!Array.isArray(messages)) {
      //     setMessages([]);
      //     // If messages is not an array, handle the error or return
      //     console.error("Invalid messages format");
      //     return;
      //   }
      //   if (userQueryFail === false) {
      //     let update_messages = [
      //       ...messages,
      //       {
      //         content: userContent,
      //         isUser: true,
      //       },
      //       {
      //         content: "loading_message",
      //         isUser: false,
      //       },
      //     ];
      //     setMessages(update_messages);
      //     if (userQuerySuccess === true && messages.length !== 0) {
      //       if (messages[messages.length - 1].content === "loading_message") {
      //         update_messages = messages.map((item, index) => {
      //           if (index === messages.length - 1) {
      //             return {
      //               ...item,
      //               content: userQuerySuccess
      //                 ? userQueryResponse.response_data.bot_response
      //                 : "",
      //               typing: true,
      //               isUser: false,
      //             };
      //           }
      //           return item;
      //         });
      //         setMessages(update_messages);
      //       }
      //     }
      //   } else {
      //     dispatch(ChatMessagesClearData());
      //     setMessages([]);
      //   }
      //   handleMenuCloseChatHistory();
      // } catch (error) {
      //   console.error("Error handling chat history click:", error);
      // }
    } else {
      const response = await dispatch(ChatMessages());
      setisChatHistory(true);
    }
  };

  const handleSubMenuOpen = (event, loanType) => {
    setSubMenuAnchor(event.currentTarget);
    setSelectedLoanType(loanType);
  };

  const handleSubMenuClose = () => {
    setSubMenuAnchor(null);
    setSelectedLoanType(null);
  };

  const handleLoanTypeSelect = (type) => {
    console.log("Selected Loan Type:", type);
    if (type == "Secured") {
      settypeOfLoan("micro_loan_secured");
      const sampleQuestions1 = [
        "What documents are needed for collateral verification?",
        "What type of collateral is accepted?",
        "What is interest rate charged?",
        "How to apply for a secured loan?",
      ];
      setsampleQuestions(sampleQuestions1);
    }
    if (type == "Unsecured") {
      settypeOfLoan("micro_loan_unsecured");
      const sampleQuestions1 = [
        "How quickly is the loan approved?",
        "What is minimum and maximum tenure?",
        "What type of income proof is required?",
        "What is the maximum loan amount possible?",
      ];
      setsampleQuestions(sampleQuestions1);
    }
    // Perform any action based on the selected loan type
    // For example, set state, update UI, etc.
    // You can also dispatch an action if using Redux
    // Remember to close the sub-menu
    handleSubMenuClose();
  };
  const userQuerySuccess = useSelector(
    (state) => state.BasicDetailsReducers.userQuerySuccess
  );
  const userQueryLoading = useSelector(
    (state) => state.BasicDetailsReducers.userQueryLoading
  );
  const userQueryFail = useSelector(
    (state) => state.BasicDetailsReducers.userQueryFail
  );
  const userQueryResponse = useSelector(
    (state) => state.BasicDetailsReducers.userQueryResponse
  );
  const bot_response = userQuerySuccess ? userQueryResponse.response_data : [];

  const chatMessagesSuccess = useSelector(
    (state) => state.BasicDetailsReducers.chatMessagesSuccess
  );
  const chatMessagesLoading = useSelector(
    (state) => state.BasicDetailsReducers.chatMessagesLoading
  );
  const chatMessagesFail = useSelector(
    (state) => state.BasicDetailsReducers.chatMessagesFail
  );
  const chatMessagesResponse = useSelector(
    (state) => state.BasicDetailsReducers.chatMessagesResponse
  );
  const chat_history = chatMessagesSuccess ? chatMessagesResponse : [];
  useEffect(() => {
    const sampleQuestions1 = [
      "How do I apply for a loan?",
      "How is the annual percentage rate (APR) calculated? ",
      "How long does it typically take to get approved for a loan?",
      "Can I request an extension on my loan repayment?",
    ];
    setsampleQuestions(sampleQuestions1);
    if (chatMessagesFail === true) {
      navigate("/login");
    }
  }, [chatMessagesFail]);
  //if (userQueryFail === true)
  useEffect(() => {
    const sampleQuestions1 = [
      "How do I apply for a loan?",
      "How is the annual percentage rate (APR) calculated? ",
      "How long does it typically take to get approved for a loan?",
      "Can I request an extension on my loan repayment?",
    ];
    setsampleQuestions(sampleQuestions1);
    if (userQueryFail === true) {
      navigate("/login");
      let update_messages = [
        ...messages,
        {
          content: userContent,
          isUser: true,
        },
        {
          content: "loading_message",
          isUser: false,
        },
      ];
      setMessages(update_messages);

      if (userQuerySuccess === false && messages.length !== 0) {
        if (messages[messages.length - 1].content === "loading_message") {
          update_messages = messages.map((item, index) => {
            // if it is the last element, return a new object with the updated content
            if (index === messages.length - 1) {
              setErrorAlert(true);
              return {
                ...item,
                content: `An Error Occurred, Please Try Again.`,
                typing: true,
                isUser: false,
                // time: err.response.data.response_data.created_datetime,
              };
            }
            // otherwise, return the original item
            return item;
          });
          setMessages(update_messages);
        }
      }
    }
  }, [userQueryFail]);

  //if (userQueryFail === false)
  useEffect(() => {
    if (userQueryFail === false) {
      let update_messages = [
        ...messages,
        {
          content: userContent,
          isUser: true,
        },
        {
          content: "loading_message",
          isUser: false,
        },
      ];
      setMessages(update_messages);
      if (userQuerySuccess === true && messages.length !== 0) {
        if (messages[messages.length - 1].content === "loading_message") {
          update_messages = messages.map((item, index) => {
            // if it is the last element, return a new object with the updated content
            if (index === messages.length - 1) {
              return {
                ...item,
                content: userQuerySuccess
                  ? userQueryResponse.response_data.bot_response
                  : "",
                typing: true,
                isUser: false,
                // time: err.response.data.response_data.created_datetime,
              };
            }
            // otherwise, return the original item
            return item;
          });
          setMessages(update_messages);
        }
      }
    }
  }, [userQueryLoading]);

  // useEffect(() => {
  //   if (messages.length === 0 && jwtToken && isChatHistory == true) {
  //     setMessages(chat_history);
  //   }
  // }, [isChatHistory]);

  useEffect(() => {
    const fetchChatMessages = async () => {
      try {
        setMessages([]);
        const messages = await dispatch(ChatMessages());
        if (userQueryFail === false) {
          let update_messages = [
            ...messages,
            {
              content: userContent,
              isUser: true,
            },
            {
              content: "loading_message",
              isUser: false,
            },
          ];
          setMessages(update_messages);
          if (userQuerySuccess === true && messages.length !== 0) {
            if (messages[messages.length - 1].content === "loading_message") {
              update_messages = messages.map((item, index) => {
                // if it is the last element, return a new object with the updated content
                if (index === messages.length - 1) {
                  return {
                    ...item,
                    content: userQuerySuccess
                      ? userQueryResponse.response_data.bot_response
                      : "",
                    typing: true,
                    isUser: false,
                    // time: err.response.data.response_data.created_datetime,
                  };
                }
                // otherwise, return the original item
                return item;
              });
              setMessages(update_messages);
            }
          }
        }

        // Do something with messages
      } catch (error) {
        // Handle error
      }
    };

    fetchChatMessages();
  }, [dispatch]);
  const postUserQuery = async (content) => {
    setMessages([]);
    setisChatHistory(false);
    setUserContent(content);
    setErrorAlert(false);
    setJwtTokenStatus("");
    setLoading(true);
    dispatch(UserQuery(content, typeOfLoan));
    const messages1 = await dispatch(ChatMessages());
    if (userQueryFail === false) {
      let update_messages = [
        ...messages,
        {
          content: content,
          isUser: true,
        },
        {
          content: "loading_message",
          isUser: false,
        },
      ];
      setMessages(update_messages);
      if (userQuerySuccess === true && messages.length !== 0) {
        if (messages[messages.length - 1].content === "loading_message") {
          update_messages = messages.map((item, index) => {
            // if it is the last element, return a new object with the updated content
            if (index === messages.length - 1) {
              return {
                ...item,
                content: userQuerySuccess
                  ? userQueryResponse.response_data.bot_response
                  : "",
                typing: true,
                isUser: false,
                // time: err.response.data.response_data.created_datetime,
              };
            }
            // otherwise, return the original item
            return item;
          });
          setMessages(update_messages);
        }
      }
    }
  };
  useEffect(() => {
    // Scroll to the bottom of the box when messages change
    if (boxRef.current) {
      boxRef.current.scrollTop = boxRef.current.scrollHeight;
    }
  }, [messages]);
  const handleKeyDown = (event) => {
    //<------use enter key to submit form
    if (event.which === 13) {
      // inputRef.current.getElementsByTagName("textarea")[0].style.height = "auto";
      postUserQuery(messageInput, typeOfLoan);
      setMessageInput("");
      event.preventDefault();
    }
  };

  const handleMainPromptSubmit = (event) => {
    event.preventDefault();

    postUserQuery(messageInput, typeOfLoan);
    setMessageInput("");
  };

  const handleIconAppear = (iconPosition, newValue) => {
    setShowIcon((prev) => {
      return { ...prev, [iconPosition]: newValue };
    });
  };
  const openBotwithMessages = async () => {
    try {
      const fetchedMessages = await dispatch(ChatMessages());
      if (fetchedMessages != undefined && fetchedMessages != null) {
        console.log("Fetched Message", fetchedMessages);
        const messages = Array.isArray(fetchedMessages) ? fetchedMessages : [];

        if (!userQueryFail) {
          const update_messages = [
            ...messages,
            {
              content: userContent,
              isUser: true,
            },
            {
              content: "loading_message",
              isUser: false,
            },
          ];

          setMessages(update_messages);

          if (userQuerySuccess && messages.length > 0) {
            const lastMessage = messages[messages.length - 1];

            if (lastMessage.content === "loading_message") {
              const updatedMessages = messages.map((item, index) => {
                if (index === messages.length - 1) {
                  return {
                    ...item,
                    content: userQueryResponse.response_data.bot_response || "",
                    typing: true,
                    isUser: false,
                    // time: err.response.data.response_data.created_datetime,
                  };
                }
                return item;
              });

              setMessages(updatedMessages);
            }
          }
        }
      }
      setChatOpenStatus(true);
    } catch (error) {
      // Handle errors here
      console.error("Error fetching messages:", error);
    }
  };

  const handleContactUsClick = (event) => {
    setSubMenuAnchor(null); // Close the Loans submenu if open
    setContactUsMenuAnchor(event.currentTarget); // Open the Contact Us submenu
  };
  return (
    <>
      {chatOpenStatus ? (
        // if chat open
        <Box
          sx={{
            position: "fixed",
            right: "14px",
            bottom: "5%",
            width: 440,
            height: 550,
            backgroundColor: "#ffffff",
            borderRadius: "10px",
            border: "1px solid #ffffff",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
            zIndex: 1000,
          }}
        >
          {errorAlert === true && <ErrorAlert jwtToken={jwtTokenStatus} />}
          {/* header */}

          <div
            style={{
              background: "#890044",
              padding: "10px",
              position: "relative",
              height: "4vh",
              color: "#ffffff",
            }}
          >
            <div>
              {/* Hamburger icon */}
              <IconButton
                onClick={handleMenuOpenChatHistory}
                edge="start"
                color="inherit"
                aria-label="menu"
              >
                <MenuIcon />
              </IconButton>

              {/* Menu */}
              <Menu
                anchorEl={menuAnchorChatHistory}
                open={Boolean(menuAnchorChatHistory)}
                onClose={handleMenuCloseChatHistory}
              >
                {/* Chat History */}
                <MenuItem onClick={handleChatHistoryClick}>
                  Chat History
                </MenuItem>
              </Menu>
            </div>
            <h3 style={{ position: "absolute", left: "170px", top: "-4%" }}>
              <span style={{ color: "#ffffff" }}>DigiAssistant</span>
            </h3>
            {/* close chat button */}
            <Tooltip title="Close chat">
              <IconButton
                sx={{
                  position: "absolute",
                  right: "10px",
                  top: "10px",
                  color: "#ffffff",
                }}
                aria-label="Close Chat"
                onClick={() => {
                  setChatOpenStatus(false);
                }}
              >
                <KeyboardArrowDownIcon />
              </IconButton>
            </Tooltip>
          </div>
          {/* chat area */}
          <Box
            ref={boxRef}
            sx={{
              position: "absolute",
              top: "50px",
              width: "100%",
              height: "85%",
              overflow: "auto",
              borderRadius: "0px 0px 0px 10px",
              backgroundColor: "#F0EDE4",
            }}
          >
            {messages.map((message, index) => (
              <Message
                key={index}
                content={message.content}
                isUser={message.isUser}
                choices={message.choices}
                typing={message.typing}
                time={message.time}
                handleChoice={postUserQuery}
              />
            ))}
            <Box
              id="section2"
              style={{
                height: "3.625rem",
                width: "100%",
                backgroundColor: "#F0EDE4",
              }}
            ></Box>
          </Box>

          {/* starting screen with sample questions */}
          {messages.length === 0 && (
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                height: "calc(100% - 50px)",
                position: "absolute",
                top: "50px",
                left: "50%", // Centering the content horizontally
                transform: "translateX(-50%)", // Adjusting for centering
              }}
              gap={4}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "700",
                  color: "#707070",
                  width: "320px",
                }}
              >
                To start, you can type your question one at a time, or click on
                any of the popular topics
              </Typography>
              <Stack
                sx={{ zIndex: 10 }}
                rowGap={1}
                style={{ marginBottom: "5%" }}
              >
                {sampleQuestions.map((text, index) => {
                  return (
                    <Stack
                      columnGap={2}
                      key={index}
                      sx={{
                        border: "2px solid #E7E7E7",
                        borderRadius: "12px",
                        height: "50px",
                        ":hover": {
                          border: "2px solid #707070",
                        },
                      }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{
                          flexShrink: 0,
                          padding: "4px 8px",
                          width: "calc(100% - 1rem)",
                          height: "84%",
                          maxWidth: "60rem",
                          color: "#890044",
                          border: "1px solid #890044", // Replace "yourBorderColor" with the desired color
                          borderRadius: "10px",
                          ":hover": {
                            cursor: "pointer",
                            backgroundColor: "#d7d7d7",
                            opacity: "100",
                            color: "#890044",
                          },
                        }}
                        onMouseEnter={() => handleIconAppear(index, true)}
                        onMouseLeave={() => handleIconAppear(index, false)}
                        onClick={() => {
                          postUserQuery(text, typeOfLoan);
                        }}
                      >
                        <Typography sx={{ fontSize: "12px", width: "84%" }}>
                          {text}
                        </Typography>
                        <Tooltip title="Send">
                          {showIcon[index] ? (
                            <SendIcon />
                          ) : (
                            <SendIcon
                              sx={{
                                opacity: "0",
                                ":hover": {
                                  cursor: "pointer",
                                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                                  borderRadius: "10px",
                                  opacity: "100",
                                },
                              }}
                            />
                          )}
                        </Tooltip>
                      </Stack>
                    </Stack>
                  );
                })}
              </Stack>
            </Stack>
          )}
          {/* user input area */}
          {/* <TextField
            sx={{
              position: "absolute",
              bottom: "0px",
              left: "0px",
              width: "calc(100% - 34.8px)",
              padding: "10px 10px 0px 20px",
              // background: "red",
              background:
                "linear-gradient(180deg, rgba(255, 255, 255, 0.8) 1.0%, #fff 20.00%)",
              height: "5rem",
              borderRadius: "0px 0px 2px 8px",
            }}
            fullWidth
            variant="outlined"
            autoComplete="off"
            size="small"
            ref={inputRef}
            onKeyDown={handleKeyDown}
            // multiline
            // maxRows={2}
            autoFocus={true}
            disabled={userQueryLoading ? true : false}
            placeholder="e.g. How do I start loan application?"
            value={messageInput}
            onChange={(event) => setMessageInput(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    variant="contained"
                    onClick={handleMainPromptSubmit}
                    type="submit"
                    disabled={messageInput === "" ? true : false}
                  >
                    <Tooltip title="Send Message">
                      <SendIcon
                        className="send-message-icon"
                        style={{ color: "#148F5B" }}
                      />
                    </Tooltip>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          /> */}
          <div>
            <Menu
              anchorEl={menuAnchor}
              open={Boolean(menuAnchor)}
              onClose={handleMenuClose}
            >
              {/* Contact Us */}
              <MenuItem onClick={(event) => handleContactUsClick(event)}>
                Contact Us
              </MenuItem>

              {/* Loans */}
              <MenuItem onClick={(event) => handleSubMenuOpen(event, "Loans")}>
                Loans &gt;
              </MenuItem>
            </Menu>
            <Menu
              anchorEl={contactUsMenuAnchor}
              open={Boolean(contactUsMenuAnchor)}
              onClose={() => setContactUsMenuAnchor(null)}
            >
              <MenuItem>
                {" "}
                Feel free to reach out to us at DlaSupport@corestrat.ai <br />{" "}
                connect with us via chat at 8129767770.
              </MenuItem>
            </Menu>
            {/* Sub Menu for Loans */}
            <Menu
              anchorEl={subMenuAnchor}
              open={Boolean(subMenuAnchor)}
              onClose={handleSubMenuClose}
            >
              {/* Secured Loan */}
              <MenuItem onClick={() => handleLoanTypeSelect("Secured")}>
                Micro Loan Secured
              </MenuItem>

              {/* Unsecured Loan */}
              <MenuItem onClick={() => handleLoanTypeSelect("Unsecured")}>
                Micro Loan Unsecured
              </MenuItem>
            </Menu>
            <TextField
              sx={{
                position: "absolute",
                bottom: "0px",
                left: "0px",
                width: "96%",
                padding: "10px 10px 0px 10px", // Adjusted left padding
                background:
                  "linear-gradient(180deg, rgba(255, 255, 255, 0.8) 1.0%, #fff 20.00%)",
                height: "5rem",
                borderRadius: "0px 0px 2px 8px",
              }}
              fullWidth
              variant="outlined"
              autoComplete="off"
              size="small"
              placeholder="e.g. How do I start a loan application?"
              value={messageInput}
              onChange={(event) => setMessageInput(event.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {/* Hamburger icon */}
                    <IconButton onClick={handleMenuOpen}>
                      <MenuIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {/* Send button */}
                    <IconButton
                      variant="contained"
                      onClick={handleMainPromptSubmit}
                      type="submit"
                      disabled={messageInput === ""}
                    >
                      <Tooltip title="Send Message">
                        {/* Send Icon */}
                        <SendIcon
                          className="send-message-icon"
                          style={{ color: "#148F5B" }}
                        />
                      </Tooltip>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {/* Main Menu */}
          </div>
          {/* powered by section */}
          {/* <Stack
            direction="row"
            columnGap={1}
            sx={{ position: "absolute", bottom: "6px", left: "136px" }}
          >
            <Typography sx={{ fontWeight: "700" }}>Powered by</Typography>
            <a
              href="https://corestrat.ai/"
              target="_blank"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={CorestratLogo}
                alt="Corestrat"
                style={{ height: "18px" }}
              ></img>
            </a>
          </Stack> */}
        </Box>
      ) : (
        // if chat close
        <Box
          sx={{ position: "fixed", right: "14px", bottom: "5%", zIndex: 999 }}
        >
          <IconButton aria-label="Chat Window" onClick={openBotwithMessages}>
            <Tooltip title="Click Me">
              <Avatar
                alt="GenInsight.ai"
                src={genInsight}
                sx={{
                  padding: "10px 10px 10px 12px",
                  width: 60,
                  height: 60,
                  flexShrink: 0,
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
                  bgcolor: "#ffffff",
                }}
              />
            </Tooltip>
          </IconButton>
        </Box>
      )}{" "}
    </>
  );
}

export default GenInsight;
