// Header.js

import React from "react";
import { Grid, Typography } from "@mui/material";
import "./Header1Women.css";
import Divider from "@mui/material/Divider";
import { Widgets } from "@mui/icons-material";
import pic1 from "./../../Assets/customLogo.png";
import womenPic from "./../../Assets/internationalWomenSlim.png"

const Header1 = () => {
  const gridData = [
    {
      title: "Approved NBFC by",
      value: "RBI",
    },
    {
      title: "Years In Business",
      value: "10+",
    },
    {
      title: "Loans disbursed so far",
      value: "500 cr",
    },
    {
      title: "Customer Satisfaction",
      value: "99%",
    },
    // Add more items as needed
  ];
  return (
    <header className="header2">
        <div className="logo">
            {" "}
            <img className="logo-image" style={{ cursor: "pointer" }} src={pic1} />
        </div>
        <div className="upper-header-parent" >
            <div className="upper-header-women">
                <img className="women-image" src={womenPic} />
            </div>
            <div className="upper-header">
                <div className="title">
                Welcome to <b>Gosree Finance</b>
                </div>
                <div className="subtitle">
                <b>Exclusive Women's Day Offer</b>
                </div>
                <div className="subsubtitle">
                    First 100 Business Loans for Women at
                </div>
                <div className="subtitle"><b>9.9 % p.a.*</b></div>
                <div style={{ position:"absolute", bottom:"0", right:"0"}}> *T&C apply </div> 
            </div>
        </div>
      {/* <Grid container sx={{ mt: 2, mb: 2 }}>
        <Divider orientation="horizontal" flexItem className="header-divider" />
      </Grid> */}
      <div className="bottom-header">
        {gridData.map((item, index) => (
          <div key={index}>
            <div className="grid-item">
              <div className="grid-item-key">{item.title}</div>
              <div className="grid-item-value">
                <b>{item.value}</b>
              </div>
            </div>
          </div>
          
        ))}
      </div>
      
    </header>
  );
};

export default Header1;