import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { debounce } from 'lodash';
import Grid from "@mui/material/Grid";
import {
  GenerateLoanAgreement,
  ESign,
  ESignCheck,
  ESignCheckClearData,
  ESignClearData,
  GenerateLoanAgreementClearData,
  EStamp,
  loanUserDecision,
  Enach,
  EnachStatus,
  InitiateDebitFunction,
} from "../BasicDetails/BasicDetailsActions/BasicDetailsActions";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CircularProgressWithLabel from "../../Common/CircularProgressWithLabel";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import Alert from "@mui/material/Alert";
import { Document, Page, pdfjs } from "react-pdf";
import DownloadIcon from "@mui/icons-material/Download";
import { ESignDialogView } from "../../Components/BasicDetails/BasicDetailsActions/BasicDetailsActions";
import { TextField } from "@mui/material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import InitateDebit from "./InitateDebit";
import SessionModal from "./../../Common/SessionModel";
const DEBOUNCE_DELAY = 1000;
const steps = [
  {
    label: "Loan Agreement",
    description: ``,
  },
  {
    label: "eStamp & eSign",
    description: "",
  },
  {
    label: "eMandate",
    description: ``,
  },
  {
    label: "Confirm & Close",
    description: ``,
  },
];

export default function VerticalLinearStepper(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedLoanId = props.loanID;
  const [activeStep, setActiveStep] = React.useState(0);
  const [eSignInitatedFlag, seteSignInitatedFlag] = React.useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const esiginDialog = useSelector(
    (state) => state.BasicDetailsReducers.esiginDialog
  );
  const handleLogoutButton = () => {
    localStorage.removeItem("jwt_token");
    localStorage.removeItem("EmailId");
    localStorage.removeItem("branch");
    setShowAlert(true);
  };
  const handleCloseErrorModal1 = () => {
    setShowAlert(false);
    navigate("/login");
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // if (activeStep === 1) {
    //   navigate("/MyLoan");
    //   dispatch(ESignDialogView(!esiginDialog));
    //   dispatch(loanUserDecision(selectedLoanId, "APPROVED", ""));
    // }
    if (activeStep === 1) {
      // dispatch(ESignCheck());
    }
    if (activeStep === 2) {
      dispatch(EnachStatus(selectedLoanId, handleLogoutButton));
    }
    if (activeStep === 3) {
      navigate("/MyLoan");
      dispatch(ESignDialogView(!esiginDialog));
      // dispatch(loanUserDecision(selectedLoanId, "APPROVED", ""));
    }
  };

  const initiateDebitLoading = useSelector(
    (state) => state.BasicDetailsReducers.initiateDebitLoading
  );
  const initiateDebitSuccess = useSelector(
    (state) => state.BasicDetailsReducers.initiateDebitSuccess
  );
  const initiateDebitResponse = useSelector(
    (state) => state.BasicDetailsReducers.initiateDebitResponse
  );
  const initiateDebitFail = useSelector(
    (state) => state.BasicDetailsReducers.initiateDebitFail
  );

  const handleDebit = () => {
    dispatch(InitiateDebitFunction(selectedLoanId, handleLogoutButton));
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // if (activeStep === 2) {
    //   dispatch(ESignCheckClearData());
    // }
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleGenerateLoanAgreement = () => {
    // dispatch(GenerateLoanAgreement(selectedLoanId));
    dispatch(GenerateLoanAgreement(selectedLoanId));
  };

  const handleEsign = () => {
    if (generateLoanAgreementResponse) {
      if (generateLoanAgreementResponse.status == true) {
        localStorage.setItem(
          "file_name",
          generateLoanAgreementResponse.file_name
        );
        // dispatch(ESign(1908, generateLoanAgreementResponse.file_name));
        dispatch(
          EStamp(selectedLoanId, generateLoanAgreementResponse.file_name)
        );
      } else {
        alert("Loan agreement not found");
      }
    }
  };
  //Estamp - API Response
  const eStampLoading = useSelector(
    (state) => state.BasicDetailsReducers.eStampLoading
  );
  const eStampSuccess = useSelector(
    (state) => state.BasicDetailsReducers.eStampSuccess
  );
  const eStampFail = useSelector(
    (state) => state.BasicDetailsReducers.eStampFail
  );

  useEffect(() => {
    InitiateEsignCall();
  }, [eStampSuccess]);

  const InitiateEsignCall = () => {
    if (eStampSuccess === true) {
      seteSignInitatedFlag(!eSignInitatedFlag);
      dispatch(ESign(selectedLoanId, generateLoanAgreementResponse.file_name));
    }
  };

  //Generate Agreement API Response

  const generateLoanAgreementLoading = useSelector(
    (state) => state.BasicDetailsReducers.generateLoanAgreementLoading
  );

  const generateLoanAgreementSuccess = useSelector(
    (state) => state.BasicDetailsReducers.generateLoanAgreementSuccess
  );

  const generateLoanAgreementResponse = useSelector(
    (state) => state.BasicDetailsReducers.generateLoanAgreementResponse
  );


  const generateLoanAgreementFail = useSelector(
    (state) => state.BasicDetailsReducers.generateLoanAgreementFail
  );

  // useEffect(() => {
  //   initiateEsign();
  // }, [generateLoanAgreementResponse]);

  // const initiateEsign = () => {

  //   if(generateLoanAgreementResponse) {
  //         if(generateLoanAgreementResponse.status == true) {
  //           setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //           dispatch(ESign(1908,generateLoanAgreementResponse.file_name))
  //         }
  //   }
  // }

  //
  useEffect(() => {
    clearLocalStorage();
  }, []);

  const clearLocalStorage = () => {
    localStorage.removeItem("docket_id");
    localStorage.removeItem("document_id");
    localStorage.removeItem("file_name");
    dispatch(ESignCheckClearData());
    dispatch(ESignClearData());
    dispatch(GenerateLoanAgreementClearData());
  };

  const [pdfUrlView, setPdfUrlView] = React.useState();

  useEffect(() => {
    previewPDF();
  }, [generateLoanAgreementResponse]);

  const previewPDF = () => {
    if (generateLoanAgreementResponse) {
      if (generateLoanAgreementResponse.status == true) {
        const base64Content = generateLoanAgreementResponse.file_content;
        const base64Data = base64Content.split(",")[1];
        const pdfData = atob(base64Data);
        const uint8Array = new Uint8Array(pdfData.length);
        for (let i = 0; i < pdfData.length; i++) {
          uint8Array[i] = pdfData.charCodeAt(i);
        }
        const pdfBlob = new Blob([uint8Array], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfUrlView(pdfUrl);
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
      }
    }
  };

  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber((prevPage) => prevPage - 1);
    }
  };
  const debouncedNextPage = debounce(handleNextPage, DEBOUNCE_DELAY);
  const debouncedPrevPage = debounce(handlePrevPage, DEBOUNCE_DELAY);

  //-- Download In Web verison

  // const handleDownload = () => {
  //   fetch(pdfUrlView)
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       const url = URL.createObjectURL(blob);
  //       const a = document.createElement("a");
  //       a.href = url;
  //       a.download = "Loan_agreement_document.pdf"; // Change the filename if needed
  //       document.body.appendChild(a);
  //       a.click();
  //       document.body.removeChild(a);
  //       URL.revokeObjectURL(url);
  //     })
  //     .catch((error) => console.error("Error downloading PDF:", error));
  // };

  //--------------------------------------------------------------------------------------
  const handleDownload = () => {
    fetch(pdfUrlView)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Loan_agreement_document.pdf"; // Change the filename if needed
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch((error) => console.error("Error downloading PDF:", error));
  };

  //Esign API Response

  const eSignLoading = useSelector(
    (state) => state.BasicDetailsReducers.eSignLoading
  );
  const eSignResponse = useSelector(
    (state) => state.BasicDetailsReducers.eSignResponse
  );

  const eSignSuccess = useSelector(
    (state) => state.BasicDetailsReducers.eSignSuccess
  );

  const eSignFail = useSelector(
    (state) => state.BasicDetailsReducers.eSignFail
  );

  //Esign Check

  const eSignCheckLoading = useSelector(
    (state) => state.BasicDetailsReducers.eSignCheckLoading
  );

  const eSignCheckSuccess = useSelector(
    (state) => state.BasicDetailsReducers.eSignCheckSuccess
  );

  const eSignCheckResponse = useSelector(
    (state) => state.BasicDetailsReducers.eSignCheckResponse
  );

  useEffect(() => {
    saveEsignDetails();
  }, [eSignResponse]);

  const saveEsignDetails = () => {
    if (Object.keys(eSignResponse).length) {
      localStorage.setItem("docket_id", eSignResponse.docket_id);
      localStorage.setItem(
        "document_id",
        eSignResponse.signer_info[0].document_id
      );
    }
  };

  const eSignCheckFail = useSelector(
    (state) => state.BasicDetailsReducers.eSignCheckFail
  );

  //E Mandate
  //Auth -> it will be either a mobile number or email ->
  //Where user will get a link to initate the eMandate process

  const [auth, setAuth] = React.useState(localStorage.getItem("EmailId"));

  const handleAuthChange = (e) => {
    setAuth(e);
  };

  const handleEmandate = () => {
    dispatch(Enach(selectedLoanId, auth));
  };

  //E-Mandate/Enach - Initate API Response

  const eNachLoading = useSelector(
    (state) => state.BasicDetailsReducers.eNachLoading
  );

  const eNachResponse = useSelector(
    (state) => state.BasicDetailsReducers.eNachResponse
  );

  const eNachSuccess = useSelector(
    (state) => state.BasicDetailsReducers.eNachSuccess
  );

  const eNachFail = useSelector(
    (state) => state.BasicDetailsReducers.eNachFail
  );

  // Enach Status
  const eNachStatusLoading = useSelector(
    (state) => state.BasicDetailsReducers.eNachStatusLoading
  );
  const eNachStatusSuccess = useSelector(
    (state) => state.BasicDetailsReducers.eNachStatusSuccess
  );
  const eNachStatusResponse = useSelector(
    (state) => state.BasicDetailsReducers.eNachStatusResponse
  );
  const eNachStatusFail = useSelector(
    (state) => state.BasicDetailsReducers.eNachStatusFail
  );

  //--------------------- Status of e -For all id

  const statusLoading = useSelector(
    (state) => state.BasicDetailsReducers.statusLoading
  );
  const statusSuccess = useSelector(
    (state) => state.BasicDetailsReducers.statusSuccess
  );
  const statusResponse = useSelector(
    (state) => state.BasicDetailsReducers.statusResponse
  );
  const statusFail = useSelector(
    (state) => state.BasicDetailsReducers.statusFail
  );

  return (
    <>
      <div>
        {/* Your component content */}
        <SessionModal isOpen={showAlert} onClose={handleCloseErrorModal1} />
      </div>
      {statusLoading ? (
        <>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                m: 5,
                mt: 4,
                ml: 0,
              }}
            >
              <CircularProgressWithLabel />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center", ml: 5, ml: 0 }}
            >
              <Alert severity="info">
                Please hold for few seconds , we are fetching your details.
              </Alert>
            </Grid>
          </Grid>
        </>
      ) : null}

      {/*{statusResponse.state === "success" && !statusLoading ? (
        <>
          <InitateDebit />
        </>
      ) : null} */}
      {!statusLoading ? (
        <>
          <Grid container>
            <Grid item xs={12} md={6} sx={{ p: 5 }}>
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel
                      optional={
                        index === 3 ? (
                          <Typography variant="caption">Last step</Typography>
                        ) : null
                      }
                    >
                      {step.label}
                    </StepLabel>
                    <StepContent>
                      {/* Based on Active Step Content Will Change */}
                      {/* Active Stepm 0 ->  Generate Loan Agreement */}
                      {activeStep === 0 ? (
                        <>
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                m: 5,
                                ml: 0,
                                display: "flex",
                                justifyContent: "flex-start",
                                mt: 7,
                                mb: 3,
                              }}
                            >
                              <Button
                                onClick={handleGenerateLoanAgreement}
                                variant="outlined"
                                endIcon={<AssignmentIcon />}
                                sx={{ textTransform: "capitalize" }}
                              >
                                Generate Loan Agreement
                              </Button>
                            </Grid>
                            {generateLoanAgreementLoading ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    m: 5,
                                    mt: 1,
                                    ml: 0,
                                  }}
                                >
                                  <CircularProgressWithLabel />
                                </Grid>
                              </>
                            ) : null}
                            {generateLoanAgreementSuccess ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{ display: "flex", ml: 5, ml: 0 }}
                                >
                                  <Alert severity="success">
                                    Loan agreement is successfully generated.
                                  </Alert>
                                </Grid>
                              </>
                            ) : null}
                            {generateLoanAgreementFail ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{ display: "flex", ml: 5, ml: 0 }}
                                >
                                  <Alert severity="error">
                                    Sorry! Failed to generate loan agreement
                                  </Alert>
                                </Grid>
                              </>
                            ) : null}
                          </Grid>
                        </>
                      ) : null}

                      {/* Active Step 1 -> Esign And Estaamp */}
                      {activeStep === 1 ? (
                        <>
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                m: 5,
                                display: "flex",
                                justifyContent: "flex-start",
                                mt: 7,
                                mb: 3,
                                ml: 0,
                              }}
                            >
                              <Button
                                variant="outlined"
                                onClick={handleEsign}
                                disabled
                                endIcon={<DocumentScannerIcon />}
                                sx={{ textTransform: "capitalize" }}
                              >
                                initiate process
                              </Button>
                            </Grid>
                            <Grid item xs={12}>
                              <Alert severity="info">
                                Please skip the step for now and go next
                              </Alert>
                            </Grid>
                            {eSignInitatedFlag ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    m: 5,
                                    mt: 2,
                                    ml: 0,
                                  }}
                                >
                                  <Alert severity="info">
                                    To finalize the eSign procedure for your
                                    account, we have sent an email to your
                                    registered email address with all the
                                    necessary instructions and a link to
                                    complete the process. Kindly check your
                                    inbox to find the email from our team.
                                  </Alert>
                                </Grid>
                              </>
                            ) : null}
                            {eStampLoading ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    m: 5,
                                    mt: 1,
                                  }}
                                >
                                  <CircularProgressWithLabel />
                                </Grid>
                              </>
                            ) : null}
                            {eStampFail ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{ display: "flex", ml: 0 }}
                                >
                                  <Alert severity="error">
                                    Sorry! Failed to eStamp loan agreement
                                  </Alert>
                                </Grid>
                              </>
                            ) : null}
                          </Grid>
                        </>
                      ) : null}

                      {/* Active Step 1 -> Esign Confirmation */}
                      {activeStep === 2 ? (
                        <>
                          {eSignCheckLoading ? (
                            <>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  m: 5,
                                  mt: 1,
                                }}
                              >
                                <CircularProgressWithLabel />
                              </Grid>
                            </>
                          ) : null}
                          {/* {eSignCheckFail ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            m: 5,
                            mt: 2,
                            mb: 3,
                            ml: 0,
                          }}
                        >
                          <Alert severity="error">
                            Sorry! Kindly check your inbox to find the email
                            from our team and complete the eSign.
                          </Alert>
                        </Grid>
                      </>
                    ) : null} */}

                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              m: 5,
                              mt: 3,
                              mb: 2,
                              ml: 0,
                            }}
                          >
                            <Typography sx={{ fontWeight: 900 }}>
                              Kindly share your mobile number or email, in order
                              to proceed with your request!
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            spacing={2}
                            sx={{ display: "flex", mt: 2 }}
                          >
                            <Grid item xs={6} sx={{}}>
                              <TextField
                                type="text"
                                size="small"
                                name="auth"
                                fullWidth
                                disabled={true}
                                id="auth"
                                label="Mobile Number / Email Address"
                                value={auth}
                                onChange={(e) =>
                                  handleAuthChange(e.target.value)
                                }
                              ></TextField>
                            </Grid>
                            {statusResponse.error == "Mandate not found" ? (
                              <Grid item xs={6} sx={{}}>
                                <Button
                                  variant="outlined"
                                  onClick={handleEmandate}
                                  endIcon={<AccountBalanceIcon />}
                                  sx={{ textTransform: "capitalize", p: 0.87 }}
                                >
                                  Initiate Request
                                </Button>
                              </Grid>
                            ) : (
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  m: 5,
                                  mt: 3,
                                  mb: 2,
                                  ml: 0,
                                }}
                              >
                                <Alert severity="info">
                                  {statusResponse.error}
                                </Alert>
                              </Grid>
                            )}
                          </Grid>

                          {eNachLoading ? (
                            <>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  m: 5,
                                  mt: 3,
                                  ml: 0,
                                }}
                              >
                                <CircularProgressWithLabel />
                              </Grid>
                            </>
                          ) : null}
                          {eNachFail ? (
                            <>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  m: 5,
                                  mt: 2,
                                  mb: 3,
                                  ml: 0,
                                }}
                              >
                                <Alert severity="error">
                                  Sorry! Failed to process the request!
                                </Alert>
                              </Grid>
                            </>
                          ) : null}
                          {eNachSuccess ? (
                            <>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  m: 5,
                                  mt: 2,
                                  mb: 3,
                                  ml: 0,
                                }}
                              >
                                <Alert severity="info">
                                  {eNachResponse
                                    ? eNachResponse.message
                                    : "Sorry! Failed to process the request!"}
                                </Alert>
                              </Grid>
                            </>
                          ) : null}

                          {/* {eSignCheckSuccess ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            m: 5,
                            mt: 3,
                            mb: 2,
                            ml: 0,
                          }}
                        >
                          <Alert severity="success">
                            Thank you! All the steps are completed.
                          </Alert>
                        </Grid>
                      </>
                    ) : null} */}
                   
                          <Grid container>
                            <Grid
                            item
                            xs={12}
                            sx={{
                              m: 5,
                              ml: 0,
                              display: "flex",
                              justifyContent: "flex-start",
                              mt: 7,
                              mb: 3,
                            }}
                            >
                            <Button
                              onClick={handleDebit}
                              disabled={initiateDebitSuccess || !statusResponse.initiate_mandate || statusResponse.state !== "success"}
                              variant="outlined"
                              endIcon={<AssignmentIcon />}
                              sx={{ textTransform: "capitalize" }}
                            >
                              Initiate Debit
                            </Button>
                          </Grid>
                        </Grid>
                        {initiateDebitLoading ? (
                          <>
                            <Grid
                            item
                            xs={12}
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              m: 5,
                              mt: 1,
                              ml: 0,
                            }}
                            >
                              <CircularProgressWithLabel />
                            </Grid>
                          </>
                        ) : null}
                        {initiateDebitSuccess ? (
                          <>
                            <Grid
                            item
                            xs={12}
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              m: 5,
                              mt: 2,
                              mb: 3,
                              ml: 0,
                            }}
                            >
                            <Alert severity="info">
                              Great! All steps completed.
                            </Alert>
                            </Grid>
                          </>
                        ) : null}
                        {initiateDebitFail ? (
                          <>
                            <Grid
                            item
                            xs={12}
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              m: 5,
                              mt: 2,
                              mb: 3,
                              ml: 0,
                            }}
                            >
                            <Alert severity="info">
                              Sorry! Request hasn't been initiated. Try again
                            </Alert>
                            </Grid>
                          </>
                        ) : null}
                 
                        </>
                      ) : null}
                      {activeStep === 3 ? (
                        <>
                          {eNachStatusLoading ? (
                            <>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  m: 5,
                                  mt: 1,
                                  ml: 0,
                                }}
                              >
                                <CircularProgressWithLabel />
                              </Grid>
                            </>
                          ) : null}
                          {eNachStatusFail ? (
                            <>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  m: 5,
                                  mt: 2,
                                  mb: 3,
                                  ml: 0,
                                }}
                              >
                                <Alert severity="error">
                                  Sorry! Failed to confirm the eMandate details!
                                </Alert>
                              </Grid>
                            </>
                          ) : null}
                          {eNachStatusSuccess ? (
                            <>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  m: 5,
                                  mt: 2,
                                  mb: 3,
                                  ml: 0,
                                }}
                              >
                                <Alert severity="info">
                                  {eNachStatusResponse
                                    ? eNachStatusResponse.error
                                    : ""}
                                </Alert>
                              </Grid>
                            </>
                          ) : null}
                        </>
                      ) : null}

                      <Box sx={{ mb: 2 }}>
                        <Grid container>
                          <Grid item xs={12} sx={{ m: 5, ml: 1, mr: 1 }}>
                            <Button
                              variant="contained"
                              onClick={handleNext}
                              disabled={
                                Object.keys(generateLoanAgreementResponse)
                                  .length === 0
                              }
                              sx={{ mt: 1, mr: 1, textTransform: "capitalize" }}
                            >
                              {index === steps.length - 1
                                ? "Finish"
                                : "Continue"}
                            </Button>
                            <Button
                              disabled={index === 0}
                              onClick={handleBack}
                              sx={{ mt: 1, mr: 1, textTransform: "capitalize" }}
                            >
                              Back
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Grid>
            {Object.keys(generateLoanAgreementResponse).length &&
            activeStep === 0 ? (
              <>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ border: "1px solid #000000", mt: 5 }}
                >
                  <div
                    style={{
                      padding: "10px",
                      height: "550px",
                      overflow: "auto",
                    }}
                  >
                    <div style={{ height: "500px" }}>
                      <Document
                        file={pdfUrlView}
                        onLoadSuccess={onDocumentLoadSuccess}
                        options={{ workerSrc: "/pdf.worker.js" }}
                      >
                        <Page pageNumber={pageNumber} />
                      </Document>
                    </div>
                    {numPages && (
                      <p>
                        Page {pageNumber} of {numPages}
                      </p>
                    )}
                  </div>
                  {numPages && (
                    <Grid container>
                      <Grid item xs={12} md={9}>
                        <div>
                          <Button
                            sx={{ textTransform: "capitalize" }}
                            onClick={debouncedPrevPage}
                            disabled={pageNumber === 1}
                          >
                            Previous Page
                          </Button>
                          <Button
                            sx={{ textTransform: "capitalize" }}
                            onClick={debouncedNextPage}
                            disabled={pageNumber === numPages}
                          >
                            Next Page
                          </Button>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Button
                          endIcon={<DownloadIcon />}
                          onClick={handleDownload}
                          sx={{ textTransform: "capitalize" }}
                        >
                          Download Agreement
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </>
            ) : null}
          </Grid>
        </>
      ) : null}
    </>
  );
}
