export const initialData = {
  data: {
    code: 0,
    data: {
      address: {
        careOf: "",
        country: "",
        district: "",
        house: "",
        landmark: "",
        locality: "",
        pin: "",
        postOffice: "",
        state: "",
        street: "",
        subDistrict: "",
        vtc: "",
      },
      dateOfBirth: "",
      email: "",
      gender: "",
      generatedAt: "",
      maskedNumber: "",
      name: "",
      phone: "",
      photo: "",
    },
    message: "",
    timestamp: "",
    transaction_id: "",
  },
  save_status: "success",
  type: "success",
};
  export const gender = [
    {
      label: "Male",
      value: "M",
    },
    {
      label: "Female",
      value: "F",
    },
];
  export const states = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Lakshadweep",
    "Delhi",
    "Puducherry",
  ];
