import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { getRepaymentSchedule1 } from "../../Components/BasicDetails/BasicDetailsActions/BasicDetailsActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Tooltip,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SessionModal from "./../../Common/SessionModel";
import { useNavigate } from "react-router-dom";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#148F5B",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function CustomizedTables({ onPopupFunction }) {
  const dialogStyle = {
    width: "90vw !important", // Set the width to 80%
    height: "90vh !important", // Set the height to 80vh
    maxWidth: "90vw !important",
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get("loanID");
  const myloanLoading = useSelector(
    (state) => state.BasicDetailsReducers.myloanLoading
  );

  const myloanResponse = useSelector(
    (state) => state.BasicDetailsReducers.myloanResponse
  );

  const getLoanDetailsResponse = useSelector(
    (state) => state.BasicDetailsReducers.getLoanDetailsResponse
  );
  const getRepaymentSchedule = useSelector(
    (state) => state.BasicDetailsReducers.getRepaymentScheduleSuccess
  );
  const handleLogoutButton = () => {
    localStorage.removeItem("jwt_token");
    localStorage.removeItem("EmailId");
    localStorage.removeItem("branch");
    setShowAlert(true);
  };
  const handleCloseErrorModal1 = () => {
    setShowAlert(false);
    navigate("/login");
  };
  useEffect(() => {
    if (code != null) {
      if (getLoanDetailsResponse.length != 0) {
        dispatch(
          getRepaymentSchedule1(
            getLoanDetailsResponse[0].Loan_Application_id,
            getLoanDetailsResponse[0].Sanctioned_amount,
            getLoanDetailsResponse[0].interest_rate,
            getLoanDetailsResponse[0].loan_tenure,
            handleLogoutButton
          )
        );
      }
    } else {
      // dispatch(GetLoanDetails());
    }
  }, [getLoanDetailsResponse]);
  return (
    <div>
      <div>
        {/* Your component content */}
        <SessionModal isOpen={showAlert} onClose={handleCloseErrorModal1} />
      </div>
      <Dialog
        open={true}
        onClose={onPopupFunction}
        PaperProps={{ sx: dialogStyle }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "30px",
            color: "#148F5B",
            cursor: "default",
          }}
        >
          Repayment Schedule
          <Tooltip title="Close">
            <Button
              variant="contained"
              sx={{ backgroundColor: "#148F5B" }}
              onClick={onPopupFunction}
            >
              <CloseOutlinedIcon />
            </Button>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          {/* Upload Button */}
          <Grid
            container
            justifyContent="flex-end"
            sx={{ mt: 3.2, mb: 2 }}
            gap={2}
          ></Grid>

          {/* DataGrid */}
          <div style={{ height: 500, width: "100%" }}>
            <div>
              <TableContainer component={Paper}>
                {getRepaymentSchedule.length >= 0 &&
                getRepaymentSchedule != undefined ? (
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          sx={{ fontWeight: 900 }}
                          align="center"
                        >
                          Payment Number
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ fontWeight: 900 }}
                          align="center"
                        >
                          Payment Date
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ fontWeight: 900 }}
                          align="center"
                        >
                          EMI Payment
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ fontWeight: 900 }}
                          align="center"
                        >
                          Principal
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ fontWeight: 900 }}
                          align="center"
                        >
                          Interest
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{ fontWeight: 900 }}
                          align="center"
                        >
                          Closing Principal Balance
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    {getRepaymentSchedule.length >= 0 &&
                    getRepaymentSchedule != undefined ? (
                      <TableBody sx={{ p: 2 }}>
                        {getRepaymentSchedule.map((Details) => (
                          <StyledTableRow sx={{ p: 2 }}>
                            <StyledTableCell
                              sx={{ fontWeight: 900 }}
                              align="center"
                            >
                              {Details?.Payment_Number}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ fontWeight: 900 }}
                              align="center"
                            >
                              {Details?.Payment_Date}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ fontWeight: 900 }}
                              align="center"
                            >
                              {Details?.EMI_Payment?.toLocaleString("en-IN", {
                                style: "currency",
                                currency: "INR",
                              })}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ fontWeight: 900 }}
                              align="center"
                            >
                              {Details?.Principal?.toLocaleString("en-IN", {
                                style: "currency",
                                currency: "INR",
                              })}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ fontWeight: 900 }}
                              align="center"
                            >
                              {Details?.Interest?.toLocaleString("en-IN", {
                                style: "currency",
                                currency: "INR",
                              })}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ fontWeight: 900 }}
                              align="center"
                            >
                              {Details?.Closing_Principal_Balance?.toLocaleString(
                                "en-IN",
                                {
                                  style: "currency",
                                  currency: "INR",
                                }
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    ) : null}
                  </Table>
                ) : null}
              </TableContainer>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
    // <BootstrapDialog
    //   className="repaymentCSS"
    //   onClose={onPopupFunction}
    //   aria-labelledby="customized-dialog-title"
    //   open={open1}
    // >
    //   <BootstrapDialogTitle
    //     id="customized-dialog-title"
    //     onClose={onPopupFunction}
    //     style={{
    //       fontWeight: "800",
    //       color: "#148F5B",
    //     }}
    //   >
    //     Repayment Schedule
    //   </BootstrapDialogTitle>

    //   <DialogContent>
    //     <div>
    //       <TableContainer component={Paper}>
    //         {getRepaymentSchedule.length >= 0 &&
    //         getRepaymentSchedule != undefined ? (
    //           <Table sx={{ minWidth: 700 }} aria-label="customized table">
    //             <TableHead>
    //               <TableRow>
    //                 <StyledTableCell sx={{ fontWeight: 900 }} align="center">
    //                   Payment Number
    //                 </StyledTableCell>
    //                 <StyledTableCell sx={{ fontWeight: 900 }} align="center">
    //                   Payment Date
    //                 </StyledTableCell>
    //                 <StyledTableCell sx={{ fontWeight: 900 }} align="center">
    //                   EMI Payment
    //                 </StyledTableCell>
    //                 <StyledTableCell sx={{ fontWeight: 900 }} align="center">
    //                   Principal
    //                 </StyledTableCell>
    //                 <StyledTableCell sx={{ fontWeight: 900 }} align="center">
    //                   Interest
    //                 </StyledTableCell>
    //                 <StyledTableCell sx={{ fontWeight: 900 }} align="center">
    //                   Closing Principal Balance
    //                 </StyledTableCell>
    //               </TableRow>
    //             </TableHead>
    //             {getRepaymentSchedule.length >= 0 &&
    //             getRepaymentSchedule != undefined ? (
    //               <TableBody sx={{ p: 2 }}>
    //                 {getRepaymentSchedule.map((Details) => (
    //                   <StyledTableRow sx={{ p: 2 }}>
    //                     <StyledTableCell
    //                       sx={{ fontWeight: 900 }}
    //                       align="center"
    //                     >
    //                       {Details?.Payment_Number}
    //                     </StyledTableCell>
    //                     <StyledTableCell
    //                       sx={{ fontWeight: 900 }}
    //                       align="center"
    //                     >
    //                       {Details?.Payment_Date}
    //                     </StyledTableCell>
    //                     <StyledTableCell
    //                       sx={{ fontWeight: 900 }}
    //                       align="center"
    //                     >
    //                       {Details?.EMI_Payment}
    //                     </StyledTableCell>
    //                     <StyledTableCell
    //                       sx={{ fontWeight: 900 }}
    //                       align="center"
    //                     >
    //                       {Details?.Principal}
    //                     </StyledTableCell>
    //                     <StyledTableCell
    //                       sx={{ fontWeight: 900 }}
    //                       align="center"
    //                     >
    //                       {Details?.Interest}
    //                     </StyledTableCell>
    //                     <StyledTableCell
    //                       sx={{ fontWeight: 900 }}
    //                       align="center"
    //                     >
    //                       {Details?.Closing_Principal_Balance}
    //                     </StyledTableCell>
    //                   </StyledTableRow>
    //                 ))}
    //               </TableBody>
    //             ) : null}
    //           </Table>
    //         ) : null}
    //       </TableContainer>
    //     </div>
    //   </DialogContent>
    // </BootstrapDialog>
  );
}
