import TYPES from "../BasicDetailsTypes/BasicDetailsTypes";
const initialState = {
  aadharDrawer: false,
  aadharDrawerCoborrower: false,
  safLoading: null,
  safSuccess: null,
  safResponse: [],
  safFail: null,
  panDrawer: false,

  companyDetailsLoading: null,
  companyDetailsSuccess: [],
  companyDetailsFail: null,

  saveDetailsLoading: null,
  saveDetailsSuccess: [],
  saveDetailsFail: null,

  initiateSessionAadharLoading: null,
  initiateSessionAadharSuccess: [],
  initiateSessionAadharFail: null,

  genOTPLoading: null,
  genOTPSuccess: [],
  genOTPFail: null,

  verifyOTPLoading: null,
  verifyOTPSuccess: [],
  verifyOTPFail: null,

  saveAadharLoading: null,
  saveAadharSuccess: [],
  saveAadharFail: null,

  confirmDailog: false,

  stepCount: 0,

  uploadDocumentLoading: null,
  uploadDocumentSuccess: null,
  uploadDocumentResponse: null,
  uploadDocumentFail: null,

  uploadDailog: false,

  getLoanDetailsLoading: null,
  getLoanDetailsSuccess: null,
  getLoanDetailsResponse: [],
  getLoanDetailsFail: null,

  loanConfirmLoading: null,
  loanConfirmSuccess: null,
  loanConfirmResponse: [],
  loanConfirmFail: null,

  breLoading: null,
  breSuccess: null,
  breResponse: [],
  breFail: null,

  myloanLoading: null,
  myloanSuccess: null,
  myloanResponse: [],
  myloanFail: null,

  guarantorDetailsLoading: null,
  guarantorDetailsSuccess: [],
  guarantorDetailsFail: null,

  getRepaymentScheduleLoading: null,
  getRepaymentScheduleSuccess: [],
  getRepaymentScheduleFail: null,

  companyDataLoaded: false,

  employmentType: 0,

  esiginDialog: false,

  generateLoanAgreementLoading: null,
  generateLoanAgreementSuccess: null,
  generateLoanAgreementResponse: [],
  generateLoanAgreementFail: null,

  eSignLoading: null,
  eSignSuccess: null,
  eSignResponse: [],
  eSignFail: null,

  eStampLoading: null,
  eStampSuccess: null,
  eStampResponse: [],
  eStampFail: null,

  eSignCheckLoading: null,
  eSignCheckSuccess: null,
  eSignCheckResponse: [],
  eSignCheckFail: null,

  eNachLoading: null,
  eNachSuccess: null,
  eNachResponse: [],
  eNachFail: null,

  eNachStatusLoading: null,
  eNachStatusSuccess: null,
  eNachStatusResponse: [],
  eNachStatusFail: null,

  initiateDebitLoading: null,
  initiateDebitSuccess: null,
  initiateDebitResponse: [],
  initiateDebitFail: null,

  generateLoanApplicationLoading: null,
  generateLoanApplicationSuccess: null,
  generateLoanApplicationResponse: [],
  generateLoanApplicationFail: null,

  generateKfsDocumentLoading: null,
  generateKfsDocumentSuccess: null,
  generateKfsDocumentResponse: [],
  generateKfsDocumentFail: null,

  getSanctionLetterLoading: null,
  getSanctionLetterSuccess: null,
  getSanctionLetterResponse: [],
  getSanctionLetterFail: null,

  saveSanctionLetterLoading: null,
  saveSanctionLetterSuccess: null,
  saveSanctionLetterResponse: [],
  saveSanctionLetterFail: null,

  getSanctionLetterFlagLoading: null,
  getSanctionLetterFlagSuccess: null,
  getSanctionLetterFlagResponse: [],
  getSanctionLetterFlagFail: null,

  statusLoading: null,
  statusSuccess: null,
  statusResponse: [],
  statusFail: null,

  eSignAgreementLoading: null,
  eSignAgreementSuccess: null,
  eSignAgreementResponse: [],
  eSignAgreementFail: null,

  eSignInitiateAgreementLoading: null,
  eSignInitiateAgreementSuccess: null,
  eSignInitiateAgreementResponse: [],
  eSignInitiateAgreementFail: null,

  uploadDialog: false,

  tenure: [],
  maritalStatus: [],
  branchname: [],
  channelname: [],
  bankNames: [],

  loanApplicationFormData: [],
  loanSanctionLetterData: [],
  loanAgreementLetterData: [],
  kfsDocumentFormData: [],

  loanAgreementStartDate: null,

  userQueryLoading: null,
  userQuerySuccess: null,
  userQueryResponse: [],
  userQueryFail: null,
  userQueryError: null,

  chatMessagesLoading: null,
  chatMessagesSuccess: null,
  chatMessagesResponse: [],
  chatMessagesFail: null,
  chatMessagesError: null,

  verifyOTPMobileLoading: null,
  verifyOTPMobileSuccess: [],
  verifyOTPMobileFail: null,

  verifyPanSuccess: false,
  panDetails: {},
  verifyPanFail: false,

  verifykycSuccess: [],
  verifykycFail: false,

  navigateFromDigitalLoans: false,

  verifyOTPEmailLoading: null,
  verifyOTPEmailSuccess: [],
  verifyOTPEmailFail: null,

  verifyOTPCoborrowerLoading: null,
  verifyOTPCoborrowerSuccess: [],
  verifyOTPCoborrowerFail: null,

  verifyPanSuccessCoborrower: false,
  panDetailsCoborrower: [],
  verifyPanFailCoborrower: false,


  QuickApplyDetailsSuccess: [],
  QuickApplyDetailsFail: null,
  QuickApplyDetailsLoading: null,

  QuickApplySuccess: [],
  QuickApplyFail: null,
  QuickApplyLoading: null,

  verifyOTPEmailCoborrowerLoading: null,
  verifyOTPEmailCoborrowerSuccess: [],
  verifyOTPEmailCoborrowerFail: null,

  verifyOTPMobileDigitalLoansLoading: null,
  verifyOTPMobileDigitalLoansSuccess: [],
  verifyOTPMobileDigitalLoansFail: null,

  KfsDocumentLoading: null,
  KfsDocumentSuccess: null,
  KfsDocumentResponse: [],
  KfsDocumentFail: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TYPES.AADHAR_DRAWER:
      return {
        ...state,
        aadharDrawer: action.payload,
      };
    case TYPES.PAN_DRAWER:
      return {
        ...state,
        panDrawer: action.payload,
      };
    case TYPES.SAF_LOADING:
      return {
        ...state,
        safLoading: true,
        ssafSuccess: false,
        safResponse: [],
        safFail: false,
      };
    case TYPES.SAF_SUCCESS:
      return {
        ...state,
        safLoading: false,
        safSuccess: true,
        safResponse: action.payload,
        safFail: false,
      };
    case TYPES.SAF_FAIL:
      return {
        ...state,
        safLoading: false,
        safSuccess: false,
        safResponse: [],
        safFail: true,
      };
    case TYPES.SAF_CLEAR_DATA:
      return {
        ...state,
        safLoading: null,
        safSuccess: null,
        safResponse: [],
        safFail: null,
      };
    case TYPES.COMPANY_DETAILS_LOADING:
      return {
        ...state,
        companyDetailsLoading: true,
        companyDetailsSuccess: [],
        companyDetailsFail: false,
      };

    case TYPES.COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        companyDetailsLoading: false,
        companyDetailsSuccess: action.payload,
        companyDetailsFail: false,
      };
    case TYPES.COMPANY_DETAILS_FAIL:
      return {
        ...state,
        companyDetailsLoading: false,
        companyDetailsSuccess: [],
        companyDetailsFail: true,
      };
    case TYPES.COMPANY_DETAILS_CLEAR_DATA:
      return {
        ...state,
        companyDetailsLoading: null,
        companyDetailsSuccess: [],
        companyDetailsFail: null,
      };
    case TYPES.SAVE_DETAILS_LOADING:
      return {
        ...state,
        saveDetailsLoading: true,
        saveDetailsSuccess: [],
        saveDetailsFail: false,
      };
    case TYPES.SAVE_DETAILS_SUCCESS:
      return {
        ...state,
        saveDetailsLoading: false,
        saveDetailsSuccess: action.payload,
        saveDetailsFail: false,
      };
    case TYPES.SAVE_DETAILS_FAIL:
      return {
        ...state,
        saveDetailsLoading: false,
        saveDetailsSuccess: [],
        saveDetailsFail: true,
      };
    case TYPES.SAVE_DETAILS_CLEAR_DATA:
      return {
        ...state,
        saveDetailsLoading: null,
        saveDetailsSuccess: [],
        saveDetailsFail: null,
      };
    case TYPES.INITIATE_SESSION_AADHAR_LOADING:
      return {
        ...state,
        initiateSessionAadharLoading: true,
        initiateSessionAadharSuccess: [],
        initiateSessionAadharFail: false,
      };
    case TYPES.INITIATE_SESSION_AADHAR_SUCCESS:
      return {
        ...state,
        initiateSessionAadharLoading: false,
        initiateSessionAadharSuccess: action.payload,
        initiateSessionAadharFail: false,
      };
    case TYPES.INITIATE_SESSION_AADHAR_FAIL:
      return {
        ...state,
        initiateSessionAadharLoading: false,
        initiateSessionAadharSuccess: [],
        initiateSessionAadharFail: true,
      };
    case TYPES.INITIATE_SESSION_AADHAR_CLEAR_DATA:
      return {
        ...state,
        initiateSessionAadharLoading: false,
        initiateSessionAadharSuccess: [],
        initiateSessionAadharFail: false,
      };
    case TYPES.GEN_OTP_LOADING:
      return {
        ...state,
        genOTPLoading: true,
        genOTPSuccess: [],
        genOTPFail: false,
      };
    case TYPES.GEN_OTP_SUCCESS:
      return {
        ...state,
        genOTPLoading: false,
        genOTPSuccess: action.payload,
        genOTPFail: false,
      };
    case TYPES.GEN_OTP_FAIL:
      return {
        ...state,
        genOTPLoading: false,
        genOTPSuccess: [],
        genOTPFail: true,
      };
    case TYPES.GEN_OTP_CLEAR_DATA:
      return {
        ...state,
        genOTPLoading: null,
        genOTPSuccess: [],
        genOTPFail: null,
      };
    case TYPES.VERIFY_OTP_LOADING:
      return {
        ...state,
        verifyOTPLoading: true,
        verifyOTPSuccess: [],
        verifyOTPFail: false,
      };
    case TYPES.VERIFY_OTP_SUCCESS:
      return {
        ...state,
        verifyOTPLoading: false,
        verifyOTPSuccess: action.payload,
        verifyOTPFail: false,
      };
    case TYPES.VERIFY_OTP_FAIL:
      return {
        ...state,
        verifyOTPLoading: false,
        verifyOTPSuccess: [],
        verifyOTPFail: true,
      };
    case TYPES.VERIFY_OTP_CLEAR_DATA:
      return {
        ...state,
        verifyOTPLoading: null,
        verifyOTPSuccess: [],
        verifyOTPFail: null,
      };
    case TYPES.SAVE_AADHAR_LOADING:
      return {
        ...state,
        saveAadharLoading: true,
        saveAadharSuccess: [],
        saveAadharFail: false,
      };
    case TYPES.SAVE_AADHAR_SUCCESS:
      return {
        ...state,
        saveAadharLoading: false,
        saveAadharSuccess: action.payload,
        saveAadharFail: false,
      };
    case TYPES.SAVE_AADHAR_FAIL:
      return {
        ...state,
        saveAadharLoading: false,
        saveAadharSuccess: [],
        saveAadharFail: true,
      };
    case TYPES.SAVE_AADHAR_CLEAR_DATA:
      return {
        ...state,
        saveAadharLoading: null,
        saveAadharSuccess: [],
        saveAadharFail: null,
        };
    case TYPES.CONFIRM_DIALOG:
      return {
        ...state,
        confirmDailog: action.payload,
      };
    case TYPES.TOPBAR_VIEW_STEP:
      return {
        ...state,
        stepCount: action.payload,
      };
    case TYPES.UPLOAD_DOCUMENT_LOADING:
      return {
        ...state,
        uploadDocumentLoading: true,
        uploadDocumentSuccess: false,
        uploadDocumentFail: false,
      };
    case TYPES.UPLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        uploadDocumentLoading: false,
        uploadDocumentSuccess: true,
        uploadDocumentResponse: action.payload,
        uploadDocumentFail: false,
      };
    case TYPES.UPLOAD_DOCUMENT_FAIL:
      return {
        ...state,
        uploadDocumentLoading: false,
        uploadDocumentSuccess: false,
        uploadDocumentFail: true,
      };
    case TYPES.UPLOAD_DOCUMENT_CLEAR_DATA:
      return {
        ...state,
        uploadDocumentLoading: null,
        uploadDocumentSuccess: null,
        uploadDocumentResponse: null,
        uploadDocumentFail: null,
      };
    case TYPES.GET_LOAN_DETAILS_LOADING:
      return {
        ...state,
        getLoanDetailsLoading: true,
        getLoanDetailsSuccess: false,
        getLoanDetailsResponse: [],
        getLoanDetailsFail: false,
      };
    case TYPES.GET_LOAN_DETAILS_SUCCESS:
      return {
        ...state,
        getLoanDetailsLoading: false,
        getLoanDetailsSuccess: true,
        getLoanDetailsResponse: action.payload,
        getLoanDetailsFail: false,
      };
    case TYPES.GET_LOAN_DETAILS_FAIL:
      return {
        ...state,
        getLoanDetailsLoading: false,
        getLoanDetailsSuccess: false,
        getLoanDetailsResponse: [],
        getLoanDetailsFail: true,
      };
    case TYPES.GET_LOAN_DETAILS_CLEAR_DATA:
      return {
        ...state,
        getLoanDetailsLoading: null,
        getLoanDetailsSuccess: null,
        getLoanDetailsResponse: [],
        getLoanDetailsFail: null,
      };
    case TYPES.UPLOAD_DIALOG:
      return {
        ...state,
        uploadDailog: action.payload,
      };
    case TYPES.LOAN_CONFIRM_LOADING:
      return {
        ...state,
        loanConfirmLoading: true,
        loanConfirmSuccess: false,
        loanConfirmResponse: [],
        loanConfirmFail: false,
      };
    case TYPES.LOAN_CONFIRM_SUCCESS:
      return {
        ...state,
        loanConfirmLoading: false,
        loanConfirmSuccess: true,
        loanConfirmResponse: action.payload,
        loanConfirmFail: false,
      };
    case TYPES.LOAN_CONFIRM_FAIL:
      return {
        ...state,
        loanConfirmLoading: false,
        loanConfirmSuccess: false,
        loanConfirmResponse: [],
        loanConfirmFail: true,
      };
    case TYPES.LOAN_CONFIRM_CLEAR_DATA:
      return {
        ...state,
        loanConfirmLoading: null,
        loanConfirmSuccess: null,
        loanConfirmResponse: [],
        loanConfirmFail: null,
      };
    case TYPES.BRE_LOADING:
      return {
        ...state,
        breLoading: true,
        breSuccess: false,
        breResponse: [],
        breFail: false,
      };
    case TYPES.BRE_SUCCESS:
      return {
        ...state,
        breLoading: false,
        breSuccess: true,
        breResponse: action.payload,
        breFail: false,
      };
    case TYPES.BRE_FAIL:
      return {
        ...state,
        breLoading: false,
        breSuccess: false,
        breResponse: [],
        breFail: true,
      };
    case TYPES.BRE_CLEAR_DATA:
      return {
        ...state,
        breLoading: null,
        breSuccess: null,
        breResponse: [],
        breFail: null,
      };
    case TYPES.MY_LOAN_LOADING:
      return {
        ...state,
        myloanLoading: true,
        myloanSuccess: false,
        myloanResponse: [],
        myloanFail: false,
      };
    case TYPES.MY_LOAN_SUCCESS:
      return {
        ...state,
        myloanLoading: false,
        myloanSuccess: true,
        myloanResponse: action.payload,
        myloanFail: false,
      };
    case TYPES.MY_LOAN_FAIL:
      return {
        ...state,
        myloanLoading: false,
        myloanSuccess: false,
        myloanResponse: [],
        myloanFail: true,
      };
    case TYPES.MY_LOAN_CLEAR_DATA:
      return {
        ...state,
        myloanLoading: null,
        myloanSuccess: null,
        myloanResponse: [],
        myloanFail: null,
      };
    case TYPES.GUARANTOR_DETAILS_LOADING:
      return {
        ...state,
        guarantorDetailsLoading: true,
        guarantorDetailsSuccess: [],
        guarantorDetailsFail: false,
      };

    case TYPES.GUARANTOR_DETAILS_SUCCESS:
      return {
        ...state,
        guarantorDetailsLoading: false,
        guarantorDetailsSuccess: action.payload,
        guarantorDetailsFail: false,
      };
    case TYPES.GUARANTOR_DETAILS_FAIL:
      return {
        ...state,
        guarantorDetailsLoading: false,
        guarantorDetailsSuccess: [],
        guarantorDetailsFail: true,
      };
    case TYPES.REPAYMENT_DETAILS_LOADING:
      return {
        ...state,
        getRepaymentScheduleLoading: true,
        getRepaymentScheduleSuccess: [],
        getRepaymentScheduleFail: false,
      };

    case TYPES.REPAYMENT_DETAILS_SUCCESS:
      return {
        ...state,
        getRepaymentScheduleLoading: false,
        getRepaymentScheduleSuccess: action.payload,
        getRepaymentScheduleFail: false,
      };
    case TYPES.REPAYMENT_DETAILS_FAIL:
      return {
        ...state,
        getRepaymentScheduleLoading: false,
        getRepaymentScheduleSuccess: [],
        getRepaymentScheduleFail: true,
      };
    case TYPES.COMPANY_DATA_LOADED:
      return {
        companyDataLoaded: true,
      };
    case TYPES.IDENTIFY_EMPLOYMENT_TYPE:
      return {
        ...state,
        employmentType: action.payload,
      };
    case TYPES.IDENTIFY_LOANPRODUCT_TYPE:
      return {
        ...state,
        loanProductType: action.payload,
      };
    case TYPES.ESIGN_DIALOG:
      return {
        ...state,
        esiginDialog: action.payload,
      };
    case TYPES.GENERATE_LOAN_AGREEMENT_LOADING:
      return {
        ...state,
        generateLoanAgreementLoading: true,
        generateLoanAgreementSuccess: false,
        generateLoanAgreementResponse: [],
        generateLoanAgreementFail: false,
      };
    case TYPES.GENERATE_LOAN_AGREEMENT_SUCCESS:
      return {
        ...state,
        generateLoanAgreementLoading: false,
        generateLoanAgreementSuccess: true,
        generateLoanAgreementResponse: action.payload,
        generateLoanAgreementFail: false,
      };
    case TYPES.GENERATE_LOAN_AGREEMENT_FAIL:
      return {
        ...state,
        generateLoanAgreementLoading: false,
        generateLoanAgreementSuccess: false,
        generateLoanAgreementResponse: [],
        generateLoanAgreementFail: true,
      };
    case TYPES.GENERATE_LOAN_AGREEMENT_CLEAR_DATA:
      return {
        ...state,
        generateLoanAgreementLoading: null,
        generateLoanAgreementSuccess: null,
        generateLoanAgreementResponse: [],
        generateLoanAgreementFail: null,
      };
    case TYPES.ESIGN_LOADING:
      return {
        ...state,
        eSignLoading: true,
        eSignSuccess: false,
        eSignResponse: [],
        eSignFail: false,
      };
    case TYPES.ESIGN_SUCCESS:
      return {
        ...state,
        eSignLoading: false,
        eSignSuccess: true,
        eSignResponse: action.payload,
        eSignFail: false,
      };
    case TYPES.ESIGN_FAIL:
      return {
        ...state,
        eSignLoading: false,
        eSignSuccess: false,
        eSignResponse: [],
        eSignFail: true,
      };
    case TYPES.ESIGN_CLEAR_DATA:
      return {
        ...state,
        eSignLoading: null,
        eSignSuccess: null,
        eSignResponse: [],
        eSignFail: null,
      };
    case TYPES.ESTAMP_LOADING:
      return {
        ...state,
        eStampLoading: true,
        eStampSuccess: false,
        eStampResponse: [],
        eStampFail: false,
      };
    case TYPES.ESTAMP_SUCCESS:
      return {
        ...state,
        eStampLoading: false,
        eStampSuccess: true,
        eStampResponse: action.payload,
        eStampFail: false,
      };
    case TYPES.ESTAMP_FAIL:
      return {
        ...state,
        eStampLoading: false,
        eStampSuccess: false,
        eStampResponse: [],
        eStampFail: true,
      };
    case TYPES.ESTAMP_CLEAR_DATA:
      return {
        ...state,
        eStampLoading: null,
        eStampSuccess: null,
        eStampResponse: [],
        eStampFail: null,
      };

    case TYPES.ESIGN_CHECK_LOADING:
      return {
        ...state,
        eSignCheckLoading: true,
        eSignCheckSuccess: false,
        eSignCheckResponse: [],
        eSignCheckFail: false,
      };
    case TYPES.ESIGN_CHECK_SUCCESS:
      return {
        ...state,
        eSignCheckLoading: false,
        eSignCheckSuccess: true,
        eSignCheckResponse: action.payload,
        eSignCheckFail: false,
      };
    case TYPES.ESIGN_CHECK_FAIL:
      return {
        ...state,
        eSignCheckLoading: false,
        eSignCheckSuccess: false,
        eSignCheckResponse: [],
        eSignCheckFail: true,
      };
    case TYPES.ESIGN_CHECK_CLEAR_DATA:
      return {
        ...state,
        eSignCheckLoading: null,
        eSignCheckSuccess: null,
        eSignCheckResponse: [],
        eSignCheckFail: null,
      };
    case TYPES.ENACH_REGISTER_LOADING:
      return {
        ...state,
        eNachLoading: true,
        eNachSuccess: false,
        eNachResponse: [],
        eNachFail: false,
      };
    case TYPES.ENACH_REGISTER_SUCCESS:
      return {
        ...state,
        eNachLoading: false,
        eNachSuccess: true,
        eNachResponse: action.payload,
        eNachFail: false,
      };
    case TYPES.ENACH_REGISTER_FAIL:
      return {
        ...state,
        eNachLoading: false,
        eNachSuccess: false,
        eNachResponse: action.payload,
        eNachFail: true,
      };
    case TYPES.ENACH_REGISTER_CLEAR_DATA:
      return {
        ...state,
        eNachStatusLoading: null,
        eNachStatusSuccess: null,
        eNachStatusResponse: [],
        eNachStatusFail: null,
      };
    case TYPES.ENACH_STATUS_LOADING:
      return {
        ...state,
        eNachStatusLoading: true,
        eNachStatusSuccess: false,
        eNachStatusResponse: [],
        eNachStatusFail: false,
      };
    case TYPES.ENACH_STATUS_SUCCESS:
      return {
        ...state,
        eNachStatusLoading: false,
        eNachStatusSuccess: true,
        eNachStatusResponse: action.payload,
        eNachStatusFail: false,
      };
    case TYPES.ENACH_STATUS_FAIL:
      return {
        ...state,
        eNachStatusLoading: false,
        eNachStatusSuccess: false,
        eNachStatusResponse: [],
        eNachStatusFail: true,
      };
    case TYPES.ENACH_STATUS_CLEAR_DATA:
      return {
        ...state,
        eNachStatusLoading: null,
        eNachStatusSuccess: null,
        eNachStatusResponse: [],
        eNachStatusFail: null,
      };
    case TYPES.INITIATE_DEBIT_LOADING:
      return {
        ...state,
        initiateDebitLoading: true,
        initiateDebitSuccess: false,
        initiateDebitResponse: [],
        initiateDebitFail: false,
      };
    case TYPES.INITIATE_DEBIT_SUCCESS:
      return {
        ...state,
        initiateDebitLoading: false,
        initiateDebitSuccess: true,
        initiateDebitResponse: action.payload,
        initiateDebitFail: false,
      };
    case TYPES.INITIATE_DEBIT_FAIL:
      return {
        ...state,
        initiateDebitLoading: false,
        initiateDebitSuccess: false,
        initiateDebitResponse: [],
        initiateDebitFail: true,
      };
    case TYPES.INITIATE_DEBIT_CLEAR_DATA:
      return {
        ...state,
        initiateDebitLoading: null,
        initiateDebitSuccess: null,
        initiateDebitResponse: [],
        initiateDebitFail: null,
      };
    case TYPES.GENERATE_LOAN_APPLICATION_LOADING:
      return {
        ...state,
        generateLoanApplicationLoading: true,
        generateLoanApplicationSuccess: false,
        generateLoanApplicationResponse: [],
        generateLoanApplicationFail: false,
      };
    case TYPES.GENERATE_LOAN_APPLICATION_SUCCESS:
      return {
        ...state,
        generateLoanApplicationLoading: false,
        generateLoanApplicationSuccess: true,
        generateLoanApplicationResponse: action.payload,
        generateLoanApplicationFail: false,
      };
    case TYPES.GENERATE_LOAN_APPLICATION_FAIL:
      return {
        ...state,
        generateLoanApplicationLoading: false,
        generateLoanApplicationSuccess: false,
        generateLoanApplicationResponse: [],
        generateLoanApplicationFail: true,
      };
    case TYPES.GENERATE_LOAN_APPLICATION_FAIL:
      return {
        ...state,
        generateLoanApplicationLoading: false,
        generateLoanApplicationSuccess: false,
        generateLoanApplicationResponse: [],
        generateLoanApplicationFail: true,
      };
    case TYPES.GENERATE_LOAN_APPLICATION_CLEAR_DATA:
      return {
        ...state,
        generateLoanApplicationLoading: null,
        generateLoanApplicationSuccess: null,
        generateLoanApplicationResponse: [],
        generateLoanApplicationFail: null,
      };
    case TYPES.GET_SANCTION_LETTER_LOADING:
      return {
        ...state,
        getSanctionLetterLoading: true,
        getSanctionLetterSuccess: false,
        getSanctionLetterResponse: [],
        getSanctionLetterFail: false,
      };
    case TYPES.GET_SANCTION_LETTER_SUCCESS:
      return {
        ...state,
        getSanctionLetterLoading: false,
        getSanctionLetterSuccess: true,
        getSanctionLetterResponse: action.payload,
        getSanctionLetterFail: false,
      };
    case TYPES.GET_SANCTION_LETTER_FAIL:
      return {
        ...state,
        getSanctionLetterLoading: false,
        getSanctionLetterSuccess: false,
        getSanctionLetterResponse: [],
        getSanctionLetterFail: true,
      };
    case TYPES.GET_SANCTION_LETTER_CLEAR_DATA:
      return {
        ...state,
        getSanctionLetterLoading: null,
        getSanctionLetterSuccess: null,
        getSanctionLetterResponse: [],
        getSanctionLetterFail: null,
      };
    case TYPES.SAVE_SANCTION_LETTER_LOADING:
      return {
        ...state,
        saveSanctionLetterLoading: true,
        saveSanctionLetterSuccess: false,
        saveSanctionLetterResponse: [],
        saveSanctionLetterFail: false,
      };
    case TYPES.SAVE_SANCTION_LETTER_SUCCESS:
      return {
        ...state,
        saveSanctionLetterLoading: false,
        saveSanctionLetterSuccess: true,
        saveSanctionLetterResponse: action.payload,
        saveSanctionLetterFail: false,
      };
    case TYPES.SAVE_SANCTION_LETTER_FAIL:
      return {
        ...state,
        saveSanctionLetterLoading: false,
        saveSanctionLetterSuccess: false,
        saveSanctionLetterResponse: [],
        saveSanctionLetterFail: true,
      };
    case TYPES.SAVE_SANCTION_LETTER_CLEAR_DATA:
      return {
        ...state,
        saveSanctionLetterLoading: null,
        saveSanctionLetterSuccess: null,
        saveSanctionLetterResponse: [],
        saveSanctionLetterFail: null,
      };
    case TYPES.GET_SANCTION_STATUS_LOADING:
      return {
        ...state,
        getSanctionLetterFlagLoading: true,
        getSanctionLetterFlagSuccess: false,
        getSanctionLetterFlagResponse: [],
        getSanctionLetterFlagFail: false,
      };
    case TYPES.GET_SANCTION_STATUS_SUCCESS:
      return {
        ...state,
        getSanctionLetterFlagLoading: false,
        getSanctionLetterFlagSuccess: true,
        getSanctionLetterFlagResponse: action.payload,
        getSanctionLetterFlagFail: false,
      };
    case TYPES.GET_SANCTION_STATUS_FAIL:
      return {
        ...state,
        getSanctionLetterFlagLoading: false,
        getSanctionLetterFlagSuccess: false,
        getSanctionLetterFlagResponse: [],
        getSanctionLetterFlagFail: true,
      };
    case TYPES.GET_SANCTION_STATUS_CLEAR_DATA:
      return {
        ...state,
        getSanctionLetterFlagLoading: null,
        getSanctionLetterFlagSuccess: null,
        getSanctionLetterFlagResponse: [],
        getSanctionLetterFlagFail: null,
      };
    case TYPES.STATUS_LOADING:
      return {
        ...state,
        statusLoading: true,
        statusSuccess: false,
        statusResponse: [],
        statusFail: false,
      };
    case TYPES.STATUS_SUCCESS:
      return {
        ...state,
        statusLoading: false,
        statusSuccess: true,
        statusResponse: action.payload,
        statusFail: false,
      };
    case TYPES.STATUS_FAIL:
      return {
        ...state,
        statusLoading: false,
        statusSuccess: false,
        statusResponse: [],
        statusFail: true,
      };
    case TYPES.STATUS_CLEAR_DATA:
      return {
        ...state,
        statusLoading: null,
        statusSuccess: null,
        statusResponse: [],
        statusFail: null,
      };
    case TYPES.ESIGNAGREEMENT_LOADING:
      return {
        ...state,
        eSignAgreementLoading: true,
        eSignAgreementSuccess: false,
        eSignAgreementResponse: [],
        eSignAgreementFail: false,
      };
    case TYPES.ESIGNAGREEMENT_SUCCESS:
      return {
        ...state,
        eSignAgreementLoading: false,
        eSignAgreementSuccess: true,
        eSignAgreementResponse: action.payload,
        eSignAgreementFail: false,
      };
    case TYPES.ESIGNAGREEMENT_FAIL:
      return {
        ...state,
        eSignAgreementLoading: false,
        eSignAgreementSuccess: false,
        eSignAgreementResponse: [],
        eSignAgreementFail: true,
      };
    case TYPES.ESIGNAGREEMENT_CLEAR_DATA:
      return {
        ...state,
        eSignAgreementLoading: null,
        eSignAgreementSuccess: null,
        eSignAgreementResponse: [],
        eSignAgreementFail: null,
      };
    case TYPES.UPLOAD_DIALOG:
      return {
        ...state,
        uploadDialog: action.payload,
      };
    case TYPES.TENURE:
      return {
        ...state,
        tenure: action.payload,
      };
    case TYPES.ESIGNINITIATEAGREEMENT_LOADING:
      return {
        ...state,
        eSignInitiateAgreementLoading: true,
        eSignInitiateAgreementSuccess: false,
        eSignAgreementResponse: [],
        eSignInitiateAgreementFail: false,
      };
    case TYPES.ESIGNINITIATEAGREEMENT_SUCCESS:
      return {
        ...state,
        eSignInitiateAgreementLoading: false,
        eSignInitiateAgreementSuccess: true,
        eSignInitiateAgreementResponse: action.payload,
        eSignInitiateAgreementFail: false,
      };
    case TYPES.ESIGNINITIATEAGREEMENT_FAIL:
      return {
        ...state,
        eSignInitiateAgreementLoading: false,
        eSignInitiateAgreementSuccess: false,
        eSignInitiateAgreementResponse: [],
        eSignInitiateAgreementFail: true,
      };
    case TYPES.ESIGNINITIATEAGREEMENT_CLEAR_DATA:
      return {
        ...state,
        eSignInitiateAgreementLoading: null,
        eSignInitiateAgreementSuccess: null,
        eSignInitiateAgreementResponse: [],
        eSignInitiateAgreementFail: null,
      };
    case TYPES.LOAN_APPLICATION_DOWNLOAD_DATA:
      return {
        ...state,
        loanApplicationFormData: action.payload,
      };
    case TYPES.SANCTION_LETTER_DOWNLOAD_DATA:
      return {
        ...state,
        loanSanctionLetterData: action.payload,
      };
    case TYPES.LOAN_AGREEMENT_DOWNLOAD_DATA:
      return {
        ...state,
        loanAgreementLetterData: action.payload,
      };
   case TYPES.KFS_DOCUMENT_DOWNLOAD_DATA:
      return {
        ...state,
        kfsDocumentFormData: action.payload,
      };
    case TYPES.MARITALSTATUS:
      return {
        ...state,
        maritalStatus: action.payload,
      };
    case TYPES.BRANCHNAME:
      return {
        ...state,
        branchname: action.payload,
      };
    case TYPES.CHANNELNAME:
      return {
        ...state,
        channelname: action.payload,
      };
    case TYPES.BANKNAMES:
      return {
        ...state,
        bankNames: action.payload,
      };
    case TYPES.LOAN_AGREEMENT_START_DATE:
      return {
        ...state,
        loanAgreementStartDate: action.payload,
      };
    case TYPES.USER_QUERY_LOADING:
      return {
        ...state,
        userQueryLoading: true,
        userQuerySuccess: false,
        userQueryResponse: [],
        userQueryFail: false,
        userQueryError: false,
      };
    case TYPES.USER_QUERY_SUCCESS:
      return {
        ...state,
        userQueryLoading: false,
        userQuerySuccess: true,
        userQueryResponse: action.payload,
        userQueryFail: false,
        userQueryError: false,
      };
    case TYPES.USER_QUERY_FAIL:
      return {
        ...state,
        userQueryLoading: false,
        userQuerySuccess: false,
        userQueryResponse: [],
        userQueryFail: true,
        userQueryError: false,
      };
    case TYPES.USER_QUERY_NETWORK_ERROR:
      return {
        ...state,
        userQueryLoading: false,
        userQuerySuccess: false,
        userQueryResponse: [],
        userQueryFail: true,
        userQueryError: true,
      };
    case TYPES.USER_QUERY_CLEAR_DATA:
      return {
        ...state,
        userQueryLoading: null,
        userQuerySuccess: null,
        userQueryResponse: [],
        userQueryFail: null,
        userQueryError: null,
      };
    case TYPES.CHAT_MESSAGES_LOADING:
      return {
        ...state,
        chatMessagesLoading: true,
        chatMessagesSuccess: false,
        chatMessagesResponse: [],
        chatMessagesFail: false,
        chatMessagesError: false,
      };
    case TYPES.CHAT_MESSAGES_SUCCESS:
      return {
        ...state,
        chatMessagesLoading: false,
        chatMessagesSuccess: true,
        chatMessagesResponse: action.payload,
        chatMessagesFail: false,
        chatMessagesError: false,
      };
    case TYPES.CHAT_MESSAGES_FAIL:
      return {
        ...state,
        chatMessagesLoading: false,
        chatMessagesSuccess: false,
        chatMessagesResponse: [],
        chatMessagesFail: true,
        chatMessagesError: false,
      };
    case TYPES.CHAT_MESSAGES_NETWORK_ERROR:
      return {
        ...state,
        chatMessagesLoading: false,
        chatMessagesSuccess: false,
        chatMessagesResponse: [],
        chatMessagesFail: false,
        chatMessagesError: true,
      };
    case TYPES.CHAT_MESSAGES_CLEAR_DATA:
      return {
        ...state,
        chatMessagesLoading: null,
        chatMessagesSuccess: null,
        chatMessagesResponse: [],
        chatMessagesFail: null,
        chatMessagesError: null,
      };
    case TYPES.VERIFY_OTP_MOBILE_LOADING:
      return {
        ...state,
        verifyOTPMobileLoading: true,
        verifyOTPMobileSuccess: [],
        verifyOTPMobileFail: false,
      };
    case TYPES.VERIFY_OTP_MOBILE_SUCCESS:
      return {
        ...state,
        verifyOTPMobileLoading: false,
        verifyOTPMobileSuccess: action.payload,
        verifyOTPMobileFail: false,
      };
    case TYPES.VERIFY_OTP_MOBILE_FAIL:
      return {
        ...state,
        verifyOTPMobileLoading: false,
        verifyOTPMobileSuccess: [],
        verifyOTPMobileFail: true,
      };
    case TYPES.VERIFY_PAN_SUCCESS:
      return {
        ...state,
        verifyPanSuccess: true,
        verifyPanFail: false,
        panDetails: action.payload,
      };
    case TYPES.VERIFY_PAN_FAIL:
      return {
        ...state,
        verifyPanFail: true,
        verifyPanSuccess: false,
        panDetails: {},
      };
    case TYPES.VERIFY_PAN_CLEAR:
      return {
        ...state,
        verifyPanSuccess: false,
        verifyPanFail: false,
        panDetails: {},
      };
    case TYPES.VERIFY_OTP_EMAIL_LOADING:
      return {
        ...state,
        verifyOTPEmailLoading: true,
        verifyOTPEmailSuccess: [],
        verifyOTPEmailFail: false,
      };
    case TYPES.VERIFY_OTP_EMAIL_SUCCESS:
      return {
        ...state,
        verifyOTPEmailLoading: false,
        verifyOTPEmailSuccess: action.payload,
        verifyOTPEmailFail: false,
      };
    case TYPES.VERIFY_OTP_EMAIL_FAIL:
      return {
        ...state,
        verifyOTPEmailLoading: false,
        verifyOTPEmailSuccess: [],
        verifyOTPEmailFail: true,
      };
    case TYPES.NAVIGATE_FROM_DIGITAL_LOANS:
      return {
        ...state,
        navigateFromDigitalLoans: true,
      };
    case TYPES.NAVIGATE_FROM_LOGIN:
      return {
        ...state,
        navigateFromDigitalLoans: false,
      };
    case TYPES.AADHAR_DRAWER_COBORROWER:
      return {
        ...state,
        aadharDrawerCoborrower: action.payload,
      };
    case TYPES.VERIFY_OTP_COBORROWER_SUCCESS:
      return {
        ...state,
        verifyOTPCoborrowerLoading: false,
        verifyOTPCoborrowerSuccess: action.payload,
        verifyOTPCoborrowerFail: false,
      };
    case TYPES.VERIFY_OTP_COBORROWER_FAIL:
      return {
        ...state,
        verifyOTPCoborrowerLoading: false,
        verifyOTPCoborrowerSuccess: [],
        verifyOTPCoborrowerFail: true,
      };
    case TYPES.VERIFY_OTP_COBORROWER_LOADING:
      return {
        ...state,
        verifyOTPCoborrowerLoading: true,
        verifyOTPCoborrowerSuccess: [],
        verifyOTPCoborrowerFail: false,
      };
    case TYPES.VERIFY_OTP_COBORROWER_CLEAR_DATA:
      return {
        ...state,
        verifyOTPCoborrowerLoading: false,
        verifyOTPCoborrowerSuccess: [],
        verifyOTPCoborrowerFail: false,
      };
    case TYPES.VERIFY_PAN_SUCCESS_COBORROWER:
      return {
        ...state,
        verifyPanSuccessCoborrower: true,
        verifyPanFailCoborrower: false,
        panDetailsCoborrower: action.payload,
      };
    case TYPES.VERIFY_PAN_FAIL_COBORROWER:
      return {
        ...state,
        verifyPanFailCoborrower: true,
        verifyPanSuccessCoborrower: false,
        panDetailsCoborrower: [],
      };
    case TYPES.VERIFY_PAN_CLEAR_COBORROWER:
      return {
        ...state,
        verifyPanSuccessCoborrower: false,
        verifyPanFailCoborrower: false,
        panDetailsCoborrower: [],
      };
    case TYPES.VERIFY_OTP_EMAIL_COBORROWER_LOADING:
      return {
        ...state,
        verifyOTPEmailCoborrowerLoading: true,
        verifyOTPEmailCoborrowerSuccess: [],
        verifyOTPEmailCoborrowerFail: false,
      };
    case TYPES.VERIFY_OTP_EMAIL_COBORROWER_SUCCESS:
      return {
        ...state,
        verifyOTPEmailCoborrowerLoading: false,
        verifyOTPEmailCoborrowerSuccess: action.payload,
        verifyOTPEmailCoborrowerFail: false,
      };
    case TYPES.VERIFY_OTP_EMAIL_COBORROWER_FAIL:
      return {
        ...state,
        verifyOTPEmailCoborrowerLoading: false,
        verifyOTPEmailCoborrowerSuccess: [],
        verifyOTPEmailCoborrowerFail: true,
      };
    case TYPES.SEND_KYC_SUCCESS:
      return {
        ...state,
        verifykycSuccess: action.payload,
        verifykycFail: null,
      };
    case TYPES.SEND_KYC_FAIL:
      return {
        ...state,
        verifykycSuccess: {},
        verifykycFail: true,
        };
    case TYPES.QUICK_APPLY_DETAILS_SUCCESS:
      return {
        ...state,
        QuickApplyDetailsSuccess: action.payload,
        QuickApplyDetailsFail: false,
        QuickApplyDetailsLoading: false
      };
    case TYPES.QUICK_APPLY_DETAILS_FAIL:
      return {
        ...state,
        QuickApplyDetailsSuccess: [],
        QuickApplyDetailsFail: true,
        QuickApplyDetailsLoading: false
      };
    case TYPES.QUICK_APPLY_DETAILS_LOADING:
      return {
        ...state,
        QuickApplyDetailsSuccess: [],
        QuickApplyDetailsFail: false,
        QuickApplyDetailsLoading: true
      };
    case TYPES.QUICK_APPLY_SUCCESS:
      return {
        ...state,
        QuickApplySuccess: action.payload,
        QuickApplyFail: false,
        QuickApplyLoading: false
      };
    case TYPES.QUICK_APPLY_FAIL:
      return {
        ...state,
        QuickApplySuccess: [],
        QuickApplyFail: true,
        QuickApplyLoading: false
      };
    case TYPES.QUICK_APPLY_LOADING:
      return {
        ...state,
        QuickApplySuccess: [],
        QuickApplyFail: false,
        QuickApplyLoading: true
      };
    case TYPES.VERIFY_OTP_MOBILE_DIGITAL_LOANS_LOADING:
      return {
        ...state,
        verifyOTPMobileDigitalLoansLoading: true,
        verifyOTPMobileDigitalLoansSuccess: [],
        verifyOTPMobileDigitalLoansFail: false,
      };
    case TYPES.VERIFY_OTP_MOBILE_DIGITAL_LOANS_SUCCESS:
      return {
        ...state,
        verifyOTPMobileDigitalLoansLoading: false,
        verifyOTPMobileDigitalLoansSuccess: action.payload,
        verifyOTPMobileDigitalLoansFail: false,
      };
    case TYPES.VERIFY_OTP_MOBILE_DIGITAL_LOANS_FAIL:
      return {
        ...state,
        verifyOTPMobileDigitalLoansLoading: false,
        verifyOTPMobileDigitalLoansSuccess: [],
        verifyOTPMobileDigitalLoansFail: true,
      };
    case TYPES.KFS_DOCUMENT_LOADING:
        return {
          ...state,
        KfsDocumentLoading: true,
        KfsDocumentSuccess: false,
        KfsDocumentResponse: [],
        KfsDocumentFail: false,
        };
    case TYPES.KFS_DOCUMENT_SUCCESS:
        return {
          ...state,
        KfsDocumentLoading: false,
        KfsDocumentSuccess: true,
        KfsDocumentResponse: action.payload,
        KfsDocumentFail: false,
        };
    case TYPES.KFS_DOCUMENT_FAIL:
        return {
          ...state,
        KfsDocumentLoading: false,
        KfsDocumentSuccess: false,
        KfsDocumentResponse: [],
        KfsDocumentFail: true,
        };
    case TYPES.KFS_DOCUMENT_CLEAR_DATA:
        return {
          ...state,
        KfsDocumentLoading: null,
        KfsDocumentSuccess: null,
        KfsDocumentResponse: [],
        KfsDocumentFail: null,
        };

    default:
      return {
        ...state,
      };
  }
}
