import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Grid, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  AadharDrawer,
  InitiateSession,
  GenOTP,
  VerifyOTPCoborrower,
  GenOTPClearData,
  InitiateOTPClearData,
  aadharDrawerCoborrower1,
  VerifyOTPClearData,
} from "../BasicDetailsActions/BasicDetailsActions";
import ImageTextField from "./ImageTextField";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

export default function AadharVerificationCoborrower(props) {
  const dispatch = useDispatch();
  const [sessionID, setSessionID] = React.useState();
  const [captcha, setCaptcha] = React.useState();
  //----------------------------------------------------------------
  const [progress, setProgress] = React.useState(5);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 5
      );
    }, 3000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  //-----------------------------------------------------------------
  useEffect(() => {
    dispatch(InitiateSession());
  }, []);

  const initiateSessionAadharSuccess = useSelector(
    (state) => state.BasicDetailsReducers.initiateSessionAadharSuccess
  );

  const initiateSessionAadharLoading = useSelector(
    (state) => state.BasicDetailsReducers.initiateSessionAadharLoading
  );

  const initiateSessionAadharFail = useSelector(
    (state) => state.BasicDetailsReducers.initiateSessionAadharFail
  );

  useEffect(() => {
    handleParse();
  }, [initiateSessionAadharSuccess]);

  const handleParse = () => {
    if (Object.keys(initiateSessionAadharSuccess).length) {
      const data1 = initiateSessionAadharSuccess.data;
      const obj = JSON.parse(data1);
      setSessionID(obj.data.session_id);
      setCaptcha(obj.data.captcha);
    }
  };

  //------------------------------------------------------------------------------------

  const data = { ...props };
  const aadharDrawerCoborrower = useSelector(
    (state) => state.BasicDetailsReducers.aadharDrawerCoborrower
  );
  const handleClose = (e) => {
    setOtpMessage("");
    setOtVerifypMessage("");
    dispatch(aadharDrawerCoborrower1(!aadharDrawerCoborrower));
    dispatch(GenOTPClearData());
    dispatch(InitiateOTPClearData());
    if (verifyOTPCoborrowerSuccess?.data?.code == 400) {
      dispatch(VerifyOTPClearData());
    }
  };

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  //-----------------------------------------------------------
  const [confirmnCaptcha, setConfirmCaptcha] = React.useState();
  const [otpMessage, setOtpMessage] = React.useState();
  const [otVerifypMessage, setOtVerifypMessage] = React.useState();
  const [regenerateCaptcha, setregenerateCaptcha] = React.useState(false);
  //-----------------------------------------------------------
  const handleConfirmCaptcha = (e) => {
    setConfirmCaptcha(e);
  };

  const verifyCaptcha = () => {
    setOtpMessage("");
    dispatch(GenOTP(data.selectedAadhar, confirmnCaptcha, sessionID));
  };
  //--------------------------------------------------------------

  //--------------------------------------------------------------
  const [otp, setOTP] = React.useState();

  const handleOTP = (e) => {
    setOTP(e);
  };

  const handleConfirmOTP = () => {
    setOtVerifypMessage("");
    dispatch(VerifyOTPCoborrower(otp, sessionID, data.isCoBorrower));
  };

  //------------------------------------------------------------
  const genOTPLoading = useSelector(
    (state) => state.BasicDetailsReducers.genOTPLoading
  );

  const genOTPSuccess = useSelector(
    (state) => state.BasicDetailsReducers.genOTPSuccess
  );

  const genOTPFail = useSelector(
    (state) => state.BasicDetailsReducers.genOTPFail
  );

  useEffect(() => {
    handleParseOTP();
  }, [genOTPSuccess]);

  //--------------------------------------------------------------

  const handleParseOTP = () => {
    if (Object.keys(genOTPSuccess).length) {
      const data1 = genOTPSuccess.data;
      const obj = JSON.parse(data1);
      setOtpMessage(obj.message);
    }
  };

  //-------------------------------------------------------------
  const verifyOTPLoading = useSelector(
    (state) => state.BasicDetailsReducers.verifyOTPCoborrowerLoading
  );

  const verifyOTPCoborrowerSuccess = useSelector(
    (state) => state.BasicDetailsReducers.verifyOTPCoborrowerSuccess
  );

  useEffect(() => {
    handleVerifyOTP();
  }, [verifyOTPCoborrowerSuccess]);

  useEffect(() => {
    if (verifyOTPCoborrowerSuccess?.data?.code == 200) {
      handleClose();
    }
  }, [verifyOTPCoborrowerSuccess]);

  //--------------------------------------------------------------

  const handleVerifyOTP = () => {
    if (Object.keys(verifyOTPCoborrowerSuccess).length) {
      const data1 = verifyOTPCoborrowerSuccess.data;
      const obj = data1;
      setOtVerifypMessage(obj.message);
    }
  };
  const regenerateOTP = () => {
    dispatch(InitiateSession());
    setConfirmCaptcha("");
    setOtpMessage("");
    setOtVerifypMessage("");
    setOTP("");
    setregenerateCaptcha(true);
  };
  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={aadharDrawerCoborrower}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{ fontweight: 900 }}
        >
          <Typography sx={{ fontweight: 900 }}>
            Aadhaar Verification{" "}
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent>
          <Grid container sx={{ pl: 4, pr: 4, mt: 4 }}>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center", pt: 2 }}
            >
              {initiateSessionAadharLoading ? (
                <>
                  <CircularProgressWithLabel value={progress} />
                </>
              ) : null}
            </Grid>
            {initiateSessionAadharFail ? (
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Typography sx={{ fontSize: 14, color: "#FF0000", mb: 2 }}>
                  * Sorry! Session has expired. Please try again.
                </Typography>
              </Grid>
            ) : null}
            <Grid item xs={12} sx={{ borderBottom: "2px solid #BEBEBE" }}>
              <Typography sx={{ fontSize: 14 }}>Generate OTP</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1} sx={{ mt: 2 }}>
                <Grid item xs={12} md={3}>
                  <TextField
                    label="Aadhaar"
                    fullWidth
                    value={data.selectedAadhar}
                  />
                </Grid>
                {!initiateSessionAadharLoading ? (
                  <>
                    <Grid item xs={12} md={3}>
                      <ImageTextField base64Image={captcha} />
                    </Grid>
                  </>
                ) : null}

                <Grid item xs={12} md={3}>
                  <TextField
                    id="confirmcaptcha"
                    fullWidth
                    name="confirmcaptcha"
                    value={confirmnCaptcha}
                    onChange={(e) => handleConfirmCaptcha(e.target.value)}
                    label="Confirm Captcha"
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <Button
                    variant="outlined"
                    fullWidth
                    disabled={!confirmnCaptcha || !data.selectedAadhar}
                    onClick={verifyCaptcha}
                    sx={{ textTransform: "capitalize", p: 1.8 }}
                  >
                    Generate OTP
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  style={{
                    width: "100%",
                  }}
                >
                  <Button
                    sx={{ textTransform: "none" }}
                    variant="contained"
                    className="download-button"
                    onClick={regenerateOTP}
                  >
                    Regenerate Captcha
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ color: "#148F5B", mt: 1 }}>
                    * {otpMessage}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center", pt: 2 }}
                >
                  {genOTPLoading ? (
                    <>
                      <CircularProgressWithLabel value={progress} />
                    </>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container sx={{ pl: 4, pr: 4, mt: 4 }}>
            <Grid item xs={12} sx={{ borderBottom: "2px solid #BEBEBE" }}>
              <Typography sx={{ fontSize: 14 }}>Verify OTP</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1} sx={{ mt: 2 }}>
                <Grid item xs={12} md={3}>
                  <TextField
                    label="OTP"
                    id="otp"
                    fullWidth
                    name="otp"
                    value={otp}
                    onChange={(e) => handleOTP(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Button
                    variant="outlined"
                    onClick={handleConfirmOTP}
                    disabled={
                      initiateSessionAadharFail ||
                      genOTPFail == null ||
                      genOTPFail == true
                    }
                    fullWidth
                    sx={{ textTransform: "capitalize", p: 1.8 }}
                  >
                    Verify OTP
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ color: "#148F5B", mt: 1 }}>
                    * {otVerifypMessage}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center", pt: 2 }}
                >
                  {verifyOTPLoading ? (
                    <>
                      <CircularProgressWithLabel value={progress} />
                    </>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ mt: 3, mb: 2 }}>
          <Button
            variant="outlined"
            autoFocus
            sx={{ textTransform: "capitalize" }}
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
