import React from "react";
import loader from "../../Assets/loader.png";
import "./customLoader.css";
import { Typography } from "@mui/material";
const CustomLoader = () => {
  return (
    <div className="loadingbackground">
      <div className="loader">
        <img src={loader} alt="Corestrat Logo" />
      </div>
      <Typography
        component="div"
        style={{
          textAlign: "center",
          color: "#148f5b",
        }}
      >
        <b>We are setting up your account..</b>
      </Typography>
    </div>
  );
};

export default CustomLoader;
